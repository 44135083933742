import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../_core/services/date.service';

@Pipe({
  name: 'elapsedTimePipe',
  pure: false,
})
export class ElapsedTimePipe implements PipeTransform {

  private static secondInMils = 1000;
  private static minuteInMils = 60 * ElapsedTimePipe.secondInMils;
  private static hourInMils = 60 * ElapsedTimePipe.minuteInMils;
  private static dayInMils = 24 * ElapsedTimePipe.hourInMils;
  private static monthInMils = 30 * ElapsedTimePipe.dayInMils;
  private static yearInMils = 12 * ElapsedTimePipe.monthInMils;

  private dateExportMapper: { [key: string]: (value) => string } = {};

  constructor(
    private dateService: DateService
  ) {
    this.dateExportMapper[`${TimePeriod.second}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.secondInMils);
      return this.getDisplayText(flooredVal, 's');
    };
    this.dateExportMapper[`${TimePeriod.minute}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.minuteInMils);
      return this.getDisplayText(flooredVal, 'm');
    };
    this.dateExportMapper[`${TimePeriod.hour}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.hourInMils);
      return this.getDisplayText(flooredVal, 'h');
    };
    this.dateExportMapper[`${TimePeriod.day}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.dayInMils);
      return this.getDisplayText(flooredVal, 'day');
    };
    this.dateExportMapper[`${TimePeriod.month}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.monthInMils);
      return this.getDisplayText(flooredVal, 'month');
    };
    this.dateExportMapper[`${TimePeriod.year}`] = (value: number) => {
      const flooredVal = Math.floor(value / ElapsedTimePipe.yearInMils);
      return this.getDisplayText(flooredVal, 'year');
    };
  }

  getDisplayText(value: number, baseWord: string) {
    if (baseWord === 's' && value < 10) {
      return 'just now';
    }
    return `${value}${baseWord}`;
  }

  transform(date: Date): string {
    const elapsedTime = Math.max(this.dateService.now - date.valueOf(), 0);
    const displayPeriod = this.getPeriodToDisplay(elapsedTime);
    return this.dateExportMapper[`${displayPeriod}`](elapsedTime);
  }

  private getPeriodToDisplay(value: number): TimePeriod {
    if (value > ElapsedTimePipe.yearInMils) {
      return TimePeriod.year;
    }
    if (value > ElapsedTimePipe.monthInMils) {
      return TimePeriod.month;
    }
    if (value > ElapsedTimePipe.dayInMils) {
      return TimePeriod.day;
    }
    if (value > ElapsedTimePipe.hourInMils) {
      return TimePeriod.hour;
    }
    if (value > ElapsedTimePipe.minuteInMils) {
      return TimePeriod.minute;
    }
    return TimePeriod.second;
  }
}

export enum TimePeriod {
  second,
  minute,
  hour,
  day,
  month,
  year
}
