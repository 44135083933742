import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EmojiService } from '../../_core/services/emoji.service';
import { environment } from '../../../environments/environment';
import { ERROR_CODE } from '../../error/error.component';
import { AliveSessionService } from '../../_core/services/alive-session.service';
import { BrandingService } from '../../_core/services/branding.service';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { RoomService } from '../../_core/services/room.service';
import { SmiliesService } from '../../_core/services/smilies.service';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { PinboardMessagesService } from '../panel-pinboard-messages/services/pinboard-messages.service';
import { SocketStatus } from './../../_core/services/socket.service/models/socket-status';
// import * as LogRocket from 'logrocket';

@Injectable({ providedIn: 'root' })
export class ChatResolver implements Resolve<void> {

  reconnect = false;

  constructor(
    private router: Router,
    private smiliesService: SmiliesService,
    private socketService: SocketService,
    private profileService: ProfileService,
    private teamService: TeamService,
    private messageService: MessageService,
    private aliveService: AliveSessionService,
    private pinboardMessagesService: PinboardMessagesService,
    private panelsService: PanelsService,
    private roomService: RoomService,
    private brandingService: BrandingService,
    private emojiService: EmojiService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.log(route.children[0].paramMap.get('team')); // team parameter exists in child 0 of the route the resolver runs for
    return this.asyncResolve(route);
  }

  private async asyncResolve(route: ActivatedRouteSnapshot) {
    this.teamService.setup();

    // MANAGE DOMAIN REDIRECTS
    const isCustom = this.brandingService.isCustomDomain();
    // if not in echofin.com domain, keep the custom domain, get teamname and switch to it
    if (isCustom) {
      // const team = this.teamService.teams.find(t => t.name === this.brandingService.team.name);
      await this.brandingService.loadBranding();
      await this.teamService.switchTeam(this.brandingService.team.id);
      // return;
    }

    this.smiliesService.setup();
    this.panelsService.setup();
    this.profileService.setup();
    this.messageService.setup();
    this.pinboardMessagesService.setup();
    this.roomService.setup();
    this.aliveService.setup();
    this.emojiService.setup();

    this.socketService.status$.subscribe(async (status: SocketStatus) => {
      if (status === SocketStatus.Connected && this.reconnect) {
        await this.loadMe();
        await this.loadTeams();
        await this.reloadTeam();
        await this.loadDirects();
      } else if (status === SocketStatus.Connected) {
        this.reconnect = true;
      }
    });

    // console.log('load me from resolve');
    await this.loadMe(route);
    await this.loadTeams();
    await this.loadTeam(route);
    await this.loadDirects();

    await this.socketService.connect();
  }

  async loadMe(route?: ActivatedRouteSnapshot) {
    this.log('Loading me$');
    await this.profileService
      .loadMe()
      .then((uid) => {
        this.profileService.setupSentry();
        // LogRocket.identify(uid);
      })
      .catch(err => {
        if (err.status === 401) {
          if (route.children[0].paramMap.get('team')) {
            window.location.href = `https://${this.brandingService.team.name}.${environment.config.domain}/signup`;
          } else {
            this.router.navigate(['/signup']);
          }
          return;
        }
        this.router.navigate(['/error'], {
          queryParams: {
            err: ERROR_CODE.OFFLINE
          }
        });
      });
  }

  private async loadTeam(route: ActivatedRouteSnapshot) {
    const { team, correctTeamName } = this.teamService.getTeamToLoad(route.children[0].paramMap.get('team'));

    if (team && correctTeamName) {
      this.log(`Loading team ${team.id}`);
      await this.teamService.switchTeam(team.id);
    }
  }

  private async reloadTeam() {
    if (this.teamService.activeTeamId || (this.teamService.teams && this.teamService.teams.length)) {
      const tid = this.teamService.activeTeamId || this.teamService.teams[0].id;
      this.log(`Loading team ${tid}`);
      await this.teamService.switchTeam(tid);
    }
  }

  private async loadTeams() {
    this.log('Loading teams');
    await this.teamService.loadTeams();
  }

  private async loadDirects() {
    this.log('Loading directs');
    await this.roomService.loadDirects();
  }

  private log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[CHAT-RESOLVER]', 'color:#33DD33', ...arg);
    }
  }
}
