<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Search Filters</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="searchFilterForm" #f="ngForm" (ngSubmit)="applyFilters()">
    <div class="modal-body">
      <div class="form-group">
        <label for="mlink text-muted">Only from user:</label>
        <div class="input-group">
          <input type="text" formControlName="from" class="form-control" placeholder="@user" [ngbTypeahead]="userTypeahead" />
        </div>
      </div>
      <div class="form-group">
        <label for="mlink text-muted">In these chatrooms:</label>
        <div class="input-group">
            <input type="text" class="form-control" placeholder="#chatroom" readonly [value]="chatroomText" />
            <div class="btn-group" ngbDropdown role="group" placement="bottom-right">
              <button class="btn form-control dropdown-toggle-split" style="height: 38px;" ngbDropdownToggle (click)="$event.preventDefault()"></button>
              <div class="dropdown-menu" ngbDropdownMenu style="width: 200px">
                  <div class="form-group" style="margin-bottom: 0px;">

                    <div *ngFor="let chatroom of chatrooms; let i = index" class="px-3 py-2">
                      <div class="custom-control custom-checkbox" formArrayName="chatrooms">
                        <input [formControlName]="i" type="checkbox" class="custom-control-input" id="signal_filter_room_{{i}}" (change)="updateChatrooms();">
                        <label class="custom-control-label" for="signal_filter_room_{{i}}">#{{chatrooms[i].label}}</label>
                      </div>
                    </div>

                  </div>
              </div>
            </div>
        </div>
      </div>
      <div class="form-group">
        <label for="mlink text-muted">Message Type:</label>
        <div class="input-group">
            <input type="text" class="form-control" placeholder="text, signal etc." readonly [value]="selectedMessageTypes" />
            <div class="btn-group" ngbDropdown role="group" placement="bottom-right">
              <button class="btn form-control dropdown-toggle-split" style="height: 38px;" ngbDropdownToggle (click)="$event.preventDefault()"></button>
              <div class="dropdown-menu" ngbDropdownMenu style="width: 200px">
                  <div class="form-group" style="margin-bottom: 0px;">

                    <div *ngFor="let messageType of formMessageTypes; let s = index" class="px-3 py-2">
                      <div class="custom-control custom-checkbox" formArrayName="messageTypes">
                        <input [formControlName]="s" type="checkbox" class="custom-control-input" id="search_filter_message_type_{{s}}" (change)="updateMessageTypes();">
                        <label class="custom-control-label" for="search_filter_message_type_{{s}}">{{formMessageTypes[s]}}</label>
                      </div>
                    </div>

                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-block text-right">
        <button class="btn mr-4 text-grey" (click)="$event.preventDefault(); resetForm()">Reset filters</button>
        <button class="btn btn-success">Apply filters</button>
      </div>
    </div>
  </form>
</div>