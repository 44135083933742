import { Injectable } from '@angular/core';
import { SearchMessagesServiceApi } from '@echofin/libraries';
import { MessageService, MESSAGES_PER_PAGE } from '../../../_core/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Message } from '../../../_shared/models/commons/message';
import { MessageType } from '../../../_shared/models/message/message-type';
import { MessageHelpers } from 'app/_shared/helpers/message-helpers';

@Injectable({
  providedIn: 'root'
})
export class SearchMessagesService {

  searchPanelState: { [key: string]: { text: string, from: string, chatroomIds: string[], messageTypes: MessageType[] } } = {};

  constructor(
    private messageService: MessageService,
    private searchMessagesServiceApi: SearchMessagesServiceApi,
    private toastr: ToastrService,
  ) {
  }

  setSearchPanelState(teamId: string, text: string, from: string, chatroomIds: string[], messageTypes: MessageType[]) {
    this.searchPanelState[teamId] = { text: text, from: from, chatroomIds: chatroomIds, messageTypes: messageTypes };
  }

  async loadSearchMessages(panelId: string, teamId: string, timestamp?: string) {
    if (timestamp && !this.messageService.hasMoreMessages[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }

    const messages = await this.load(
      teamId,
      timestamp,
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessages[panelId] = false;
    } else {
      this.messageService.hasMoreMessages[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeSearchMessages(messages);
  }

  async load(teamId: string, timestamp: string) {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const messages = await this.searchMessagesServiceApi
      .GetSearchMessages({
        Text: this.searchPanelState[teamId].text,
        TeamId: teamId,
        Timestamp: timestampString,
        From: this.searchPanelState[teamId].from,
        ChatroomIds: this.searchPanelState[teamId].chatroomIds,
        MessageTypes: this.searchPanelState[teamId].messageTypes,
        Limit: MESSAGES_PER_PAGE
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to load messages');
      });

    if (!messages) return;

    const mappedMessages = messages.messages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedMessages;
  }

  async loadSearchMessagesAfter(panelId: string, teamId: string, timestamp?: string, includeTimestamp?: boolean) {
    if (timestamp && !this.messageService.hasMoreMessagesBelow[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }

    const messages = await this.loadAfter(
      teamId,
      timestamp,
      includeTimestamp
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessagesBelow[panelId] = false;
    } else {
      this.messageService.hasMoreMessagesBelow[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeSearchMessages(messages);
  }

  async loadAfter(teamId: string, timestamp: string, includeTimestamp: boolean) {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const messages = await this.searchMessagesServiceApi
      .GetSearchMessagesAfter({
        Text: this.searchPanelState[teamId].text,
        TeamId: teamId,
        Timestamp: timestampString,
        IncludeTimestamp: includeTimestamp,
        From: this.searchPanelState[teamId].from,
        ChatroomIds: this.searchPanelState[teamId].chatroomIds,
        MessageTypes: this.searchPanelState[teamId].messageTypes,
        Limit: MESSAGES_PER_PAGE
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to load messages');
      });

    if (!messages) return;

    const mappedMessages = messages.messages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedMessages;
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[SEARCH PANEL SERVICE]', 'color:#FF9800', ...arg);
    }
  }
}
