import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef, OnChanges, SimpleChanges, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { BaseComponent } from '../../base-component';
import { ReactionModel } from '@echofin/libraries/api/message/models/reaction-model';
import { MessageService } from '../../../_core/services/message.service';
import { ReplyModel } from '@echofin/libraries/api/message/models/reply-model';
import { TimelineType } from '../models/timeline-type.enum';
import { HelperService } from '../../../_core/services/helper.service';
import { ProfileService } from '../../../_core/services/profile.service';

@Component({
  selector: 'app-message-footer',
  templateUrl: './message-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFooterComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() reactions: ReactionModel[];
  @Input() messageId: string;
  @Input() panelId: string;
  @Input() reply: ReplyModel;
  @Input() timelineType: TimelineType;
  @Input() msgVersion: number;
  @Input() mouseoverReaction: string;

  @ViewChildren('reactions') reactionElements: QueryList<ElementRef>;

  tooltip: ElementRef;
  tooltipArrow: ElementRef;

  groupedReactions: any;
  groupedReactionsKeys = [];

  isReplyPanel = false;

  menuHovering = false;
  replyPanelId: string;

  constructor(
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private profileService: ProfileService
  ) {
    super();

  }

  ngOnInit(): void {
    this.isReplyPanel = this.timelineType === TimelineType.RepliedMessages;
    this.groupedReactions = this.messageService.calculateReactions(this.reactions, this.profileService.me.username);
    this.groupedReactionsKeys = Object.keys(this.groupedReactions);
    this.setReplyPanelId();

    this.subscribe(this.messageService.messageReceived$, (message) => {
      if (message.id === this.messageId) {
        this.calculateView(message.reactions, message.reply);
        this.cdr.detectChanges();
      }
    });

    this.subscribe(this.messageService.messageReactionsChanged$, e => {
      if (e.id === this.messageId) {
        this.calculateView(e.reactions, this.reply);
        this.recalculateTooltip(this.mouseoverReaction);
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['reactions']) || (changes['reply'])) {
      this.calculateView(this.reactions, this.reply);
    }
    if (changes['mouseoverReaction'] && !changes['mouseoverReaction'].firstChange) {
      this.recalculateTooltip(changes['mouseoverReaction'].currentValue);
    }
  }

  private recalculateTooltip(emoji: string) {
    if (this.tooltip) {
      this.removeTooltip();
    }
    if (emoji?.length && !!this.groupedReactions[emoji]) {
      let rest = '';
      let usernames = [...this.groupedReactions[emoji]].filter(u => u !== this.profileService.me.username);
      if (usernames.length !== this.groupedReactions[emoji].length) {
        usernames = ['You', ...usernames];
      }
      if (usernames.length === 11) {
        rest = ' and one other';
      } else if (usernames.length > 11) {
        rest = ` and ${usernames.length - 10} others`;
      }
      let tooltipContent = `${usernames.slice(0, 10).join(', ')}${rest} reacted with ${emoji}`;
      this.cdr.detectChanges();
      this.appendTooltip(tooltipContent);
    }
  }

  calculateView(reactions, reply) {
    if (!!reply) {
      this.reply = { ...reply };
    } else {
      this.reply = null;
    }
    this.groupedReactions = this.messageService.calculateReactions(reactions, this.profileService.me.username);
    this.groupedReactionsKeys = Object.keys(this.groupedReactions);
    this.setReplyPanelId();
  }

  setReplyPanelId() {
    this.replyPanelId = !this.reply || this.reply.parent ? this.messageId : this.reply.parentId;;
  }

  colorizeReply(replyID) {
    // tslint:disable-next-line:prefer-template
    return '#' + HelperService.intToRGB(replyID);
  }

  appendTooltip(content: string) {
    const r = this.reactionElements.find(c => c.nativeElement.classList.contains('hovering'));
    const elementRect = (r.nativeElement as HTMLElement).getBoundingClientRect();

    this.tooltip = this.renderer.createElement('div');
    this.renderer.setProperty(this.tooltip, 'innerHTML', content);
    this.renderer.addClass(this.tooltip, 'reaction-userlist');
    this.renderer.appendChild(document.body, this.tooltip);

    this.tooltipArrow = this.renderer.createElement('div');
    this.renderer.appendChild(document.body, this.tooltipArrow);

    let tooltipRect = (this.tooltip as unknown as HTMLElement).getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    let up = false;
    let center = false;
    let left = false;
    let right = false;

    if (bodyRect.width < elementRect.right) {
      // an h deksia pleyra tou reaction feygei ektos o8onhs
      left = true;
      this.renderer.addClass(this.tooltipArrow, 'reaction-userlist-arrow-right');
      this.renderer.setStyle(this.tooltipArrow, 'left', `${elementRect.x - 6}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${elementRect.x - 6 - tooltipRect.width}px`);
    } else if (elementRect.x < 0) {
      // an h aristerh pleyra tou reaction feygei ektos o8onhs
      right = true;
      this.renderer.addClass(this.tooltipArrow, 'reaction-userlist-arrow-left');
      this.renderer.setStyle(this.tooltipArrow, 'left', `${elementRect.right}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${elementRect.right + 6}px`);
    } else {
      center = true;
      const calculatedLeft = elementRect.x + elementRect.width / 2 - tooltipRect.width / 2;
      this.renderer.setStyle(this.tooltipArrow, 'left', `${elementRect.x + elementRect.width / 2 - 3}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${calculatedLeft > 0 ? calculatedLeft : 0}px`);
    }

    tooltipRect = (this.tooltip as unknown as HTMLElement).getBoundingClientRect();
    if (elementRect.y > tooltipRect.height + 6) {
      up = true;
    }

    if (center && up) {
      this.renderer.addClass(this.tooltipArrow, 'reaction-userlist-arrow-down');
      this.renderer.setStyle(this.tooltipArrow, 'top', `${elementRect.y - 6}px`);
      this.renderer.setStyle(this.tooltip, 'top', `${elementRect.y - tooltipRect.height - 6}px`);
    } else if (center && !up) {
      this.renderer.addClass(this.tooltipArrow, 'reaction-userlist-arrow-up');
      this.renderer.setStyle(this.tooltipArrow, 'top', `${elementRect.bottom}px`);
      this.renderer.setStyle(this.tooltip, 'top', `${elementRect.bottom + 6}px`);
    } else {
      const calculatedLTop = elementRect.y + elementRect.height / 2 - tooltipRect.height / 2;
      const calculatedLArrowTop = elementRect.y + elementRect.height / 2 - 6;
      this.renderer.setStyle(this.tooltip, 'top', `${calculatedLTop > 0 ? calculatedLTop : 0}px`);
      this.renderer.setStyle(this.tooltipArrow, 'top', `${calculatedLArrowTop}px`);
    }
  }

  removeTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.renderer.removeChild(document.body, this.tooltipArrow);
      this.tooltip = null;
      this.tooltipArrow = null;
    }
  }

  ngOnDestroy() {
    this.removeTooltip();
    super.ngOnDestroy();

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
