import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { RoomService } from '../../_core/services/room.service';
import { SocketStatus } from '../../_core/services/socket.service/models/socket-status';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { TitleHandlerService } from '../../_core/services/title-handler.service';
import { Message, MessageStatus } from '../../_shared/models/commons/message';
import { DEFAULTS } from '../../_shared/models/defaults';
import { RoomType } from '../../_shared/models/enums/room-type';
import { Panel, PanelType } from '../../_shared/models/room/channel';
import { Chatroom, DirectRoom } from '../../_shared/models/room/room';
import { DirectLabelPipe } from '../../_shared/pipes/direct-channel-members.pipe';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { TimelineComponent } from '../timeline/timeline.component';
import { RULE } from './../../_shared/models/commons/permissions.constants';
import { ChatroomNotificationSettingsComponent } from '../notifications/chatroom-notification-settings/chatroom-notification-settings.component';
import { MessageCountService } from '../message-count/services/message-count.service';
import { ChatroomResp } from '@echofin/libraries/api/team/models/chatroom-resp';

@Component({
  selector: 'app-panel-chatroom',
  templateUrl: './panel-chatroom.component.html',
  styleUrls: ['./panel-chatroom.component.scss']
})
export class PanelChatroomComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  @ViewChild('timeline') timeline: TimelineComponent;

  private _panel: Panel;
  @Input()
  set panel(p: Panel) {
    if (p) {
      this._panel = p;
      if (!p.isHidden && !this.hasLoaded && !this.setupError) {
        try {
          this.setup();
        } catch (ex) {
          console.log('setupError', ex);
          this.setupError = true;
        }
        this.hasLoaded = true;
      }
    }
  }
  get panel() {
    return this._panel;
  }
  @Input() openedChannels;

  roomType = RoomType;
  room: Chatroom & DirectRoom;
  roomStyle: any = null;
  loadingMessages: boolean;
  msgText: string;
  messageStatuses = MessageStatus;
  statusStyle = ['OFFLINE', 'IDLE', 'AWAY', 'BUSY', 'ONLINE'];
  inputPlaceholder: string;
  lockedToBottom: boolean = true;
  timelineType: TimelineType;
  hasLoaded = false;
  setupError = false;
  charsLeft: number = null;
  rules = RULE;
  initialRoomMessage = 'Welcome trader. The conversation in this chatroom starts here.';
  initialDirectMessage = 'You are in conversation with ';
  initialGroupMessage = 'You are in a group chat.';
  roomName = '';
  canDelete: boolean;
  canPin: boolean;
  afterViewInitHappened$ = new Subject<boolean>();
  initialMessages: Message[];

  constructor(
    private panelsService: PanelsService,
    private messageService: MessageService,
    private teamService: TeamService,
    private profileService: ProfileService,
    private roomService: RoomService,
    private modal: NgbModal,
    private hostElement: ElementRef,
    private directLabelPipe: DirectLabelPipe,
    private titleHandler: TitleHandlerService,
    private socketService: SocketService,
    private messageCountService: MessageCountService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  get currentMessages() {
    return this.messageService.messages[this.panel.id];
  }

  get maximized() {
    return (this.panelsService.maximizedPanels[this.panel.teamId] === this.room.id) || (this.panelsService.maximizedPanels['direct'] === this.room.id);
  }

  get me() {
    return this.profileService.me;
  }

  get roomColor() {
    return this.room.color || DEFAULTS.room.color;
  }

  resetRoomStyle() {
    this.roomStyle = {
      'background-image': `-webkit-gradient(linear, 0% 0%, 100% 0%, from(${this.colorLuminance(this.roomColor, -0.4)}), to(${this.roomColor}))`,
      // tslint:disable-next-line: object-literal-key-quotes
      'display': this.room.topic ? 'block' : 'none'
    };
  }

  get hasMoreMessages() {
    return (this.panel && this.panel.id) ? this.messageService.hasMoreMessages[this.panel.id] : false;
  }

  get hasMoreMessagesBelow() {
    return (this.panel && this.panel.id) ? this.messageService.hasMoreMessagesBelow[this.panel.id] : false;
  }

  conversationJustStarted = false;

  setup() {
    this.messageService.hasMoreMessages[this.panel.id] = false;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;
    this.setupSubscriptions();
    this.loadRoom();
    if (this.panel.id.indexOf('local-id-') < 0) {
      this.loadMessages();
    } else {
      this.conversationJustStarted = true;
    }
    if (this.room.type === RoomType.Direct || this.room.type === RoomType.Group) {
      this.roomName = this.createName();
    }
    this.panelsService.panelElements[this.panel.id] = this.hostElement;
    this.canDelete = this.room.permissions.user[RULE.DELETE_ALL_MESSAGES];
    const team = this.teamService.teams.find(t => t.id === this.room.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.subscribe(this.messageCountService.chatroomMessageCounterChanged$, (room: ChatroomResp) => {
      if (this.panel.id === room.id) {
        this.room.unread = room.unread;
      }
    });

    this.subscribe(this.messageCountService.chatroomLastTimestampChanged$, ({ chatroomId, timestamp }) => {
      if (this.panel.id === chatroomId) {
        this.room.lastReadTimestamp = timestamp.toString();
      }
    });

    this.subscribe(this.messageService.messageReceived$, msg => {
      if (msg.chatroom.id === this.panel.id) {
        if (!this.timeline.isLookingPastMessages()) {
          this.messageService.reduceMessages(this.panel.id);
        }
        // console.log('LOADMESSAGES msg received');
        this.initialMessages = [...this.currentMessages];
      }
    });

    this.subscribe(this.messageService.messageDeleted$, e => {
      if (e.chatroomId === this.panel.id) {
        // console.log('LOADMESSAGES msg delete');
        this.initialMessages = [...this.currentMessages];
      }
    });

    this.setupPanelHeader();
  }

  setupPanelHeader() {
    this.panelsService.panelTitle$.next([this.panel.id, (this.room.name && this.room.name !== '') ? this.room.name : this.roomName]);

    this.panelsService.panelChatroomType$.next([this.panel.id, this.room.type]);

    if (this.room && this.room.type === 'Direct') {
      const user = this.getOtherUser(this.room);
      const status = this.getOtherUserStatus(this.room);
      this.panelsService.panelUserIdAndStatus$.next([this.panel.id, user.userId, status]);
    }
  }

  getOtherUser(room: DirectRoom) {
    const otherUser = room.participants.find(c => c.userId !== this.profileService.me.id);
    return otherUser;
  }

  getOtherUserStatus(room: DirectRoom) {
    const otherUser = this.getOtherUser(room);
    if (otherUser && otherUser.user && otherUser.user.status) {
      return otherUser.user.status;
    }
    return null;
  }

  ngAfterViewInit() {
    this.afterViewInitHappened$.next(true);
  }

  chatboxFocus() {
    this.panelsService.focusedItem = this.panel.id;
    const calculatedRoomName = (this.room.name && this.room.name !== '') ? this.room.name : this.roomName;
    const calculatedTeamName = this.teamService.activeTeam?.name ?? '';
    const titleEmoji = this.getTitleEmoji(this.room.type);
    const title = `${titleEmoji}${calculatedRoomName} | ${calculatedTeamName}`;
    this.titleHandler.setTitle(title, this.panel.id);
    this.panelsService.clickedMenuItem$.next(this.panel.id);
    this.panelsService.slideToPanel(this.panelsService.focusedItem);
    // this.socketService.focus({ chatrooms: [{ id: this.panel.id, focused: true }] });
    this.messageCountService.sendFocusChange({ chatrooms: [{ id: this.panel.id, focused: true }] });
    this.log('focus room', this.panel.id);
  }

  chatboxBlur() {
    this.panelsService.focusedItem = null;
    this.panelsService.clickedMenuItem$.next(null);
    this.titleHandler.removeTitle(this.panel.id);
    // this.socketService.focus({ chatrooms: [{ id: this.panel.id, focused: false }] });
    this.messageCountService.sendFocusChange({ chatrooms: [{ id: this.panel.id, focused: false }] });
    this.log('unfocus room', this.panel.id);
  }

  msgSent() {
    if (this.timeline) {
      this.timeline.scrollToBottom();
    }
  }

  getTitleEmoji(type: 'Direct' | 'Group' | 'Team') {
    switch (type) {
      case RoomType.Direct:
        return '@';
      case RoomType.Group:
        return '💬';
      case RoomType.Team:
      default:
        return '#';
    }
  }

  headerAction(action) {
    switch (action) {
      case 'close': this.close(); break;
      case 'maximize': this.maximize(); break;
      case 'minimize': this.minimize(); break;
    }
  }

  private close() {
    if (!this.room.localOnly) {
      // this.messageService.removedExcessMessages(this.room.id);
      this.messageService.messages[this.room.id] = [];
      delete this.messageService.messages[this.room.id];
    }
    this.panel.visible = false;
    this.panelsService.close(this.panel);
  }

  private maximize() {
    this.panelsService.maximize(this.panel);
  }

  private minimize() {
    this.panelsService.minimizeAll(this.room.teamId);
  }

  // editGroup() {
  //   const modal = this.modal.open(EditGroupchatComponent, {
  //     centered: true,
  //     windowClass: 'modal-dark',
  //     backdrop: 'static'
  //   });
  //   modal.componentInstance.room = this.room;
  // }

  colorLuminance(ohex, olum) {
    let hex = String(ohex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    const lum = olum || 0;
    let rgb = '#';
    let c;
    let i;
    const orig = '00';
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += (orig + c).substr(c.length);
    }
    return rgb;
  }

  async requestTimeframe(args: Date) {
    this.panelsService.timestampCursors[this.panel.id] = args;
    await this.loadMessages();
    // console.log('LOADMESSAGES req timeframe');
    this.initialMessages = [...this.currentMessages];
  }

  scrollToBottom() {
    this.timeline.scrollToBottom();
  }

  onScrollDown() {
    this.timeline.onScrollDown();
  }

  onScrolledUp() {
    this.timeline.onScrolledUp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
      delete this.panelsService.panelElements[this.panel.id];
    }
    delete this.panel;
    delete this.openedChannels;
    delete this.room;
    delete this.msgText;
    delete this.messageStatuses;
    delete this.statusStyle;

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }

  private setupSubscriptions() {
    this.subscribe(this.panelsService.showedItem$, (panelId) => {
      if (this.room && this.panel.id === panelId && this.panelsService.timestampCursors[panelId]) {
        this.loadMessages();
      }
    });

    this.subscribe(this.teamService.teamRoomPermUpdated$, (room: Chatroom) => {
      if (room.id !== this.panel.id) {
        return;
      }
      this.loadRoom();
      if (room.permissions.user.read_messages && !this.currentMessages) {
        this.loadMessages();
      }

      this.canDelete = this.room.permissions.user[RULE.DELETE_ALL_MESSAGES];
      const team = this.teamService.teams.find(t => t.id === this.room.teamId);
      this.canPin = team && team.permissions && team.permissions.pinboard;
    });
    this.subscribe(this.roomService.teamRoomUpdated$, (room: Chatroom) => {
      if (room.id !== this.panel.id) {
        return;
      }
      this.loadRoom();
      if (room.permissions.user.read_messages && !this.currentMessages) {
        this.loadMessages();
      }

      this.canDelete = this.room.permissions.user[RULE.DELETE_ALL_MESSAGES];
      const team = this.teamService.teams.find(t => t.id === this.room.teamId);
      this.canPin = team && team.permissions && team.permissions.pinboard;
    });
    this.subscribe(this.socketService.status$, (status: SocketStatus) => {
      if (status === SocketStatus.Connected && this.room) {
        this.loadMessages();
      }
    });
    this.subscribe(this.roomService.participantJoined$, (s) => {
      if (s.chatroomId !== this.room.id) return;
      const msg: Message = {
        systemText: `${s.user.username} has joined`,
        type: 'TEXT',
      };
      this.roomName = this.createName();
      this.messageService.messages[this.panel.id].push(msg);
    });
    this.subscribe(this.roomService.participantRemoved$, (s) => {
      if (s.chatroomId !== this.room.id) return;
      const msg: Message = {
        systemText: `${s.user.username} has left`,
        type: 'TEXT',
      };
      this.roomName = this.createName();
      this.messageService.messages[this.panel.id].push(msg);
    });
    this.subscribe(this.panelsService.openChatroomNotificationPreferences$, (panelId) => {
      if (this.panel.id === panelId) {
        this.openChatroomNotificationModal();
      }
    });
  }

  private async loadMessages() {
    if (!this.room.localOnly && this.room.permissions && this.room.permissions.user && this.room.permissions.user[RULE.READ_MESSAGES]) {
      this.panelsService.panelPermissions$.next([this.panel.id, true]);
      this.loadingMessages = true;
      this.panelsService.panelLoading$.next([this.panel.id, true]);

      const timestampCursor = this.panelsService.timestampCursors[this.panel.id];
      if (!timestampCursor) {
        await this.messageService.getMessages(this.room.id, null);
        if (this.timeline) {
          // console.log('LOADMESSAGES get msgs');
          this.initialMessages = [...this.currentMessages];
          this.timeline.loadingBetween = null;
        }
        this.messageService.hasMoreMessagesBelow[this.panel.id] = false;
      } else {
        await this.messageService.getMessagesBeforeAndAfter(this.room.id, timestampCursor);
        // console.log('LOADMESSAGES get msgs time cursor');
        this.initialMessages = [...this.currentMessages];
      }

      this.loadingMessages = false;
      this.panelsService.panelLoading$.next([this.panel.id, false]);
    } else {
      this.panelsService.panelPermissions$.next([this.panel.id, false]);
    }
  }

  private loadRoom() {
    if (this.panel.type === PanelType.Direct) {
      this.timelineType = TimelineType.DirectOrGroupMessages;
      const room = this.roomService.directs.find(d => d.id === this.panel.id);
      this.room = { ...room };
    } else {
      this.timelineType = TimelineType.TeamChatroomMessages;
      const room = this.teamService.getRoom(this.panel.id);
      this.room = { ...room };
    }
    if (!this.room) {
      this.room = {
        id: this.panel.id,
        type: RoomType.Team,
      };
    }
    this.resetRoomStyle();
  }

  createName() {
    return this.directLabelPipe.transform(this.room as any, this.me.id);
  }

  removeQuotes() {
    this.messageService.removeQuotes(this.room.id);
  }

  roomJustCreated(roomId: string) {
    this.panel = { ...this.panel, id: roomId };
    this.conversationJustStarted = false;
  }

  openChatroomNotificationModal() {
    const modal = this.modal.open(ChatroomNotificationSettingsComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'sm'
    });
    modal.componentInstance.room = this.room;
  }

  private log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[PANEL-CHATROOM-COMPONENT]', 'color:#1496A3', ...arg);
    }
  }
}
