<ng-container *ngIf="me; else loading">
  <div ngbDropdown placement="right-top" container="body">
    <div id="main-avatar" ngbDropdownToggle class="btn teambar-team-btn teambar-team-btn-profile member-avatar">
      <app-user-avatar [avatarUrl]="me.avatar" [username]="me.username"></app-user-avatar>
      <span class="indicator" [ngClass]="me.currentStatus"></span>
    </div>
    <div ngbDropdownMenu style="width: 200px; margin: 5px 0 0 5px;" class="preferences-dropdown">
      <h4 class="dropdown-header text-white">{{me.username}}</h4>
      <div class="dropdown-divider"></div>
      <button class="dropdown-item" (click)="setUserStatus('AVAILABLE')">
        <i class="fas fa-circle fa-fw status-online"></i> Available
      </button>
      <button class="dropdown-item" (click)="setUserStatus('BUSY')">
        <i class="fas fa-circle fa-fw status-away"></i> Busy
      </button>
      <div class="dropdown-divider"></div>
      <button class="dropdown-item" (click)="openUserPreferencesModal()">
        <i class="fal fa-cogs fa-fw"></i> Preferences
      </button>
      <div class="dropdown-divider"></div>

      <button class="dropdown-item" (click)="logout()">
        <i class="fal fa-sign-out-alt fa-fw"></i> Logout
      </button>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="btn teambar-team-btn bar-loader">
    <i class="fas fa-sync-alt fa-spin"></i>
  </div>
</ng-template>