import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ProfilePasswordServiceApi } from '@echofin/libraries';
import { BrandingService } from '../../_core/services/branding.service';
import { CustomValidators } from '../../_shared/validators/custom-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loading: boolean;
  emailOrUsername = new FormControl('', [Validators.required, Validators.email]);
  resetpass = new FormControl('', [Validators.required, Validators.pattern(/^\S*$/), Validators.minLength(6)]);
  confirmpass = new FormControl('', [Validators.required, CustomValidators.matches(this.resetpass)]);
  status: string = 'start';
  err: string;

  token: string;

  colorCode: string;
  contrastColor: string;

  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private profileServiceApi: ProfilePasswordServiceApi,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.colorCode = this.getColorCode();
    this.contrastColor = this.getContrastYIQ(this.colorCode);

    this.route.queryParams.subscribe((params: Params) => {
      if (this.token && !params.t) {
        this.status = 'start';
      }

      if (params.t) {
        this.token = params.t;
        this.profileServiceApi
          .VerifyToken({
            token: this.token
          })
          .toPromise()
          .then(() => {
            this.status = 'change';
            this.loading = false;
          })
          .catch((resp) => {
            if (!resp.error) {
              this.status = 'fail-generic';
            }
            switch (resp.error) {
              case 'LINK_ALREADY_USED':
                this.status = 'LINK_ALREADY_USED';
                break;
              case 'INVALID_TOKEN':
                this.status = 'INVALID_TOKEN';
                break;
              case 'USER_NOT_FOUND':
                this.status = 'USER_NOT_FOUND';
                break;
            }
            this.loading = false;
          });
      }
    });
  }

  async requestReset() {
    // passes email validation, so it is email
    if (this.emailOrUsername.valid) {
      await this.sendResetRequest(null, this.emailOrUsername.value);
    } else if (!this.emailOrUsername.errors.required && this.emailOrUsername.errors.email) {
      // there is an input but it's not a valid email, so we consider this a username
      await this.sendResetRequest(this.emailOrUsername.value, null);
    }
  }

  async sendResetRequest(username: string, email: string) {
    this.loading = true;

    this.profileServiceApi.RequestResetPassword(
      {
        email,
        username,
        joinTeam: this.brandingService.team.name
      }).toPromise()
      .then((res) => {
        this.status = 'request_success';
        this.loading = false;
      })
      .catch((resp) => {
        if (!resp.error) {
          this.status = 'fail-generic';
          this.err = resp.message;
        }
        if (resp.error === 'USER_NOT_FOUND') {
          this.status = 'USER_NOT_FOUND';
        }
        this.loading = false;
      });
  }

  async reset() {
    this.resetpass.updateValueAndValidity();
    this.confirmpass.updateValueAndValidity();
    if (this.resetpass.valid && this.confirmpass.valid) {
      this.loading = true;

      this.profileServiceApi.ResetPassword(
        {
          token: this.token,
          password: this.resetpass.value
        }).toPromise()
        .then((res) => {
          this.status = 'success';
          this.loading = false;
        })
        .catch((resp) => {
          if (!resp.error) {
            this.status = 'fail-generic';
            this.err = resp.message;
          }
          switch (resp.error) {
            case 'LINK_ALREADY_USED':
              this.status = 'LINK_ALREADY_USED';
              break;
            case 'INVALID_TOKEN':
              this.status = 'INVALID_TOKEN';
              break;
            case 'USER_NOT_FOUND':
              this.status = 'USER_NOT_FOUND';
              break;
            default:
              this.status = 'fail-generic';
          }
          this.loading = false;
        });
    } else {
      this.submitted = true;
    }
  }

  getColorCode() {
    if (this.brandingService.team &&
      this.brandingService.team.config &&
      this.brandingService.team.config.branding &&
      this.brandingService.team.config.branding.colorCode) {
      return this.brandingService.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  getContrastYIQ(hexcolor) {
    const hc = hexcolor.replace('#', '');
    const r = parseInt(hc.substr(0, 2), 16);
    const g = parseInt(hc.substr(2, 2), 16);
    const b = parseInt(hc.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 136) ? 'black' : 'white';
  }
}
