import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class ClientErrorHandler implements ErrorHandler {

  sentryInitialized = false;
  // logRocketInitialized = false;

  initSentry() {
    if (this.sentryInitialized) return;
    Sentry.init({
      dsn: environment.config.sentry.dsn,
      debug: environment.config.debug,
      release: environment.version.hash,
      environment: environment.config.name,
    });
    console.log('%c[SYSTEM]', 'color:#8800AA', 'Sentry enabled', environment.config.sentry.dsn);

    this.sentryInitialized = true;
  }

  // initLogRocket() {
  //   if (this.logRocketInitialized || !environment.config.logRocketId) return;

  //   let hostname = window.location.hostname;
  //   const hostnameArr = hostname.split('.');
  //   hostnameArr.splice(0, 1);
  //   hostname = hostnameArr.join('.');
  //   LogRocket.init(environment.config.logRocketId, {
  //     rootHostname: hostname
  //   });
  //   console.log('%c[SYSTEM]', 'color:#8800AA', 'LogRocket enabled');

  //   this.logRocketInitialized = true;
  // }

  addSentryExtraData(key: string, value: string, error: any) {
    Sentry.configureScope(scope => {
      scope.setExtra(key, value);
    });
    Sentry.captureException(error.originalError || error);
  }

  handleError(error) {
    // log errors only on production
    // if (environment.production) {
    // this.initLogRocket();
    // LogRocket.captureException(error.originalError || error);

    // REMOVE DSN FROM CONFIG IF YOU DON'T WANT TO LOG TO SENTRY FROM LOCAL MACHINE
    if (environment.config.sentry && environment.config.sentry.dsn) {
      this.initSentry();

      // add logRocket reference to sentry
      // if (this.logRocketInitialized) {
      //   try {
      //     if (LogRocket.sessionURL !== null) {
      //       this.addSentryExtraData('logRocketUrl', LogRocket.sessionURL, error);
      //     } else {
      //       // async and slower, but will get value if null
      //       LogRocket.getSessionURL(sessionURL => {
      //         this.addSentryExtraData('logRocketUrl', sessionURL, error);
      //       });
      //     }
      //   } catch (exception) {
      //     this.addSentryExtraData('logRocketError', exception, error);
      //     Sentry.captureException(error.originalError || error);
      //   }
      // }

      Sentry.captureException(error.originalError || error);
    }
    // }

    // Log the error, do not throw
    console.error('Logged by event handler: ', error);
  }
}
