<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Send a secret message!</h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <form [formGroup]="secretForm" (ngSubmit)="send()">
                    <div class="form-group">
                        <input #title style="width:100%;" formControlName="title"
                            type="text" class="form-control" placeholder="Message title (unencrypted)"
                            [ngClass]="{'is-invalid': submitted && secretForm.controls.title.errors }" />
                        <div class="borderfx"></div>
                    </div>
                    <div class="form-group">
                        <input #secret style="width:100%;" formControlName="secret"
                            type="text" class="form-control" placeholder="Message content to be encrypted"
                            [ngClass]="{'is-invalid': submitted && secretForm.controls.secret.errors }" />
                        <div class="borderfx"></div>
                    </div>
                    <div class="form-group">
                        <input style="width:100%;" formControlName="encryptKey" (keydown)="onKeyDown($event)"
                            type="text" class="form-control mask-text" placeholder="Encryption key"
                            [ngClass]="{'is-invalid': submitted && secretForm.controls.encryptKey.errors }" />
                        <div class="borderfx"></div>
                    </div>
                    <div class="invalid-feedback d-block">
                        <small class="d-block"
                            *ngIf="submitted && secretForm.controls.title.errors && secretForm.controls.title.errors.required">
                            The Message title is required.</small>
                        <small class="d-block"
                            *ngIf="submitted && secretForm.controls.secret.errors && secretForm.controls.secret.errors.required">
                            The Message content is required.</small>
                        <small class="d-block"
                            *ngIf="submitted && secretForm.controls.encryptKey.errors && secretForm.controls.encryptKey.errors.required">
                            The Encryption key is required.</small>
                        <small class="d-block"
                            *ngIf="submitted && secretForm.controls.encryptKey.errors && secretForm.controls.encryptKey.errors.minlength">
                            The Encryption key must be at least 3 characters.</small>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-secondary" type="button" (click)="close()">Cancel</button>
        <button class="btn btn-sm btn-success" type="button" (click)="send()">Send</button>
    </div>
</div>