import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TeamTeamsServiceApi } from '@echofin/libraries';
// import * as LogRocket from 'logrocket';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../_core/services/auth.service';
import { BrandingService } from '../../_core/services/branding.service';
import { ProviderError } from '../../_shared/models/commons/provider-error';
import { Location } from '@angular/common';

export const introUrls = [
  'register',
  'login',
  'reset-password',
  'verify',
  'mobile',
  'signup',
  'invitation'
];

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  closedTeamVerifiedInvitation = null;

  constructor(
    private authService: AuthService,
    private teamsTeamApi: TeamTeamsServiceApi,
    private brandingService: BrandingService,
    private router: Router,
    private location: Location
  ) { }

  async login(identity: string, password: string, teamName: string, invCode?: string): Promise<any> {
    const response = await this.authService.login(identity, password);

    if (response.message) {
      return { error: response.message };
    }

    if (invCode) {
      this.router.navigateByUrl(`/invitation/${invCode}`);
      return;
    }

    const prefix = 'app';

    if (teamName === prefix) {
      this.router.navigateByUrl('/');
      return;
    }

    const userId = await this.authService.getUserId();
    if (!userId) {
      return { error: 'To open the web app, you need to change your browser settings to allow third-party cookies.' };
    }

    // LogRocket.identify(userId);

    const teams = await this.teamsTeamApi
      .GetUserTeams()
      .toPromise()
      .catch(() => { });
    if (teams instanceof ProviderError) {
      this.redirect(teamName);
      return;
    }

    if (teams && teams.find(t => t.name === teamName)) {
      this.redirect(teamName);
      return;
    }

    this.redirect(teamName, true);
  }

  async logout() {
    await this.authService.logout();
  }

  redirect(teamName: string, join = false) {
    // custom domain
    if (this.brandingService.team && this.brandingService.team.name === teamName && this.brandingService.team.customDomain) {
      window.location.href = `${window.location.protocol}//${this.brandingService.team.customDomain}/${teamName}`;
      return;
    }

    const prefix = 'app';

    let url = `${window.location.protocol}//${prefix}.${environment.config.domain}`;

    // already at app.echofin.com
    if (window.location.host === `${prefix}.${environment.config.domain}`) {
      if (teamName !== 'app') {
        this.router.navigateByUrl(`/${teamName}`);
      }
      let currentPath = this.location.path();
      if (currentPath && currentPath[0] === '/') {
        currentPath = currentPath.slice(1);
      }
      if (introUrls.indexOf(currentPath) > -1) {
        this.router.navigateByUrl('/');
      }
      return;
    }

    url = url + (teamName !== 'app' ? (join ? `/join?team=${teamName}` : `/${teamName}`) : '');

    // go to app.echofin.com
    console.log(`Redirecting to ${url}`);
    window.location.href = url;
  }
}
