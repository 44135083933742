<div class="modal-header">
    <h4 class="modal-title">Create your team</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pd-2">
    <form *ngIf="invitationIsValid" id="team" [formGroup]="teamForm" (ngSubmit)="doCreateTeam()">
        <div class="alert alert-success" *ngIf="!loading && status=='success'">
            <strong>Team Created!</strong>
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-in-use'">
            <strong>Team name "{{failedName}}" is taken</strong>
            <hr>
            Please try another one
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-reserved'">
            <strong>Team name "{{failedName}}" is reserved.</strong>
            <hr>
            Please try another one
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-generic'">
            <strong>Something went wrong</strong>
            <hr>
            {{err}}
        </div>
        <div class="col-12" *ngIf="status!='success'">
            <div class="form-group">
                <div class="input-group mb-3">
                    <input formControlName="Name" type="text" class="form-control bg-dark text-white border-secondary"
                        placeholder="team-url">
                    <div class="input-group-append">
                        <span class="input-group-text bg-primary" id="basic-addon2">.echofin.com</span>
                    </div>
                </div>
                <p class="create-team-err"
                    *ngIf="teamForm.controls.Name.touched && teamForm.controls.Name.dirty && teamForm.controls.Name.errors">
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.required">The team name is
                        required.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.maxlength">The team name must be at most
                        62 characters long.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.minlength">The team name must be at
                        least 2 characters long.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.pattern">The team name must contain only
                        lower case letters, numbers, dashes and underscores.</small>
                </p>
            </div>
            <div class="row">
                <div class="col-md-4 form-group logo-formgroup">
                    <label>Logo</label>
                    <div class="d-flex flex-column">
                        <div class="team-logo-container">
                            <img #logo id="logo" alt="team logo" class="d-block team-logo"
                                [src]="(teamForm.controls.Logo && teamForm.controls.Logo.value)? (teamForm.controls.Logo.value + filestackUrlParameters) : '/assets/no-logo.png'">
                        </div>
                        <div class="text-grey p-2 flex-grow-1 text-center"><small>Max image size: 512x512</small></div>
                        <button type="button" class="btn btn-sm btn-block btn-outline-secondary" (click)="changeLogo()">
                            <i *ngIf="pickerLoading" class="far fa-sync-alt fa-fw fa-spin"></i>
                            <i *ngIf="!pickerLoading" class="fas fa-upload fa-fw"></i>
                            <span class="ml-2">Change</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Description</label>
                        <textarea formControlName="Description" class="form-control" rows="8"></textarea>
                    </div>
                    <p class="create-team-err"
                        *ngIf="teamForm.controls.Description.errors && teamForm.controls.Description.dirty">
                        <small class="d-block" *ngIf="teamForm.controls.Description.errors.maxlength">The team
                            description must be at most 250 characters long.</small>
                    </p>
                </div>
            </div>
            <div class="form-group">
                <label>Asset classes and trading style</label>
                <input id="typeahead-focus" type="text" class="form-control" [ngbTypeahead]="search"
                    [disabled]="tagsValue.length>4"
                    [placeholder]="tagsValue.length>4 ? 'Max items selected' : 'Add up to 5 items'"
                    (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)"
                    (selectItem)="onSelectItem($event, typeahead)" #typeahead="ngbTypeahead"
                    [inputFormatter]="clearInput" />
                <div class="mb-2 mt-2 tags-container">
                    <button type="button" *ngFor="let tag of tagsValue" class="btn btn-primary btn-sm mr-1 mb-1"
                        (click)="removeTag(tag)">
                        {{tag}} <i class="far fa-times"></i>
                    </button>
                </div>
            </div>
            <hr />
            <div class="form-group">
                <label>Invitation Code: </label>
                <input type="text" formControlName="invitation" class="form-control" />
            </div>
        </div>
        <button class="btn btn-block btn-md btn-success submit-btn" [disabled]="loading" type="submit">
            <i *ngIf="loading" class="far fa-sync-alt fa-fw fa-spin"></i>
            <i *ngIf="!loading" class="fas fa-users-crown fa-fw"></i>
            <span class="ml-2">Create Team</span>
        </button>
    </form>

    <form *ngIf="!invitationIsValid" id="verificationForm" class="text-white" style="font-family: 'Poppins';"
        (ngSubmit)="verifyInvitation()" #invForm="ngForm">
        <div>
            To create your team on Echofin you will need to subscribe to one of our plans.
            <br><br>With a premium plan you can customize your team's branding, use your own domain name, and remove the
            team discovery/creation button.
            <br><br>You can get started with a 7-day free trial. Find all the details <a
                href="https://www.echofin.com/plans/?utm_source=create-team" target="_blank">here</a>.
            <br><br>
            After subscribing to a plan you will receive the invite code.<br><br>
        </div>
        <div class="form-group">
            <input type="text" [formControl]="invitation" placeholder="Enter your invite code" class="form-control" />
        </div>
        <div *ngIf="invitationIsValid===false || (invitation.dirty && invitation.touched && invitation.invalid)"
            class="alert alert-danger text-center">
            <small>The invite code is invalid!</small>
        </div>
        <button class="btn btn-block btn-md btn-success submit-btn" [disabled]="loading" type="submit">
            <i *ngIf="loading" class="far fa-sync-alt fa-fw fa-spin"></i>
            <i *ngIf="!loading" class="far fa-badge-check fa-fw"></i>
            <span class="ml-2">Verify Invite Code</span>
        </button>
    </form>
</div>