import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { SignalsMessagesService } from '../../_core/services/signals-messages.service';
import { EvType } from '../../_core/services/socket.service/models/all.models';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { LocalMessageProcessed, Message } from '../../_shared/models/commons/message';
import { MessageType } from '../../_shared/models/message/message-type';
import { Panel } from '../../_shared/models/room/channel';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { TimelineComponent } from '../timeline/timeline.component';

@Component({
  selector: 'app-panel-signals',
  templateUrl: './panel-signals.component.html',
  styleUrls: ['./panel-signals.component.scss']
})
export class PanelSignalsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @ViewChild('timeline') timeline: TimelineComponent;
  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  initialMessages: Message[];

  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.SignalMessages;

  config: any;
  // isConfigOpened: boolean = false;
  slOrder: 'MessageDate' | 'CreatedDate' = 'MessageDate';

  isLoading: boolean = false;
  canPin: boolean;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private messageService: MessageService,
    private signalsMessagesService: SignalsMessagesService,
    private socketService: SocketService,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  async ngOnInit() {
    console.log(this.panel.id);
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelTitle$.next([this.panel.id, 'Team Signals']);
    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.registerEvents();

    this.slOrder = this.panelsService.getMessageOrder(this.panel.id).order;
    this.isLoading = true;
    await this.signalsMessagesService.loadSignalMessages(this.panel.id, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  registerEvents() {
    this.subscribe(this.socketService.getStream(EvType.MessageProcessed),
      (m: LocalMessageProcessed) => {
        if (m.type === 'NEW' && this.teamService.activeTeam.id === m.message.chatroom?.teamId) {
          if (m.message.type === MessageType.SIGNAL) {
            this.onSignalAdded(m.message);
          }
        }
      });
    this.subscribe(this.messageService.messageDeleted$, m => {
      if (this.teamService.activeTeam.id === m.messageId) {
        this.onSignalRemoved(m.messageId);
      }
    });
    this.subscribe(this.messageService.messageReplacementReceived$, message => this.processReplacementMessage(message.replace as Message));
    this.subscribe(this.messageService.messageDeleted$, messageDeleted => { if (messageDeleted) { this.deleteMessage(messageDeleted.messageId); } });
  }

  onSignalAdded(signal) {
    const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === signal.id.toString());
    if (messageIndex < 0) {
      const m = signal as unknown as Message;
      this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray([...this.messageService.messages[this.panel.id], m], this.messageService.hasMoreMessages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    }
  }

  onSignalRemoved(messageId: string) {
    const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === messageId.toString());
    if (messageIndex > -1) {
      this.messageService.messages[this.panel.id].splice(messageIndex, 1);
      this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray(this.messageService.messages[this.panel.id], this.messageService.hasMoreMessages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    }
  }

  processReplacementMessage(message: Message) {
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === message.id);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== message.id);
        this.messageService.messages[this.panel.id].push(message);
        this.messageService.messages[this.panel.id] = MessageHelpers.organizeSignalMessages(this.messageService.messages[this.panel.id], this.messageService.hasMoreMessages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  deleteMessage(messageId: string) {
    if (messageId === null || messageId === undefined) {
      return;
    }
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === messageId);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray(this.messageService.messages[this.panel.id].filter(m => m.id !== messageId), this.messageService.hasMoreMessages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }
}
