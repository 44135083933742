import { OnInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, OnDestroy, ElementRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DefaultAvatarService } from '../../_core/services/default-avatar.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnDestroy {

  @Input() avatarUrl: SafeResourceUrl;

  @Input() username: string;
  @Input() userId: string;

  defaultColor: string;
  defaultInitials: string;

  constructor(
    private avatarService: DefaultAvatarService,
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef
  ) { }

  ngOnInit() {
    this.calcAvatar();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<any> {
    if ((changes['avatarUrl'] || changes['username']) &&
      !this.avatarUrl) {
      await this.calcAvatar();
    }
  }

  calcAvatar() {
    if ((this.avatarUrl === null || this.avatarUrl === '') && this.username) {
      // this.avatarUrl = await this.avatarService.getDefaultAvatar(this.username);

      this.defaultInitials = this.username.substr(0, 2).toUpperCase();
      this.defaultColor = this.avatarService.getRandomColor(this.username).toRgbString();
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
