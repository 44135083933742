import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { TeamService } from './../../_core/services/team.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  terms;

  get m() {
    return this.activeModal;
  }

  constructor(
    private http: HttpClient,
    private activeModal: NgbActiveModal,
    private teamService: TeamService
  ) { }

  async ngOnInit() {
    this.terms = await this.http.get<any>(`${environment.config.endpoints.team}/team/teams/${this.teamService.activeTeamId}/terms`)
      .toPromise()
      .then(res => res.terms ? res.terms.split('\n') : []);
  }

}
