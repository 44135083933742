import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../_core/services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BrandingService } from '../../_core/services/branding.service';
import { TeamTeamsServiceApi } from '@echofin/libraries';
import { LoginService } from '../_commons/login.service';

@Component({
  selector: 'app-public-invite',
  templateUrl: './public-invite.component.html',
  styleUrls: ['./public-invite.component.scss']
})
export class PublicInviteComponent implements OnInit {

  loading = true;

  invitationCode: string;

  get team() {
    return this.brandingService.team;
  }

  get verifiedInvitation() {
    return this.loginService.closedTeamVerifiedInvitation;
  }

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private teamApi: TeamTeamsServiceApi,
    private profileService: ProfileService,
    private brandingService: BrandingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      (async params => {
        this.loginService.closedTeamVerifiedInvitation = null;
        this.invitationCode = params.get('code');

        if (!this.invitationCode) {
          return;
        }

        if (!this.brandingService.team) {
          console.log('ERROR - NO TEAM??');
        }

        if (this.profileService.me) {
          this.router.navigateByUrl(`/invite?team=${this.brandingService.team.id}&inv=${encodeURIComponent(this.invitationCode)}`);
          return;
        }
        this.profileService.loadMe()
          .then(() => {
            this.router.navigateByUrl(`/invite?team=${this.brandingService.team.id}&inv=${encodeURIComponent(this.invitationCode)}`);
          })
          .catch(async () => {
            if (this.team && this.team.config && this.team.config.join && this.team.config.join.state && this.team.config.join.state !== 'ALL') {
              const invResp = await this.teamApi.VerifyInvitation({ teamId: this.team.id, invitationName: this.invitationCode }).toPromise()
                .catch(() => { return null; });
              if (invResp) {
                // verified invitation
                this.loginService.closedTeamVerifiedInvitation = true;
                this.router.navigateByUrl(`/signup?inv=${encodeURIComponent(this.invitationCode)}`);
                return;
              }
              this.loading = false;
              this.loginService.closedTeamVerifiedInvitation = false;
            } else {
              this.loginService.closedTeamVerifiedInvitation = true;
              this.router.navigateByUrl(`/signup?inv=${encodeURIComponent(this.invitationCode)}`);
            }
          });
      }));
  }
}
