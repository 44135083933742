import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, SafeUrl } from '@angular/platform-browser';
import { ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../_core/services/branding.service';
import { MobileService } from '../_commons/mobile.service';
import { TeamService } from '../../_core/services/team.service';
import { environment } from 'environments/environment';
import { ProfileService } from 'app/_core/services/profile.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit, OnDestroy {

  action = '';
  extraData: ParamMap = null;
  path = '';

  rawLink = '';
  link: SafeUrl;
  params: { [key: string]: string; } = null;

  isAndroid = false;
  isiOS = false;

  androidLink = 'https://play.google.com/store/apps/details?id=com.echofin.echofin2';
  iOSLink = 'https://apps.apple.com/us/app/echofin-2-0/id1505197038?ls=1';

  @ViewChild('teamNameInput')
  teamNameInput: ElementRef;

  get isEchofin() {
    return this.branding.team.name === 'app';
  }

  get teamNameHeader() {
    if (this.isEchofin) {
      return 'Echofin';
    }
    if (this.branding.team.label) {
      return this.branding.team.label;
    }
    return this.branding.team.name;
  }

  get teamName() {
    return this.branding.team.name;
  }

  get teamLabel() {
    return this.branding.team.label;
  }

  get me() {
    return this.profileService.me;
  }

  get colorCode() {
    if (this.branding.team &&
      this.branding.team.config &&
      this.branding.team.config.branding &&
      this.branding.team.config.branding.colorCode) {
      return this.branding.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  constructor(
    private teamService: TeamService,
    private profileService: ProfileService,
    private router: Router,
    private mobile: MobileService,
    private sanitizer: DomSanitizer,
    private branding: BrandingService,
    private meta: Meta,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) { }

  ngOnInit() {
    this.isAndroid = /android/i.test(window.navigator.userAgent);
    this.isiOS = /iPad|iPhone|iPod/i.test(window.navigator.userAgent);

    // if in app.echofin.com --> auto redirect to store
    // if (window.location.host === `app.${environment.config.domain}`) {


    if (window.location.host === `mobile.${environment.config.domain}`) {
      this.redirectToStore();
      return;
    }

    // this.action = this.mobile.action;
    this.path = this.mobile.path;
    this.extraData = this.mobile.extraData;

    let queryString = '';
    if (this.extraData) {
      for (let k = 0; k < this.extraData.keys.length; k++) {
        const key = this.extraData.keys[k];
        const value = this.extraData.get(key);
        queryString += `${key}=${value}`;

        if (k < this.extraData.keys.length - 1) {
          queryString += '&';
        }
      }
    }

    if (queryString.length > 0) {
      queryString += '&';
    }
    queryString += `mobTeamName=${this.branding.team.name}`;

    this.rawLink = `${window.location.protocol}//mobile.${environment.config.domain}${this.path !== '' ? `/${this.path}` : ''}${(queryString !== '' ? `?${queryString}` : '')}`;
    this.link = this.sanitizer.bypassSecurityTrustUrl(this.rawLink);

    this.meta.updateTag(
      { name: 'apple-itunes-app', content: `app-id=1505197038, app-argument=${this.rawLink}` },
      "name='apple-itunes-app'"
    );
  }

  copyTeamName() {
    if (this.teamNameInput) {
      (this.teamNameInput.nativeElement as HTMLInputElement).select();
      (this.teamNameInput.nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand('copy');
      (this.teamNameInput.nativeElement as HTMLInputElement).blur();
      this.toastr.info('The team name has been copied, please paste it in the Echofin app to join', null, { closeButton: true, timeOut: 6000 });
    }
  }

  goToApp() {
    this.fallbackCopyTextToClipboard(this.rawLink);

    window.location.href = this.rawLink;
  }

  redirectToStore() {
    if (this.isAndroid) {
      window.location.href = this.androidLink;
    }
    if (this.isiOS) {
      window.location.href = this.iOSLink;
    }
  }

  fallbackCopyTextToClipboard(text: string) {
    var textArea = this.renderer.createElement("textarea");
    const container = this.hostElement.nativeElement;
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    this.renderer.appendChild(container, textArea);
    textArea.focus();
    textArea.select();

    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);

    this.renderer.removeChild(container, textArea, false);
  }

  async logout() {
    const options = this.teamService.activeTeam ? {
      queryParams: {
        team: this.teamService.activeTeam.name
      }
    } : {};
    await this.router.navigate(['/logout'], options);
  }

  ngOnDestroy() {
    this.meta.updateTag(
      { name: 'apple-itunes-app', content: 'app-id=1505197038' },
      "name='apple-itunes-app'"
    );
  }
}
