<ng-container *ngIf="!listItem">
    <div class="row">
        <div class="col-md-4">
            <img class="logo" [src]="team.logo||'/assets/no-logo.png'" onError="this.src='/assets/no-logo.png';"
                alt="team logo" class="w-100 rounded" />
            <div *ngIf="team.config.join.state === 'NONE' || team.config.join.state === 'CUSTOM'"
                class="private-ribbon">
            </div>
        </div>
        <div class="col-md-8">
            <div class="d-flex flex-column">
                <!-- <h3 class="title">{{team.label && team.label.length ? team.label : team.name}}
                    <ng-container *ngIf="team['features'].indexOf('TeamVerifiedBadge') !== -1">
                        <sup class="verified-badge ml-2" title="Team verified">
                            <i class="fad fa-badge-check"
                                style="--fa-primary-color: #fff; --fa-secondary-color: #35729e;--fa-primary-opacity: 1.0; --fa-secondary-opacity: 1.0;"></i>
                        </sup>
                    </ng-container>
                </h3> -->

                <h3 class="title">
                    <span class="one-liner">
                        <ng-container>{{team.label && team.label.length ? team.label : team.name}}</ng-container>
                    </span>
                    <ng-container *ngIf="team['features'].indexOf('TeamVerifiedBadge') !== -1">
                        <sup class="verified-badge align-middle ml-2" ngbTooltip="Team verified" container="body">
                            <i class="fad fa-badge-check"
                                style="--fa-primary-color: #fff; --fa-secondary-color: #35729e;--fa-primary-opacity: 1.0; --fa-secondary-opacity: 1.0;"></i>
                        </sup>
                    </ng-container>
                </h3>



                <h6 class="text-primary one-line-ellipsis">{{teamUrl}}</h6>
                <div class="description mb-3">{{team.description || ''}}</div>
                <div *ngIf="team.profileTags">
                    <p class="mb-0"><small class="text-muted">Asset classes and trading style: </small></p>
                    <span *ngFor="let tag of team.profileTags.split(',')"
                        class="badge badge-primary mr-1">{{tag}}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showLeave && !isOwner" class="row jc-r">
        <a class="abandon" title="Abandon Team" href="javascript:void(0)" (click)="abandon()">
            Abandon Team
        </a>
    </div>
</ng-container>

<ng-container *ngIf="listItem">
    <div class="d-flex list-item ml-0 mr-0 z-100" [ngClass]="{'featured': featured}">
        <div class="pr-3">
            <img class="logo" [src]="team.logo||'/assets/no-logo.png'" onError="this.src='/assets/no-logo.png';"
                alt="team logo" class="rounded" width="75" />
        </div>
        <div class="list-item-info">
            <div class="d-flex flex-column">
                <h3 class="title">
                    <span class="one-liner">
                        <ng-container>{{team.label && team.label.length ? team.label : team.name}}</ng-container>
                    </span>
                    <ng-container *ngIf="team['features'].indexOf('TeamVerifiedBadge') !== -1">
                        <span class="verified-badge align-middle ml-2" ngbTooltip="Team verified" container="body">
                            <i class="fad fa-badge-check"
                                style="--fa-primary-color: #fff; --fa-secondary-color: #35729e;--fa-primary-opacity: 1.0; --fa-secondary-opacity: 1.0;"></i>
                        </span>
                    </ng-container>
                </h3>
                <div class="rest-of-info mt-1">
                    <div class="description">{{(team.description || '') | truncateText:155}}</div>
                    <div *ngIf="team.profileTags" class="one-liner list-item-tag-container">
                        <span *ngIf="featured"
                            class="badge badge-primary badge-featured mr-2 list-item-tag">Featured</span>
                        <span *ngFor="let tag of team.profileTags.split(',')"
                            class="badge badge-primary mr-2 list-item-tag">{{tag}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>