import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrandingService } from '../_core/services/branding.service';
import { SharedModule } from '../_shared/shared.module';
import { CopyrightComponent } from './copyright/copyright.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { IntroResolver } from './_commons/intro.resolver';
import { IntroShellComponent } from './_shell/intro-shell.component';
import { RequestEmailComponent } from './request-email/request-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MobileComponent } from './mobile/mobile.component';
import { PinRegistrationComponent } from './pin-registration/pin-registration.component';
import { WebStorageModule } from '@efaps/ngx-store';
import { PublicInviteComponent } from './public-invite/public-invite.component';

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    WebStorageModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    CopyrightComponent,
    IntroShellComponent,
    RequestEmailComponent,
    ResetPasswordComponent,
    MobileComponent,
    PinRegistrationComponent,
    PublicInviteComponent,
  ],
  providers: [
    IntroResolver,
    BrandingService,
  ]
})
export class IntroModule { }
