import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Team } from '../../_shared/models/teams/team';
import { TeamService } from '../../_core/services/team.service';

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html',
  styleUrls: ['./team-info.component.scss']
})
export class TeamInfoComponent implements OnInit {

  @Input() team: Team;
  @Input() showLeave = false;
  @Input() listItem = false;
  @Input() featured = false;
  @Output() abandonTeam: EventEmitter<any> = new EventEmitter();
  isOwner: boolean;

  get teamUrl() {
    return this.team.customDomain ? this.team.customDomain : `${this.team.name}.echofin.com`;
  }

  constructor(
    private teamService: TeamService
  ) { }

  ngOnInit() {
    this.isOwner = this.teamService.activeTeam?.member && this.teamService.activeTeam?.member?.isOwner;
  }

  abandon() {
    this.abandonTeam.emit(null);
  }
}
