import { Injectable } from '@angular/core';
import { LocalStorage } from '@efaps/ngx-store';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  @LocalStorage() visibleModals: { [key: string]: string[] } & any = {
    GLOBAL: [],
  };
  openedModals: any[] = [];

  constructor(private teamService: TeamService) { }

  openFloatModal(modal) {
    console.log('Open modal',  modal);
    const existingModal = this.openedModals.find(m => m.id === modal.id);
    if (!existingModal) {
      this.openedModals.push(modal);
      this.openedModals = this.openedModals.filter((elem, pos, arr) => {
        return arr.findIndex(e => e.id === elem.id) === pos;
      });
    }

    this.focusFloatModal(modal);

    if (!this.visibleModals[modal.teamId]) this.visibleModals[modal.teamId] = [];
    this.visibleModals[modal.teamId].push(modal.id);
    this.visibleModals[modal.teamId] =
      this.visibleModals[modal.teamId].filter((elem, pos, arr) => {
        return arr.findIndex(e => e === elem) === pos;
      });
    this.visibleModals.save();
  }

  focusFloatModal(modal) {
    const lastIndex = this.openedModals[this.openedModals.length - 1].zIndex;
    modal.zIndex = lastIndex + 1;
    const sortedIndexes = [...this.openedModals];

    sortedIndexes
      .sort((a, b) => a.zIndex - b.zIndex)
      .forEach((w, i) => w.zIndex = 100 + i);

    this.openedModals
      .forEach((w) => {
        w.zIndex = sortedIndexes.find(x => x.id === w.id).zIndex;
      });
  }

  minimizeFloatModal(modal) {
    this.visibleModals[modal.teamId] =
      this.visibleModals[modal.teamId].filter((id) => {
        return id !== modal.id;
      });
  }

  closeFloatModal(modal) {
    this.openedModals = this.openedModals.filter((w) => {
      return w.id !== modal.id;
    });
  }

  isFloatModalVisible(modal) {
    return (modal.teamId === 'GLOBAL' ||
      modal.teamId === this.teamService.activeTeamId) &&
      this.visibleModals[modal.teamId].findIndex(e => e === modal.id) > -1;
  }
}
