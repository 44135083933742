<div class="intro-box">
  <img class="logo" src="https://assets.echofin.com/logos/logo_white_md.png">
  <div class="text-center p-4">
    <div>
        <h1>Page not found...</h1>           
    </div>
    <div class="text-light">
     <p><a routerLink="/login">sign in</a></p>
    </div>
  </div>
</div>