import { Component, HostBinding, Input, NgZone, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Events } from '@echofin/libraries';
import { MessageService, MESSAGES_PER_PAGE } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { Message, MessageStatus } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { SearchMessagesService } from './services/search-messages.service';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageType } from '../../_shared/models/message/message-type';
import { ToastrService } from 'ngx-toastr';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';

@Component({
  selector: 'app-panel-search-messages',
  templateUrl: './panel-search-messages.component.html',
  styleUrls: ['./panel-search-messages.component.scss']
})
export class PanelSearchMessagesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  @ViewChild('timeline') timeline: TimelineComponent;
  @ViewChild('searchInput') searchInput: ElementRef;

  teamId: string;
  messages: Message[] = [];
  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.SearchMessages;
  searching: boolean = false;

  text: string = '';

  initialTimelineMessage = 'Type a word or a phrase in the search bar below, to search across team chatrooms.';

  // Modal filter
  from: string = null;
  chatroomIds: string[] = [];
  messageTypes: MessageType[] = [];
  fromId: string = null;

  filterCount = 0;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private searchMessagesService: SearchMessagesService,
    private hostElement: ElementRef,
    private messageService: MessageService,
    private modal: NgbModal,
    private toastr: ToastrService,
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  async ngOnInit() {
    this.messageService.hasMoreMessages[this.panel.id] = false;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelTitle$.next([this.panel.id, 'Search']);
    this.teamId = this.panel.teamId;
    this.messageService.messages[this.panel.id] = [];

    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    this.subscribe(this.panelsService.showedItem$, (roomId: string) => {
      if (roomId === this.panel.id) {
        setTimeout(
          () => {
            this.focusRoom();
          },
          100);
      }
    });
  }

  focusRoom() {
    this.panelsService.focusedItem = this.panel.id;
    this.searchInput.nativeElement.focus({ preventScroll: true });

    this.panelsService.clickedMenuItem$.next(this.panel.id);
  }

  unfocusRoom() {
    this.panelsService.focusedItem = null;
    this.panelsService.clickedMenuItem$.next(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }

  async onSearchClick() {
    this.messages = [];
    this.timeline.searchText = this.searchInput.nativeElement.value.trim();
    if (this.timeline.searchText === '' && this.filterCount === 0) {
      this.toastr.error('Please enter search term');
      return;
    }

    this.searchMessagesService.setSearchPanelState(
      this.teamId,
      this.timeline.searchText,
      this.fromId,
      this.chatroomIds,
      this.messageTypes);

    this.searching = true;
    await this.searchMessagesService.loadSearchMessages(this.panel.id, this.teamId);
    this.messages = this.messageService.messages[this.panel.id];
    this.searching = false;
    this.initialTimelineMessage = null; // disable initial timeline text
  }

  calculateFilterCount() {
    this.filterCount = 0;
    if (this.from && this.fromId) {
      this.filterCount += 1;
    }
    if (this.chatroomIds.length > 0) {
      this.filterCount += 1;
    }
    if (this.messageTypes.length > 0) {
      this.filterCount += 1;
    }
  }

  async handleModalValues(formValues) {
    this.chatroomIds = formValues.chatroomIds;
    this.messageTypes = formValues.messageTypes
      .map((v, i) => (v ? MessageType[v] : null))
      .filter(v => v !== null);

    if (formValues.from) {
      const search = formValues.from.substr(1);
      const selectedMembers = (await this.teamService.searchMembers(search))
        .filter(member => !member.isBanned && member.user.username.toLowerCase().indexOf(search.toLowerCase()) === 0)
        .map(member => member.user.id);
      if (selectedMembers && selectedMembers.length > 0) {
        this.from = formValues.from;
        this.fromId = selectedMembers[0];
      } else {
        this.from = null;
        this.fromId = null;
      }
    } else {
      this.from = null;
      this.fromId = null;
    }
    this.calculateFilterCount();
  }

  openFilterPanel() {
    const modal = this.modal.open(SearchFilterComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'sm',
    });

    modal.componentInstance.from = this.from;
    modal.componentInstance.chatroomIds = this.chatroomIds;
    if (typeof (this.messageTypes) !== 'undefined') {
      modal.componentInstance.messageTypes = this.messageTypes.map(mt => MessageType[mt]);
    }

    modal.result.then(async result => {
      console.log('openFilterPanel apply', result);
      if (result) {
        await this.handleModalValues(result);
      }
    }).catch(error => {
      this.calculateFilterCount();
    });
  }
}
