import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../_core/services/team.service';
import { environment } from 'environments/environment';
import { BaseComponent } from '../base-component';
import { ProfileService } from '../../_core/services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { InvitationExtResponse } from '@echofin/libraries/api/team/models/invitation-ext-response';

@Component({
  selector: 'app-personal-invite',
  templateUrl: './personal-invite.component.html',
  styleUrls: ['./personal-invite.component.scss']
})
export class PersonalInviteComponent extends BaseComponent implements OnInit {

  loading = true;
  invitation: InvitationExtResponse;

  inviteUrl: string;
  @ViewChild('invUrl', { static: false }) inviteUrlElement: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private teamService: TeamService,
    private profileService: ProfileService,
    private toastr: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.invitation = await this.http.get<any>(`${environment.config.endpoints.team}/team/teams/${this.teamService.activeTeamId}/personal-invitation`)
      .toPromise()
      .then(res => { return res; })
      .catch(() => { return null; });
    this.loading = false;

    if (this.invitation) {
      this.inviteUrl = this.constructInvitationUrl();
    }
  }

  constructInvitationUrl() {
    const invCode = this.profileService.me.id.replace("usr_", "");
    if (this.teamService.activeTeam && this.teamService.activeTeam.customDomain) {
      return `https://${this.teamService.activeTeam.customDomain}/invitation/${invCode}`;
    }
    const teamName = (this.teamService.activeTeam && this.teamService.activeTeam.name && this.teamService.activeTeam.name.length > 0) ? this.teamService.activeTeam.name : 'app';

    return `https://${teamName}.${environment.config.domain}/invitation/${invCode}`;
  }

  preventChange() {
    return false;
  }

  copyLink() {
    (this.inviteUrlElement.nativeElement as HTMLInputElement).select();
    (this.inviteUrlElement.nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    (this.inviteUrlElement.nativeElement as HTMLInputElement).blur();
    this.toastr.info('The link has been copied!', null, { closeButton: true, timeOut: 6000 });
  }

  async createPesonalInviteLink() {
    this.loading = true;
    this.invitation = await this.http.post<any>(`${environment.config.endpoints.team}/team/teams/${this.teamService.activeTeamId}/personal-invitation`, {})
      .toPromise()
      .then(res => { return res; })
      .catch(() => { return null; });

    if (this.invitation) {
      this.inviteUrl = this.constructInvitationUrl();
    }
    this.loading = false;
  }
}
