<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Terms and Conditions</h4>
        <button type="button" class="close" aria-label="Close" (click)="m.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="max-height: 400px;overflow-y: auto;">
        <ng-container *ngIf="terms; else loader">
            <ng-container *ngIf="terms.length; else noTerms">
                <ng-container *ngFor="let t of terms">
                    <p *ngIf="t">{{t}}</p>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #loader>
    <div class="text-muted text-center p-5">loading...</div>
</ng-template>

<ng-template #noTerms>
    <div class="text-muted text-center p-5">No Terms</div>
</ng-template>