import { Component, Input, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MessageType } from '../../../_shared/models/message/message-type';
import { TeamService } from '../../../_core/services/team.service';
import { ItemType } from '../../../_shared/models/room/channel';
import { RULE } from '../../../_shared/models/commons/permissions.constants';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ViewMessageType } from '../models/view-message-type';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  messageTypeEnum = MessageType;

  formMessageTypes: string[];
  from: string;
  chatroomIds: string[] = [];
  messageTypes: string[] = [];

  searchFilterForm: FormGroup;
  chatrooms = null;
  rules = RULE;

  chatroomText: string = '';
  selectedMessageTypes: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private hostElement: ElementRef,
    private teamService: TeamService,
    private builder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.chatrooms = this.teamService.activeTeam.sidebarResolved.filter(s => ItemType[s.itemType] === ItemType.Channel && s.permissions.user && s.permissions.user[this.rules.READ_MESSAGES]);

    if (this.from != null || this.chatroomIds.length > 0 || this.messageTypes.length > 0) {
      this.loadForm();
    } else {
      this.resetForm();
    }
  }

  addCheckboxes() {
    this.chatrooms.forEach((o, i) => {
      const selected = this.chatroomIds.filter(c => c === o.refId).length > 0;
      const control = new FormControl(selected);
      (this.searchFilterForm.controls.chatrooms as FormArray).push(control);
    });
    Object.keys(MessageType).forEach((o, i) => {
      const selected = this.messageTypes.filter(c => c === o).length > 0;
      const control = new FormControl(selected);
      (this.searchFilterForm.controls.messageTypes as FormArray).push(control);
    });
  }

  updateMessageTypes() {
    this.selectedMessageTypes = this.searchFilterForm.value.messageTypes
      .map((v, i) => (v ? Object.keys(ViewMessageType)[i] : null))
      .filter(v => v !== null).join(', ');
  }

  updateChatrooms() {
    this.chatroomText = this.searchFilterForm.value.chatrooms
      .map((v, i) => (v ? this.chatrooms[i].label : null))
      .filter(v => v !== null).join(', ');
  }

  userTypeahead = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap(async (text) => {
      if (text.startsWith('@')) {
        const mentions = await this.suggestMentions(text);
        return mentions;
      }
      return [];
    })
  )

  private async suggestMentions(lastTerm: string) {
    const searchTerm = lastTerm.substr(1);
    const members = (await this.teamService.searchMembers(searchTerm))
      .filter(member => !member.isBanned && member.user.username.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0)
      .map(member => `@${member.user.username}`);
    return members
      .sort((a, b) => a > b ? 1 : -1);
  }

  resetForm() {
    this.searchFilterForm = this.builder.group(
      {
        from: [''],
        chatrooms: new FormArray([]),
        messageTypes: new FormArray([]),
        chatroomsText: ['']
      },
      {});
    this.formMessageTypes = Object.keys(ViewMessageType);
    this.chatroomText = '';
    this.selectedMessageTypes = '';
    this.chatroomIds = [];
    this.messageTypes = [];
    this.from = '';
    this.searchFilterForm.value.from = '';
    this.addCheckboxes();
  }

  loadForm() {
    this.searchFilterForm = this.builder.group(
      {
        from: [this.from],
        chatrooms: new FormArray([]),
        messageTypes: new FormArray([]),
        chatroomsText: ['']
      },
      {});
    this.formMessageTypes = Object.keys(ViewMessageType);
    this.addCheckboxes();
    this.updateChatrooms();
    this.updateMessageTypes();
  }

  async applyFilters() {
    const selectedChatrooms = this.searchFilterForm.value.chatrooms
      .map((v, i) => (v ? this.chatrooms[i].refId : null))
      .filter(v => v !== null);

    const selectedMessageTypes = this.searchFilterForm.value.messageTypes
      .map((v, i) => (v ? Object.keys(MessageType)[i] : null))
      .filter(v => v !== null);

    this.chatroomIds = selectedChatrooms;
    this.messageTypes = selectedMessageTypes;
    this.activeModal.close({ from: this.searchFilterForm.value.from, chatroomIds: selectedChatrooms, messageTypes: selectedMessageTypes }); // apply
  }

  ngOnDestroy(): void {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
