import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { User } from '@echofin/libraries/api/message/models/user';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TrackingService } from '../../../_core/services/tracking.service';
import { ToastrService } from 'ngx-toastr';
import { MessageShareComponent } from '../../../chat/message-share/message-share.component';
import { ConfirmService } from '../../../_core/services/confirm.service';
import { MessageService } from '../../../_core/services/message.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { TeamService } from '../../../_core/services/team.service';
import { MessageType } from '../../../_shared/models/message/message-type';
import { BaseComponent } from '../../base-component';
import { ConfirmComponent } from '../../confirm/confirm.component';
import { MarketOverviewComponent } from '../../market-overview/market-overview.component';
import { MultilineModalComponent } from '../../multiline-modal/multiline-modal.component';
import { BookmarkMessagesService } from '../../panel-bookmark-messages/services/bookmark-messages.service';
import { PinboardMessagesService } from '../../panel-pinboard-messages/services/pinboard-messages.service';
import { TimelineType } from '../../timeline/models/timeline-type.enum';
import { RoomType } from '../../../_shared/models/enums/room-type';
import { EmojiService } from '../../../_core/services/emoji.service';
import { RoomService } from '../../../_core/services/room.service';

@Component({
  selector: '[app-message-hover-menu]',
  templateUrl: './message-hover-menu.component.html',
  styleUrls: ['./message-hover-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageHoverMenuComponent extends BaseComponent implements OnDestroy {
  @Input() timelineType: TimelineType;
  @Input() messageId: string;
  @Input() channelId: string;
  @Input() bookmarked: boolean;
  @Input() pinned: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canPin: boolean;
  @Input() sender: User;
  @Input() messageType: MessageType;
  @Input() chatroomType: RoomType;
  @Input() messageText: string;
  @Input() canPost: boolean;
  @Input() panelId: string;
  @Input() isReplyPanel: boolean;

  @Output('menuHoverChange') menuHoverChange = new EventEmitter<boolean>();

  messageTypeEnum = MessageType;
  chatroomTypeEnum = RoomType;

  menu: NgbPopover;
  // reactions: NgbPopover;

  hoverMenuHovering = true;
  clickMenuHovering = false;

  // hack for refocus and tooltip display after modal open/close
  // ngbTooltip - ng-bootstrap issue #3517
  disabledTooltip = false;

  @HostListener('mouseleave')
  mouseLeftComponent() {
    this.hoverMenuHovering = false;

    this.closeAllCheck();
  }

  @HostListener('mouseenter')
  mouseEnteredComponent() {
    this.hoverMenuHovering = true;
    this.menuHoverChange.emit(true);
  }

  get canQuote() {
    return this.canPost && !(this.messageType === MessageType.SECRET || this.messageType === MessageType.SIGNAL || this.messageType === MessageType.MARKET_ANALYSIS) && (this.timelineType === 1 || this.timelineType === 4 || this.timelineType === 8);
  }

  get canReply() {
    return this.canPost && !(this.messageType === MessageType.SECRET) && this.timelineType === 1;
  }

  get canBookmark() {
    return this.chatroomType === this.chatroomTypeEnum.Team;
  }

  constructor(
    private messageService: MessageService,
    private pinboardMessagesService: PinboardMessagesService,
    private bookmarkMessagesService: BookmarkMessagesService,
    private teamService: TeamService,
    private roomService: RoomService,
    private confirmService: ConfirmService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private tracking: TrackingService,
    private emojiService: EmojiService
  ) {
    super();
  }

  private closeAllCheck() {
    // extracted the closeAll method
    // this allows to set a timeout in case the menu is open and easily handle the case of hovering outside while needing the menu to stay open
    // otherwise we close immediately, disabling the "accordeon" effect due to the latency while hovering on consecutive short messages
    if (this.menu && this.menu.isOpen()) {
      setTimeout(
        () => {
          this.closeAll();
        },
        200);
    } else {
      this.closeAll();
    }
  }

  private closeAll() {
    if (!this.hoverMenuHovering && !this.clickMenuHovering) {
      if (this.menu) {
        this.menu.close();
      }
      this.renderer.removeClass(this.hostElement.nativeElement, 'stay-open');
      this.menuHoverChange.emit(false);
    }
  }

  async edit() {
    this.tracking.trackEvent('edit', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    if (this.messageType === this.messageTypeEnum.MARKET_ANALYSIS) {
      await this.editMarketAnalysis();
    } else {
      await this.openMultiLineInput();
    }
  }

  async quote() {
    this.tracking.trackEvent('quote', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    const msg = this.messageService.messages[this.channelId].find(m => m.id === this.messageId);
    this.messageService.addQuotes(this.panelId, this.messageText, msg.files, this.sender.username);
  }

  async reply() {
    this.tracking.trackEvent('reply', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    this.messageService.removeQuotes(this.channelId);
    const message = this.messageService.getMessageFromAnyChannel(this.messageId);

    // check if the message is first reply else send the parentId
    this.messageService.addReplies(this.channelId, !message.reply || message.reply.parent ? this.messageId : message.reply.parentId);
  }

  async openMultiLineInput() {
    const msg = this.messageService.getMessageFromAnyChannel(
      this.messageId
    );
    if (!msg) {
      return;
    }

    const modal = this.modal.open(MultilineModalComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: 'modal-dark'
    });

    const room = this.roomService.getRoomById(msg.chatroom.id);

    modal.componentInstance.messageText = msg.text;
    modal.componentInstance.chatroomName = msg.chatroom.type === 'Team' ? msg.chatroom.name : '';
    modal.componentInstance.participants = room.participants;

    modal.componentInstance.isTeamChatroom = msg.chatroom.type === 'Team';
    modal.componentInstance.chatroomId = msg.chatroom.id;
    modal.componentInstance.isReplyPanel = this.isReplyPanel;

    modal.componentInstance.messageTimestamp = msg.timestamp;
    modal.componentInstance.senderId = msg.sender.id;
    modal.componentInstance.okBtnLabel = 'Update message';
    await modal.result
      .then(async res => {
        if (res && res.text) {
          await this.messageService.replaceMessage(this.messageId, res.text, this.panelId);
        }
      })
      .catch((err) => {
        if (err && err !== 1 && err !== 'cancel') {
          this.toastr.error(err);
        }
      });
  }

  async editMarketAnalysis() {
    const existingMsg = this.messageService.getMessageFromAnyChannel(
      this.messageId
    );
    if (!existingMsg) {
      return;
    }

    const modal = this.modal.open(MarketOverviewComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-dark'
    });

    modal.componentInstance.chatroomId = this.channelId;
    modal.componentInstance.textToEdit = this.messageText;
    modal.componentInstance.isTeamChatroom = this.timelineType === TimelineType.TeamChatroomMessages;
    modal.componentInstance.messageTimestamp = existingMsg.timestamp;
    modal.componentInstance.senderId = existingMsg.sender.id;
    await modal.result
      .then(async res => {
        if (res && res.text) {
          await this.messageService.replaceMessage(this.messageId, res.text, this.panelId);
        }
      })
      .catch((err) => {
        if (err && err !== 1) {
          this.toastr.error(err);
        }
      });
  }

  async delete() {
    this.tracking.trackEvent('delete', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    const res = await this.confirmService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    );
    if (res) {
      await this.messageService.deleteMessage(this.messageId);
    }
  }

  async addPinboardMessage() {
    this.tracking.trackEvent('pinboard-add', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    await this.pinboardMessagesService.addPinboardMessage(
      this.messageId
    );
  }

  async removePinboardMessage() {
    this.tracking.trackEvent('pinboard-remove', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    await this.pinboardMessagesService.removePinboardMessage(
      this.messageId
    );
  }
  async addBookmarkMessage() {
    this.tracking.trackEvent('bookmark-add', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    await this.bookmarkMessagesService.addBookmarkMessage(
      this.messageId
    );
  }

  async removeBookmarkMessage() {
    this.tracking.trackEvent('bookmark-remove', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    await this.bookmarkMessagesService.removeBookmarkMessage(this.messageId);
  }

  async reportMessage() {
    this.tracking.trackEvent('report', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    const modal = this.modal.open(ConfirmComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.confirmTitle = 'Report Message';
    modal.componentInstance.confirmMessage = 'You are about to report this message as inappropriate. Are you sure?';
    modal.result.then(async (result) => {
      if (result) {
        this.messageService.reportMessage(this.messageId).then(() => {
          this.toastr.success('You have succesfully flagged this message as inappropriate');
        })
          .catch(() => {
            this.toastr.error('Error while reporting the message');
          });
      }
    });

  }

  canDeleteMessage() {
    if (this.sender && this.sender.id === this.profileService.me.id) {
      return true;
    }

    return this.canDelete;
  }

  canPinMessage() {
    return this.timelineType !== 4 && this.teamService.activeTeam && this.teamService.activeTeam.permissions.pinboard;
  }

  addReaction(event) {
    this.emojiService.openMessagePicker(event.target, this.messageId);
  }

  shareMessage() {
    this.tracking.trackEvent('share', {
      messageid: this.messageId,
      teamname: this.teamService.activeTeam?.name
    });
    const modal = this.modal.open(MessageShareComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-dark'
    });

    // hack for tooltip not closing
    modal.result.then(() => { this.disabledTooltip = false; }, reason => { this.disabledTooltip = false; });
    this.disabledTooltip = true;

    modal.componentInstance.messageId = this.messageId;
    modal.componentInstance.messageType = this.messageType;
  }

  clickMenuShown(menuModal: NgbPopover) {
    this.menu = menuModal;
  }

  beginHovering() {
    this.clickMenuHovering = true;
    this.menuHoverChange.emit(true);
  }

  stopHovering() {
    this.clickMenuHovering = false;
    this.closeAllCheck();
  }

  clickMenuHoveringInterrupted() {
    this.clickMenuHovering = false;
    this.hoverMenuHovering = false;
    this.closeAllCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
