<ng-container *ngIf="urlData.type==='YOUTUBE'">
    <div class="mt-2 url-preview">
        <div #youTubeElement [innerHtml]="getEmbeddedVideoHtml(this.fullUrl)" class="video-wrapper"></div>
    </div>
</ng-container>
<ng-container *ngIf="urlData.type==='GENERAL' && isVideoUrl">
    <div class="mt-2" [title]="urlData.title">
        <div #generalVideoElement [innerHtml]="getEmbeddedVideoHtml(urlData.url)"></div>
        <a target="_blank" [href]="fullUrl" class="d-block">
            <div class="">
                <strong class="link_title">{{urlData.title}}</strong>
            </div>
            <div>
                <span class="link_description">{{urlData.description}}</span>
            </div>
        </a>
    </div>
</ng-container>
<ng-container *ngIf="urlData.type==='GENERAL' && !isVideoUrl">
    <div *ngIf="urlData.title || urlData.description || urlData.image" class="mt-2 url-preview" [title]="urlData.title">
        <!-- <app-lightbox *ngIf="urlData.image" [src]="urlData.image" [thumb]="urlData.image" [width]="urlData.width"
            [height]="urlData.height" [filename]="urlData.title" (viewIsLoaded)="onViewIsLoaded()">
        </app-lightbox> -->
        <a *ngIf="urlData.image" target="_blank" [href]="fullUrl" class="d-block">
            <img class="img-thumbnail max-h240 p-0" [src]="urlData.image | fixHttps" (error)="urlData.image = null">
        </a>
        <a target="_blank" [href]="fullUrl" class="d-block">
            <div class="">
                <strong class="link_title">{{urlData.title}}</strong>
            </div>
            <div>
                <span class="link_description">{{urlData.description}}</span>
            </div>
        </a>
        <!-- <a target="_blank" [href]="fullUrl" class="d-block">{{urlData.url}} </a> -->
    </div>
</ng-container>
<ng-container *ngIf="urlData.type==='IMAGE'">
    <div *ngIf="urlData.title || urlData.description || urlData.image" class="mt-2 url-preview" [title]="urlData.title">
        <app-lightbox *ngIf="urlData.image" [src]="urlData.image" [thumb]="urlData.image | fixHttps"
            [fullView]="fullView" [messageId]="messageId" [type]="'url'">
        </app-lightbox>
    </div>
</ng-container>