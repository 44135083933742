import { Component, OnInit } from '@angular/core';
import { InvitationServiceApi, TeamTeamsServiceApi } from '@echofin/libraries';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamService } from '../../_core/services/team.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Team } from '../../_shared/models/teams/team';
import { InvitationResponse } from '@echofin/libraries/api/team/models/invitation-response';
import { skipWhile, take, tap, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-invite-preview',
  templateUrl: './invite-preview.component.html',
  styleUrls: ['./invite-preview.component.scss']
})
export class InvitePreviewComponent implements OnInit {

  loading = true;
  invitationId: string;
  invitationName: string;
  invitationTeamId: string;
  invitationTeamName: string;
  invitation: InvitationResponse;
  verificationStatus: 'FAIL_GENERIC' | 'INVITATION_EXPIRED' | 'INVITATION_FULL' | 'INVITATION_ALREADY_ACCEPTED' | 'NOT_FOUND' | 'USER_BANNED' | 'INVALID_EMAIL';
  routeSub: Subscription;
  additionalChatroomNames: string[] = [];
  additionalChatroomNamesFormatted: string;
  roleName: string;
  team: Team;
  alreadyInTeam = false;
  isBanned = false;
  accepting = false;

  get teamLabel() {
    return this.team ? (this.team.label && this.team.label.length ? this.team.label : this.team.name) : '';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    private inviteApi: InvitationServiceApi,
    private teamApi: TeamTeamsServiceApi,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe(async (queryParams) => {
      if (queryParams['inv'] && queryParams['team']) {
        this.invitationTeamId = queryParams['team'];
        this.invitationName = encodeURIComponent(queryParams['inv']);
        const resp = (await this.teamApi.VerifyInvitation({ teamId: queryParams['team'], invitationName: this.invitationName }).toPromise().catch(err => {
          if (!err.error) {
            this.verificationStatus = 'FAIL_GENERIC';
          }
          switch (err.error) {
            case 'INVITATION_EXPIRED':
              this.verificationStatus = 'INVITATION_EXPIRED';
              break;
            case 'INVITATION_FULL':
              this.verificationStatus = 'INVITATION_FULL';
              break;
            case 'INVITATION_ALREADY_ACCEPTED':
              this.verificationStatus = 'INVITATION_ALREADY_ACCEPTED';
              break;
            case 'INVALID_EMAIL':
              this.verificationStatus = 'INVALID_EMAIL';
              break;
          }
          if (err.status === 404) {
            this.verificationStatus = 'NOT_FOUND';
          }
          return null;
        }));

        if (resp) {
          this.invitationId = resp.id;
          this.invitation = resp;

          this.team = await this.getTeam();
          this.invitationTeamName = this.team.name;
          this.isBanned = await this.teamApi.GetIfBanned(this.invitationTeamId).toPromise();
          if (this.isBanned) {
            this.verificationStatus = 'USER_BANNED';
            this.loading = false;
            return;
          }

          if (this.invitation.chatrooms) {
            if (this.alreadyInTeam) {
              const accessibleChatrooms = this.team.chatrooms.filter(c => c.permissions && c.permissions.user && c.permissions.user.read_messages).map(c => c.id);
              // get chatrooms that are in the invitation and are not yet accessible
              const additionalChatrooms = this.team.chatrooms.filter(c => this.invitation.chatrooms.indexOf(c.id) >= 0 && accessibleChatrooms.indexOf(c.id) < 0);
              this.additionalChatroomNames = additionalChatrooms.map(c => `#${c.name}`);
              this.additionalChatroomNamesFormatted = this.additionalChatroomNames.join(', ');
            } else {
              this.additionalChatroomNames = this.invitation.chatroomNames.map(c => `#${c}`);
              this.additionalChatroomNamesFormatted = this.additionalChatroomNames.join(', ');
            }
          }

          this.roleName = this.invitation.roleName;
        }
      }
      this.loading = false;
    });
  }

  async getTeam() {
    const t = this.teamService.teams.find(t => t.id === this.invitationTeamId);
    if (t) {
      this.alreadyInTeam = true;
      return t;
    }

    const tm = await this.teamApi.GetTeam({ teamId: this.invitationTeamId }).toPromise().catch(() => { return null; });
    if (!tm) {
      return null;
    }
    const teamsResp = await this.teamApi.SearchTeams(tm.name).toPromise();
    if (teamsResp && teamsResp.length > 0) {
      return teamsResp[0];
    }
    return null;
  }

  async acceptInvitation() {
    this.accepting = true;

    // set observable before call or we may lose the event
    if (!this.alreadyInTeam) {
      this.teamService.teamAdded$
        .pipe(
          tap(next => {
            console.log('INVITATION - teamAdded', next);
          }),
          timeout(10000),
          skipWhile(t => t.name !== this.team.name),
          take(1))
        .subscribe(
          async t => {
            console.log('INVITATION - teamAdded sub', t);
            await this.close(true);
          },
          async t => {
            // not yet added team
            console.log('INVITATION - teamAdded err', t);
            await this.close(false);
          });
    }

    this.invitation = await this.inviteApi.VerifyUser(this.invitationId).toPromise().catch(err => {
      if (!err.error) {
        this.verificationStatus = 'FAIL_GENERIC';
      }
      switch (err.error) {
        case 'INVITATION_EXPIRED':
          this.verificationStatus = 'INVITATION_EXPIRED';
          break;
        case 'INVITATION_FULL':
          this.verificationStatus = 'INVITATION_FULL';
          break;
        case 'INVITATION_ALREADY_ACCEPTED':
          this.verificationStatus = 'INVITATION_ALREADY_ACCEPTED';
          break;
        case 'INVALID_EMAIL':
          this.verificationStatus = 'INVALID_EMAIL';
          break;
      }
      if (err.status === 404) {
        this.verificationStatus = 'NOT_FOUND';
      }
      return null;
    });

    // if we have any error code set, return and don't proceed to close
    if (this.verificationStatus) {
      return;
    }

    if (this.alreadyInTeam) {
      await this.close(true);
    }
  }

  async close(redirectToTeam: boolean) {
    await this.activeModal.dismiss();
    if (redirectToTeam) {
      console.log('INVITATION - close with redirect');
      await this.teamService.switchTeam(this.invitationTeamId);
      this.router.navigateByUrl(`/${this.invitationTeamName}`);
    } else {
      console.log('INVITATION - close redirect root');
      this.router.navigateByUrl('/');
    }
  }
}
