import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Events, ProfileMembersServiceApi, TeamMembersServiceApi, TeamProfilesServiceApi, TeamRoomsServiceApi, TeamTeamsServiceApi, WidgetsApi } from '@echofin/libraries';
import { ChatroomResp } from '@echofin/libraries/api/team/models/chatroom-resp';
import { MemberResp } from '@echofin/libraries/api/team/models/member-resp';
import { TeamResp } from '@echofin/libraries/api/team/models/team-resp';
import { LocalStorage } from '@efaps/ngx-store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { teamNameRules } from '../../chat/_commons/team-name-rules';
import { Sortable } from '../../_shared/models/commons/sortable';
import { User } from '../../_shared/models/commons/user';
import { CurrentStatus } from '../../_shared/models/enums/current-status';
import { LocalUserStatusChanged, UserStatus } from '../../_shared/models/enums/user-status';
import { Frame } from '../../_shared/models/frame/frame';
import { Chatroom } from '../../_shared/models/room/room';
import { StreamPublished } from '../../_shared/models/stream/stream.published';
import { StreamUnpublished } from '../../_shared/models/stream/stream.unpublished';
import { Divider } from '../../_shared/models/teams/divider';
import { Member } from '../../_shared/models/teams/member';
import { Role } from '../../_shared/models/teams/role';
import { Team } from '../../_shared/models/teams/team';
import { SidebarItem } from './../../_shared/models/teams/sidebar-item';
import { BrandingService } from './branding.service';
import { ProfileService } from './profile.service';
import { EvType, MeetingStarted, MeetingStopped } from './socket.service/models/all.models';
import { SocketService } from './socket.service/socket.service';
import { TeamHandlers } from './state-handlers/team.handlers';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  @LocalStorage()
  sidebarClosed = false;

  @LocalStorage() activeTeamId: string = null;
  @LocalStorage() orderTeamIds: { teamIds: string[], save?: any } = { teamIds: [] };
  @LocalStorage() disclaimerAccepted: { teamIds: string[], save?: any } = { teamIds: [] };

  teams: Team[] = [];
  activeTeamId$: Subject<string> = new Subject<string>();
  teamChanged$: Subject<string> = new Subject<string>();

  connectedUsers: string[] = [];
  members: Member[] = [];
  teamMembers$: Subject<Member[]> = new Subject<Member[]>();
  memberJoined$: Subject<Events.MemberJoined> = new Subject<Events.MemberJoined>();
  memberRoleChanged$: Subject<Events.MemberRoleChanged> = new Subject<Events.MemberRoleChanged>();
  memberLeft$: Subject<Events.MemberLeft> = new Subject<Events.MemberLeft>();
  userDisconnected$: Subject<string> = new Subject<string>();
  userConnected$: Subject<string> = new Subject<string>();

  teams$: Subject<Team[]> = new Subject<Team[]>();
  teamAdded$: Subject<Team> = new Subject<Team>();
  teamUpdated$: Subject<Team> = new Subject<Team>();
  teamRemoved$: Subject<Team> = new Subject<Team>();
  // teamLoaded$: Subject<Team> = new Subject<Team>();
  teamMemberBanned$: Subject<Events.MemberBanned> = new Subject<Events.MemberBanned>();
  teamMemberUnbanned$: Subject<Events.MemberUnbanned> = new Subject<Events.MemberUnbanned>();

  teamSidebarChanged$: Subject<Team> = new Subject<Team>();
  roleChanged$: Subject<Role> = new Subject<Role>();
  rolesReordered$: Subject<Events.RolesReordered> = new Subject<Events.RolesReordered>();

  frameCreated$: Subject<Events.FrameCreated> = new Subject<Events.FrameCreated>();
  frameUpdated$: Subject<Events.FrameUpdated> = new Subject<Events.FrameUpdated>();
  frameDeleted$: Subject<Events.FrameDeleted> = new Subject<Events.FrameDeleted>();

  streamPublished$: Subject<StreamPublished> = new Subject<StreamPublished>();
  streamUnpublished$: Subject<StreamUnpublished> = new Subject<StreamUnpublished>();

  widgetAdded$: Subject<Events.WidgetAdded> = new Subject<Events.WidgetAdded>();
  widgetRemoved$: Subject<Events.WidgetRemoved> = new Subject<Events.WidgetRemoved>();
  widgetUpdated$: Subject<Events.WidgetUpdated> = new Subject<Events.WidgetUpdated>();
  widgetsReordered$: Subject<Events.WidgetsReordered> = new Subject<Events.WidgetsReordered>();

  mustUpdateSidebar$: Subject<void> = new Subject<void>();

  teamRoomPermUpdated$: Subject<Chatroom> = new Subject<Chatroom>();

  meetingsUpdated$: Subject<{ [key: string]: any[] }> = new Subject<{ [key: string]: any[] }>();
  meetings: { [key: string]: any[] } = {};

  constructor(
    private teamApi: TeamTeamsServiceApi,
    private roomApi: TeamRoomsServiceApi,
    private memberApi: TeamMembersServiceApi,
    private profileMembersApi: ProfileMembersServiceApi,
    private teamProfileApi: TeamProfilesServiceApi,
    private profileService: ProfileService,
    private brandingService: BrandingService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private socketService: SocketService,
    private widgetsApi: WidgetsApi,
    private router: Router,
    private http: HttpClient,
  ) { }

  get activeTeam() {
    return this.teams.find(t => t.id === this.activeTeamId);
  }

  async loadTeams() {
    this.teams = await this.teamApi.GetUserTeams().toPromise();
    this.teams.forEach((team) => {
      this.recalculateUnread(team);
    });
    this.teams$.next(this.teams);
  }

  getTeamToLoad(teamName: string): { team: Team, correctTeamName: string } {
    let team: Team;
    let correctTeamName = (teamNameRules.blackListedTeamNames.indexOf(teamName) < 0) ? teamName : null;
    if (!teamName && !this.brandingService.isCustomDomain()) {
      // got in on app.echofin.com, so get localStorage active team else default team
      team =
        this.activeTeam ?
          this.activeTeam :
          (
            (this.teams && this.teams.length) ?
              this.teams[0] :
              null
          );
    } else if (!teamName && this.brandingService.isCustomDomain()) {
      // got in on custom domain, so get teamname from branding to be sure
      correctTeamName = this.brandingService.team.name;
      // and try to find team from myteams
      team = this.teams.find(t => t.name === correctTeamName);
    } else {
      // got in on app.echofin.com/teamname get the team
      team = this.teams.find(t => t.name === teamName);
    }
    return { team, correctTeamName };
  }

  recalculateUnread(team) {
    team.unread = team.chatrooms.reduce((accumulator, currentValue) => accumulator + (currentValue['unread'] || 0), 0);
  }

  setup() {
    this.setupSocketSubs();
    this.setupTeamSubs();
    this.setupOtherSubs();
  }

  getTeam(teamId: string): Team {
    return this.teams.find(t => t.id === teamId);
  }

  findRole(roleId: string): Role {
    let role = null;
    this.teams.forEach(t => {
      if (t.roles && !role) {
        role = t.roles.find(o => o.id === roleId);
      }
    });
    return role;
  }

  getRoom(roomId: string): ChatroomResp {
    let chatroom = null;
    for (let index = 0; index < this.teams.length; index++) {
      const team = this.teams[index];
      if (!team.chatrooms) continue;
      if (team.chatrooms.find(c => c.id === roomId)) {
        chatroom = team.chatrooms.find(c => c.id === roomId);
      }
    }
    return chatroom;
  }

  getFrame(frameId: string): Frame {
    let ret = null;
    this.teams.forEach((team: Team) => {
      if (!team.frames) { return; }
      team.frames.forEach((frame: Frame) => {
        if (frame.id === frameId) { ret = frame; }
      });
    });
    return ret;
  }

  searchTeams(term: string) {
    if (term && term.length < 2) {
      return;
    }

    return this.teamApi.SearchTeams(term).toPromise();
  }

  async discoverTeams(term: string) {
    return await this.teamApi.DiscoverTeams(term).toPromise();
  }

  getFeaturedTeams() {
    return this.teamApi.GetFeaturedTeams().toPromise();
  }

  async searchMembers(term: string, online: boolean = false, skip: number = 0, take: number = 100): Promise<Member[]> {
    if (!this.activeTeamId) { return; }
    this.members = await this.profileMembersApi
      .SearchMembers({
        TeamId: this.activeTeamId,
        Online: online,
        Username: term,
        Skip: skip,
        Take: take
      })
      .toPromise()
      .then((resp: MemberResp[]) =>
        resp.map((r: MemberResp) => {
          return { ...r, lastActive: new Date(r.lastActive) } as Member;
        })
      )
      .catch((err) => {
        this.toastr.error('Could not get members');
        return [];
      });
    return this.members;
  }

  joinTeam(teamId: string, acceptedTermsField: boolean) {
    return this.memberApi
      .CreateMember({
        teamId,
        userId: this.profileService.me.id,
        acceptedTerms: acceptedTermsField
      })
      .toPromise();
  }

  leaveActiveTeam() {
    return this.memberApi
      .RemoveMember({
        TeamId: this.activeTeam.id,
        UserId: this.profileService.me.id
      })
      .toPromise()
      .catch(err => {
        if (err.status === 403) {
          this.toastr.error('You can\'t leave team');
        } else {
          this.toastr.error('Leave team failed');
        }
        return Promise.reject(err);
      });
  }

  async createTeam(team: { name: string, logo: string, profileTags: string, description: string }, invitation: string): Promise<any> {
    return this.teamApi
      .CreateTeam({
        name: team.name,
        logo: team.logo,
        profileTags: team.profileTags,
        description: team.description,
        invitationCode: invitation
      })
      .toPromise()
      .catch(err => Promise.reject(err));
  }

  // recalcUnreads(teamId) {
  //   const team = this.getTeam(teamId);
  //   team.unread = team.chatrooms
  //     .reduce((accumulator, currentValue) => accumulator + (currentValue['unread'] || 0), 0);
  // }

  getMember(userId: string): Member {
    return this.members.find(mem => mem.user.id === userId);
  }

  async getMemberRequest(userId: string): Promise<Member> {
    if (!this.activeTeamId) { return; }
    const member = await this.memberApi
      .GetMember({
        UserId: userId,
        TeamId: this.activeTeamId
      })
      .toPromise()
      .then((resp: MemberResp) => {
        return { ...resp, lastActive: new Date(resp.lastActive) } as Member;
      })
      .catch((err) => {
        this.toastr.error('Could not find user on team');
        return null;
      });
    return member;
  }

  async blockUser(blockerId: string, blockedId: string) {
    await this.teamProfileApi
      .Block({
        userId: blockerId,
        body: {
          blockedUserId: blockedId
        }
      })
      .toPromise()
      .then((res) => {
        const member = this.members.find(m => m.user.id === blockedId);
        member.isBlocked = true;
        return res;
      })
      .catch((err) => {
        this.toastr.error(err.error.message, 'Error');
        return null;
      });
  }

  async unblockUser(blockerId: string, blockedId: string) {
    await this.teamProfileApi
      .Unblock({
        userId: blockerId,
        body: {
          blockedUserId: blockedId
        }
      })
      .toPromise()
      .then((res) => {
        const member = this.members.find(m => m.userId === blockedId);
        member.isBlocked = false;
        return res;
      })
      .catch((err) => {
        this.toastr.error(err.error.message, 'Error');
        return null;
      });
  }

  toggleBan(userId: string, teamId: string) {
    return this.memberApi
      .Ban({
        UserId: userId,
        TeamId: teamId,
      })
      .toPromise()
      .then((res) => {
        if (res.isBanned) {
          this.toastr.success('User Banned', 'Success');
        } else {
          this.toastr.success('User Unbanned', 'Success');
        }
        // const member = this.teamMembers.find(m => m.id === banId);
        // member.blocked = true;
        return res;
      })
      .catch((err) => {
        this.toastr.error(err.error.message, 'Error');
        return null;
      });
  }

  joinGroupRoom(roomId: string, userId: string) {
    return this.roomApi
      .AddParticipant({
        roomId,
        model: {
          userId,
        }
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error(err.error.Message, 'Error');
        return null;
      });
  }

  async switchTeam(teamId: string, forceLoad: boolean = false) {
    const index = this.teams.findIndex(x => x.id === teamId);

    // if we do not want to force api reload and the team exists in the teams list and...
    if (!forceLoad && this.teams[index] &&
      // if resolved team sidebar
      ((this.teams[index].sidebarResolved && this.teams[index].sidebarResolved.length) ||
        // or is banned (so sidebar is not resolved0)
        this.teams[index].member && this.teams[index].member.isBanned)) {

      this.activeTeamId = this.teams[index].id;
      this.activeTeamId$.next(teamId);
      return;
    }

    const fetchedTeam = await this.teamApi.GetTeam({ teamId, ext: true }).toPromise().catch(() => {
      return;
    });
    if (!fetchedTeam) {
      this.toastr.error('You are not a member of this team!');
      // switch to first team IF it exists and it's not the team you just tried to fetch!
      if (this.teams && this.teams.length > 0 && teamId !== this.teams[0].id) {
        this.switchTeam(this.teams[0].id);
      }
      return;
    }

    this.parseSidebar(fetchedTeam);

    this.upsertTeamInTeams(fetchedTeam);

    this.activeTeamId = fetchedTeam.id;
    this.recalculateUnread(this.activeTeam);
    this.activeTeamId$.next(fetchedTeam.id);
  }

  upsertTeamInTeams(fetchedTeam: TeamResp) {
    const index = this.teams.findIndex(x => x.id === fetchedTeam.id);
    if (index === -1) {
      this.log('PUSH EXTENDED INTO TEAMS');
      this.teams.push(fetchedTeam);
    } else {
      this.log('REPLACE TEAM WITH EXTENDED', index);
      this.log(this.teams[index].member);
      this.teams[index] = fetchedTeam;
      this.log(this.teams[index].member);
    }
  }

  parseSidebar(team: Team) {
    const newSidebar: (SidebarItem & Sortable)[] = [];

    (team.dividers || []).sort((a, b) => {
      return a.sort - b.sort;
    });
    const firstDivider = (team.dividers && team.dividers.length > 0) ? team.dividers[0].id : null;

    (team.dividers || []).filter(c => c.sort !== -1).forEach(d => {
      newSidebar.push({
        refId: d.id,
        teamId: team.id,
        itemType: 'Divider',
        label: d.label,
        sort: d.sort,
        isHidden: false,
        isFirstDivider: firstDivider === d.id,
        color: null
      });
    });

    (team.chatrooms || []).filter(c => c.sort !== -1).forEach(c => {
      newSidebar.push({
        refId: c.id,
        teamId: team.id,
        itemType: 'Channel',
        label: c.name,
        permissions: c.permissions,
        sort: c.sort,
        isHidden: c.isHidden,
        subtype: c.subtype,
        color: c.color
      });
    });

    (team.frames || []).filter(c => c.sort !== -1).forEach(f => {
      newSidebar.push({
        refId: f.id,
        teamId: team.id,
        itemType: 'Frame',
        label: f.name,
        sort: f.sort,
        isHidden: false,
        newWindow: f.newWindow,
        color: f.color
      });
    });

    team.sidebarResolved = (newSidebar || []).sort((a, b) => {
      return a.sort - b.sort;
    });

    if (team.id === this.activeTeamId) {
      this.mustUpdateSidebar$.next();
    }
  }

  // privates

  private setupTeamSubs() {

    this.activeTeamId$.subscribe(async (activeTeamId) => {
      this.activeTeamId = activeTeamId;
      this.members = [];
      this.teamChanged$.next(this.activeTeamId);

      await this.loadMeetings();
    });

    // this.teamLoaded$.subscribe((team) => {
    //   this.log('Team loaded', team);
    //   this.parseSidebar(team);
    //   const index = this.teams.findIndex(t => t.id === team.id);
    //   this.teams[index] = team;
    // });

    this.teamAdded$.subscribe((team) => {
      this.teams.push({ ...team });
    });

    this.teamUpdated$.subscribe((team) => {
      const index = this.teams.findIndex(t => t.id === team.id);
      this.teams[index] = { ...team };
    });

    this.teamRemoved$.subscribe((team) => {
      const isActive = this.activeTeam.id === team.id;

      const i = this.teams.findIndex(x => x.id === team.id);
      if (i !== -1) {
        this.teams.splice(i, 1);
      }

      if (isActive) {
        if (this.teams && this.teams.length > 0) {
          this.router.navigateByUrl(`/${this.teams[0].name}`);
        } else {
          this.router.navigateByUrl('');
        }
      }
    });

    this.profileService
      .userStatusChanged$
      .subscribe((data: LocalUserStatusChanged) => {
        this.setMemberStatus({
          id: data.userId,
          status: CurrentStatus[data.currentStatus]
        });
        // this.ngZone.run(() => {
        if (data.currentStatus !== UserStatus.OFFLINE) {
          this.setConnectedUsers([data.userId]);
          this.userConnected$.next(data.userId);
          return;
        }
        this.setDisConnectedUsers([data.userId]);
        this.userDisconnected$.next(data.userId);
        // });
      });

    this.socketService
      .getStream(EvType.RolesReordered)
      .subscribe((data: Events.RolesReordered) => {
        const team = this.teams.find(t => t.id === data.teamId);
        const roles = data.roleIds.map((roleId) => team.roles.find(r => r.id === roleId));
        team.roles = roles;
        this.rolesReordered$.next(data);
      });

    this.socketService
      .getStream(EvType.RoleSaved)
      .subscribe((data: Events.RoleSaved) => {
        if (this.activeTeamId !== data.teamId) return;
        const roleIndex = this.activeTeam.roles.findIndex((r: Role) => r.id === data.role.id);
        if (roleIndex >= 0) {
          this.activeTeam.roles[roleIndex] = data.role;
        } else {
          this.activeTeam.roles.push(data.role);
        }
        this.roleChanged$.next(data.role);
      });

    this.socketService
      .getStream(EvType.MemberBanned)
      .subscribe((data: Events.MemberBanned) => {
        if (this.activeTeam.member.userId === data.userId && this.activeTeam.member.teamId === data.teamId) { // for banned user
          this.activeTeam.member.isBanned = true;

          this.switchTeam(data.teamId, true); // reload team
        }
        if (this.activeTeam.members) { // for other users
          const member = this.activeTeam.members.find(f => f.teamId === data.userId && f.userId === data.userId);
          if (member) {
            member.isBanned = true;
          }
        }
        this.teamMemberBanned$.next(data);
      });

    this.socketService
      .getStream(EvType.MemberUnbanned)
      .subscribe(async (data: Events.MemberUnbanned) => {
        if (this.activeTeam.member.userId === data.userId && this.activeTeam.member.teamId === data.teamId) { // for unbanned user
          this.activeTeam.member.isBanned = false;

          this.switchTeam(data.teamId, true); // reload team
        }
        // if (this.activeTeam.member.userId === data.userId) {
        //   await this.socketService.connect();
        // }
        if (this.activeTeam.members) { // for other users
          const member = this.activeTeam.members.find(f => f.teamId === data.teamId && f.userId === data.userId);
          if (member) {
            member.isBanned = false;
          }
        }
        this.teamMemberUnbanned$.next(data);
      });
  }

  async loadMeetings() {
    const url = environment?.config?.endpoints ? environment?.config?.endpoints['stream'] : '';
    const resp = await this.http
      .get<{ meetings: any[] }>(`${url}/stream/meetings`, {
        params: {
          teamId: this.activeTeamId,
          status: 'Started'
        }
      })
      .toPromise()
      .catch(() => { return null; });

    this.meetings[this.activeTeamId] = resp?.meetings || [];
    this.meetingsUpdated$.next(this.meetings);
  }

  private setupOtherSubs() {
    // this.profileService.me$.subscribe((me) => {
    //   this.teamApi
    //     .GetTeams()
    //     .toPromise()
    //     .then((res) => {
    //       this.setTeams(res.map(r => r as unknown as Team));
    //     })
    //     .catch((err) => {
    //       this.teams.concat([]);
    //       this.teams$.next(this.teams);
    //     });
    // });

    this.socketService
      .getStream(EvType.MeetingStarted)
      .subscribe((data: MeetingStarted) => {
        this.ngZone.run(async () => {
          if (data.teamId !== this.activeTeamId) return;
          await this.loadMeetings();
        });
      });

    this.socketService
      .getStream(EvType.MeetingStopped)
      .subscribe((data: MeetingStopped) => {
        this.ngZone.run(async () => {
          if (data.teamId !== this.activeTeamId) return;
          this.meetings[data.teamId] = this.meetings[data.teamId].filter(x => x.chatroomId !== data.chatroomId);
          this.meetingsUpdated$.next(this.meetings);
        });
      });

    this.socketService
      .getStream(EvType.MemberJoined)
      .subscribe((data: Events.MemberJoined) => {
        this.ngZone.run(async () => {
          if (data.teamId !== this.activeTeamId) return;
          if (this.members.findIndex(m => m.userId === data.userId) >= 0) { return; }

          const profile: User = await this.profileService.getUser(data.userId);
          if (!profile) return;
          this.members.push({
            user: profile as User,
            roleId: profile.roleId,
            teamId: data.teamId,
            lastActive: new Date()
          });

          this.memberJoined$.next(data);
        });
      });

    this.socketService
      .getStream(EvType.MemberRoleChanged)
      .subscribe((data: Events.MemberRoleChanged) => {
        if (this.activeTeamId === data.teamId) {
          const member = this.members.find(m => m.userId === data.userId);
          if (member) {
            member.roleId = data.roleId;
          }
        }
        this.memberRoleChanged$.next(data);
      });

    this.socketService
      .getStream(EvType.WidgetAdded)
      .subscribe(async (data: Events.WidgetAdded) => {
        if (data.teamId !== this.activeTeamId) return;
        const team = this.teams.find(x => x.id === data.teamId);
        if (!team) return;
        const widget = await this.widgetsApi.GetWidget(data.widget.id).toPromise().catch(() => { return null; });
        if (!widget) return;
        if (!team.widgets) {
          team.widgets = [];
        }
        team.widgets.push(widget);
        this.widgetAdded$.next(data);
      });

    this.socketService
      .getStream(EvType.WidgetRemoved)
      .subscribe(async (data: Events.WidgetRemoved) => {
        if (data.teamId !== this.activeTeamId) return;
        const team = this.teams.find(x => x.id === data.teamId);
        if (!team) return;

        team.widgets = team.widgets.filter(w => w.id !== data.widgetId);

        this.widgetRemoved$.next(data);
      });
    this.socketService
      .getStream(EvType.WidgetsReordered)
      .subscribe(async (data: Events.WidgetsReordered) => {
        if (data.teamId !== this.activeTeamId) return;
        const team = this.teams.find(x => x.id === data.teamId);
        if (!team) return;
        this.widgetsReordered$.next(data);
      });
  }

  private setupSocketSubs() {
    this.socketService
      .getStream(EvType.TeamUpdated)
      .subscribe((data: Events.TeamUpdated) => {
        const team = TeamHandlers.handleTeamUpdated(data, this.teams);
        this.teamUpdated$.next(team);
        this.mustUpdateSidebar$.next();
      });

    this.socketService
      .getStream(EvType.SidebarReorderded)
      .subscribe(async (data: Events.SidebarReorderded) => {

        this.log('SidebarReorderded', data);

        await this.fillMissingSidebarItems(data);

        const team = TeamHandlers.handleSidebarChanged(data, this.teams);

        if (!team) return;
        this.parseSidebar(team);

        this.teamSidebarChanged$.next(team);
      });

    this.socketService
      .getStream(EvType.DividerCreated)
      .subscribe((data: Events.DividerCreated) => {
        const team: Team = this.teams.find(t => t.id === data.teamId);
        if (!team) return;
        if (!team.dividers) {
          team.dividers = [];
        }
        team.dividers.push(data.divider as (Divider & Sortable));
        this.parseSidebar(team);
        this.log('Sidebar changed', team.sidebarResolved);

        this.teamSidebarChanged$.next(team);
      });

    this.socketService
      .getStream(EvType.DividerUpdated)
      .subscribe((data: Events.DividerUpdated) => {
        const team: Team = this.teams.find(t => t.id === data.teamId);
        if (!team) return;

        const d = team.dividers.find(d => d.id === data.dividerId);
        d.label = data.label;
        this.parseSidebar(team);
        this.log('Sidebar changed', team.sidebarResolved);

        this.teamSidebarChanged$.next(team);
      });

    this.socketService
      .getStream(EvType.DividerRemoved)
      .subscribe((data: Events.DividerRemoved) => {
        const team: Team = this.teams.find(t => t.id === data.teamId);
        if (!team) return;

        team.dividers = team.dividers.filter(d => d.id !== data.dividerId);
        this.parseSidebar(team);
        this.log('Sidebar changed', team.sidebarResolved);

        this.teamSidebarChanged$.next(team);
      });

    this.socketService
      .getStream(EvType.RoleRemoved)
      .subscribe((data: Events.RoleRemoved) => {
        const team: Team = this.teams.find((t: Team) => t.id === data.teamId);
        if (!team) return;
        const roleIndex = team.roles.findIndex((r: Role) => r.id === data.roleId);
        if (roleIndex !== -1) {
          team.roles.splice(roleIndex, 1);
          this.roleChanged$.next(team);
        }
      });

    this.socketService
      .getStream(EvType.ProfileTeamsChanged)
      .subscribe(async (data: Events.ProfileTeamsChanged) => {
        this.removeNotExistingTeams(data.teamIds);

        const currentTeamIds = this.teams.map(c => c.id);
        const newTeamIds = data.teamIds.filter(t => !currentTeamIds.includes(t));

        if (newTeamIds.length) {
          const teams = await this.teamApi.GetUserTeams().toPromise();
          const newTeams = teams.filter(t => newTeamIds.includes(t.id));
          this.addNewTeams(newTeams);
        }

        this.teams$.next(this.teams);
      });

    // this.socketService
    //   .getStream(EvType.FrameCreated)
    //   .subscribe((data: Events.FrameCreated) => {
    //     this.log('Events.FrameCreated', data);
    //     const team = this.teams.find(x => x.id === data.frame.teamId);
    //     if (!team) {
    //       return;
    //     }
    //     data.frame.sort = -1;
    //     team.frames.push(data.frame);
    //     this.parseSidebar(team);
    //     this.frameCreated$.next(data);
    //   });

    this.socketService
      .getStream(EvType.FrameUpdated)
      .subscribe((data: Events.FrameUpdated) => {
        this.log('Events.FrameUpdated', data);

        const team = this.teams.find(x => x.id === data.frame.teamId);
        if (!team) {
          return;
        }
        if (team.frames) {
          const index = team.frames.findIndex(t => t.id === data.frame.id);
          if (index < 0) {
            return;
          }
          team.frames[index] = { ...data.frame, sort: team.frames[index].sort };
        }
        this.parseSidebar(team);

        this.frameUpdated$.next(data);
      });

    this.socketService
      .getStream(EvType.FrameDeleted)
      .subscribe((data: Events.FrameDeleted) => {
        this.log('Events.FrameDeleted', data);
        const team = this.teams.find(x => x.id === data.frame.teamId);
        if (!team) {
          return;
        }
        if (team.frames) {
          const index = team.frames.findIndex(t => t.id === data.frame.id);
          if (index !== -1) {
            team.frames.splice(index, 1);
          }
        }
        this.parseSidebar(team);
        this.frameDeleted$.next(data);
      });

    this.socketService
      .getStream(EvType.MemberLeft)
      .subscribe((data: Events.MemberLeft) => {
        this.log('Events.MemberLeft', data);
        if (data.teamId === this.activeTeamId && this.profileService.me.id !== data.userId) {

          const mIdx = this.members.findIndex(m => m.userId !== data.userId);
          if (mIdx !== -1) {
            this.members.splice(mIdx, 1);
          }
        }
        this.memberLeft$.next(data);
      });

    this.socketService
      .getStream(EvType.MembersPermChanged)
      .subscribe(async (data: Events.MembersPermChanged) => {
        this.log('Events.MembersPermChanged', data);

        const team = this.teams.find(x => x.id === data.teamId);
        if (!team) {
          return;
        }

        const newteam = await this.teamApi.GetTeam({
          teamId: data.teamId,
          ext: true
        }).toPromise().catch(() => { return null; });
        if (!newteam) {
          return;
        }

        team.permissions = newteam.permissions;

        newteam.chatrooms.forEach(newchatroom => {
          const chatroom = team.chatrooms.find(c => c.id === newchatroom.id);
          if (chatroom) {
            chatroom.permissions = newchatroom.permissions;
            this.teamRoomPermUpdated$.next(chatroom);
          }
        });
      });

    this.socketService
      .getStream(EvType.RolePermChanged)
      .subscribe(async (data: Events.RolePermChanged) => {
        this.log('Events.RolePermChanged', data);

        const team = this.teams.find(x => x.id === data.teamId);
        if (!team) {
          return;
        }

        // skip if it is not my role
        if (team.member.roleId !== data.roleId) {
          return;
        }

        const newteam = await this.teamApi.GetTeam({
          teamId: data.teamId,
          ext: true
        }).toPromise().catch(() => { return null; });
        if (!newteam) {
          return;
        }

        newteam.chatrooms.forEach(newchatroom => {
          const chatroom = team.chatrooms.find(c => c.id === newchatroom.id);
          if (chatroom) {
            chatroom.permissions = newchatroom.permissions;
          }
        });

        this.parseSidebar(team);

        team.chatrooms.forEach(chatroom => {
          this.teamRoomPermUpdated$.next(chatroom);
        });

        // skip if same permissions
        if (team.permissions.length !== newteam.permissions.length) {
          team.permissions = newteam.permissions;
        } else {
          let count = 0;
          for (const permission in newteam.permissions) {
            if (team.permissions[permission] === newteam.permissions[permission]) {
              count++;
            } else {
              break;
            }
          }
          if (count !== Object.keys(team.permissions).length) {
            team.permissions = newteam.permissions;
          }
        }

        this.mustUpdateSidebar$.next();
      });

    this.socketService
      .getStream(EvType.MemberOwnRoleChanged)
      .subscribe(async (data: Events.MemberOwnRoleChanged) => {
        this.log('Events.MemberOwnRoleChanged ', data);

        const team = this.teams.find(x => x.id === data.teamId);
        if (!team || !team.sidebarResolved) {
          return;
        }

        team.member.roleId = data.roleId;

        const newteam = await this.teamApi.GetTeam({
          teamId: data.teamId,
          ext: true
        }).toPromise().catch(() => { return null; });
        if (!newteam) {
          return;
        }

        newteam.chatrooms.forEach(newchatroom => {
          const chatroom = team.chatrooms.find(c => c.id === newchatroom.id);
          if (chatroom) {
            chatroom.permissions = newchatroom.permissions;
          }
        });

        this.parseSidebar(team);

        team.chatrooms.forEach(chatroom => {
          this.teamRoomPermUpdated$.next(chatroom);
        });

        // skip if same permissions
        if (team.permissions && team.permissions.length === newteam.permissions.length) {
          let count = 0;
          for (const permission in newteam.permissions) {
            if (team.permissions[permission] === newteam.permissions[permission]) {
              count++;
            } else {
              break;
            }
          }
          if (count === Object.keys(team.permissions).length) {
            return;
          }
        }

        team.member = newteam.member;
        team.permissions = newteam.permissions;

        this.mustUpdateSidebar$.next();
      });
  }

  async fillMissingSidebarItems(event: Events.SidebarReorderded) {

    const team = this.teams.find(t => t.id === event.teamId);
    if (!team) {
      return;
    }
    const currentIds = (team.chatrooms || []).map(c => c.id).concat((team.frames || []).map(f => f.id)).concat((team.dividers || []).map(d => d.id));

    const missingItems = event.items.map((item, i, arr) => { return { id: item, order: i }; }).filter((item, i, arr) => currentIds.indexOf(item.id) === -1);

    let fetchedTeam: TeamResp;
    for (let i = 0; i < missingItems.length; i++) {
      const sidebarItem = missingItems[i];
      switch (sidebarItem.id.substr(0, 4)) {
        case 'srv_':
          fetchedTeam = fetchedTeam ?
            fetchedTeam :
            (await this.teamApi.GetTeam({ teamId: event.teamId, ext: true }).toPromise().catch(() => { return null; }));
          if (!fetchedTeam) {
            break;
          }
          const f = (fetchedTeam.frames || []).find(i => i.id === sidebarItem.id);
          if (!team.frames) {
            team.frames = [];
          }
          team.frames.push({ ...f, sort: -1 });
          break;
        case 'chr_':
        case 'chn_':
          const c = fetchedTeam ?
            (fetchedTeam.chatrooms || []).find(i => i.id === sidebarItem.id) :
            await this.roomApi.GetChatroom(sidebarItem.id).toPromise().catch(() => { return null; });
          if (!c) {
            break;
          }
          if (!team.chatrooms) {
            team.chatrooms = [];
          }
          team.chatrooms.push({ ...c, sort: -1 } as Chatroom);
          break;
        default:
          fetchedTeam = fetchedTeam ?
            fetchedTeam :
            (await this.teamApi.GetTeam({ teamId: event.teamId, ext: false }).toPromise().catch(() => { return null; }));
          if (!fetchedTeam) {
            break;
          }
          const d = (fetchedTeam.dividers || []).find(i => i.id === sidebarItem.id);
          if (!team.dividers) {
            team.dividers = [];
          }
          team.dividers.push({ ...d, sort: -1 });
          break;
      }
    }
  }




  private setConnectedUsers(profileIds: string[]) {
    for (const profileId of profileIds) {
      if (this.connectedUsers.indexOf(profileId) === -1) {
        this.connectedUsers = this.connectedUsers.concat([profileId]);
      }
    }
  }

  private setDisConnectedUsers(profileIds: string[]) {
    for (const profileId of profileIds) {
      this.connectedUsers = this.connectedUsers.filter(x => x !== profileId);
    }
  }

  private setMemberStatus(user: User): any {
    this.ngZone.run(() => {
      const memb = this.members.find(x => x.user.id === user.id);
      if (!memb) {
        return;
      }
      memb.user.status = user.status;
    });
  }

  private removeNotExistingTeams(teamIds: string[]): void {
    let finalTeams: Team[] = Object.assign([], this.teams);
    finalTeams.forEach((team, i) => {
      const foundInNewSet = teamIds.find(id => id === team.id);
      if (!foundInNewSet) {
        this.log('team splice');
        finalTeams = finalTeams.filter(t => t.id !== team.id);
        this.teamRemoved$.next(team);
      }
    });
    this.teams = finalTeams;
  }

  private addNewTeams(teams: Team[]): void {
    teams.forEach((team) => {
      this.log('Team not found and inserting', team.name, team);
      this.teamAdded$.next(team);
    });
  }

  public removeTeam(teamId: string) {
    const team = this.teams.find(t => t.id === teamId);
    this.teamRemoved$.next(team);
  }

  checkFeature(teamId: string, name: string) {
    var team = this.getTeam(teamId)
    return team['features'].indexOf(name) > -1;
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[TEAM]', 'color:#9C27B0', ...arg);
    }
  }
}
