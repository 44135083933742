import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateService } from '../../../_core/services/date.service';
import { Message } from '../../../_shared/models/commons/message';

@Component({
  selector: 'app-daily-date',
  templateUrl: './daily-date.component.html',
  styleUrls: ['./daily-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyDate implements OnInit, OnDestroy {
  @Input() message: Message | any;
  titleFormatted: string;

  private subs: Subscription[] = [];

  constructor(
    private dateService: DateService,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {

  }

  ngOnInit() {
    this.titleFormatted = this.dateService.getDayHeader(this.message.timestamp);

    this.subs.push(this.dateService.timezoneChanged$.subscribe(
      (timezone: string) => {
        this.titleFormatted = this.dateService.getDayHeader(this.message.timestamp);
        this.ch.markForCheck();
      }
    ));

    this.subs.push(this.dateService.timeformatChanged$.subscribe(
      (is24h: boolean) => {
        this.titleFormatted = this.dateService.getDayHeader(this.message.timestamp);
        this.ch.markForCheck();
      }
    ));
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      s.unsubscribe();
    });

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
