import { UrlMatchResult, UrlSegment } from '@angular/router';
import { ChatShellComponent } from '../_shell/chat-shell.component';
import { ChatResolver } from './chat.resolver';
import { DomainGuard } from './domain.guard';
import { DesktopGuard } from '../../intro/_commons/desktop.guard';
import { teamNameRules } from './team-name-rules';

export function restrictBlacklistNames(url: UrlSegment[]): UrlMatchResult {
  if (url.length > 0) {
    if (teamNameRules.blackListedTeamNames.indexOf(url[0].path) === -1) {
      return ({ consumed: [] });
    }
    return null;

  }
  if (url.length === 0) {
    return ({ consumed: [] });
  }

  // should not hit
  return null;
}

export const CHAT_ROUTES = [{
  matcher: restrictBlacklistNames,
  resolve: {
    meAndTeams: ChatResolver
  },
  // not needed at the moment
  // canActivate: [
  //   ,
  //   // AuthCheckGuard
  // ],
  children: [{
    path: ':team',
    component: ChatShellComponent,
    canActivate: [DomainGuard, DesktopGuard]
  }, {
    path: '',
    component: ChatShellComponent,
    canActivate: [DomainGuard, DesktopGuard]
  }]
}];
