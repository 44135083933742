<!-- <div class="panel-config panel-config-filter" [ngClass]="{'opened':isConfigOpened}">
  <div class="panel-config-box">
    <div class="panel-config-box-add">
      <label for="order-by-component" class="text-muted"><small>Order By: </small></label>
      <select class="form-control form-control-sm flex-grow-1 bg-dark" [(ngModel)]="slOrder">
        <option value="MessageDate">Message Date</option>
        <option value="CreatedDate">Date Added</option>
      </select>
      <button [disabled]="isLoading" class="btn btn-sm btn-primary" (click)="changeOrder()">
        <i class="far fa-sort-alpha-down"></i>
      </button>
    </div>
  </div>
</div> -->
<div class="chatroom-wrapper">
  <app-timeline #timeline [isLocked]="false" [messages]="initialMessages"
    [initialTimelineMessage]="'This is your team\'s Pinboard. All posts which are pinned by your team admins are found here.'"
    [timelineType]="timelineType" [showRoomInfo]="true" [canPin]="canPin" [teamId]="panel.teamId" [panelId]="panel.id">
  </app-timeline>
  <div class="footer" [ngClass]="{'isMaximized':maximized}">

  </div>
</div>