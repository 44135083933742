import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-leave-team',
  templateUrl: './leave-team.component.html'
})
export class LeaveTeamComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.activeModal.close(true);
  }

  reject() {
    this.activeModal.close(false);
  }
}
