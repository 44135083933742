export interface Signal {
  title: string;
  orderType: 'NONE' | 'BUY' | 'BUY_MARKET' | 'BUY_STOP' | 'BUY_LIMIT' | 'SELL' | 'SELL_MARKET' | 'SELL_STOP' | 'SELL_LIMIT';
  symbol: string;
  entry: string;
  fields: SignalField[];
}

export interface SignalField {
  label: string;
  value: string;
}

export enum OrderType {
  NONE = 'NONE',
  BUY = 'BUY',
  BUY_MARKET = 'BUY_MARKET',
  BUY_STOP = 'BUY_STOP',
  BUY_LIMIT = 'BUY_LIMIT',
  SELL = 'SELL',
  SELL_MARKET = 'SELL_MARKET',
  SELL_STOP = 'SELL_STOP',
  SELL_LIMIT = 'SELL_LIMIT'
}
