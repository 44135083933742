<div class="secret-wrapper">
  <div class="secret unlocked" *ngIf="decryptedMessage">
    <p [innerHTML]="parsedHtml"></p>
    <button class="btn btn-sm btn-success" (click)="hideSecret()">
      <i class="far fa-eye fa-fw left"></i>
      Hide
    </button>
  </div>
  <div class="secret locked" *ngIf="!decryptedMessage">
    <span class="title">This is an encrypted message for your eyes only</span>
    <span
      class="blur-message">IFCi/mSpLKtd7vFhdPfccWJtjReob7YaS89NVq79Rq3OeXYwDByyrzXItWErXprwScr3bXs5tj2Qp/MmBYgy7JoEiHuLj3xJjwc6FZDkfX4EOP6xdy1JalDldUT0aEme</span>
    <button class="right btn btn-sm btn-success" (click)="decryptMessage()">
      <i class="far fa-key fa-fw left"></i>
      Decrypt
    </button>
  </div>
</div>