import { Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MessageTextFormatterService } from '../../_core/services/message-text-formatter.service';

export class CustomValidators extends Validators {
  static matches(controlToMatch: AbstractControl): ValidatorFn {
    return (c: AbstractControl) => {
      if (c.value === controlToMatch.value) {
        return null;
      }
      return { matches: true };
    };
  }

  static validKey(mtf: MessageTextFormatterService, text): ValidatorFn {
    return (c: AbstractControl) => {
      if (mtf.decrypt(text, c.value)) {
        return null;
      }
      return { validKey: true };
    };
  }
}
