import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface Smilie {
  id: number;
  code: string;
  img: string;
  title: string;
  reference: string;
  tab: string;
  char: string;
  unicode: string;
}

@Injectable({
  providedIn: 'root'
})
export class SmiliesService {

  pattern: RegExp;
  smilies: Smilie[];
  smilieTabs: { key: string, smilies: Smilie[] }[];
  smiliesWDuplicates: Smilie[];

  smiliesLoaded$ = new ReplaySubject<void>();

  constructor(private http: HttpClient) {
    this.smilieTabs = [];
  }

  setup() {
    this.http
      .get<Smilie[]>('/assets/smilies.json')
      .toPromise()
      .then((smilies) => {
        this.smiliesWDuplicates = smilies;

        this.smilies = [];
        const smiliesByCode = [...smilies];
        smiliesByCode.sort((a, b) => a.unicode.localeCompare(b.unicode));
        for (let i = 1; i < smiliesByCode.length; i++) {
          if (smiliesByCode[i].unicode === smiliesByCode[i - 1].unicode) {
            continue;
          }
          this.smilies.push(smiliesByCode[i]);
        }
        this.smilies.sort((a, b) => a.id - b.id);

        // we must remove :* tracking due to conflict with market analysis title
        const kissIndex = this.smiliesWDuplicates.findIndex(c => c.code === ':*');
        this.smiliesWDuplicates.splice(kissIndex, kissIndex > -1 ? 1 : 0);
        const smiliesCodes = this.smiliesWDuplicates.map(s => `(${s.code.replace(/[.*+?^${}()|[\]\\\/\-]/g, '\\$&')})`).join('|');

        // we must not put spaces before and after, even optional, because they are captured and replaced! - in edge cases this breaks other things
        // like if ending a message with emoji and we quote, it captures the new line added by quote and breaks it!
        this.pattern = new RegExp(`(${smiliesCodes})`, 'gim');

        this.smilieTabs = this.smilies.reduce((l, i) => {
          let tab = l.find(x => x.key === i.tab);
          if (!tab) {
            tab = {
              key: i.tab, smilies: []
            };
            l.push(tab);
          }
          tab.smilies.push(i);
          return l;
        }, []);

        this.smiliesLoaded$.next();
      });
  }

  extract(input: string) {
    const smilies: string[] = [];
    const smiliesOutput = input.replace(this.pattern, match => {
      smilies.push(match);
      return '{smilie}';
    });
    return { smiliesOutput, smilies };
  }

  apply(text: string, smilies: string[]) {
    const smiliesParsed = smilies.map(s => s.trim().toLowerCase());
    const icons = (this.smiliesWDuplicates || [])
      .filter(s => smiliesParsed.indexOf(s.code.trim().toLowerCase()) !== -1)
      .reduce((l, s) => {
        l[s.code.trim().toLowerCase()] = s.char;
        return l;
      }, {});
    let ret = ` ${text} `;
    smilies.forEach(s => {
      ret = ret.replace('{smilie}', icons[s.trim().toLowerCase()]);
    });
    return ret.trim();
  }

  smilify(richParsedText: any) {
    let compiledText = ` ${richParsedText} `;
    const match = compiledText.match(this.pattern);
    if (match) {
      match.forEach((m) => {
        const icon = (this.smiliesWDuplicates || []).find(x => x.code.toLowerCase() === m.trim().toLowerCase());
        if (icon) {
          compiledText = compiledText
            // tslint:disable-next-line:max-line-length
            .replace(m, ` ${icon.char} `);
        }
      });
    }
    return compiledText.trim();
  }
}
