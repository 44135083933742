import { Pipe, PipeTransform } from '@angular/core';
import { Chatroom } from '../models/room/room';

@Pipe({
  name: 'directLabel',
  pure: false
})
export class DirectLabelPipe implements PipeTransform {
  transform(room: { name: string, participants: { userId: string, user: { username: string } }[] }, ownUserId: string): string {
    if (room.name) return room.name;
    if (!room.participants || !room.participants.length) return '???';
    const participants = room.participants.filter(m => m.userId !== ownUserId);
    if (!participants.length) return '???';

    if (participants.length === 1) {
      return participants[0].user.username;
    }
    if (participants.length === 2) {
      return `${participants[0].user.username} & ${participants[1].user.username}`;
    }
    return `${participants[0].user.username}, ${participants[1].user.username} & ${participants.length - 2} more`;
  }
}
