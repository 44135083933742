import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralResourcesService } from '../../../../_core/services/general-resources.service';
import { ModalsService } from '../../../../_core/services/modals.service';

@Component({
  selector: 'app-tradingview-link',
  templateUrl: './tradingview-link.component.html',
  styleUrls: ['./tradingview-link.component.scss'],
})
export class TradingviewLinkComponent implements OnInit, OnDestroy {
  _urlData: any = null;
  @Input()
  set urlData(val) {
    this._urlData = val;
  }
  get urlData() {
    return this._urlData;
  }

  extractedUrl: string;
  @Input()
  messageId: string;
  @Input()
  showOpenButton: boolean = false;

  constructor(
    private tvLinks: GeneralResourcesService,
    private sanitizer: DomSanitizer,
    private hostElement: ElementRef
  ) { }

  async ngOnInit() {
    this.extractedUrl = await this.getChartUrl();
  }

  getLinkUrl() {
    return `https://www.tradingview.com/chart/${this.urlData.key}/${this.urlData.id}`;
  }

  getChartUrl() {
    return this.tvLinks.getIframeUrl(this.urlData.id);
  }

  getImageUrl() {
    try {
      const constructed = `https://s3.tradingview.com/${this.urlData.id[0].toLowerCase()}/${this.urlData.id}_mid.png`;
      const sanitized = this.sanitizer.sanitize(SecurityContext.URL, (this._urlData && this._urlData.image) ? this._urlData.image : constructed);
      return sanitized;
    } catch (ex) {
      return null;
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
