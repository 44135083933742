import { Component, OnInit, Output, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormControl, ValidatorFn, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss']
})
export class UserInputComponent implements OnInit {
  @ViewChild('input') inputEl: ElementRef;
  @Input() title: string;
  @Input() maskText: boolean;
  @Input() buttonLabel: string;
  @Input() inputValidators: ValidatorFn | ValidatorFn[];
  @Input() validatorTexts: { validatorName: string, validatorText: string }[];
  inputForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    // this.inputText = new FormControl('', this.inputValidators);
    this.inputForm = new FormGroup({
      inputText: new FormControl('', this.inputValidators)
    });

    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 0);
  }

  onKeyDown(event) {
    this.submitted = false;
    setTimeout(() => {
      if (event.key === 'Enter') {
        this.confirm();
      }
    }, 0);
  }

  confirm() {
    this.submitted = true;
    if (this.inputForm.valid) {
      this.inputEl.nativeElement.blur();
      this.activeModal.close(this.inputForm.controls.inputText.value);
    }
  }

  reject() {
    this.inputEl.nativeElement.blur();
    this.activeModal.close(null);
  }
}
