import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnDestroy, ElementRef } from '@angular/core';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { EFitOptions, TransformOptions } from 'filestack-js';
import { FileUploadService } from './../../../../_core/services/file-upload.service';

enum MimeCategory {
  IMAGE,
  VIDEO,
  AUDIO,
  PDF
}

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileAttachmentComponent implements OnInit, OnDestroy {

  @Input() attachments: FileAttachment[];
  @Input() hideFilename = false;
  @Input() fullView: boolean;
  @Input() messageId: string;

  attachment: FileAttachment;

  mimeCategory: MimeCategory;
  mimeCategories = MimeCategory;

  url: string;
  thumb: string;

  icon: string = 'fa-file';

  mainOptions: TransformOptions;
  thumbOptions: TransformOptions;

  constructor(
    private fileUploadService: FileUploadService,
    private hostElement: ElementRef
  ) {
    this.mainOptions = {
      cache: {
        expiry: 31536000
      },
      security: {
        policy: this.fileUploadService.policy,
        signature: this.fileUploadService.signature,
      }
    };
    this.thumbOptions = {
      cache: {
        expiry: 31536000
      },
      security: {
        policy: this.fileUploadService.policy,
        signature: this.fileUploadService.signature,
      },
      resize: {
        width: 300,
        height: 200,
        fit: EFitOptions.max,
      }
    };
  }

  ngOnInit(): void {
    this.attachment = this.attachments[0];

    if (!this.attachment.mime) this.attachment.mime = '';
    if (this.attachment.mime.indexOf('image') > -1) this.mimeCategory = MimeCategory.IMAGE;
    if (this.attachment.mime.indexOf('video') > -1) this.mimeCategory = MimeCategory.VIDEO;
    if (this.attachment.mime.indexOf('audio') > -1) this.mimeCategory = MimeCategory.AUDIO;
    if (this.attachment.mime.indexOf('pdf') > -1) this.mimeCategory = MimeCategory.PDF;

    if (this.attachment.mime.includes('x-tar')
      || this.attachment.mime.includes('x-zip')
      || this.attachment.mime.includes('compressed')
    ) {
      this.icon = 'fa-file-archive';
    } else if (this.attachment.mime.includes('opendocument.text')
      || this.attachment.mime.includes('msword')
      || this.attachment.mime.includes('ms-word')
      || this.attachment.mime.includes('wordprocessingml')
      || this.attachment.mime.includes('kswps')
    ) {
      this.icon = 'fa-file-word';
    } else if (this.attachment.mime.includes('excel')
      || this.attachment.mime.includes('spreadsheetml')
      || this.attachment.mime.includes('ms-excel')
    ) {
      this.icon = 'fa-file-excel';
    } else if (this.attachment.mime.includes('powerpoint')
      || this.attachment.mime.includes('presentationml')
      || this.attachment.mime.includes('ms-powerpoint')
    ) {
      this.icon = 'fa-file-powerpoint';
    } else if (this.attachment.mime.includes('pdf')) {
      this.icon = 'fa-file-pdf';
    } else if (this.attachment.mime.includes('text/plain')) {
      this.icon = 'fa-file-alt';
    } else if (this.attachment.mime.includes('text/csv')) {
      this.icon = 'fa-file-csv';
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
