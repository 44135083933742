import { Component, Input, OnInit, ViewChild, OnDestroy, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MessageTextFormatterService } from '../../../../_core/services/message-text-formatter.service';
import { TeamService } from '../../../../_core/services/team.service';
import { Member } from '../../../../_shared/models/teams/member';

@Component({
  selector: 'app-content-mention',
  templateUrl: './content-mention.component.html',
  styleUrls: ['./content-mention.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentMentionComponent implements OnInit, OnDestroy {
  @Input() userId: string;
  @Input() username: string;
  @Input() teamId: string;
  @Input() searchTerm: string;

  member: Member;
  avatar: string;

  viewUsername: string;

  @ViewChild('p') public popover: NgbPopover;

  constructor(
    private teamService: TeamService,
    private hostElement: ElementRef,
    private mtf: MessageTextFormatterService
  ) { }

  ngOnInit() {
    this.viewUsername = '@' + this.username;
    if (this.searchTerm) {
      this.viewUsername = this.mtf.applyHighlight(this.viewUsername, this.searchTerm);
    }
  }

  async openProfile() {
    if (!this.userId) {
      return;
    }

    if (!this.popover.isOpen()) {
      this.member = await this.teamService.getMember(this.userId);
      if (this.member && this.member.user) {
        this.avatar = this.member.user.avatar;
      }
      // if (!this.member) {
      //   this.member = await this.teamService.getMemberRequest(this.userId);
      // }
      this.popover.open();
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
