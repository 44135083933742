import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, ElementRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-content-shout',
  templateUrl: './content-shout.component.html',
  styleUrls: ['./content-shout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentShoutComponent implements OnDestroy {

  @Input() parsedHtml: SafeHtml = '';

  constructor(private hostElement: ElementRef) { }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
