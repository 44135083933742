<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{confirmTitle}}</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="reject()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="break-word">{{confirmMessage}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="reject()">Cancel</button>
    <button class="btn btn-success" type="button" (click)="confirm()">Confirm </button>
  </div>
</div>