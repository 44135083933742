<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Desktop Notification Settings</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>Add Chatroom specific settings:</p>
          <div class="form-group">
            <label>Chatroom: </label>
            <div class="row">
              <div class="col-md-10">
                <select class="form-control ng-valid ng-dirty ng-touched" [(ngModel)]="selectedChannel">
                    <option *ngFor="let channel of availableChannels" [ngValue]="channel.id">{{channel.name}}</option>
                </select>
              </div>
              <div class="col-md-2">
                <button class="btn btn-lg btn-block btn-success" type="button" (click)="addChannelNotification(selectedChannel)">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top:10px"></div>
      <div class="row">
        <div class="col-md-2">Chatroom</div>
        <div class="col-md-2">None</div>
        <div class="col-md-2">All</div>
        <div class="col-md-2">Mentions</div>
        <div class="col-md-2">Signals</div>
        <div class="col-md-2">Delete</div>
      </div>
      <div class="row" *ngFor="let notification of notifications; let i = index">
          <div class="col-md-2">{{notification.chatroomName}}</div>
          <div class="col-md-2"><input class="checkbox" type="checkbox" [(ngModel)]="notification.none" (click)="noneNotificationClick(notification)"></div>
          <div class="col-md-2"><input class="checkbox" type="checkbox" [(ngModel)]="notification.all" (click)="allNotificationClick(notification)"></div>
          <div class="col-md-2"><input class="checkbox" type="checkbox" [disabled]="notification.none || notification.all" [(ngModel)]="notification.mentions"></div>
          <div class="col-md-2"><button class="btn btn-danger" type="button" *ngIf="!notification.default" (click)="notifications.splice(i, 1);loadAvailableChannelList();">Delete</button></div>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="activeModal.dismiss()">Close</button>
    <button class="btn btn-sm btn-success" type="button" (click)="savePreferences()">Save</button>
  </div>
</div>