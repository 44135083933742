import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProfileMeServiceApi } from '@echofin/libraries';
import { environment } from '../../../environments/environment';
import { BrandingService } from '../../_core/services/branding.service';
import { LoginService } from './login.service';
import { ProfileService } from '../../_core/services/profile.service';

@Injectable({ providedIn: 'root' })
export class IntroResolver implements Resolve<void> {

  constructor(
    private profileService: ProfileService,
    private brandingService: BrandingService,
    private loginService: LoginService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let alreadyAuth;
    const promises = [];
    promises.push(this.profileService.loadMe()
      .then(() => { alreadyAuth = true; })
      .catch(() => { alreadyAuth = false; }));
    let branding;
    promises.push(await this.brandingService.loadBranding()
      .then(r => { branding = r; }));
    const t = await Promise.all(promises);

    // mobile subdomain just allow to go to mobile guard as is
    const subdomain = window.location.host.replace(`.${environment.config.domain}`, '');
    if (subdomain !== 'mobile') {


      if (route.children &&
        route.children[0] &&
        route.children[0].url &&
        route.children[0].url[0].path.toLowerCase() === 'invitation' &&
        route.children[0].url[1].path) {
        return;
      }

      if (branding === 'TEAM_NOT_FOUND') {
        const prefix = 'app';
        window.location.href = `${window.location.protocol}//${prefix}.${environment.config.domain}`;
      }
      if (alreadyAuth) {
        this.loginService.redirect(this.brandingService.team.name);
      }
    }


  }
}
