import { Component, EventEmitter, Input, Output, OnDestroy, ElementRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { MessageStatus } from '../../../../_shared/models/commons/message';

@Component({
  selector: 'app-content-market-analysis',
  templateUrl: './content-market-analysis.component.html',
  styleUrls: ['./content-market-analysis.component.scss']
})
export class ContentMarketAnalysisComponent implements OnDestroy {

  @Input() urlData: any;
  @Input() parsedHtml: SafeHtml = '';

  @Input() files: FileAttachment[];
  @Input() messageStatus: MessageStatus;
  @Input() messageId: string;

  @Input() fullView: boolean;
  @Input() fullViewType: string;

  status = MessageStatus;

  constructor(private hostElement: ElementRef) { }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
