import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  { name: 'getsitecontrol', src: 'https://l.getsitecontrol.com/d7ojj554.js' },
];

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loading: false,
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loading: false, loaded: true, status: 'Already Loaded' });
      } else if (this.scripts[name].loading) {
        resolve({ script: name, loading: true, loaded: false, status: 'Already Loading' });
      } else {
        this.scripts[name].loading = true;
        //load script
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          this.scripts[name].loading = false;
          resolve({ script: name, loading: false, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => {
          this.scripts[name].loaded = false;
          this.scripts[name].loading = false;
          resolve({ script: name, loading: false, loaded: false, status: 'Loaded' });
        }
        this.renderer.appendChild(document.head, script);
      }
    });
  }
}