import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthenticationApiModule, AuthenticationConfiguration, FilestackApiModule, FilestackConfiguration, MessageApiModule, MessageConfiguration, NotificationApiModule, NotificationConfiguration, ProfileApiModule, ProfileConfiguration, ProspectApiModule, ProspectConfiguration, SessionApiModule, SessionConfiguration, TeamApiModule, TeamConfiguration } from '@echofin/libraries';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../../environments/environment';
import { authenticationConfigurationFactory, filestackConfigurationFactory, InitService, init_app, messageConfigurationFactory, notificationConfigurationFactory, profileConfigurationFactory, prospectConfigurationFactory, sessionConfigurationFactory, teamConfigurationFactory } from './init-functions';
import { AliveSessionService } from './services/alive-session.service';
import { AuthService, TokenInterceptor } from './services/auth.service';
import { BillingService } from './services/billing.service';
import { CountriesService } from './services/countries.service';
import { DateService } from './services/date.service';
import { DefaultAvatarService } from './services/default-avatar.service';
import { FileUploadService } from './services/file-upload.service';
import { FocusService } from './services/focus.service';
import { MessageTextFormatterService } from './services/message-text-formatter.service';
import { MessageService } from './services/message.service';
import { PanelsService } from './services/panels.service';
import { ProfileService } from './services/profile.service';
import { ScriptService } from './services/script.service';
import { SmiliesService } from './services/smilies.service';
import { SocketService } from './services/socket.service/socket.service';
import { SocketIOService } from './services/socket.service/socketio.service';
import { EcfnStipeService } from './services/stripe.service';
import { TeamService } from './services/team.service';
import { TitleHandlerService } from './services/title-handler.service';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationApiModule,
    MessageApiModule,
    NotificationApiModule,
    ProfileApiModule,
    ProspectApiModule,
    SessionApiModule,
    TeamApiModule,
    FilestackApiModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    AuthService,
    ProfileService,
    TeamService,
    TitleHandlerService,
    MessageService,
    SmiliesService,
    AliveSessionService,
    PanelsService,
    // TimezonesService,
    BillingService,
    CountriesService,
    FileUploadService,
    DefaultAvatarService,
    MessageTextFormatterService,
    DateService,
    ScriptService,
    FocusService,
    EcfnStipeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: SocketService,
      useClass: SocketIOService
    },
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitService],
      multi: true
    },
    { provide: AuthenticationConfiguration, useFactory: authenticationConfigurationFactory, deps: [InitService] },
    { provide: MessageConfiguration, useFactory: messageConfigurationFactory, deps: [InitService] },
    { provide: NotificationConfiguration, useFactory: notificationConfigurationFactory, deps: [InitService] },
    { provide: ProfileConfiguration, useFactory: profileConfigurationFactory, deps: [InitService] },
    { provide: ProspectConfiguration, useFactory: prospectConfigurationFactory, deps: [InitService] },
    { provide: SessionConfiguration, useFactory: sessionConfigurationFactory, deps: [InitService] },
    { provide: TeamConfiguration, useFactory: teamConfigurationFactory, deps: [InitService] },
    { provide: FilestackConfiguration, useFactory: filestackConfigurationFactory, deps: [InitService] }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    console.log('loading version', environment.version);
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
}
