import { Events } from '@echofin/libraries/events/all';

export interface SocketEvent {
  channel: string;
  type: EvType;
  data: EvData;
}
namespace SocketEvents {
  export interface TeamConnected {
    teamId: string;
  }
}

export interface MeetingStarted {
  chatroomId: string;
  teamId: string;
}
export interface MeetingStopped {
  chatroomId: string;
  teamId: string;
}

export type EvData = null
  | Events.BookmarkMessageAdded
  | Events.BookmarkMessageRemoved
  | Events.ChatroomFocusChanged
  | Events.DividerCreated
  | Events.DividerRemoved
  | Events.DividerResp
  | Events.FileAttachment
  | Events.FrameCreated
  | Events.FrameDeleted
  | Events.FrameResp
  | Events.FrameUpdated
  | Events.GroupRoomCreated
  | Events.IUrlData
  | Events.MemberBanned
  | Events.MemberJoined
  | Events.MemberLeft
  | Events.MemberOwnRoleChanged
  | Events.MemberRoleChanged
  | Events.MemberUnbanned
  | Events.Message
  | Events.MessageDeleted
  | Events.MessageProcessed
  | Events.MessageResp
  | Events.ParticipantJoined
  | Events.ParticipantRemoved
  | Events.PinboardMessageAdded
  | Events.PinboardMessageRemoved
  | Events.ProfileStored
  | Events.ProfileTeamsChanged
  | Events.RolePermChanged
  | Events.RoleRemoved
  | Events.RoleSaved
  | Events.RolesReordered
  | Events.RoomDeleted
  | Events.RoomPermUpdated
  | Events.RoomUpdated
  | Events.SidebarReorderded
  | Events.Signal
  | Events.SignalField
  | Events.AvatarUpdated
  | Events.UsernameChanged
  | Events.TeamRoomCreated
  | Events.TeamUpdated
  | Events.Typing
  | Events.MembersPermChanged
  | Events.User
  | Events.UserStatusChanged
  | Events.WidgetAdded
  | Events.WidgetRemoved
  | Events.WidgetsReordered
  | Events.WidgetUpdated

  // Extra Socket Only Events
  | SocketEvents.TeamConnected
  | MeetingStarted
  | MeetingStopped;

export enum EvType {
  MeetingStarted = 'MeetingStarted',
  MeetingStopped = 'MeetingStopped',
  MembersPermChanged = 'MembersPermChanged',
  RolePermChanged = 'RolePermChanged',
  BookmarkMessageAdded = 'BookmarkMessageAdded',
  BookmarkMessageRemoved = 'BookmarkMessageRemoved',
  ChatroomFocusChanged = 'ChatroomFocusChanged',
  DividerCreated = 'DividerCreated',
  DividerRemoved = 'DividerRemoved',
  DividerResp = 'DividerResp',
  DividerUpdated = 'DividerUpdated',
  FileAttachment = 'FileAttachment',
  FrameCreated = 'FrameCreated',
  FrameDeleted = 'FrameDeleted',
  FrameResp = 'FrameResp',
  FrameUpdated = 'FrameUpdated',
  GroupRoomCreated = 'GroupRoomCreated',
  IUrlData = 'IUrlData',
  MemberBanned = 'MemberBanned',
  MemberJoined = 'MemberJoined',
  MemberLeft = 'MemberLeft',
  MemberOwnRoleChanged = 'MemberOwnRoleChanged',
  MemberRoleChanged = 'MemberRoleChanged',
  MemberUnbanned = 'MemberUnbanned',
  Message = 'Message',
  MessageDeleted = 'MessageDeleted',
  MessageProcessed = 'MessageProcessed',
  MessageResp = 'MessageResp',
  ParticipantJoined = 'ParticipantJoined',
  ParticipantRemoved = 'ParticipantRemoved',
  PinboardMessageAdded = 'PinboardMessageAdded',
  PinboardMessageRemoved = 'PinboardMessageRemoved',
  ProfileStored = 'ProfileStored',
  ProfileTeamsChanged = 'ProfileTeamsChanged',
  RoleRemoved = 'RoleRemoved',
  RoleResp = 'RoleResp',
  RoleSaved = 'RoleSaved',
  RolesReordered = 'RolesReordered',
  RoomDeleted = 'RoomDeleted',
  RoomPermModel = 'RoomPermModel',
  RoomPermUpdated = 'RoomPermUpdated',
  RoomUpdated = 'RoomUpdated',
  SidebarReorderded = 'SidebarReorderded',
  Signal = 'Signal',
  SignalField = 'SignalField',
  AvatarUpdated = 'AvatarUpdated',
  UsernameChanged = 'UsernameChanged',
  TeamRoomCreated = 'TeamRoomCreated',
  TeamUpdated = 'TeamUpdated',
  Typing = 'Typing',
  User = 'User',
  UserStatusChanged = 'UserStatusChanged',
  TeamConnected = 'TeamConnected',
  WidgetAdded = 'WidgetAdded',
  WidgetRemoved = 'WidgetRemoved',
  WidgetUpdated = 'WidgetUpdated',
  WidgetsReordered = 'WidgetsReordered',
  // Extra Socket Only Events
  Reload = 'Reload',
  LocalMessageProcessed = 'LocalMessageProcessed',
  UserMuteChanged = 'UserMuteChanged'
}
