import { MessageResp } from '@echofin/libraries/api/message/models/message-resp';
import { Events } from '@echofin/libraries';

export interface LocalMessageProcessed extends Omit<Events.MessageProcessed, 'message' | 'type'> {
  message: Message;
  type: string;
}

export interface Message extends Omit<MessageResp, 'timestamp'> {
  firstMessageOfDay?: boolean;
  status?: MessageStatus;
  hasFile?: boolean;
  isCollapsed?: boolean;
  localId?: string;
  timestamp?: Date;
  systemText?: string;
  isFirstNewMessage?: boolean;
  localVersion?: number;
}

export enum MessageStatus {
  PENDING = 'PENDING', // is only local
  SEND = 'SEND', // is send and has id
  DELIVERED = 'DELIVERED', // has confirmed through api or socket that is delivered
  FAILED = 'FAILED'
}
