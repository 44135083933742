import { Events } from '@echofin/libraries';
import { Chatroom } from '../../../_shared/models/room/room';
import { Team } from '../../../_shared/models/teams/team';

export class ChatroomHandlers {

  static handleTeamRoomCreated(model: Events.TeamRoomCreated, teams: Team[]) {
    const team: Team = teams.find((t: Team) => t.id === model.teamId);
    const room: Chatroom = {
      ...model.chatroom,
      sort: -1,
      type: Events.RoomType[Events.RoomType[model.chatroom.type]] as 'Direct' | 'Group' | 'Team',
      subtype: Events.RoomSubtype[Events.RoomType[model.chatroom.subtype]] as 'Public' | 'Private' | 'Premium'
    };
    team.chatrooms.push(room);
    return room;
  }

  static handleRoomUpdated(model: Events.RoomUpdated, teams: Team[], directs: Chatroom[]) {

    let room: Chatroom;

    teams.forEach(t => {
      const i = t.chatrooms.findIndex(x => x.id === model.chatroom.id);
      if (i === -1) {
        t.chatrooms[i] = Object.assign(t.chatrooms[i], {
          name: model.chatroom.name,
          color: model.chatroom.color,
          description: model.chatroom.description,
          topic: model.chatroom.topic,
        });
        room = t.chatrooms[i];
      }
    });

    if (room) return room;

    const j = directs.findIndex(x => x.id === model.chatroom.id);
    if (j === -1) {
      directs[j] = Object.assign(directs[j], {
        name: model.chatroom.name,
        color: model.chatroom.color,
        description: model.chatroom.description,
        topic: model.chatroom.topic,
      });
      room = directs[j];
    }

    if (room) return room;
  }

  static handleRoomDeleted(model: Events.RoomDeleted, teams: Team[]) {
    let teamId = null;
    teams.forEach(t => {
      const i = t.chatrooms.findIndex(x => x.id === model.chatroom.id);
      if (i !== -1) {
        teamId = t.chatrooms[i].teamId;
        t.chatrooms.splice(i, 1);
      }
    });
    return {
      teamId,
      chatroomId: model.chatroom.id,
    };
  }
}
