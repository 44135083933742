import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { LocalStorage, LocalStorageService } from '@efaps/ngx-store';
import { environment } from './../../environments/environment';

const APP_STORAGE_VERSION = '0.002';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @LocalStorage()
  localStorageState: { version: string } & { save?: any };

  constructor(
    private localStorage: LocalStorageService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    if (environment.config.name === 'echofin') {
      this.log('load analytics');

      const amplitudeId = 'b117e6d217d5fa89cf1b737458f525f4';
      // var amplitudeId = "e150245163e216dc4e869cc56351fca8" // test
      const isElectron =
        navigator.userAgent.toLowerCase().indexOf('electron') > -1;
      if (isElectron) {
        window['amplitude'].getInstance().init(amplitudeId, null, {
          platform: 'Desktop',
        });
      } else {
        window['amplitude'].getInstance().init(amplitudeId, null, {
          platform: 'Web'
        });
      }
      window['amplitude'].getInstance().setVersionName(environment.version.hash);

      // do not put amplitude in whole app, only in chat --> this moved to chat-shell
      // angulartics2Amplitude.startTracking();
      window['ga-disable-G-B2EKW4M455'] = false;
      angulartics2GoogleTagManager.startTracking();
    }
  }

  ngOnInit() {
    this.log(
      'CURRENT LOCAL STORAGE VERSION: ',
      this.localStorageState ? this.localStorageState.version : ''
    );
    this.log('APP LOCAL STORAGE VERSION: ', APP_STORAGE_VERSION);
    if (
      this.localStorageState &&
      this.localStorageState.version < APP_STORAGE_VERSION
    ) {
      this.localStorage.clear();
      this.log('LOCAL STORAGE CLEARED!');
    }
    this.localStorageState = { version: APP_STORAGE_VERSION };
    this.localStorageState.save();
    this.log(
      'CURRENT LOCAL STORAGE VERSION: ',
      this.localStorageState ? this.localStorageState.version : ''
    );
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[APP-COMPONENT]', 'color:#E91E93', ...arg);
    }
  }
}
