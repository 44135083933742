<div class="profile-wrapper simplerow" *ngIf="!collapsed" [attr.data-messageId]="messageId">
    <div class="member-avatar no-indicator clickable" popoverClass="popover-usermenu"
        [placement]="['left', 'left-bottom', 'left-top', 'right-bottom', 'right-top', 'auto']" container="body"
        [ngbPopover]="popContent" [popoverTitle]="sender.username || 'anonymous'" triggers="manual"
        #avatarpopover="ngbPopover">
        <app-user-avatar *ngIf="!collapsed" [username]="(sender && sender.username)?sender.username:null"
            [avatarUrl]="(sender && sender.avatar)?sender.avatar:null">
        </app-user-avatar>
    </div>
    <div class="profile">
        <span class="profile-header hand-cursor" *ngIf="!collapsed" popoverClass="popover-usermenu"
            [placement]="['left', 'left-bottom', 'left-top', 'right-bottom', 'right-top', 'auto']" container="body"
            [ngbPopover]="popContent" [popoverTitle]="sender.username || 'anonymous'" triggers="manual"
            #namepopover="ngbPopover">
            <span class="profile-username">{{sender.username || 'anonymous'}}</span>

            <ng-container *ngIf="timelineType!==4 && role">
                <div class="role-container">
                    <span class="badge-role">
                        <i class="far" [ngClass]="icon"
                            [ngStyle]="{color: role.color || defaults.role.color, backgroundColor: role.color || defaults.role.color}"></i>
                    </span>
                    <span class="role-name"
                        [ngStyle]="{backgroundColor: role.color || defaults.role.color}">{{role.name}}</span>
                </div>
            </ng-container>
            <!-- <span *ngIf="profile-completed" class="profile-details badge-profile-completed">
                    <span class="badge-indicator">
                        <fa-icon [icon]="['fad','badge-check']"></fa-icon>
                    </span>
                    <span class="badge-role">Profile Completed</span>
                </span> -->
        </span>
        <span class="status" [ngSwitch]="messageStatuses[status]">
            <i *ngSwitchCase="'PENDING'" class="fal fa-hourglass fa-spin"></i>
            <i *ngSwitchCase="'SEND'" class="fal fa-check-circle"></i>
            <i *ngSwitchCase="'FAILED'" class="fal fa-times-circle"></i>
            <ng-container *ngSwitchCase="'DELIVERED'">
                <ng-container *ngIf="!showFullDate">
                    <ng-container *ngIf="isToday">
                        <div [ngClass]="isToday ? 'clickable' : ''" class="time-format"
                            [attr.data-timeFormat]="showRelativeTimeFormat">
                            <span *ngIf="!showRelativeTimeFormat">{{timestampFormatted}}</span>
                            <span *ngIf="showRelativeTimeFormat">{{timestamp | elapsedTimePipe}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isToday">
                        <div>
                            <span>{{timestampFormatted}}</span>
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="showFullDate">
                    <span>{{timestamp | date:'shortDate'}} {{timestampFormatted}}</span>
                </div>
            </ng-container>
        </span>
    </div>
</div>


<ng-template #popContent>
    <app-profile-view [member]="member" [userId]="(sender && sender.id)?sender.id:null"
        [username]="(sender && sender.username)?sender.username:null" [avatar]="sender.avatar" [teamId]="teamId">
    </app-profile-view>
</ng-template>