import { Component, OnInit, Input } from '@angular/core';
import { PanelsService } from '../../_core/services/panels.service';
import { BaseComponent } from '../base-component';
import { PanelType, Panel } from '../../_shared/models/room/channel';
import { HelperService } from '../../_core/services/helper.service';
import { TeamService } from '../../_core/services/team.service';
import { ProfileService } from '../../_core/services/profile.service';
import { LocalUserStatusChanged, UserStatus } from '../../_shared/models/enums/user-status';
import { RoomService } from '../../_core/services/room.service';

@Component({
  selector: 'app-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent extends BaseComponent implements OnInit {

  panelType = PanelType;

  @Input()
  panel: Panel;

  chatroomType: string = null;

  title: string;
  canReadChatroom: boolean;
  loading = false;
  panelIcon: any;
  directUserId: string = null;
  directUserStatus: string = null;

  color: string = null;

  refreshing = true;

  get maximized() {
    return (this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id) ||
      (this.panelsService.maximizedPanels['direct'] === this.panel.id);
  }

  get config() {
    return this.panelsService.panelsConfig;
  }

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private profileService: ProfileService,
    private roomService: RoomService
  ) {
    super();
  }

  ngOnInit(): void {
    this.colorize();

    this.subscribe(this.roomService.teamRoomUpdated$, (room) => {
      if (room.id === this.panel.id) {
        this.colorize();
      }
    });

    this.subscribe(this.teamService.frameUpdated$, (frameUpdated) => {
      if (frameUpdated.frame.id === this.panel.id) {
        this.colorize();
      }
    });

    this.subscribe(this.panelsService.panelTitle$, ([panelId, title]) => {
      if (panelId === this.panel.id) {
        this.title = title;
      }
    });

    this.subscribe(this.panelsService.panelPermissions$, ([panelId, canReadChatroom]) => {
      if (panelId === this.panel.id) {
        this.canReadChatroom = canReadChatroom;
      }
    });

    this.subscribe(this.panelsService.panelLoading$, ([panelId, loading]) => {
      if (panelId === this.panel.id) {
        this.loading = loading;
      }
    });

    this.subscribe(this.panelsService.refreshPanelState$, ([panelId, isRefreshing]) => {
      if (panelId === this.panel.id && !isRefreshing) {
        this.refreshing = false;
      }
    });

    this.subscribe(this.panelsService.panelUserIdAndStatus$, ([panelId, userId, status]) => {
      if (panelId === this.panel.id) {
        this.directUserId = userId;
        this.directUserStatus = status;
      }
    });

    if (this.panel.type === PanelType.Direct) {
      this.subscribe(
        this.profileService.userStatusChanged$,
        async (data: LocalUserStatusChanged) => {
          if (data.userId === this.directUserId) {
            this.directUserStatus = data.currentStatus;
          }
        });
    }

    this.subscribe(this.panelsService.panelChatroomType$, ([panelId, chatroomType]) => {
      if (panelId === this.panel.id) {
        this.chatroomType = chatroomType;
        switch (this.chatroomType) {
          case 'Team':
            this.panelIcon = ['fas', 'fa-hashtag'];
            break;
          case 'Group':
            this.panelIcon = ['fad', 'fa-comments-alt'];
            break;
          case 'Direct':
            this.panelIcon = ['far', 'fa-at'];
            break;
          default:
            this.panelIcon = null;
            break;
        }
      }
    });

    switch (this.panel.type) {
      case PanelType.ReplyMessage:
        this.panelIcon = ['fas', 'fa-reply'];
        break;
      case PanelType.Search:
        this.panelIcon = ['fad', 'fa-search'];
        break;
      case PanelType.BookmarkMessages:
        this.panelIcon = ['fad', 'fa-bookmark'];
        break;
      case PanelType.Filter:
        this.panelIcon = ['fad', 'fa-ballot-check'];
        break;
      case PanelType.PinboardMessages:
        this.panelIcon = ['fad', 'fa-thumbtack'];
        break;
      case PanelType.Mentions:
        this.panelIcon = ['fad', 'fa-at'];
        break;
      case PanelType.Files:
        this.panelIcon = ['far', 'fa-paperclip'];
        break;
      case PanelType.Frame:
        this.panelIcon = ['far', 'fa-globe-americas'];
        break;
      case PanelType.PulseMessages:
        this.panelIcon = ['far', 'fa-heart-rate'];
        break;
      case PanelType.SignalMessages:
        // this is needed to show the svg container
        this.panelIcon = ['far', 'SIGNAL-CUSTOM'];
        break;
      default:
        this.panelIcon = null;
        break;
    }
  }

  colorize() {
    if (this.panel.type === PanelType.ReplyMessage) {
      // reply Id is the panel Id
      this.color = `#${HelperService.intToRGB(this.panel.id)}`;
      return;
    }

    const chatroom = (this.teamService.activeTeam?.chatrooms || []).find(c => c.id === this.panel.id);
    if (chatroom && chatroom.color) {
      this.color = chatroom.color;
      return;
    }

    const frame = (this.teamService.activeTeam?.frames || []).find(c => c.id === this.panel.id);
    if (frame && frame.color) {
      this.color = frame.color;
      return;
    }

    this.color = '#F99500';
  }

  headerAction(action) {
    switch (action) {
      case 'close': this.close(); break;
      case 'maximize': this.maximize(); break;
      case 'minimize': this.minimize(); break;
    }
  }

  private close() {
    this.panel.visible = false;
    this.panelsService.close(this.panel);
  }

  private maximize() {
    this.panelsService.maximize(this.panel);
  }

  private minimize() {
    this.panelsService.minimizeAll(this.teamService.activeTeam.id);
  }

  configure(size: string) {
    this.panelsService.minimizeAll(this.panel.teamId);

    const c = Object.assign({}, this.config);
    c[this.panel.id] = {
      size
    };
    this.panelsService.panelsConfig = c;
  }

  refresh() {
    this.panelsService.refreshPanelState$.next([this.panel.id, true]);
    this.refreshing = true;
  }

  openChatroomNotificationModal() {
    this.panelsService.openChatroomNotificationPreferences$.next(this.panel.id);
  }

  colorClassByStatus() {
    if (this.directUserStatus) {
      if (this.directUserStatus === 'UNKNOWN' || 'AWAY' || 'BUSY' || 'AVAILABLE' || 'OFFLINE') {
        return this.directUserStatus;
      }
    }
    return 'text-muted';
  }
}
