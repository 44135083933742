<div class="modal-header">
  <h4 class="modal-title">{{subtitle}}</h4>
  <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="m.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <ng-container [ngSwitch]="state">

    <ng-container *ngSwitchCase="'LOADING'">
      <app-spinner></app-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'UPGRADE'">
      <div class="text-center">
        You need to update your desktop client to the
        <a href="javascript:void(0);" (click)="openDownloadClient()">
          latest version</a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'NO_ALLOWED'">
      <div>You do not have access to this Live Meeting</div>
    </ng-container>

    <ng-container *ngSwitchCase="'MEETING_START'">
      <div class="d-flex align-items-center justify-content-center">
        <div class="permissions">
          <div class="title">Participants can join with:</div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm" [ngClass]="{'active': settings.desktop}">
              <i class="far fa-desktop fa-fw"></i>
              Screen sharing
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch-desktop"
                [(ngModel)]="settings.desktop">
              <label class="custom-control-label" for="customSwitch-desktop">&nbsp;</label>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm" [ngClass]="{'active': settings.camera}">
              <i class="far fa-video fa-fw"></i>
              Camera
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch-camera"
                [(ngModel)]="settings.camera">
              <label class="custom-control-label" for="customSwitch-camera">&nbsp;</label>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm" [ngClass]="{'active': settings.microphone}">
              <i class="far fa-microphone-alt fa-fw"></i>
              Microphone
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch-microphone"
                [(ngModel)]="settings.microphone">
              <label class="custom-control-label" for="customSwitch-microphone">&nbsp;</label>
            </div>
          </div>
        </div>
        <button class="btn btn-stream mt-4 ml-4" (click)="startMeeting()">
          <i class="fad fa-users-class fa-fw fa-4x"></i>
          <br />
          <span>Start Meeting</span>
        </button>
      </div>
      <!-- <div style="margin-top: 12px; color: silver; font-size: 13px;">Screen sharing is currently functional only on the
        web app</div> -->
    </ng-container>

    <ng-container *ngSwitchCase="'MEETING_JOIN'">
      <button *ngIf="create===false && callType=='meeting'"
        class="btn d-flex flex-column justify-content-center btn-stream" (click)="joinMeeting()">
        <i class="fad fa-user-headset fa-fw fa-4x"></i>
        <br>
        Join Meeting
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'OTHER_MEETING_ACTIVE'">
      <div>You have another active live meeting open</div>
    </ng-container>

    <ng-container *ngSwitchCase="'NO_MEETING'">
      <div class="d-flex align-items-center justify-content-center">
        <div class="permissions">
          <div class="title">Participants can join with:</div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm">
              <i class="far fa-desktop fa-fw"></i>
              Screen sharing
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm">
              <i class="far fa-video fa-fw"></i>
              Camera
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="perm">
              <i class="far fa-microphone-alt fa-fw"></i>
              Microphone
            </div>
          </div>
        </div>
        <button class="btn btn-stream mt-4 ml-4" disabled>
          <i class="fad fa-users-class fa-fw fa-4x"></i>
          <br />
          <span>Start Meeting</span>
        </button>
      </div>
    </ng-container>

  </ng-container>
</div>
<div class="stream-footer">
  <ng-container *ngIf="state === 'NO_MEETING'">
    <span style="color:#d1393c;">You do not have permissions to start a Meeting in this chatroom</span>
  </ng-container>
  <ng-container *ngIf="state !== 'NO_MEETING'">
    The Meeting will be accessible only to users who have access to the chatroom</ng-container>
</div>