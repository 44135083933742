import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(
    private route: ActivatedRoute,
    private elRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.hideLoader();
  }

  hideLoader() {
    const loader = this.elRef.nativeElement.ownerDocument.querySelector('.root-loader');
    if (loader) {
      loader.style.display = 'none'; // hide loader
    }
  }

  get name() {
    return this.route.snapshot.queryParams['name'];
  }

}
