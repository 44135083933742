import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Events } from '@echofin/libraries';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from '../../../_core/services/date.service';
import { IntervalUpdateTimerService } from '../../../_core/services/interval-update-timer.service';
import { ProfileService, LocalUsernameChanged } from '../../../_core/services/profile.service';
import { TeamService } from '../../../_core/services/team.service';
import { MessageStatus } from '../../../_shared/models/commons/message';
import { DEFAULTS } from '../../../_shared/models/defaults';
import { Member } from '../../../_shared/models/teams/member';
import { Role } from '../../../_shared/models/teams/role';
import { BaseComponent } from '../../base-component';
import { Subscription } from 'rxjs';
import { TimelineType } from '../models/timeline-type.enum';
import { User } from 'app/_shared/models/commons/user';

@Component({
  selector: 'app-message-header',
  templateUrl: './message-header.component.html',
  styleUrls: ['./message-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageHeaderComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() timelineType: TimelineType;
  @Input() sender: User;
  @Input() status: MessageStatus;
  @Input() collapsed: boolean;
  @Input() timestamp: Date;
  @Input() showAvatarPopover: boolean;
  @Input() showNamePopover: boolean;
  @Input() messageId: string;
  @Input() msgVersion: number;

  messageStatuses = MessageStatus;
  roleHover = false;
  defaults = DEFAULTS;

  role: Role = null;
  member: Member;
  isToday: boolean = false;
  timestampFormatted: string;
  counter = 0;
  icon: string;

  messageDate: Date;

  intervalSub: Subscription;
  showFullDate: boolean = false;

  teamId: string;

  @ViewChild('namepopover') public namepopover: NgbPopover;
  @ViewChild('avatarpopover') public avatarpopover: NgbPopover;

  public showRelativeTimeFormat: boolean;

  constructor(
    private teamService: TeamService,
    private profileService: ProfileService,
    private dateService: DateService,
    private updateTimeService: IntervalUpdateTimerService,
    private chr: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    if (this.teamService.activeTeam) {
      this.teamId = this.teamService.activeTeam.id;
    }
    this.showFullDate = this.timelineType === TimelineType.SearchMessages ||
      this.timelineType === TimelineType.BookmarkMessages ||
      this.timelineType === TimelineType.PinboardMessages ||
      this.timelineType === TimelineType.MentionedMessages ||
      this.timelineType === TimelineType.FilesMessages;
    this.messageDate = new Date(this.timestamp);
    this.updateTimestamp();

    this.subscribe(
      this.updateTimeService.showRelativeTimeFormat,
      (val: boolean) => {
        this.messageTimestampFormatChanged(val);
        this.chr.markForCheck();
      });
    // this.messageTimestampFormatChanged(this.updateTimeService.showRelativeTimeFormat.value);

    this.subscribe(
      this.dateService.timezoneChanged$,
      (timezone: string) => {
        this.updateTimestamp();
      });

    this.subscribe(
      this.dateService.timeformatChanged$,
      (is24h: boolean) => {
        this.updateTimestamp();
      });

    this.subscribe(
      this.teamService.memberRoleChanged$,
      (data: Events.MemberRoleChanged) => {
        if (this.sender.id === data.userId && data.teamId === this.teamId) {
          this.sender.roleId = data.roleId;
          this.loadRole();
          this.chr.markForCheck();
        }
      });

    this.subscribe(
      this.profileService.avatarChanged$,
      (data: Events.AvatarUpdated) => {
        if (this.sender.id !== data.userId) return;
        this.sender.avatar = data.avatar;
        this.chr.markForCheck();
      });

    this.subscribe(
      this.profileService.usernameChanged$,
      (data: LocalUsernameChanged) => {
        if (this.sender.id !== data.userId) return;
        this.sender.username = data.username;
        this.chr.markForCheck();
      });

    this.subscribe(
      this.teamService.roleChanged$,
      (data: Role) => {
        if (this.sender.roleId === data.id && data.teamId === this.teamId) {
          this.loadRole();
          this.chr.markForCheck();
        }
      });

    this.loadRole();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showAvatarPopover'] && changes['showAvatarPopover'].currentValue) {
      this.openProfileOnAvatar();
    }
    if (changes['showNamePopover'] && changes['showNamePopover'].currentValue) {
      this.openProfileOnName();
    }
  }

  private messageTimestampFormatChanged(val: boolean) {
    this.showRelativeTimeFormat = val;
    if (val) {
      this.beginCountingTime();
    } else {
      if (this.intervalSub) {
        this.intervalSub.unsubscribe();
        this.intervalSub = null;
      }
    }
  }

  updateTimestamp() {
    this.isToday = this.dateService.isToday(this.messageDate);
    this.timestampFormatted = this.dateService.getLocalTime(this.messageDate.valueOf());
  }

  loadRole() {
    // make sure this isn't direct message and user has roleId
    if (this.sender.roleId) {
      this.role = this.teamService.findRole(this.sender.roleId);
    } else if (!this.status || this.status === MessageStatus.PENDING || this.status === MessageStatus.SEND) {
      const activeTeam = this.teamService.teams.find(t => t.id === this.teamService.activeTeamId);
      this.role = activeTeam.member.roleId ? this.teamService.findRole(activeTeam.member.roleId) : null;
    } else {
      this.role = null;
    }
    this.icon = this.resolveFaIconFromRole(this.role);
  }

  private resolveFaIconFromRole(role: Role) {
    const roleIcon = (role && role.icon);
    if (roleIcon === null || typeof (roleIcon) === 'undefined') {
      return this.defaults.role.icon.replace(':', ' fa-');
    }

    return roleIcon.replace(':', ' fa-');
  }

  async openProfileOnName() {
    if (!this.namepopover.isOpen()) {
      this.member = await this.teamService.getMember(this.sender.id);
      this.chr.markForCheck();
      this.namepopover.open();
    }
  }

  async openProfileOnAvatar() {
    if (!this.avatarpopover.isOpen()) {
      this.member = await this.teamService.getMember(this.sender.id);
      this.chr.markForCheck();
      this.avatarpopover.open();
    }
  }

  beginCountingTime() {
    if (!this.intervalSub && this.showRelativeTimeFormat && this.isToday) {
      // console.log(`MESSAGE UPDATED -- SUBSCRIBED ${this.id}`);
      if (this.isDateDiffMoreThan1Minutes(this.messageDate, new Date())) {
        this.counter = 5;
      }
      this.intervalSub = this.updateTimeService.chatUpdateInterval.subscribe(() => {
        this.counter++;
        if (this.counter < 6 || this.counter % 6 === 0) {
          this.isToday = this.dateService.isToday(this.messageDate);
          this.timestampFormatted = this.dateService.getLocalTime(this.messageDate.valueOf());
          // console.log(`MESSAGE UPDATED -- ${this.messageDate} -- TIME NOW: ${new Date()}`);
          this.chr.markForCheck();
        }
        if (!this.isToday) {
          // console.log(`MESSAGE UPDATED -- UNNNNSUBSCRIBED ${this.id}`);
          this.intervalSub.unsubscribe();
          this.intervalSub = null;
        }
      });
    }
  }

  isDateDiffMoreThan1Minutes(dt1: Date, dt2: Date) {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
    return Math.abs(diff) > 1;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalSub) {
      // console.log(`MESSAGE UPDATED -- UNNNNSUBSCRIBED ${this.id}`);
      this.intervalSub.unsubscribe();
      this.intervalSub = null;
    }

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
