import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BrandingService } from '../../_core/services/branding.service';
import { ProfileService } from '../../_core/services/profile.service';
import { teamNameRules } from './team-name-rules';
// import * as LogRocket from 'logrocket';

@Injectable({
  providedIn: 'root'
})
export class DomainGuard implements CanActivate {
  constructor(
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService,
    private profileService: ProfileService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isCustom = this.brandingService.isCustomDomain();
    const teamName = window.location.host.replace(`.${environment.config.domain}`, '');

    console.log('load me from domain guard');
    return this.profileService.loadMe()
      .then(uid => {
        // LogRocket.identify(uid);
        // if not in app subdomain, redirect to it with correct team

        const prefix = this.brandingService.team && this.brandingService.team.customDomain ? '' : 'app';

        if (isCustom) {
          return true;
        }

        // SUPPORT INVITE LINK AFTER REDIRECT
        let teamOrInvitePath = '';
        if (route.url &&
          route.url[0] &&
          route.url[0].path &&
          route.url[0].path === 'invite') {
          teamOrInvitePath = route.url[0].path;
          const query = Object.keys(route.queryParams).map(key => {
            return `${key}=${route.queryParams[key]}`;
          }).join('&');
          teamOrInvitePath = `${teamOrInvitePath}?${query}`;
        } else {
          teamOrInvitePath = teamName;
        }

        if ((window.location.host !== `${prefix}.${environment.config.domain}` && prefix !== '') ||
          (window.location.host !== environment.config.domain && prefix === '')) {
          const teamName = window.location.host.replace(`.${environment.config.domain}`, '');
          if (prefix !== '') {
            window.location.href = `${window.location.protocol}//${prefix}.${environment.config.domain}/${teamOrInvitePath}`;
          } else {
            window.location.href = `${window.location.protocol}//${environment.config.domain}/${teamOrInvitePath}`;
          }
          return false;
        }

        return teamName === 'app';
      })
      .catch((err) => {
        // mobile subdomain just allow to go to mobile guard as is
        const subdomain = window.location.host.replace(`.${environment.config.domain}`, '');
        if (subdomain === 'mobile') {
          return true;
        }

        // SUPPORT INVITE LINK AFTER REDIRECT
        let loginOrInviteSignupPath = '';
        if (route.url &&
          route.url[0] &&
          route.url[0].path &&
          route.url[0].path.indexOf('signup?inv=') === 0) {
          loginOrInviteSignupPath = route.url[0].path;
          // return true;
        } else {
          loginOrInviteSignupPath = 'signup';
        }

        if (err.status === 401 && route.params['team'] && loginOrInviteSignupPath === 'signup') {
          const teamName = route.params['team'];
          if (teamNameRules.blackListedTeamNamesNormalRoute.indexOf(teamName) > -1) {
            window.location.href = `${window.location.protocol}//app.${environment.config.domain}/signup`;
          }
          window.location.href = `${window.location.protocol}//${route.params['team']}.${environment.config.domain}/signup`;
        }
        this.router.navigateByUrl(`/${loginOrInviteSignupPath}`);

        return false;
      });
    // if (!me) {
    //   this.router.navigateByUrl('/login');
    //   return false;
    // }
  }
}
