import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-download-clients',
  templateUrl: './download-clients.component.html',
  styleUrls: ['./download-clients.component.scss']
})
export class DownloadClientsComponent implements OnInit {

  get m() {
    return this.activeModal;
  }
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
