import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../_core/services/auth.service';
import { BrandingService } from '../_core/services/branding.service';
import { SocketService } from '../_core/services/socket.service/socket.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  @ViewChild('sso', { static: true }) sso: ElementRef;

  ssoUrl: string = `${environment.config.endpoints.authentication}/authentication/sso.html`;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private brandingService: BrandingService,
    private socketService: SocketService,
  ) { }

  ngOnInit() {
    this.authService.sso = this.sso;
    this.route.queryParams.subscribe((params) => {
      this.socketService.disconnect();
      setTimeout(
        () => {
          this.authService
            .logout()
            .then(() => {
              console.log('environment.URL_DOMAIN');
              if (this.brandingService.isCustomDomain()) {
                this.router.navigate(['/login']);
              } else {
                const prefix = 'app';
                window.location.href = `${window.location.protocol}//${params.team || prefix}.${environment.config.domain}/login`;
              }
            });
        },
        2000);
    });
  }
}
