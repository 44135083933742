<div class="modal-header">
    <h4 class="modal-title">Team invite link</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <div class="input-group">
            <input #teamUrl type="text" class="form-control bg-dark text-white" (keydown)="preventChange()"
                (click)="copyLink()">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="copyLink()">Copy Link</button>
            </div>
            <div class="borderfx"></div>
        </div>
    </div>
</div>
<div class="modal-footer text-center">
    <div>
        <span><small>Share to: </small></span>
        <a href="https://www.facebook.com/sharer/sharer.php?u={{teamUrl.value}}/" class="share-social-btn"
            (click)="track('facebook')" target="_blank">
            <i class="fab fa-facebook-square"></i> Facebook
        </a>
        <a href="http://twitter.com/share?text=Join {{teamname}} on Echofin&url={{teamUrl.value}}/&hashtags=echofin"
            class="share-social-btn" (click)="track('twitter')" target="_blank">
            <i class="fab fa-twitter-square"></i> Twitter
        </a>
        <a href="https://telegram.me/share/url?url={{teamUrl.value}}/&text=Join {{teamname}} on Echofin"
            class="share-social-btn" (click)="track('telegram')" target="_blank">
            <i class="fab fa-telegram"></i> Telegram
        </a>
    </div>
</div>