<div #wrapper class="d-flex h-100">
  <div class="leftmenu flex-shrink-0" [ngClass]="{'closed': sidebarClosed}">
    <div class="menubar flex-shrink-0">
      <app-profile-bar></app-profile-bar>
      <app-teambar class="scroll-y-hidden" (onAdd)="addTeam()"></app-teambar>
    </div>
    <app-sidebar [ngClass]="{'isMaximized':maximizedItem}"></app-sidebar>
  </div>
  <app-reload></app-reload>
  <div class="chat-container"
    [ngStyle]="{'background-image':branding.chatCoverUrl?'url('+branding.chatCoverUrl+')':'none'}">
    <div #panelsWrapper cdkDropList [cdkDropListData]="panels" cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="movePanel($event)" class="panels-wrapper" [ngClass]="{'maximized':isMaximized()}">
      <ng-container *ngIf="initialized && panels.length">
        <ng-container *ngFor="let panel of panels; let i=index; trackBy: trackByFn;">

          <ng-container *ngIf="(panel.type==='Direct' || teamIsExtended(panel))">

            <div cdkDrag [cdkDragData]="panel" (cdkDragStarted)="dragStart($event)" id="wrapper-{{panel.id}}"
              class="panel-container-wrapper"
              [ngClass]="{'panel-sm': isSM(panel), 'panel-md': isMD(panel), 'panel-lg': isLG(panel), 'panel-max': isMax(panel), 'd-none': isHidden(panel)}">
              <app-panel-header id="header-{{panel.id}}" cdkDragHandle [panel]="panel"> </app-panel-header>
              <ng-container [ngSwitch]="panel.type">
                <app-panel-chatroom class="panel-inner" *ngSwitchCase="panelType.Chatroom" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-chatroom>
                <app-panel-chatroom class="panel-inner" *ngSwitchCase="panelType.Direct" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-chatroom>
                <app-panel-service class="panel-inner" *ngSwitchCase="panelType.Frame" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-service>
                <app-panel-mentioned-messages class="panel-inner" *ngSwitchCase="panelType.Mentions"
                  id="panel-{{panel.id}}" [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-mentioned-messages>
                <app-panel-files class="panel-inner" *ngSwitchCase="panelType.Files" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id"></app-panel-files>
                <!-- <app-panel-general class="panel-inner" *ngSwitchCase="panelType.Filter" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-general> -->
                <app-panel-pinboard-messages class="panel-inner" *ngSwitchCase="panelType.PinboardMessages"
                  id="panel-{{panel.id}}" [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-pinboard-messages>
                <app-panel-bookmark-messages class="panel-inner" *ngSwitchCase="panelType.BookmarkMessages"
                  id="panel-{{panel.id}}" [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-bookmark-messages>
                <app-panel-search-messages class="panel-inner" *ngSwitchCase="panelType.Search" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-search-messages>
                <app-panel-reply-message class="panel-inner" *ngSwitchCase="panelType.ReplyMessage"
                  id="panel-{{panel.id}}" [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-reply-message>
                <app-panel-pulse class="panel-inner" *ngSwitchCase="panelType.PulseMessages" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-pulse>
                <app-panel-signals class="panel-inner" *ngSwitchCase="panelType.SignalMessages" id="panel-{{panel.id}}"
                  [panel]="panel" [attr.data-panelid]="panel.id">
                </app-panel-signals>
                <div *ngSwitchDefault> NO - PANEL </div>
              </ng-container>
            </div>
          </ng-container>


        </ng-container>
      </ng-container>
    </div>
    <app-rightbar [wrapper]="wrapper"></app-rightbar>
  </div>
</div>
<div #testerContainer class="reverse-tester">
  <div>
    <div #testerBottom class="reverse-tester-inner"></div>
    <div class="reverse-tester-inner"></div>
    <div class="reverse-tester-inner"></div>
    <div class="reverse-tester-inner"></div>
    <div class="reverse-tester-inner"></div>
    <div class="reverse-tester-inner"></div>
  </div>
</div>

<router-outlet></router-outlet>

<ng-template #noTeams>
  <div class="p-4 text-center">No teams</div>
</ng-template>
<ng-template #notSelected>
  <div class="p-4 text-center">No team is selected</div>
</ng-template>