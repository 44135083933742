import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../_core/services/team.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
})
export class DisclaimerComponent implements OnInit {

  disclaimer: string;

  state = 'DISCLAIMER'

  get teamName() {
    return this.teamService.activeTeam.name
  }

  constructor(
    private activeModal: NgbActiveModal,
    private teamService: TeamService,
  ) { }

  ngOnInit() {
    this.disclaimer = this.teamService.activeTeam['disclaimer']
  }

  accept() {
    this.teamService.disclaimerAccepted.teamIds.push(this.teamService.activeTeam.id);
    this.teamService.disclaimerAccepted.save();
    this.activeModal.close();
  }

  async confirmLeave() {
    await this.teamService.leaveActiveTeam()
    this.teamService.removeTeam(this.teamService.activeTeam.id);
    this.activeModal.close();
  }
}
