import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-billing-confirm',
  templateUrl: './billing-confirm.component.html',
  styleUrls: ['./billing-confirm.component.scss']
})
export class BillingConfirmComponent implements OnInit {

  @Input() plan: any;
  @Input() connected: {
    customer: string;
    teamId: string;
    account: string;
    cards: any[];
  };

  @Output() authorized = new EventEmitter<{ paymentId: string }>();
  @Output() switchNew: EventEmitter<void> = new EventEmitter<void>();

  cards = [];
  selected;

  ngOnInit() {
    this.cards = this.connected.cards.filter((v, i, a) => a.findIndex(t => (t.card.fingerprint === v.card.fingerprint)) === i);
    this.selected = this.cards[0];
  }

  switch() {
    this.switchNew.emit();
  }

  async authorize() {
    this.authorized.emit({ paymentId: this.selected.id });
  }
}
