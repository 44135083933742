import { Component, ChangeDetectionStrategy, OnDestroy, ElementRef } from '@angular/core';

@Component({
  selector: 'app-content-quote',
  templateUrl: './content-quote.component.html',
  styleUrls: ['./content-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentQuoteComponent implements OnDestroy {

  constructor(private hostElement: ElementRef) { }
  
  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
