import { Component, OnInit, OnDestroy } from '@angular/core';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { EvType } from './../../_core/services/socket.service/models/all.models';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent extends BaseComponent implements OnInit {

  show = false;
  message: string;
  realTimeout: number = Date.now() + 30 * 1000;
  remain: number = 0;
  timeout;

  constructor(
    private socketService: SocketService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(
      this.socketService.getStream(EvType.Reload),
      (data) => {
        this.handleReload(data.message, data.timeout);
      });

    this.subscribe(
      this.socketService.checkForVersion$,
      (check) => {
        if (check) {
          this.handleReload('There is a new version available! Reloading...', 300); // 5 minute spread reload time
        }
      });
  }

  handleReload(message: string, maxTimeoutInSeconds: number) {
    this.message = message;
    this.show = true;

    if (maxTimeoutInSeconds === -1) {
      return;
    }

    this.realTimeout = Date.now() + (Math.round(Math.random() * maxTimeoutInSeconds) * 1000);
    console.log(`reload in ${Math.round((this.realTimeout - Date.now()) / 1000)}"`);
    this.timeout = setTimeout(
      () => {
        this.reload();
      },
      this.realTimeout - Date.now());
  }

  reload() {
    window.location.reload();
  }
}
