import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Smilie {
  id: number;
  code: string;
  img: string;
  title: string;
  reference: string;
  tab: string;
  unicode: string;
}

@Injectable({ providedIn: 'root' })
export class CountriesService {

  countries: { code: string, name: string }[];

  constructor(private http: HttpClient) { }

  async setup() {
    if (!this.countries) {
      this.countries = await this.http
        .get<{ code: string, name: string }[]>('/assets/countries.json')
        .toPromise();
    }
  }

}
