import * as version from '../assets/version.json';
import { Version } from './../app/_shared/models/version';
import { DynamicEnvironment } from './dynamic-environment';

class Environment extends DynamicEnvironment {

  version: Version;
  production: boolean;

  constructor() {
    super();
    this.version = version['default'];
    this.production = true;
  }
}

export const environment = new Environment();
