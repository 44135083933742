export interface FileFailed {
  filename: string;
  mimetype: string;
  originalPath: string;
  size: number;
  source: string;
  uploadId: string;
  originalFile: {
    name: string;
    type: string;
    size: number;
  };
}

export class FileUploaded {
  constructor(input) {
    if (!input) { return; }
    this.filename = input.filename;
    this.handle = input.handle;
    this.mimetype = input.mimetype;
    this.size = input.size;
    this.status = input.status;
    this.url = input.url;
  }
  filename: string;
  handle: string;
  mimetype: string;
  size: number;
  status: string;
  url: string;

  // toMessageFile(): FileAttachment {
  //   return {
  //     filename: this.filename,
  //     mimeType: this.mimetype,
  //     originalUrl: this.url,
  //     handle: this.handle,
  //     url: null,
  //     thumbUrl: null,
  //     type: null
  //   };
  // }
}

// export interface FileStackPolicy {
//   policy: string;
//   signature: string;
// }
