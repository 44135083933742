import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ChatroomResp } from '@echofin/libraries/api/team/models/chatroom-resp';
import { BaseComponent } from '../../../chat/base-component';
import { environment } from '../../../../environments/environment';
import { Chatroom } from '../../../_shared/models/room/room';
import { MessageCountService } from '../services/message-count.service';

@Component({
  selector: 'app-room-message-count',
  templateUrl: './room-message-count.component.html',
  styleUrls: ['./room-message-count.component.scss']
})
export class RoomMessageCountComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() roomId: string;
  @Input() teamId: string = null;
  countText: string;

  isDirect = true;

  @HostBinding('style.width')
  get visible() {
    return this.isDirect ? '' : (this.countText ? '28px' : '0');
  }

  constructor(
    private messageCountService: MessageCountService,
  ) {
    super();
  }

  ngOnInit() {
    const room = this.messageCountService.getRoom(this.roomId);
    this.isDirect = !(room.type && room.type === 'Team');
    if (room) {
      this.listenRoom(room);
    }
  }

  listenRoom(room: Chatroom) {
    this.log(`listen room for ${room.id}`);

    this.countText = this.messageCountService.convertMessageCountForView(room.unread);

    this.subscribe(this.messageCountService.chatroomMessageCounterChanged$, (room: ChatroomResp) => {
      if (this.roomId === room.id) {
        this.countText = this.messageCountService.convertMessageCountForView(room.unread);
        room.unread = room.unread;
      }
    });

    this.subscribe(this.messageCountService.chatroomLastTimestampChanged$, ({ chatroomId, timestamp }) => {
      if (this.roomId === chatroomId) {
        room.lastReadTimestamp = timestamp.toString();
      }
    });
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[MESSAGE-COUNT-COMPONENT]', 'color:#2496F3', ...arg);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
