import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../environments/environment';
import { ProfileService } from './../../_core/services/profile.service';
import { Me } from './../../_shared/models/commons/me';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent implements OnInit {

  @Input() me: Me;

  connected: any[];
  activeCategory: number = 0;

  get build() {
    return environment.version.hash.substr(0, 8);
  }

  constructor(
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    await this.http
      .get<{ platform: any, connected: any[] }>(`${environment.config.endpoints['billing']}/billing/users/${this.profileService.me.id}`)
      .toPromise()
      .then((res) => {
        this.connected = res.connected;
      })
      .catch(() => { });
  }
}
