import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../../chat/confirm/confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private modal: NgbModal) { }

  public async confirm(title: string, message: string): Promise<boolean> {
    const ref = await this.modal.open(
      ConfirmComponent,
      {
        centered: true,
        windowClass: 'modal-dark',
        backdrop: 'static',
        container: 'body'
      }
    );
    ref.componentInstance.confirmTitle = title;
    ref.componentInstance.confirmMessage = message;
    const res = await ref.result.then(res => res, rej => false);
    return res;
  }
}
