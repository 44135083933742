import { Injectable } from '@angular/core';
import { Events, MessageMessagesServiceApi } from '@echofin/libraries';
import { CreateMessageReq } from '@echofin/libraries/api/message/models/create-message-req';
import { MessageResp } from '@echofin/libraries/api/message/models/message-resp';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageStatus, LocalMessageProcessed } from '../../_shared/models/commons/message';
import { Message } from './../../_shared/models/commons/message';
import { MessageIdGeneratorService } from './message-id-generator.service';
import { MessageTextFormatterService } from './message-text-formatter.service';
import { ProfileService } from './profile.service';
import { RoomService } from './room.service';
import { EvType } from './socket.service/models/all.models';
import { SocketService } from './socket.service/socket.service';
import { ReactionModel } from '@echofin/libraries/api/message/models/reaction-model';
import { TeamService } from './team.service';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { ChannelChange } from 'app/_shared/models/commons/channel-change';

export const MAX_CHARS = 12000;
export const MAX_CHARS_THRESHOLD = 50;
export const MAX_EDIT_TIMESPAN = 300000; // 5 minutes max edit time after posting
export const MESSAGES_PER_PAGE = 40;

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  messages: { [key: string]: Message[] } = {};
  replyHeaders: { [key: string]: Message } = {};

  hasMoreMessages = {};
  hasMoreMessagesBelow = {};

  messagesReceived$: Subject<Message[]> = new Subject<Message[]>();
  messageReceived$: Subject<Message> = new Subject<Message>();
  messageDeleted$: Subject<Events.MessageDeleted> = new Subject<Events.MessageDeleted>();
  messageReplacementReceived$: Subject<{ replace: Message, oldId: string }> = new Subject<{ replace: Message, oldId: string }>();
  messageFailed$: Subject<string> = new Subject<string>();
  messageReactionsChanged$: Subject<{ id: string, message: Message, reactions: ReactionModel[] }> = new Subject<{ id: string, message: Message, reactions: ReactionModel[] }>();

  messagesCount: number = MESSAGES_PER_PAGE;

  // this holds socket incoming messages that don't have API post message response yet
  messageBuffer: Message[] = [];

  isInQuotes = {};
  quotes = {};
  isInReplies = {};
  replies = {};
  quote$: Subject<{ room?: string, quote?: string }>;
  reply$: Subject<{ room?: string, parentId?: string }>;

  insertEmojiOrReact$ = new Subject<{ panelId: string, emoji: string }>();
  insertEmojiInMultiline$ = new Subject<{ panelId: string, emoji: string }>();

  constructor(
    private msgApi: MessageMessagesServiceApi,
    private profileService: ProfileService,
    private roomService: RoomService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private mtf: MessageTextFormatterService,
    private messageIdGenerator: MessageIdGeneratorService,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private teamService: TeamService
  ) {
    this.quote$ = new Subject<{ room?: string, quote?: string }>();
    this.reply$ = new Subject<{ room?: string, parentId?: string }>();
  }

  getLocalId(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const length = 8;
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  setup() {
    this.socketService
      .getStream(EvType.MessageProcessed)
      .subscribe((message: LocalMessageProcessed) => {
        console.log('MessageService LocalMessageProcessed', message);
        const m = MessageHelpers.mapMessageResp(message.message as unknown as MessageResp);
        // REACTIONS ONLY! DONT PROCESS FURTHER!
        if (message.type === 'REACTIONS') {
          const msgToUpdate = (this.messages[message.message.chatroom.id] || []).find(m => m.id === message.message.id);
          if (msgToUpdate) {
            msgToUpdate.reactions = [...message.message.reactions];
          }
          this.messageReactionsChanged$.next({ id: message.message.id, message: m, reactions: message.message.reactions });
          return;
        }
        this.processMessage(m, message.type);
      });

    this.socketService
      .getStream(EvType.MessageDeleted)
      .subscribe((data: Events.MessageDeleted) => {
        console.log('MessageService MessageDeleted', data);
        this.removeMessage(data.messageId, data.chatroomId);
        this.removeReplyThread(data.messageId);
        this.messageDeleted$.next(data);
      });
  }

  removedExcessMessages(chatroomId: string) {
    if (!this.messages[chatroomId]) {
      return;
    }
    const sliceIndex = Math.max(this.messages[chatroomId].length - MESSAGES_PER_PAGE, 0);
    this.messages[chatroomId] = this.messages[chatroomId]
      .slice(sliceIndex);
  }

  getMessageFromAnyChannel(id: string): Message {
    console.log('getMessageFromAnyChannel', this.messages);
    for (const chatroomId in this.messages) {
      if (this.messages.hasOwnProperty(chatroomId)) {
        const msg = this.getMessage(id, chatroomId);
        if (msg) { return msg; }
      }
    }
    return null;
  }

  getMessageFromAnyThread(id: string) {
    for (const threadId in this.messages) {
      if (this.messages.hasOwnProperty(threadId)) {
        const msg = this.getMessage(id, threadId);
        if (msg) { return msg; }
      }
    }
    return null;
  }

  getLastDate(profileId: string, chatroomId: string): Date {
    if (!this.messages[chatroomId]) { return null; }

    const messages = this.messages[chatroomId].filter(m => m.sender.id === profileId).sort((a, b) => MessageHelpers.compareCreatedDates(b, a));
    if (messages.length > 0) {
      return new Date(messages[0].timestamp);
    }
    return null;
  }

  calculateHasMoreMessages(change: ChannelChange) {
    if (change.first) {
      this.hasMoreMessages[change.channelId] = false;
    }
    if (change.topMessagesCount != null && change.topMessagesCount === change.topLimit) {
      this.hasMoreMessages[change.channelId] = true;
    }
    if (change.topMessagesCount < change.topLimit) {
      this.hasMoreMessages[change.channelId] = false;
    }
    if (change.bottomMessagesCount != null && change.bottomMessagesCount === change.bottomLimit) {
      this.hasMoreMessagesBelow[change.channelId] = true;
    }
    if (change.bottomMessagesCount < change.bottomLimit) {
      this.hasMoreMessagesBelow[change.channelId] = false;
    }
    if (change.latest) {
      this.hasMoreMessagesBelow[change.channelId] = false;
    }
  }

  async getReplies(messageId: string, timestamp?: Date) {
    const timestampString = (timestamp) ? timestamp.toISOString() : '';

    const repliesResponse = await this.msgApi
      .GetMessageWithReplies({
        id: messageId,
        Timestamp: timestampString
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to retrieve replies');
      });

    if (!repliesResponse) return;

    const mappedMessages = repliesResponse.replies.map((m) => {
      return MessageHelpers.mapMessageResp(m);
    });

    const topMsg = mappedMessages[0];

    if (!this.messages[messageId] || !timestamp) {
      this.messages[messageId] = [];
    }

    if (repliesResponse.message) {
      this.replyHeaders[messageId] = MessageHelpers.mapMessageResp(repliesResponse.message);
    }

    mappedMessages.forEach((message: Message) => {
      this.messages[messageId].push(message);
    });

    this.filterSortAndCollapsify(messageId, this.hasMoreMessages[messageId]);

    let channelChange: ChannelChange;
    if (typeof (topMsg) === 'undefined') {
      channelChange = {
        channelId: messageId,
        topActiveDate: new Date(),
        topMessagesCount: mappedMessages.length,
        topLimit: MESSAGES_PER_PAGE,
        latest: timestamp ? false : true
      };
    } else {
      channelChange = {
        channelId: messageId,
        topActiveDate: new Date(topMsg.timestamp),
        topMessagesCount: mappedMessages.length,
        topLimit: MESSAGES_PER_PAGE,
        latest: timestamp ? false : true
      };
    }
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    this.messagesReceived$.next(mappedMessages);

    return repliesResponse;
  }

  async getMessages(chatroomId: string, timestamp?: Date, includeTimestamp?: boolean) {

    const timestampString = (timestamp) ? timestamp.toISOString() : '';

    const messages = await this.msgApi
      .GetMessages({
        ChatroomId: chatroomId,
        Timestamp: timestampString,
        Limit: MESSAGES_PER_PAGE,
        IncludeTimestamp: includeTimestamp
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to retrieve messages');
      });

    if (!messages) return;

    if (!this.messages[chatroomId] || !timestamp) {
      this.messages[chatroomId] = [];
    }

    const mappedMessages = messages.messages.map((m) => {
      return MessageHelpers.mapMessageResp(m);
    });

    if (!mappedMessages.length) {
      let channelChange = {
        channelId: chatroomId,
        first: true,
        latest: timestamp ? false : true
      };
      this.calculateHasMoreMessages(channelChange);
      this.roomService.channelChange$.next(channelChange);
      return;
    }

    const topMsg = mappedMessages[0];

    mappedMessages.forEach((message: Message) => {
      this.messages[chatroomId] = this.messages[chatroomId].filter(m => m.id !== message.id);
      this.messages[chatroomId].push(message);
    });

    this.filterSortAndCollapsify(chatroomId, this.hasMoreMessages[chatroomId]);

    let channelChange = {
      channelId: chatroomId,
      topActiveDate: new Date(topMsg.timestamp),
      topMessagesCount: mappedMessages.length,
      topLimit: MESSAGES_PER_PAGE,
      latest: timestamp ? false : true
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    this.messagesReceived$.next(mappedMessages);
  }

  async getMessagesNext(chatroomId: string, timestamp?: Date, includeTimestamp?: boolean) {

    const timestampString = (timestamp) ? timestamp.toISOString() : '';

    const messages = await this.msgApi
      .GetMessagesAfter({
        ChatroomId: chatroomId,
        Timestamp: timestampString,
        IncludeTimestamp: includeTimestamp,
        Limit: MESSAGES_PER_PAGE
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to retrieve messages');
      });

    if (!messages) return;

    const mappedMessages = messages.messages.map((m) => {
      return MessageHelpers.mapMessageResp(m);
    });

    if (!messages) {
      return;
    }
    if (!mappedMessages.length) {
      let channelChange = {
        channelId: chatroomId,
        latest: true
      };
      this.calculateHasMoreMessages(channelChange);
      this.roomService.channelChange$.next(channelChange);
      return;
    }

    const bottomMsg = mappedMessages[mappedMessages.length - 1];

    if (!this.messages[chatroomId] || !timestamp) {
      this.messages[chatroomId] = [];
    }

    mappedMessages.forEach((message: Message) => {
      this.messages[chatroomId] = this.messages[chatroomId].filter(m => m.id !== message.id);
      this.messages[chatroomId].push(message);
    });

    this.filterSortAndCollapsify(chatroomId, this.hasMoreMessages[chatroomId]);

    let channelChange = {
      channelId: chatroomId,
      bottomActiveDate: new Date(bottomMsg.timestamp),
      bottomMessagesCount: mappedMessages.length,
      bottomLimit: MESSAGES_PER_PAGE
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    this.messagesReceived$.next(mappedMessages);
  }

  getMessagesBeforeAndAfter(chatroomId: string, timestamp?: Date) {
    const beforePromise = this.getMessagesBefore(chatroomId, timestamp);
    const afterPromise = this.getMessagesAfter(chatroomId, timestamp);
    let before;
    let after;
    return Promise.all([beforePromise, afterPromise]).then(v => {
      before = v[0] || [];
      after = v[1] || [];

      const allMessages = before.concat(after);

      const mappedMessages = [];
      allMessages.forEach((message: Message) => {
        mappedMessages.push(message);
      });

      this.messages[chatroomId] = MessageHelpers.filterSortAndCollapsifyArray(mappedMessages, this.hasMoreMessages[chatroomId]);

      console.log('SCROLL - GOT MESSAGES');

      if (!mappedMessages.length) {
        return;
      }

      const topMsg = allMessages[0];
      const bottomMsg = allMessages[allMessages.length - 1];
      let channelChange = {
        channelId: topMsg.chatroom.id,
        topActiveDate: new Date(topMsg.timestamp),
        topMessagesCount: before.length,
        topLimit: 40,
        bottomActiveDate: new Date(bottomMsg.timestamp),
        bottomMessagesCount: after.length,
        bottomLimit: 40
      };
      this.calculateHasMoreMessages(channelChange);
      this.roomService.channelChange$.next(channelChange);

      this.messagesReceived$.next(allMessages);
    });
  }

  private async getMessagesBefore(chatroomId: string, timestamp?: Date) {

    const timestampString = (timestamp) ? timestamp.toISOString() : '';

    const messages = await this.msgApi
      .GetMessagesBefore({
        ChatroomId: chatroomId,
        Timestamp: timestampString,
        IncludeTimestamp: false,
        Limit: 40
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to retrieve messages');
      });

    if (!messages) return;

    const mappedMessages = messages.messages.map((m) => {
      return MessageHelpers.mapMessageResp(m);
    });

    if (!messages || !mappedMessages.length) {
      return;
    }

    if (!this.messages[chatroomId] || !timestamp) {
      this.messages[chatroomId] = [];
    }

    return mappedMessages;
    // DONT EMIT MESSAGES - MUST COMBINE BEFORE AND AFTER AND THEN EMIT!
    // this.messagesReceived$.next(mappedMessages);
  }

  private async getMessagesAfter(chatroomId: string, timestamp?: Date) {

    const timestampString = (timestamp) ? timestamp.toISOString() : '';

    const messages = await this.msgApi
      .GetMessagesAfter({
        ChatroomId: chatroomId,
        Timestamp: timestampString,
        IncludeTimestamp: true,
        Limit: 40
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('Unable to retrieve messages');
      });

    if (!messages) return;

    const mappedMessages = messages.messages.map((m) => {
      return MessageHelpers.mapMessageResp(m);
    });

    if (!messages || !mappedMessages.length) {
      return;
    }

    if (!this.messages[chatroomId] || !timestamp) {
      this.messages[chatroomId] = [];
    }

    return mappedMessages;
    // DONT EMIT MESSAGES - MUST COMBINE BEFORE AND AFTER AND THEN EMIT!
    // this.messagesReceived$.next(mappedMessages);
  }

  private trackEvent(name, params = null) {
    if (environment.config.name !== 'echofin') return;
    this.angulartics2Amplitude.eventTrack(name, params || {});
    this.angulartics2GoogleTagManager.eventTrack(name, params || {});
  }

  async send(model: CreateMessageReq) {
    let trackingParams = null;
    if (model.chatroomId.indexOf('chr_') === 0 && this.teamService.activeTeam) {
      trackingParams = {
        teamname: this.teamService.activeTeam.name,
        teamid: this.teamService.activeTeam.id
      };
    } else if (model.chatroomId.indexOf('dmr_') === 0) {
      trackingParams = {
        teamname: 'DIRECT_MESSAGE'
      };
    } else if (model.chatroomId.indexOf('gmr_') === 0) {
      trackingParams = {
        teamname: 'GROUP_MESSAGE'
      };
    }
    this.trackEvent('message post', trackingParams);

    const message = this.generateTempMessage(model, false);
    const tempMessageId = message.id;

    let messageResp: Message;
    let failed = false;
    messageResp = await this.msgApi.CreateMessage(model)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Message failed to be sent');
        message.status = MessageStatus.FAILED;
        this.messageFailed$.next(message.id);
        failed = true;
        return null;
      });

    if (!messageResp) {
      if (!failed) {
        message.status = MessageStatus.FAILED;
        this.messageFailed$.next(message.id);
      }
      return;
    }

    if (this.hasMoreMessagesBelow[model.chatroomId]) {
      return messageResp.id;
    }

    // check if message came from socket first
    const bufferedIndex = this.messageBuffer.findIndex(c => c.id === messageResp.id);

    let updated;
    // if message came from socket, it is already processed, so get it from there and discard API response
    if (bufferedIndex > -1) {

      updated = this.handleMessageResponseFromBuffer(bufferedIndex, message.chatroom.id, message.id);
      message.id = updated.id;
    } else {

      // otherwise update from API response
      message.id = messageResp.id;
      this.messages[message.chatroom.id] = this.messages[message.chatroom.id].map(m => {
        if (m.id === message.id) {
          updated = {
            ...messageResp,
            // retain calculated first message of
            isCollapsed: m.isCollapsed, // IF NOT FOR THIS LINE, THE TIMELINE HOPS UP AND DOWN AND "FLASHES"
            firstMessageOfDay: m.firstMessageOfDay,
            timestamp: new Date(messageResp.timestamp),
            status: MessageStatus.SEND,
            localVersion: m.localVersion ? m.localVersion + 1 : 1
          };

          return updated;
        }
        return m;
      });

      // CHECK AGAIN FOR BUFFER, IN CASE EVENT CAME FROM SOCKET WHILE THIS CODE BLOCK WAS RUNNING
      const bufferedIndex = this.messageBuffer.findIndex(c => c.id === messageResp.id);
      if (bufferedIndex > -1) {

        updated = this.handleMessageResponseFromBuffer(bufferedIndex, message.chatroom.id, message.id);
      }
    }

    this.filterSortAndCollapsify(message.chatroom.id, this.hasMoreMessages[message.chatroom.id]);
    if (updated) {
      // console.log('LOADMESSAGES - replace - send response or socket buffer')
      this.messageReplacementReceived$.next({ replace: updated as unknown as Message, oldId: message.id });
    }

    let channelChange = {
      channelId: message.chatroom.id,
      topActiveDate: message.timestamp
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    return message.id;
  }

  handleMessageResponseFromBuffer(bufferedIndex: number, chatroomId: string, messageId: string) {
    const messageResp = this.messageBuffer.splice(bufferedIndex, 1)[0];
    let updatedMessage;
    this.messages[chatroomId] = this.messages[chatroomId].map(m => {
      if (m.id === messageId) {
        updatedMessage = {
          ...messageResp,
          isCollapsed: m.isCollapsed,
          firstMessageOfDay: m.firstMessageOfDay,
          timestamp: new Date(messageResp.timestamp),
          status: MessageStatus.DELIVERED,
          localVersion: m.localVersion ? m.localVersion + 1 : 1
        };
        return updatedMessage;
      }
      return m;
    });
    return updatedMessage;
  }

  async sendFromReplyPanel(model: CreateMessageReq) {
    let trackingParams = null;
    if (model.chatroomId.indexOf('chr_') === 0 && this.teamService.activeTeam) {
      trackingParams = {
        teamname: this.teamService.activeTeam.name,
        teamid: this.teamService.activeTeam.id
      };
    } else if (model.chatroomId.indexOf('dmr_') === 0) {
      trackingParams = {
        teamname: 'DIRECT_MESSAGE'
      };
    } else if (model.chatroomId.indexOf('gmr_') === 0) {
      trackingParams = {
        teamname: 'GROUP_MESSAGE'
      };
    }
    this.trackEvent('message post', trackingParams);

    const message = this.generateTempMessage(model, true);

    let messageResp: Message;
    messageResp = await this.msgApi.CreateMessage(model)
      .toPromise()
      .catch((err) => {
        this.toastr.error('Reply could not be sent');
        message.status = MessageStatus.FAILED;
        return null;
      });

    if (!messageResp) {
      message.status = MessageStatus.FAILED;
      return;
    }

    // check if message came from socket first
    const bufferedIndex = this.messageBuffer.findIndex(c => c.id === messageResp.id);

    // if message came from socket, it is already processed, so get it from there and discard API response
    if (bufferedIndex > -1) {
      messageResp = this.messageBuffer.splice(bufferedIndex, 1)[0];
      this.messages[model.reply.parentId] = this.messages[model.reply.parentId].map(m => {
        if (m.id === message.id) {
          return {
            ...messageResp,
            localVersion: m.localVersion ? m.localVersion + 1 : 1
          };
        }
        return m;
      });
      // console.log('LOADMESSAGES - replace - buffer reply panel')
      this.messageReplacementReceived$.next({ replace: messageResp as unknown as Message, oldId: message.id });
      // this.filterSortAndCollapsify(model.reply.parentId);
    } else {
      // otherwise update from API response
      message.id = messageResp.id;
      this.messages[model.reply.parentId] = this.messages[model.reply.parentId].map(m => {
        if (m.id === message.id) {
          const updated = {
            ...messageResp,
            // retain calculated first message of
            isCollapsed: m.isCollapsed, // IF NOT FOR THIS LINE, THE TIMELINE HOPS UP AND DOWN AND "FLASHES"
            firstMessageOfDay: m.firstMessageOfDay,
            timestamp: new Date(messageResp.timestamp),
            status: MessageStatus.SEND,
            localVersion: m.localVersion ? m.localVersion + 1 : 1
          };
          return updated;
        }
        return m;
      });
    }

    let channelChange = {
      channelId: message.chatroom.id,
      topActiveDate: message.timestamp
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    return message.id;

  }

  async deleteMessage(id: string) {
    return this.msgApi
      .DeleteMessage(id)
      .toPromise()
      .then((res) => {
        this.toastr.success('Deleted successfuly');
        return res;
      })
      .catch((err) => {
        this.toastr.error('Deleting message failed');
        return null;
      });
  }

  async replaceMessage(id: string, newText: string, panelId: string): Promise<any> {
    const message: Message = this.getMessageFromAnyChannel(id);
    if (!message) {
      return;
    }

    const editedMessage = {
      ...message,
      status: MessageStatus.PENDING,
      text: newText
    };

    const oldPosition = this.messages[panelId].indexOf(message);
    this.messages[panelId][oldPosition] = editedMessage;

    await this.msgApi
      .UpdateMessage({
        id,
        body: {
          text: newText
        }
      })
      .toPromise()
      .then((res) => {
        message.status = MessageStatus.SEND;
        return res;
      })
      .catch((err) => {
        this.toastr.error('Updating message failed');
        message.status = MessageStatus.FAILED;
        return null;
      });
  }

  private getMessage(id: string, chatroomId: string): Message {
    if (!this.messages[chatroomId]) { return null; }
    return this.messages[chatroomId].find(m => m.id === id);
  }

  private processMessage(message: Message, type?: string) {
    console.log('processMessage', message);
    if (message.reply && !message.reply.parent) {
      // if message is reply, then enumerate open panels (if any)
      this.processReplyMessage(message);
    }
    if (!this.messages[message.chatroom.id]) {
      // MAKE SURE THAT OPENED EMPTY CHATROOMS HAVE ALREADY EMPTY MESSAGE ARRAY IN MESSAGE SERVICE WHEN THE PANEL OPENS
      // OTHERWISE THIS RETURN COMMAND WILL DISMISS ANY INCOMING MESSAGES FROM THE SOCKET IN THESE PANELS

      // rightbar panels should work too so we must emit the value to the corresponding subject! =>
      // this works only when chatroom panel is closed
      if (type) {
        if (type === 'NEW') {
          this.messageReceived$.next(message);
        } else {
          // reactions are handled on socket receive - so this happens for edit/replies/metadata
          const updated = {
            ...MessageHelpers.mapMessageResp(message as unknown as MessageResp),
            localVersion: message.localVersion ? message.localVersion + 1 : 1
          }
          // console.log('LOADMESSAGES - replace - process type edit')
          this.messageReplacementReceived$.next({ replace: updated, oldId: updated.id });
        }
      }
      return;
    }
    const oldestId = (this.messages[message.chatroom.id] && this.messages[message.chatroom.id].length > 0) ?
      this.messages[message.chatroom.id].reduce((min, p) => p.id < min ? p.id : min, this.messages[message.chatroom.id][0].id) :
      '0';
    if (message.id < oldestId && oldestId.indexOf('tmp_') < 0) {
      // if the message is older than the oldest in our list, discard
      // UNLESS the oldest message is temp, in which case proceed

      // support message replace in other panels
      // this works only when chatroom panel is open and message is old enough not to be in chatroom messages pool
      const updated = {
        ...MessageHelpers.mapMessageResp(message as unknown as MessageResp),
        localVersion: message.localVersion ? message.localVersion + 1 : 1
      }
      // console.log('LOADMESSAGES - replace - process old message in open panel')
      this.messageReplacementReceived$.next({ replace: updated, oldId: updated.id });
      return;
    }

    const direct = this.roomService.directs.find(d => d.id === message.chatroom.id);
    if (direct) {
      direct.lastActiveDate = Date.now();
    }

    const old = this.getMessage(message.id, message.chatroom.id);

    // if you found the message (the API response already updated with the real ID)
    // update the message BUT KEEP collapsed info and emit message replacement event
    if (old) {
      const oldPosition = this.messages[message.chatroom.id].indexOf(old);
      const updated = MessageHelpers.mapMessageResp(message as unknown as MessageResp)
      updated.isCollapsed = old.isCollapsed;
      updated.firstMessageOfDay = old.firstMessageOfDay;
      updated.localVersion = old.localVersion ? old.localVersion + 1 : 1;
      this.messages[message.chatroom.id][oldPosition] = updated;
      // console.log('LOADMESSAGES - replace - process if old')
      this.messageReplacementReceived$.next({ replace: updated, oldId: old.id });
    } else if (this.profileService.me.id === message.sender.id && this.messages[message.chatroom.id].filter(c => c.status === MessageStatus.PENDING).length > 0) {
      // if the message was sent from the logged in user and there are no pending messages in the chat (messages that were sent by this user and the API response have not yet updated ID or status)
      // push to the buffer, to await the API response
      this.messageBuffer.push(message);

      // THIS WAS CAUSING DOUBLE messageReceived EVENTS
      // this.messageReceived$.next(message as unknown as Message);
    } else {
      // otherwise, the message was incoming by other user, or same user and other device, so handle as a newly received message
      // add only if the latest messages are loaded in the timeline
      if (!this.hasMoreMessagesBelow[message.chatroom.id]) {
        this.messages[message.chatroom.id].push(message);
      }
      this.filterSortAndCollapsify(message.chatroom.id, this.hasMoreMessages[message.chatroom.id]);
      this.messageReceived$.next(message as unknown as Message);
    }

    let channelChange = {
      channelId: message.chatroom.id,
      topActiveDate: message.timestamp
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);

    if (message.reply && message.reply.parent) { // update parent message of reply panel
      this.replyHeaders[message.id] = message;

      channelChange = {
        channelId: message.id,
        topActiveDate: message.timestamp
      };
      this.calculateHasMoreMessages(channelChange);
      this.roomService.channelChange$.next(channelChange);
    }
  }

  reduceMessages(panelId: string) {
    if (this.messages[panelId].length > 2 * MESSAGES_PER_PAGE) {
      this.messages[panelId].splice(0, MESSAGES_PER_PAGE);
      this.filterSortAndCollapsify(panelId, this.hasMoreMessages[panelId]);
    }
  }

  private processReplyMessage(message: Message) {
    const threadMessages = this.messages[message.reply.parentId];

    if (!threadMessages) {
      // thread not opened
      return;
    }

    const old = threadMessages.find(m => m.id === message.id);

    // if you found the message (the API response already updated with the real ID)
    // update the message and emit message replacement event
    if (old) {
      const oldPosition = threadMessages.indexOf(old);
      this.messages[message.reply.parentId][oldPosition] = message;
    } else if (this.profileService.me.id === message.sender.id && this.messages[message.reply.parentId].filter(c => c.status === MessageStatus.PENDING).length > 0) {
      // if the message was sent from the logged in user and there are no pending messages in the chat (messages that were sent by this user and the API response have not yet updated ID or status)
      // push to the buffer, to await the API response
      this.messageBuffer.push(message);
      this.messageReceived$.next(message as unknown as Message);
    } else {
      // otherwise, the message was incoming by other user, or same user and other device, so handle as a newly received message
      this.messages[message.reply.parentId].push(message);
    }

    this.filterSortAndCollapsify(message.reply.parentId, this.hasMoreMessages[message.reply.parentId]);

    let channelChange = {
      channelId: message.reply.parentId,
      topActiveDate: message.timestamp
    };
    this.calculateHasMoreMessages(channelChange);
    this.roomService.channelChange$.next(channelChange);
  }

  private filterSortAndCollapsify(chatroomId: string, hasMoreMessages) {
    this.messages[chatroomId] = MessageHelpers.filterSortAndCollapsifyArray(this.messages[chatroomId], hasMoreMessages);
  }

  private generateTempMessage(model: CreateMessageReq, isReplyPanel: boolean) {
    if (!isReplyPanel && this.isInQuotes[model.chatroomId]) {
      model.text = model.text.concat(this.quotes[model.chatroomId].text);
      model.files = [...(model.files || []), ...(this.quotes[model.chatroomId].files || [])];
      this.isInQuotes[model.chatroomId] = false;
      this.quotes[model.chatroomId].text = '';
    } else if (isReplyPanel && this.isInQuotes[model.reply.parentId]) {
      model.text = model.text.concat(this.quotes[model.reply.parentId].text);
      model.files = [...(model.files || []), ...(this.quotes[model.reply.parentId].files || [])];
      this.isInQuotes[model.reply.parentId] = false;
      this.quotes[model.reply.parentId].text = '';
    }

    if (!this.messages[model.chatroomId]) {
      this.messages[model.chatroomId] = [];
    }
    let isFirstMessage = false;
    let latestMessage: Message;
    if (this.messages[model.chatroomId].length > 0) {
      latestMessage = this.messages[model.chatroomId][this.messages[model.chatroomId].length - 1];
    } else {
      isFirstMessage = true;
    }
    const tempTimestamp = new Date();
    const tempMessage: Message = {
      id: this.messageIdGenerator.getTempId(),
      chatroom: {
        id: model.chatroomId,
      },
      type: model.type as 'TEXT' | 'SECRET' | 'SHOUT' | 'SIGNAL' | 'TTS',
      sender: this.profileService.me,
      text: model.files && model.files.length ? 'Uploading...' : model.text,
      signal: model.signal,
      timestamp: new Date(),
      status: MessageStatus.PENDING,
      firstMessageOfDay: isFirstMessage ? isFirstMessage : MessageHelpers.onDifferentDay(tempTimestamp, latestMessage.timestamp),
      isCollapsed: false
    };
    if (isReplyPanel && !this.hasMoreMessagesBelow[model.reply.parentId] && this.messages[model.reply.parentId]) {
      // create temp message only in reply panel - only if reply panels messages exist, otherwise it means it comes as a first reply from normal panel
      this.messages[model.reply.parentId].push(tempMessage);
      this.messages[model.reply.parentId] = MessageHelpers.collapsifyMessages(this.messages[model.reply.parentId]);
      this.messageReceived$.next(tempMessage);
    } else if (!this.hasMoreMessagesBelow[model.chatroomId]) {
      if (this.messages[model.chatroomId].length > 2 * MESSAGES_PER_PAGE) {
        this.messages[model.chatroomId].splice(0, MESSAGES_PER_PAGE);
      }
      this.messages[model.chatroomId] = MessageHelpers.collapsifyMessages([...this.messages[model.chatroomId], tempMessage]);
      this.messageReceived$.next(tempMessage);
    }
    return tempMessage;
  }

  removeMessage(ref: string, chatroomId: string) {
    this.messages[chatroomId] = (this.messages[chatroomId] || []).filter(message => message.id !== ref);
    this.filterSortAndCollapsify(chatroomId, this.hasMoreMessages[chatroomId]);
  }

  removeReplyThread(ref: string) {
    if (this.replyHeaders[ref]) {
      this.replyHeaders[ref] = null;
    }

    const message = this.getMessageFromAnyThread(ref);
    if (message && message.reply) {
      this.removeMessage(message.id, message.reply.parentId);
    }
  }

  addQuotes(chatroomId: string, quoteText: string, quotedFiles: any[], username: string) {
    const quote = quoteText ? quoteText : '';
    let plainQuote = this.mtf.removeAllQuotes(quote);
    plainQuote = this.mtf.decodeHTMLEntities(plainQuote);
    this.isInQuotes[chatroomId] = true;
    this.quotes[chatroomId] = { text: `\r\n> @${username} said:\r\n${plainQuote}`, files: quotedFiles };
    this.quote$.next({ room: chatroomId, quote: this.quotes[chatroomId] });
  }

  addReplies(chatroomId: string, messageId: string) {
    this.isInReplies[chatroomId] = true;
    this.replies[chatroomId] = messageId;
    this.reply$.next({ room: chatroomId, parentId: messageId });
  }

  removeQuotes(chatroomId: string) {
    this.isInQuotes[chatroomId] = false;
    if (this.quotes[chatroomId]) {
      this.quotes[chatroomId].text = '';
    }
  }

  removeReplies(chatroomId: string) {
    this.isInReplies[chatroomId] = false;
    this.replies[chatroomId] = '';
  }

  async toggleReaction(messageId: string, reaction: string): Promise<{ success: boolean, reactions?: ReactionModel[] }> {
    return await this.msgApi.ToggleReaction({ id: messageId, body: { reaction } })
      .toPromise()
      .then(response => {
        return { success: true, reactions: response.reactions };
      })
      .catch((err) => {
        this.toastr.error(err.error.ErrorMessage, 'Reaction Failed');
        return { success: true, reactions: null };
      });
  }

  calculateReactions(reactions: ReactionModel[], myUsername: string) {
    if (!reactions) {
      return;
    }

    let myReaction: string;
    const otherReactions = reactions.reduce((arr, r, i) => {
      if (r.username === myUsername) {
        myReaction = r.reaction;
        return arr;
      }
      return arr.concat(r);
    }, []);

    const groupedReactions = [];
    if (myReaction) {
      groupedReactions[myReaction] = [myUsername];
    }
    otherReactions.forEach((item) => {
      if (!groupedReactions[item.reaction]?.length) {
        groupedReactions[item.reaction] = [item.username];
      } else {
        groupedReactions[item.reaction].push(item.username);
      }
    });
    return groupedReactions;
  }

  async reportMessage(id: string) {
    await this.msgApi.ReportMessage(id).toPromise();
  }
}
