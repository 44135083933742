import { Component, EventEmitter, OnInit, Output, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { Team } from '../../_shared/models/teams/team';
import { DownloadClientsComponent } from '../download-clients/download-clients.component';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponent } from '../base-component';
import { TrackingService } from '../../_core/services/tracking.service';

// export interface OrderedTeam extends Team {
//   order?: number;
// }

@Component({
  selector: 'app-teambar',
  templateUrl: './teambar.component.html',
  styleUrls: ['./teambar.component.scss'],
})
export class TeambarComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output()
  onAdd = new EventEmitter();

  teams: Team[];

  loadingButton: any;

  showDownload = false;

  get activeTeamId() {
    return this.teamService.activeTeamId;
  }

  get activeTeam() {
    return this.teamService.activeTeam;
  }

  get sidebarClosed() {
    return this.teamService.sidebarClosed;
  }

  @HostListener('click', ['$event'])
  teambarClickHandler(event) {
    if (event.target.classList.contains('team-button')) {
      this.switchTeam(event.target.id);
    } else if (event.target.parentElement.classList.contains('team-button')) {
      this.switchTeam(event.target.parentElement.id);
    } else if (event.target.parentElement.parentElement.classList.contains('team-button')) {
      this.switchTeam(event.target.parentElement.parentElement.id);
    } else if (event.target.id === 'discover-team' || event.target.parentElement.id === 'discover-team') {
      this.addTeam();
    } else if (event.target.id === 'share-team' || event.target.parentElement.id === 'share-team') {
      this.share();
    } else if (event.target.id === 'download-client' || event.target.parentElement.id === 'download-client' || event.target.parentElement.parentElement.id === 'download-client') {
      this.openDownloadClient();
    } else if (event.target.id === 'toggle-sidebar' || event.target.parentElement.id === 'toggle-sidebar') {
      this.toggleSidebar();
    }
  }

  constructor(
    private teamService: TeamService,
    private router: Router,
    private panelsService: PanelsService,
    private modal: NgbModal,
    private tracking: TrackingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initTeamList();

    this.subscribe(this.teamService.activeTeamId$, () => {
      if (this.loadingButton) {
        this.loadingButton = null;
      }
    });

    this.subscribe(this.teamService.teamAdded$, (team) => {
      this.initTeamList();
    });

    this.subscribe(this.teamService.teamRemoved$, (team) => {
      this.initTeamList();
    });

    this.subscribe(this.teamService.teamUpdated$, (team) => {
      this.initTeamList();
    });

    this.showDownload = navigator.userAgent.toLowerCase().indexOf('electron/') === -1 &&
      (navigator.userAgent.toLowerCase().indexOf('windows') !== -1 ||
        navigator.userAgent.toLowerCase().indexOf('macos') !== -1);
  }

  initTeamList() {
    this.teams = (this.teamService.teams || []).map(t => {
      const index = this.teamService.orderTeamIds.teamIds.findIndex(i => i === t.id);
      return { ...t, order: index < 0 ? 100 : index };
    }).sort((a, b) => { return a.order > b.order ? 1 : -1; });

    if (this.teams.length !== this.teamService.orderTeamIds.teamIds.length) {
      this.teamService.orderTeamIds.teamIds = this.teams.map(c => c.id);
      this.teamService.orderTeamIds.save();
    }

    console.log('order should be', this.teamService.orderTeamIds.teamIds);
    console.log('order is', this.teams.map(c => c.id));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.teamService.orderTeamIds.teamIds, event.previousIndex, event.currentIndex);
    this.teamService.orderTeamIds.save();
    this.initTeamList();
  }

  openDownloadClient() {
    this.modal.open(DownloadClientsComponent, {
      centered: true,
      windowClass: 'modal-dark',
    });
  }

  toggleSidebar() {
    this.teamService.sidebarClosed = !this.teamService.sidebarClosed;
  }

  switchTeam(teamId: string) {
    const team = this.teams.find(t => t.id === teamId);
    if (team) {
      if (team.id === this.teamService.activeTeamId) {
        return;
      }
      this.loadingButton = team.id;
      this.panelsService.minimizeDirect = { val: true };
      this.panelsService.minimizeDirect.save();
      this.router.navigate(['/', team.name]);
    }
  }

  addTeam() {
    this.onAdd.emit();
  }

  share() {
    this.tracking.trackEvent('invite', {
      teamname: this.teamService.activeTeam ? this.teamService.activeTeam.name : null,
      teamid: this.teamService.activeTeam ? this.teamService.activeTeam.id : null
    });
    const m = this.modal.open(ShareLinkComponent, {
      centered: true,
      windowClass: 'modal-dark',
      backdrop: 'static',
      container: 'body'
    });
  }

  trackByFn(index, item: Team) {
    return item.id;
  }
}
