import { MessageResp } from "@echofin/libraries/api/message/models/message-resp";
import { Message, MessageStatus } from "../models/commons/message";

export class MessageHelpers {
    static sortMessages(messages: Message[]): Message[] {
        if (messages && messages.length > 0) {
            return messages.sort((n1, n2) => {
                if (n1.timestamp > n2.timestamp) {
                    return 1;
                }

                if (n1.timestamp < n2.timestamp) {
                    return -1;
                }

                return 0;
            });
        }
        return messages;
    }

    static collapsifyMessages(messages: Message[]): Message[] {
        let counter = 0;
        return messages.map(
            (m: Message, i: number) => {
                const pm = { ...messages[i - 1] };
                m.isCollapsed = false;
                if (pm &&
                    counter < 9 &&
                    pm.sender && m.sender &&
                    pm.sender.username === m.sender.username &&
                    MessageHelpers.isDateDiffLessThan2Minutes(pm.timestamp, m.timestamp) &&
                    pm.chatroom.id === m.chatroom.id
                ) {
                    m.isCollapsed = true;
                    counter += 1;
                } else {
                    counter = 0;
                }
                return m;
            }
        );
    }

    static filterSortAndCollapsifyArray(array: Message[], hasMoreMessages) {
        const sortedArray = array
            .sort((a, b) => MessageHelpers.compareCreatedDates(a, b))
            .map((m, i, array) => {
                return {
                    ...m,
                    firstMessageOfDay: ((i === 0 && !hasMoreMessages) || (i > 0 && MessageHelpers.onDifferentDay(m.timestamp, array[i - 1].timestamp)))
                };
            });
        const collapsifiedArray = MessageHelpers.collapsifyMessages(sortedArray);
        return collapsifiedArray;
    }

    static compareCreatedDates(a: Message, b: Message) {
        if (a.timestamp < b.timestamp) {
            return -1;
        }
        if (a.timestamp > b.timestamp) {
            return 1;
        }
        return 0;
    }

    static onDifferentDay(date1: Date, date2: Date) {
        if (date1.getFullYear() !== date2.getFullYear()) {
            return true;
        }
        if (date1.getMonth() !== date2.getMonth()) {
            return true;
        }
        if (date1.getDate() !== date2.getDate()) {
            return true;
        }
        return false;
    }

    static isDateDiffLessThan2Minutes(dt1: Date, dt2: Date) {
        const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
        return Math.abs(diff) < 2;
    }

    static mapMessageResp(message: MessageResp): Message {
      const msg: Message = {
        ...message,
        status: MessageStatus.DELIVERED,
        timestamp: new Date(message.timestamp)
      };
      return msg;
    }

    static organizeGenericMessages(messages: Message[]): Message[] {
        const organized = MessageHelpers.sortMessages(messages);

        organized.forEach(m => {
            m.status = MessageStatus.DELIVERED; // add delivered status
        });

        return MessageHelpers.collapsifyMessages(organized);
    }

    static organizeGenericMessagesWithDaySeparator(messages: Message[], hasMoreMessages: boolean): Message[] {
        const organized = MessageHelpers.sortMessages(messages);

        organized.forEach(m => {
            m.status = MessageStatus.DELIVERED; // add delivered status
        });

        return MessageHelpers.filterSortAndCollapsifyArray(organized, hasMoreMessages);
    }

    static organizeBookmarkMessages(messages: Message[]): Message[] {
        return MessageHelpers.organizeGenericMessages(messages);
    }

    static organizeMentionMessages(messages: Message[]): Message[] {
        return MessageHelpers.organizeGenericMessages(messages);
    }

    static organizeFileMessages(messages: Message[]): Message[] {
        return MessageHelpers.organizeGenericMessages(messages);
    }

    static organizePinboardMessages(messages: Message[]): Message[] {
        return MessageHelpers.organizeGenericMessages(messages);
    }

    static organizeSearchMessages(messages: Message[]): Message[] {
        return MessageHelpers.organizeGenericMessages(messages);
    }

    static organizePulseMessages(messages: Message[], hasMoreMessages: boolean): Message[] {
        return MessageHelpers.organizeGenericMessagesWithDaySeparator(messages, hasMoreMessages);
    }

    static organizeSignalMessages(messages: Message[], hasMoreMessages: boolean): Message[] {
        return MessageHelpers.organizeGenericMessagesWithDaySeparator(messages, hasMoreMessages);
    }
}