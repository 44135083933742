import { Injectable } from '@angular/core';
import { AuthenticationConfiguration, FilestackConfiguration, MessageConfiguration, NotificationConfiguration, ProfileConfiguration, ProspectConfiguration, SessionConfiguration, TeamConfiguration } from '@echofin/libraries';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class InitService {
  async init() {
    const config = await fetch(`config.json?t=${Date.now()}`).then((response) => {
      return response.json();
    });
    console.log('Initializing app with config', config);
    window.config = config;
  }
}

export function init_app(initService: InitService) {
  return () => initService.init();
}
export function authenticationConfigurationFactory(): AuthenticationConfiguration {
  return { rootUrl: window.config.endpoints.authentication };
}
export function messageConfigurationFactory(): MessageConfiguration {
  return { rootUrl: window.config.endpoints.message };
}
export function notificationConfigurationFactory(): NotificationConfiguration {
  return { rootUrl: window.config.endpoints.notification };
}
export function profileConfigurationFactory(): ProfileConfiguration {
  return { rootUrl: window.config.endpoints.profile };
}
export function prospectConfigurationFactory(): ProspectConfiguration {
  return { rootUrl: window.config.endpoints.prospect };
}
export function sessionConfigurationFactory(): SessionConfiguration {
  return { rootUrl: window.config.endpoints.session };
}
export function teamConfigurationFactory(): TeamConfiguration {
  return { rootUrl: window.config.endpoints.team };
}
export function filestackConfigurationFactory(): FilestackConfiguration {
  return { rootUrl: window.config.endpoints.filestack };
}
