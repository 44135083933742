export interface RequestOlder {
  from: Date;
  type: RequestOlderType;
}

export enum RequestOlderType {
  SCROLLING = 'SCROLLING',
  BUTTON = 'BUTTON',
}

export interface RequestNewer {
  from: Date;
  type: RequestNewerType;
}

export enum RequestNewerType {
  SCROLLING = 'SCROLLING',
  BUTTON = 'BUTTON',
}
