import { Injectable } from '@angular/core';
import { EmojiButton } from '@joeattardi/emoji-button';
import { MessageService } from './message.service';
import { TeamService } from './team.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class EmojiService {

  picker: EmojiButton;
  panelId: string;
  messageId: string;
  multilinePanelId: string;

  constructor(
    private messageService: MessageService,
    private teamService: TeamService,
    private tracking: TrackingService
  ) { }

  setup() {
    this.picker = new EmojiButton({
      theme: 'dark',
      initialCategory: 'recents',
      showAnimation: true,
      styleProperties: {
        '--emoji-size': '20px',
        '--font': 'Montserrat',
        '--dark-background-color': '#252525',
        '--font-size': '14px',
        '--category-button-active-color': '#f99500',
        '--dark-category-button-color': '#6f6f6f',
        '--search-icon-color': '#6f6f6f',
        '--dark-search-background-color': '#6f6f6f',
        '--dark-text-color': '#ececec'
      },
      // // this is needed to change name to CUSTOM category
      // i18n: {
      //   search: 'Search emojis...',
      //   categories: {
      //     recents: 'Recent Emojis',
      //     smileys: 'Smileys & Emotion',
      //     people: 'People & Body',
      //     animals: 'Animals & Nature',
      //     food: 'Food & Drink',
      //     activities: 'Activities',
      //     travel: 'Travel & Places',
      //     objects: 'Objects',
      //     symbols: 'Symbols',
      //     flags: 'Flags',
      //     custom: 'Financial'
      //   },
      //   notFound: 'No emojis found'
      // },
      // // custom emojis must be set as images - emoji property is a path and they are rendered in the picker as images
      // custom: [
      //   {
      //     name: 'credit card',
      //     emoji: '💳'
      //   },
      //   {
      //     name: 'bank',
      //     emoji: '🏦'
      //   },
      //   {
      //     name: 'yen banknote',
      //     emoji: '💴'
      //   },
      //   {
      //     name: 'euro banknote',
      //     emoji: '💶'
      //   },
      //   {
      //     name: 'pound banknote',
      //     emoji: '💷'
      //   },
      //   {
      //     name: 'chart increasing with yen',
      //     emoji: '💹'
      //   },
      //   {
      //     name: 'dollar banknote',
      //     emoji: '💵'
      //   },
      //   {
      //     name: 'heavy dollar sign',
      //     emoji: '💲'
      //   },
      //   {
      //     name: 'money-mouth face',
      //     emoji: '🤑'
      //   },
      //   {
      //     name: 'currency exchange',
      //     emoji: '💱'
      //   },
      //   {
      //     name: 'money bag',
      //     emoji: '💰'
      //   },
      //   {
      //     name: 'money with wings',
      //     emoji: '💸'
      //   }
      // ],
      // icons: {
      //   categories: {
      //     // this is rendered as img, cant style as svg with css
      //     custom: '/assets/images/dollar-sign.svg'
      //   }
      // }
    });

    this.picker.on('emoji', selection => {
      // `selection` object has an `emoji` property
      // containing the selected emoji
      if (this.panelId) {
        this.messageService.insertEmojiOrReact$.next({ panelId: this.panelId, emoji: selection.emoji });
      } else if (this.messageId) {
        this.tracking.trackEvent('reaction', {
          messageid: this.messageId,
          teamname: this.teamService.activeTeam?.name
        });
        this.messageService.toggleReaction(this.messageId, selection.emoji);
      } else if (this.multilinePanelId) {
        this.messageService.insertEmojiInMultiline$.next({ panelId: this.multilinePanelId, emoji: selection.emoji });
      }
      this.panelId = null;
      this.messageId = null;
      this.multilinePanelId = null;
    });

    this.picker.on('hidden', () => {
      this.panelId = null;
      this.messageId = null;
      this.multilinePanelId = null;
    });
  }

  openPanelPicker(element, panelId) {
    const isVisible = this.picker.isPickerVisible();
    if (isVisible) {
      if (this.panelId === panelId) {
        return;
      }
      this.panelId = null;
      this.messageId = null;
      this.multilinePanelId = null;
      this.picker.hidePicker();
    }
    this.panelId = panelId;
    this.messageId = null;
    this.multilinePanelId = null;
    this.picker.showPicker(element);
  }

  openMessagePicker(element, messageId) {
    const isVisible = this.picker.isPickerVisible();
    if (isVisible) {
      if (this.messageId === messageId) {
        return;
      }
      this.panelId = null;
      this.messageId = null;
      this.multilinePanelId = null;
      this.picker.hidePicker();
    }
    this.panelId = null;
    this.messageId = messageId;
    this.multilinePanelId = null;
    this.picker.showPicker(element);
  }

  openMultilinePicker(element, multilinePanelId) {
    const isVisible = this.picker.isPickerVisible();
    if (isVisible) {
      if (this.multilinePanelId === multilinePanelId) {
        return;
      }
      this.panelId = null;
      this.messageId = null;
      this.multilinePanelId = null;
      this.picker.hidePicker();
    }
    this.panelId = null;
    this.messageId = null;
    this.multilinePanelId = multilinePanelId;
    this.picker.showPicker(element);
  }
}
