import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateMessageReq } from '@echofin/libraries/api/message/models/create-message-req';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { LocalStorage } from '@efaps/ngx-store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaded } from '../../_shared/models/commons/filestack.models';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FileUploadService } from '../../_core/services/file-upload.service';
import { GeneralResourcesService } from '../../_core/services/general-resources.service';
import { MessageService } from '../../_core/services/message.service';
import { TeamService } from '../../_core/services/team.service';
import { RULE } from '../../_shared/models/commons/permissions.constants';
import { MessageType } from '../../_shared/models/message/message-type';
import { Signal } from '../../_shared/models/message/signal';
import { ItemType } from '../../_shared/models/room/channel';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.scss']
})
export class SignalComponent implements OnInit {

  @Input() teamId: string;
  @Input() chatroomId: string;
  @Input() isTeamChatroom: boolean;

  @Input() isReplyPanel = false;
  @Input() replyParentId: string;

  @LocalStorage() mainTitle: string = 'SIGNAL';
  @LocalStorage() signalFieldTitle1: string = 'TP';
  @LocalStorage() signalFieldTitle2: string = 'SL';
  @LocalStorage() signalFieldTitle3: string = 'Custom';

  signalForm: FormGroup;
  symbols: string[] = ['EUR', 'DOL'];
  rules = RULE;
  chatrooms = null;
  files: FileAttachment[];

  fileToUpload: File | Blob;
  uploading = false;
  uploadImagePreview: string;
  uploadPercent = 0;
  cancelUploadToken = {};

  get isInReplyMode(): boolean {
    if (this.isReplyPanel) {
      return true;
    }
    return !!this.messageService.isInReplies[this.chatroomId];
  }

  constructor(
    public activeModal: NgbActiveModal,
    private builder: FormBuilder,
    private messageService: MessageService,
    private generalResources: GeneralResourcesService,
    private teamService: TeamService,
    private toastr: ToastrService,
    private uploadService: FileUploadService
  ) { }

  get orderType(): any { return this.signalForm.get('orderType'); }
  get symbol(): any { return this.signalForm.get('symbol'); }
  get entry(): any { return this.signalForm.get('entry'); }
  get field1(): any { return this.signalForm.get('field1'); }
  get field2(): any { return this.signalForm.get('field2'); }
  get field3(): any { return this.signalForm.get('field3'); }
  get comment(): any { return this.signalForm.get('comment'); }

  get uploadLabel() { return this.files && this.files[0] ? `${this.files[0].filename} uploaded` : 'Upload file'; }

  async ngOnInit() {
    this.signalForm = this.builder.group(
      {
        orderType: ['', Validators.required],
        symbol: [''],
        entry: [''],
        field1: [''],
        field2: [''],
        field3: [''],
        comment: [''],
        chatrooms: new FormArray([])
      },
      {
        validator: [this.atLeastOneFiledFilledValidation]
      });
    this.symbols = await this.generalResources.getSymbols();
    console.log('ngOnInit', this.teamService.activeTeam.sidebarResolved);
    this.chatrooms = this.teamService.activeTeam.sidebarResolved.filter(s => ItemType[s.itemType] === ItemType.Channel && s.permissions.user && s.permissions.user[this.rules.POST_SIGNALS]);
    this.addCheckboxes();
  }

  private addCheckboxes() {
    this.chatrooms.forEach((o, i) => {
      const control = new FormControl(this.chatroomId === o.refId); // true on current chatroom
      (this.signalForm.controls.chatrooms as FormArray).push(control);
    });
  }

  async send() {
    const signal: Signal = {
      title: this.mainTitle,
      orderType: this.orderType.value,
      symbol: this.symbol.value,
      entry: this.entry.value,
      fields: []
    };

    const selectedChatroomIds = this.signalForm.value.chatrooms
      .map((v, i) => (v ? this.chatrooms[i].refId : null))
      .filter(v => v !== null);

    if (selectedChatroomIds.length === 0 && this.isTeamChatroom) {
      this.toastr.error('Please select at least one chatroom');
      return;
    }

    if (!this.isTeamChatroom) {
      // fix for dm/gm
      selectedChatroomIds.push(this.chatroomId);
    }

    if (this.field1.value) {
      signal.fields.push({ label: this.signalFieldTitle1, value: this.field1.value });
    }
    if (this.field2.value) {
      signal.fields.push({ label: this.signalFieldTitle2, value: this.field2.value });
    }
    if (this.field3.value) {
      signal.fields.push({ label: this.signalFieldTitle3, value: this.field3.value });
    }
    selectedChatroomIds.forEach(async (chatroomId) => {
      const model: CreateMessageReq = {
        chatroomId,
        signal,
        text: this.comment.value.replace(/\n/g, '\r\n'),
        type: MessageType.SIGNAL,
        files: this.files
      };
      if (this.isInReplyMode) {
        this.handleReplyRequest(model);
        await this.messageService.sendFromReplyPanel(model);
      } else {
        await this.messageService.send(model);
      }
    });
    this.activeModal.close();
  }

  handleReplyRequest(model: CreateMessageReq) {
    if (this.isReplyPanel) {
      model.reply = {
        parent: false,
        parentId: this.replyParentId
      };
    }
    // Reply Message
    if (this.messageService.isInReplies[model.chatroomId]) {
      model.reply = {
        parent: false,
        parentId: this.messageService.replies[model.chatroomId]
      };
      this.messageService.isInReplies[model.chatroomId] = false;
      this.messageService.replies[model.chatroomId] = '';
    }
  }

  atLeastOneFiledFilledValidation(fg: FormGroup) {
    return (fg.get('symbol').value
      || fg.get('entry').value
      || fg.get('field1').value
      || fg.get('field2').value
      || fg.get('field3').value
      || fg.get('comment').value)
      ? null
      : { emptyFields: true };
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.filterMatching(term))
    )

  filterMatching(term: string) {
    const termLower = term.toLocaleLowerCase();
    return this.symbols.filter((symbol) => {
      const symbolLower = symbol.toLowerCase();
      return symbolLower.indexOf(termLower) > -1;
    });
  }

  onTitleKeyDown(event, element) {
    if (event.key === 'Enter') {
      element.blur();
      event.stopPropagation();
      return false;
    }
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return
    }
    this.fileToUpload = event.target.files[0];

    this.previewImage();
    this.beginUploading();
  }

  private beginUploading() {
    if (this.fileToUpload.size > 100 * 1024 * 1024) {
      this.toastr.error('File is too big. The accepted file size is less than 100MB.');
      this.clearFiles();
      return;
    }
    this.uploading = true;
    this.uploadService.uploadFilesImmediately(this.fileToUpload, this.cancelUploadToken, (evt) => { this.onUploadProgress(evt); }).toPromise()
      .then(async (file: FileUploaded) => {
        const attachments = [{
          filename: file.filename,
          mime: file.mimetype,
          url: file.url,
          handle: file.handle,
        }];
        this.files = attachments;
      })
      .catch(() => {
        this.clearFiles();
        this.toastr.error('Could not upload file.');
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  onUploadProgress(evt) {
    this.uploadPercent = evt.totalPercent;
  }

  previewImage() {
    if (this.fileToUpload.type.indexOf('image/') < 0) {
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(this.fileToUpload);

    reader.onload = () => {
      this.uploadImagePreview = reader.result as string;
    };
  }

  clearFiles() {
    this.fileToUpload = null;
    this.uploading = false;
    this.uploadPercent = 0;
    this.uploadImagePreview = null;
    this.files = null;
  }

  closeModal() {
    if (this.cancelUploadToken && this.cancelUploadToken['cancel']) {
      this.cancelUploadToken['cancel']();
    }
    this.activeModal.dismiss();
  }
}
