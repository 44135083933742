import { Component, OnInit, Input } from '@angular/core';
import { Panel } from '../../_shared/models/room/channel';
import { PanelsService } from '../../_core/services/panels.service';

@Component({
  selector: 'app-panel-error',
  templateUrl: './panel-error.component.html',
  styleUrls: ['./panel-error.component.scss']
})
export class PanelErrorComponent implements OnInit {

  @Input()
  panel: Panel;

  constructor(private panelsService: PanelsService) { }

  ngOnInit() {
  }

  close() {
    this.panel.visible = false;
    this.panelsService.close(this.panel);
  }
}
