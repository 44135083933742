import { Component, HostBinding, Input, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { Message } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { PanelFilesService } from './services/panel-files.service';
import { BaseComponent } from '../base-component';
import { TeamService } from '../../_core/services/team.service';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';

@Component({
  selector: 'app-panel-files',
  templateUrl: './panel-files.component.html',
  styleUrls: ['./panel-files.component.scss']
})
export class PanelFilesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  @ViewChild('timeline') timeline: TimelineComponent;

  initialMessages: Message[];

  teamId: string;
  loadingMessages: boolean = false;
  lastId: string = null;

  canPin: boolean;
  timelineType = TimelineType.FilesMessages;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private messageService: MessageService,
    private panelFilesService: PanelFilesService,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  async ngOnInit() {
    console.log(this.panel.id);
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    this.panelsService.panelTitle$.next([this.panel.id, 'Attachments']);
    this.teamId = this.panel.teamId;
    this.registerEvents();
    this.messageService.messages[this.panel.id] = [];
    await this.panelFilesService.loadFileMessages(this.panel.id, this.panel.teamId);

    this.initialMessages = this.messageService.messages[this.panel.id];

    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  registerEvents() {
    // message with files attachment comes from messageReplacement
    this.subscribe(this.messageService.messageReplacementReceived$, r => {
      if (r.replace.files.length > 0) {
        this.onFilesMessageReceived(r.replace);
      }
    });
  }

  onFilesMessageReceived(message: Message) {
    this.messageService.messages[this.panel.id] = MessageHelpers.sortMessages([...this.messageService.messages[this.panel.id], message]);
    this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
    this.initialMessages = this.messageService.messages[this.panel.id];
    // this.timeline.scrollToBottom();
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }
}
