<div class="chatroom-wrapper">
  <app-timeline #timeline
    [isLocked]="false"
    [messages]="initialMessages"
    [initialTimelineMessage]="'Here you will see the messages from all the chatrooms that you <b>currently have access</b> to that contain an attachment.'"
    [timelineType]="timelineType"
    [canPin]="canPin"
    [teamId]="panel.teamId"
    [panelId]="panel.id"
    >
  </app-timeline>
  <div class="footer" [ngClass]="{'isMaximized':maximized}">

  </div>
</div>