<div class="flipper">
    <div class="panel">
        <div class="content-info">
            <h4>Something went wrong!</h4>
            <h6 class="text-muted"><small>There are no data to load for this panel. Close this and check your other
                    options from the menus to your left and to your right.</small></h6>
            <div class="text-center">
                <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>