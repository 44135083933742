import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeFactoryService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../environments/environment';
import { ProfileService } from './../../../_core/services/profile.service';
import { TeamService } from './../../../_core/services/team.service';
import { Chatroom } from './../../../_shared/models/room/room';
import { TermsComponent } from './../../terms/terms.component';

interface BillingCustomer {
  customer: string;
  teamId: string;
  account: string;
  cards: any[];
}

@Component({
  selector: 'app-lock-panel',
  templateUrl: './lock-panel.component.html',
  styleUrls: ['./lock-panel.component.scss']
})
export class LockPanelComponent implements OnInit {

  @Input() room: Chatroom;
  description;
  flip;

  plans: any[];
  selectedPlan: any;

  error: any;
  assignmentIds: any = [];
  pendingSubscriptions: any[];
  customer: BillingCustomer;
  newCard = true;

  state;
  selectedPlanDescription: any;

  unlockLoading = false;

  selectedPlanChatrooms;
  subscriptions: any[];
  trials = {};
  descLine1: string;
  descLine2: string;

  couponsEnabled = false;
  showCouponInput = false;

  appliedCoupon;
  couponTxt;

  constructor(
    private http: HttpClient,
    private teamService: TeamService,
    private profileService: ProfileService,
    private hostElement: ElementRef,
    private modal: NgbModal,
    private stripeFactory: StripeFactoryService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.error = null;

    this.description = this.room.description ? this.room.description.split('\n') : [];

    if (this.room.subtype === 'Premium') {
      await this.loadSubscriptions();
      await this.loadPremiumRoom();
      await this.filterSubscriptions();
    } else {
      this.state = 'normal';
    }
  }

  async applyCoupon() {
    const coupon = await this.http
      .get<{ coupon: any }>(`${environment.config.endpoints['billing']}/billing/plans/${this.selectedPlan.id}/coupon-verify`, {
        params: {
          coupon: this.couponTxt
        },
      })
      .toPromise()
      .catch((err) => {
        this.toastr.error('invalid coupon');
      });

    this.appliedCoupon = coupon;
    if (this.appliedCoupon) {
      this.couponTxt = `Applied coupon: ${this.couponTxt}`;
    }
  }

  async loadPremiumRoom() {
    this.plans = await this.http
      .get(`${environment.config.endpoints['billing']}/billing/assignments`, {
        params: { item: this.room.id }
      })
      .toPromise()
      .then((resp: any) => {
        const plans = resp.plans
          .filter(plans => {
            const assKey = Object.keys(plans.assignments).find(x => plans.assignments[x].item === this.room.id);
            return plans.assignments[assKey].inApp &&
              plans.type !== 'Trial' &&
              plans.stripePrice &&
              plans.stripePrice.active;
          });

        return plans;
      });

    if (!this.plans.length) return;

    this.plans = this.plans.sort((a, b) => a.stripePrice?.unit_amount - b.stripePrice?.unit_amount);
    this.plans = this.plans.sort((a, b) => a.trialId == null ? 1 : -1);
    this.selectedPlan = this.plans[0];
    this.planChanged();

    this.assignmentIds = this.plans.map(p => p.id);

    this.plans.forEach(plan => {
      const itemIds = plan.assignments.map(a => a.item);
      plan._chatrooms = this.teamService.activeTeam.chatrooms
        .filter(c => itemIds.indexOf(c.id) > -1)
        .map(c => c.name);
    });
  }

  planChanged(plan = null) {
    if (plan) {
      this.selectedPlan = plan
    }

    this.couponsEnabled =
      this.selectedPlan.type === 'Recurring' &&
      !this.selectedPlan.setupFeeId &&
      (!this.selectedPlan.trialId || this.selectedPlan.trialId && !this.selectedPlan.trial.noCredit);
    this.appliedCoupon = null;

    this.selectedPlanDescription = this.selectedPlan.description
      ? this.selectedPlan.description.split('\n')
      : [];

    // const itemIds = this.selectedPlan.assignments.map(a => a.item);
    // // if (itemIds.length === 1) {
    // //   this.selectedPlanChatrooms = null;
    // //   return;
    // // }
    // this.selectedPlanChatrooms = this.teamService.activeTeam.chatrooms
    //   .filter(c => itemIds.indexOf(c.id) > -1)
    //   .map(c => c.name);
  }

  async loadCustomer() {
    const userBilling = await this.http
      .get<{ platform: BillingCustomer, connected: BillingCustomer[] }>(`${environment.config.endpoints['billing']}/billing/users/${this.profileService.me.id}`)
      .toPromise()
      .catch((err) => {
        return null;
      });

    if (!userBilling) return;

    const customer = userBilling.connected.find(x => x.teamId === this.teamService.activeTeamId);

    if (!customer || customer.cards.length === 0) {
      this.newCard = true;
    } else {
      this.newCard = false;
    }

    this.customer = customer;
  }

  async loadSubscriptions() {
    this.subscriptions = await this.http
      .get<{ subscriptions: any[] }>(`${environment.config.endpoints['billing']}/billing/subscriptions`, {
        params: {
          user: this.profileService.me.id,
          team: this.teamService.activeTeamId,
        },
      })
      .toPromise()
      .then((res) => res.subscriptions);
  }

  async filterSubscriptions() {

    const usedTrials = this.subscriptions
      .filter(x => x.trialId && ['active', 'canceled', 'trialing'].indexOf(x.status) > -1)
      .map(x => x.trialId);

    this.plans.forEach((p) => {
      if (p.trial && usedTrials.indexOf(p.trialId) > -1) {
        console.log(`disable trial ${p.trialId} as it have been bought`);
        p.trial.used = true;
      }
      p.label = this.generatePlanLabel(p);
    });

    const subscriptions = this.subscriptions
      .filter(i => this.assignmentIds.indexOf(i.planId) !== -1);

    if (subscriptions.some(x => x.status === 'active')) {
      // if any is active then return
      console.log('some subscriptions are active. but no ac has been granded. show loading until ac allow');
      this.state = 'processing';
      return;
    }

    const incomplete = subscriptions.find(x => x.status === 'incomplete');
    if (!incomplete) {
      // if there is no active subscription and one is at incomplete state, should show form for new payment
      console.log('there is no active subscription and one is at incomplete state');
      this.state = 'normal';
      return;
    }

    const resp = await this.http
      .get<any>(`${environment.config.endpoints['billing']}/billing/subscriptions/${incomplete.id}`)
      .toPromise();

    this.state = 'processing';
    switch (resp.subscription.latest_invoice.payment_intent.status) {
      case 'requires_action':
        this.handlePaymentIntentRequiresAction(resp.subscription.latest_invoice.payment_intent, resp.account);
        break;
      case 'requires_payment_method':
        this.toastr.error('Payment failed. Please try another card');
        this.state = 'normal';
        break;
    }

    // console.log('generate labels');
    // this.plans = this.plans.map(p => {
    //   p.label = this.generatePlanLabel(p);
    //   return p;
    // });
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }

  async unlock() {
    this.unlockLoading = true;

    if (this.selectedPlan.trialId && this.selectedPlan.trial.noCredit && !this.selectedPlan.trial.used) {
      await this.subscribeNoCC();
      return;
    }

    await this.loadCustomer();
    setTimeout(() => {
      this.flip = true;
    }, 600);
  }

  hideCardForm() {
    this.unlockLoading = false;
    setTimeout(() => {
      this.flip = false;
    }, 600);
  }

  openTerms() {
    this.modal.open(TermsComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
  }

  async subscribe(data: { paymentId: string, correlationId?: string }) {
    this.state = 'processing';
    let params = {};
    if (data.correlationId) {
      params = {
        headers: {
          'Echofin-Correlation-Id': data.correlationId
        }
      };
    }
    await this.http
      .post(`${environment.config.endpoints['billing']}/billing/subscriptions`, {
        paymentMethod: data.paymentId,
        plan: this.selectedPlan.id,
        user: this.profileService.me.id,
        coupon: this.appliedCoupon ? this.appliedCoupon.id : null
      }, params)
      .toPromise()
      .then((res: any) => {
        if (!res.paymentIntent) {
          console.log('trialing...');
          return;
        }

        switch (res.paymentIntent.status) {
          case 'requires_action':
            return this.handlePaymentIntentRequiresAction(res.paymentIntent, res.account);
          case 'requires_payment_method':
            this.toastr.error('Payment failed. Please try another card');
            this.state = 'normal';
            break;
        }
      })
      .catch(res => {
        this.state = 'error';
        this.error = res.error.message;
      });
  }

  async subscribeNoCC() {
    await this.http
      .post<{ paymentIntent: any, account: string }>(`${environment.config.endpoints['billing']}/billing/subscriptions/nocc`, {
        plan: this.selectedPlan.id,
        user: this.profileService.me.id,
      })
      .toPromise()
      .then(() => {
        this.state = 'normal';
      })
      .catch((res) => {
        this.state = 'error';
        this.error = `Could not confirm payment. ${res.error.message}`;
      });
  }

  private async handlePaymentIntentRequiresAction(paymentIntent: any, account: string) {
    const stripe = this.stripeFactory.create(environment.config.stripe.publicKey, {
      stripeAccount: account
    });

    stripe.handleCardPayment(paymentIntent.client_secret, {})
      .subscribe((res) => {
        if (res.error) {
          this.state = 'error';
          this.error = `Could not confirm payment. ${res.error.message}`;
        }
      });
  }

  private generatePlanLabel(plan) {

    const hasTrial = plan.trial && !plan.trial.archived && !plan.trial.used;

    const hasSetup = !!plan.setupFeeId;

    console.log('generate label', { id: plan.id, hasTrial, hasSetup });

    const currency = this.calcCurrency(plan.stripePrice.currency);
    const amount = plan.stripePrice.unit_amount / 100;

    if (hasTrial && hasSetup) {
      const setupCurrency = this.calcCurrency(plan.setupFee.stripePrice.currency);
      const setupamount = plan.setupFee.stripePrice.unit_amount / 100;
      const recurring = this.calcRecurring(plan.stripePrice.recurring);
      return `${setupCurrency}${setupamount} for ${plan.trial.accessCount}-day trial, then ${currency}${amount}/${recurring}`;
    }

    if (hasTrial && !hasSetup) {
      const recurring = this.calcRecurring(plan.stripePrice.recurring);
      return `Free ${plan.trial.accessCount}-day trial, then ${currency}${amount}/${recurring}`;
    }

    if (!hasTrial && hasSetup) {
      const setupCurrency = this.calcCurrency(plan.setupFee.stripePrice.currency);
      const setupamount = plan.setupFee.stripePrice.unit_amount / 100;
      return `${currency}${amount} (+${setupCurrency}${setupamount} initial fee)`;
    }

    if (!hasTrial && !hasSetup && plan.type === 'Recurring') {
      const recurring = this.calcRecurring(plan.stripePrice.recurring);
      return `${currency}${amount}/${recurring}`;
    }

    if (!hasTrial && !hasSetup && plan.type === 'OneOff' && plan.access !== 'forever') {
      return `${currency}${amount} for ${plan.accessCount} ${plan.access}`;
    }
    if (!hasTrial && !hasSetup && plan.type === 'OneOff' && plan.access === 'forever') {
      return `${currency}${amount} lifetime`;
    }
  }

  private calcCurrency(currency) {
    if (!currency) return '';
    const c = currency.toUpperCase();
    const map = {
      USD: '$',
      GBP: '£'
    };
    return map[c] || `${c} `;
  }

  private calcRecurring(recurring) {
    if (!recurring) return '';
    return `${recurring.interval_count > 1 ? recurring.interval_count : ''}${recurring.interval}`;
  }
}
