import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInputComponent } from '../../chat/user-input/user-input.component';
import { ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserInputService {

  constructor(private modal: NgbModal) { }

  public async getInput(params: { title: string, maskText?: boolean, buttonLabel?: string, validators?: ValidatorFn | ValidatorFn[], validatorTexts?: { validatorName: string, validatorText: string }[] }): Promise<string> {
    const ref = await this.modal.open(
      UserInputComponent,
      {
        centered: true,
        windowClass: 'modal-dark',
        backdrop: 'static',
        container: 'body'
      }
    );
    ref.componentInstance.title = params.title;
    ref.componentInstance.maskText = params.maskText;
    ref.componentInstance.buttonLabel = params.buttonLabel;
    ref.componentInstance.inputValidators = params.validators;
    ref.componentInstance.validatorTexts = params.validatorTexts;
    const res = await ref.result
      .catch(rej => null);
    return res;
  }
}
