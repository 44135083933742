import { UrlMatchResult, UrlSegment } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { MobileComponent } from '../mobile/mobile.component';
import { PinRegistrationComponent } from '../pin-registration/pin-registration.component';
import { RegisterComponent } from '../register/register.component';
import { RequestEmailComponent } from '../request-email/request-email.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { IntroShellComponent } from '../_shell/intro-shell.component';
import { IntroResolver } from './intro.resolver';
import { introUrls } from './login.service';
import { MobileDomainGuard, MobileGuard } from './mobile.guard';
import { PublicInviteComponent } from '../public-invite/public-invite.component';
import { DesktopGuard } from './desktop.guard';

export function introMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length > 0 && introUrls.indexOf(url[0].path) > -1) {
    return ({ consumed: [] });
  }
  return null;
}

export const INTRO_ROUTES = [{
  // path: '',
  matcher: introMatcher,
  component: IntroShellComponent,
  resolve: {
    config: IntroResolver
  },
  children: [{
    path: 'mobile',
    component: MobileComponent,
    canActivate: [MobileGuard]
  }, {
    path: 'register',
    component: RegisterComponent,
    canActivate: [DesktopGuard]
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [MobileDomainGuard]
  }, {
    path: 'reset-password',
    component: ResetPasswordComponent,
    // canActivate: [DesktopGuard]
  }, {
    path: 'verify',
    component: RequestEmailComponent,
    canActivate: [DesktopGuard]
  }, {
    path: 'signup',
    component: PinRegistrationComponent,
    canActivate: [DesktopGuard]
  }, {
    path: 'invitation/:code',
    component: PublicInviteComponent,
    canActivate: [DesktopGuard]
  }],
}];
