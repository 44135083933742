import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NotificationsService } from '../notifications/notifications-settings/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  isFocused: boolean = true;

  constructor(
    private notificationsService: NotificationsService
  ) {
    console.log('NOTIFICATIONS CONSTRUCTION');
  }

  async ngOnInit() {
    await this.notificationsService.loadNotificationSettings();
  }

  private log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[NOTIFICATION-COMPONENT]', 'color:#2296F3', ...arg);
    }
  }
}
