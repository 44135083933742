import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { RoomService } from '../../_core/services/room.service';
import { TeamService } from '../../_core/services/team.service';
import { User } from '../../_shared/models/commons/user';
import { RoomType } from '../../_shared/models/enums/room-type';
import { Chatroom, DirectRoom } from '../../_shared/models/room/room';

export const MAX_USERS_ALLOWED_IN_GROUP_CHAT = 5;

@Component({
  selector: 'app-start-conversation',
  templateUrl: './start-conversation.component.html',
  styleUrls: ['./start-conversation.component.scss'],
})
export class StartConversationComponent {

  maxOtherUsers = MAX_USERS_ALLOWED_IN_GROUP_CHAT - 1;

  term: string;
  @Input() users: User[] = [];
  @Input() dms: { [key: string]: boolean } = {};
  loading: boolean = false;

  get modal() {
    return this.activeModal;
  }

  get activeTeam() {
    return this.teamService.activeTeam;
  }

  get canCreate() {
    return this.users.length > 0 && Object.keys(this.dms).every(k => this.dms[k]);
  }

  get activeTeamLabel() {
    return this.teamService.activeTeam ? (this.teamService.activeTeam.label && this.teamService.activeTeam.label.length ? this.teamService.activeTeam.label : this.teamService.activeTeam.name) : '';
  }

  constructor(
    private profileService: ProfileService,
    private teamService: TeamService,
    private roomService: RoomService,
    private activeModal: NgbActiveModal,
    private panelsService: PanelsService,
    private toastr: ToastrService
  ) { }

  async createChannel() {
    this.loading = true;
    const usersIds = this.users.map(x => x.id);
    const channelTempName = this.users.map(x => x.username).join(' & ');
    let room: Chatroom | DirectRoom;
    if (usersIds.length === 1) {
      // this is direct
      room = await this.roomService
        .createRoom(this.activeTeam.id, usersIds)
        .catch((err) => {
          this.toastr.error(err.error.Message, 'Error');
          return null;
        });
    } else if (usersIds.length > 1) {
      // this is group
      room = {
        id: `local-id-${this.getLocalId()}`,
        color: null,
        description: '',
        topic: '',
        name: channelTempName,
        permissions: {
          user: {
            read_messages: true,
            post_messages: true
          }
        },
        participants: this.users.map(u => {
          return {
            user: {
              id: u.id,
              username: u.username
            }
          };
        }),
        type: RoomType.Group,
        lastActiveDate: (new Date()).getTime()
      };
      console.log(room);
      this.roomService.handleGroupRoomCreated(room);
    }

    if (!room) { return; }

    if (room.id.indexOf('local-id-') < 0) {
      // loadDirects breaks temp rooms if left open and start conversation again - keep local panels and reattach them!
      const temps = this.roomService.directs.filter(c => c.id.indexOf('local-id-') === 0);
      await this.roomService.loadDirects();
      // reattach temp rooms
      this.roomService.directs.splice(0, 0, ...temps);
    }

    this.panelsService.openDirect(room.id);
    this.teamService.mustUpdateSidebar$.next();
    this.loading = false;
    this.activeModal.close();

    setTimeout(
      () => {
        this.panelsService.clickedMenuItem$.next(room.id);
      },
      50);
  }

  getLocalId(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const length = 8;
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(async (term) => {
        let teamMembers = await this.teamService.searchMembers(term);
        if (this.profileService.me.mutedUsers && this.profileService.me.mutedUsers.length > 0) {
          teamMembers = teamMembers.filter(f => !this.profileService.me.mutedUsers.includes(f.userId));
        }
        return teamMembers
          .filter(member => member.user.id !== this.profileService.me.id && this.users.map(u => u.id).indexOf(member.user.id) < 0)
          .map(member => member.user).slice(0, 7);
      })
    )

  formatter(x) {
    return x.username;
  }

  async selected($event) {

    const member = await this.teamService.getMemberRequest($event.item.id);

    this.dms[$event.item.id] = member['dm'];

    this.users.push($event.item);

    const result = [];
    const map = new Map();
    for (const item of this.users) {
      if (!map.has(item.id)) {
        map.set(item.id, true);    // set any value to Map
        result.push(item);
      }
    }

    result.sort((a, b) => {
      if (a.username >= b.username) return 1;
      if (b.username < a.username) return -1;
      return 0;
    });

    this.users = result;

    setTimeout(() => this.term = '', 0);
  }

  remove(userId) {
    delete this.dms[userId];
    this.users = this.users.filter(p => p.id !== userId);
  }
}
