import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectRoom, Chatroom } from '../../../_shared/models/room/room';
import { NotificationsService } from '../notifications-settings/services/notifications.service';
import { TeamService } from '../../../_core/services/team.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { AppNotification } from '../notifications-settings/models/app-notification';
import { RoomType } from '../../../_shared/models/enums/room-type';
import { NotificationModel } from '@echofin/libraries/api/notification/models/notification-model';

@Component({
  selector: 'app-chatroom-notification-settings',
  templateUrl: './chatroom-notification-settings.component.html'
})
export class ChatroomNotificationSettingsComponent implements OnInit {
  room: Chatroom & DirectRoom;
  notification: AppNotification;
  mobileNotification: AppNotification;
  roomType = RoomType;

  get me() {
    return this.profileService.me;
  }

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private notificationsService: NotificationsService,
    private teamService: TeamService,
    private profileService: ProfileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadDesktopNotifications();
    await this.loadMobileNotifications();
  }

  loadDesktopNotifications() {
    this.notification = this.notificationsService.notifications.find(n =>
      n.teamId === this.teamService.activeTeamId
      && n.chatroomId === this.room.id
    );

    if (typeof (this.notification) === 'undefined') {
      const newNotifications: AppNotification = {
        teamId: this.teamService.activeTeamId,
        userId: this.me.id,
        chatroomId: this.room.id,
        all: true,
        mentions: false,
        none: false,
      };

      this.notification = newNotifications;
    }
  }

  async loadMobileNotifications() {
    const backendNotification = await this.notificationsService.getMobileNotificationsForChatroom(this.room.id, this.teamService.activeTeamId);

    if (typeof (backendNotification) === 'undefined') {
      this.mobileNotification = {
        teamId: this.teamService.activeTeamId,
        userId: this.me.id,
        chatroomId: this.room.id,
        all: true,
        mentions: false,
        none: false,
      };
    } else {
      this.mobileNotification = {
        teamId: this.teamService.activeTeamId,
        userId: this.me.id,
        chatroomId: this.room.id,
        all: backendNotification.all,
        mentions: backendNotification.mentions,
        none: backendNotification.none,
      };
    }

  }

  async toggle(state: number) {
    switch (state) {
      case 2:
        this.notification.all = false;
        this.notification.mentions = true;
        this.notification.none = false;
        break;
      case 3:
        this.notification.all = false;
        this.notification.mentions = false;
        this.notification.none = true;
        break;
      default:
        this.notification.all = true;
        this.notification.mentions = false;
        this.notification.none = false;
        break;
    }
    await this.savePreferences();
  }

  async toggleMobile(state: number) {
    switch (state) {
      case 2:
        this.mobileNotification.all = false;
        this.mobileNotification.mentions = true;
        this.mobileNotification.none = false;
        break;
      case 3:
        this.mobileNotification.all = false;
        this.mobileNotification.mentions = false;
        this.mobileNotification.none = true;
        break;
      default:
        this.mobileNotification.all = true;
        this.mobileNotification.mentions = false;
        this.mobileNotification.none = false;
        break;
    }
    await this.saveMobilePreferences();
  }

  async savePreferences() {
    const notificationModel: NotificationModel = {
      chatroomId: this.room.id,
      teamId: this.teamService.activeTeamId,
      mentions: this.notification.mentions,
      all: this.notification.all,
      none: this.notification.none,
      type: 'Desktop',
    };
    await this.notificationsService
      .saveCurrentChatroomNotifications(this.me.id, this.teamService.activeTeamId, this.room.id, notificationModel);
    this.notificationsService
      .saveLocal(this.me.id, this.teamService.activeTeamId, this.room.id, this.notification);
  }

  async saveMobilePreferences() {
    const notificationModel: NotificationModel = {
      chatroomId: this.room.id,
      teamId: this.teamService.activeTeamId,
      mentions: this.mobileNotification.mentions,
      all: this.mobileNotification.all,
      none: this.mobileNotification.none,
      type: 'Mobile',
    };
    await this.notificationsService
      .saveCurrentChatroomNotifications(this.me.id, this.teamService.activeTeamId, this.room.id, notificationModel);
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
