import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalsService } from '../../_core/services/modals.service';
import { TeamService } from '../../_core/services/team.service';
import { Helpers } from '../../_shared/helpers/helpers';

declare var window: any;
declare var JitsiMeetExternalAPI: any;

export interface JitsiSettings {
  roomId: string;
  teamId: string;
  server: string;
  jwt: string;
  config: any;
}

@Component({
  selector: 'app-stream-jitsi',
  template: `
    <div *ngIf="state === 'ended'" class="message">
      <i class="fas fa-signal-stream"></i>
      <span>Meeting has ended</span>
    </div>
    <div *ngIf="state === 'left'" class="message">
      <i class="fas fa-signal-stream"></i>
      <span>You have left the live meeting.</span>
    </div>
    <div *ngIf="state === 'live'" #meet></div>
  `,
  styleUrls: ['./stream-jitsi.component.scss']
})
export class StreamJitsiComponent implements OnInit, AfterViewInit {

  @Input() settings: JitsiSettings;

  @ViewChild('meet') meet: ElementRef;

  state: 'live' | 'left' | 'ended' = 'live';

  constructor(
    private teamService: TeamService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit(): void {

    this.teamService
      .meetingsUpdated$
      .subscribe(meetings => {
        const ended = !meetings[this.settings.teamId].find(x => x.chatroomId === this.settings.roomId);
        if (ended) {
          this.state = 'ended';

          // close modal as it is minimized
          if (Helpers.isElectron() && Helpers.isElectronLatest('2.1')) {
            this.modalsService.closeFloatModal({
              id: 'STREAM', teamId: 'GLOBAL'
            });
          }
        }
      });
  }

  ngAfterViewInit() {

    const options = {
      ...this.settings.config,
      roomName: this.settings.roomId,
      width: '100%',
      height: '100%',
      parentNode: this.meet.nativeElement,
      jwt: this.settings.jwt
    };

    console.log('start stream with options', options);

    try {
      const api = new JitsiMeetExternalAPI(this.settings.server, options);
      api.executeCommand('subject', ' ');
      api.addEventListener('readyToClose', (e) => {
        if (!Helpers.isElectron() && Helpers.isElectronLatest('2.1')) {
          this.state = 'left';
        }
      });

      if (Helpers.isElectron() && Helpers.isElectronLatest('2.1')) {
        console.log('open jitsi');

        window.ipcRenderer.send('jitsi', ['open', api._url, this.settings.config]);

        window.ipcRenderer.once('jitsi', (event, action) => {
          console.log('jitsi-once', action);
          switch (action) {
            case 'close':
              this.modalsService.closeFloatModal({
                id: 'STREAM', teamId: 'GLOBAL'
              });
              break;
          }
        });

        api.dispose();

        this.modalsService.minimizeFloatModal({
          id: 'STREAM', teamId: 'GLOBAL'
        });
      }

    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  }
}
