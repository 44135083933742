import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'fixHttps', pure: true })
export class FixHttpsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    if (url.indexOf('http://') === 0) {
      return `https://${url.substr(7)}`;
    }

    return url;
  }
}
