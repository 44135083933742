<div class="box flipper" *ngIf="state==='normal'" [ngClass]="{'flip':flip}">
  <div class="panel front">
    <div class="inner-panel">
      <div class="premium-ribbon" *ngIf="room.subtype=='Premium' && !flip"></div>
      <div class="private-ribbon" *ngIf="room.subtype=='Private' && !flip"></div>
      <div class="content-info">
        <div>
          <i class="fal fa-lock-alt fa-2x"></i>
        </div>
        <ng-container [ngSwitch]="room.subtype">
          <ng-container *ngSwitchCase="'Premium'">
            <h4>Members Only</h4>
            <div class="chatroom-description">
              <ng-container *ngIf="description">
                <ng-container *ngFor="let t of description">
                  <p *ngIf="t">{{t}}</p>
                </ng-container>
              </ng-container>
              <!-- <p>lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
                lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem </p> -->
            </div>
            <ng-container *ngIf="plans.length">
              <!-- <div *ngIf="plans.length > 1" class="form-group">
                <label for="">Select a plan</label>
                <select [(ngModel)]="selectedPlan" class="form-control" (change)="planChanged()">
                  <option *ngFor="let plan of plans" [ngValue]="plan">
                    {{plan.label}}
                  </option>
                </select>
              </div> -->

              <ng-container *ngIf="plans.length > 1">
                <div class="text-center">Select a plan</div>
                <div ngbDropdown class="d-inline-block mb-2 plan-dropdown">
                  <button class="btn btn-dark" ngbDropdownToggle>
                    {{selectedPlan.label}}
                  </button>
                  <div ngbDropdownMenu>
                    <ng-container *ngFor="let plan of plans">
                      <button (click)="planChanged(plan)" ngbDropdownItem>
                        <strong>{{plan.label}}</strong><br>
                        <span>Unlocks: </span>
                        <ng-container *ngFor="let room of plan._chatrooms;let l = last">
                          <span># {{room}}<ng-container *ngIf="!l">, </ng-container></span>
                        </ng-container>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="selectedPlan">
                <div *ngIf="plans.length===1" class="pricing">
                  {{selectedPlan.label}}
                </div>
                <ng-container *ngIf="selectedPlan._chatrooms">
                  <div class="unlocks">
                    <div class="unlocks-info">
                      <i class="fal fa-lock"></i> This purchase unlocks the following:
                    </div>
                    <ng-container *ngFor="let room of selectedPlan._chatrooms">
                      <span># {{room}}</span>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="plan-description">
                  <ng-container *ngIf="selectedPlanDescription">
                    <ng-container *ngFor="let t of selectedPlanDescription">
                      <p *ngIf="t" class="text-muted mb-1"><small>{{t}}</small></p>
                    </ng-container>
                  </ng-container>
                </div>
                <ng-container *ngIf="couponsEnabled">
                  <ng-container *ngIf="!showCouponInput">
                    <a href="javascript:void(0)" (click)="showCouponInput = true" class="i-have-coupon">
                      <i class="fas fa-tags mr-1"></i> I have a coupon
                    </a>
                  </ng-container>
                  <ng-container *ngIf="showCouponInput">
                    <div class="form-group d-flex mb-2">
                      <input [(ngModel)]="couponTxt" ngmod class="form-control" placeholder="Coupon"
                        [disabled]="appliedCoupon">
                      <button *ngIf="!appliedCoupon" class="btn btn-outline-primary ml-2"
                        (click)="applyCoupon()">Apply</button>
                    </div>
                  </ng-container>
                </ng-container>
                <button class="btn btn-success mt-2" (click)="unlock()" [disabled]="unlockLoading">
                  <i *ngIf="unlockLoading" class="fas fa-cog fa-spin"></i>
                  <span *ngIf="!unlockLoading">Unlock</span>
                </button>
                <div class="terms">I agree to the <a href="javascript:void(0)" (click)="openTerms()">Terms and
                    Conditions</a></div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'Private'">
            <h4>Invited Members Only</h4>
            <ng-container *ngIf="description">
              <ng-container *ngFor="let t of description">
                <p *ngIf="t">{{t}}</p>
              </ng-container>
            </ng-container>
            <h6 class="text-muted"><small>Contact your team admin for access.</small></h6>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h4>Invited Members Only</h4>
            <ng-container *ngIf="description">
              <ng-container *ngFor="let t of description">
                <p *ngIf="t">{{t}}</p>
              </ng-container>
            </ng-container>
            <h6 class="text-muted"><small>Contact your team admin for access.</small></h6>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="panel back">
    <div class="inner-panel">
      <div class="billing-header">
        <span class="title">Complete Payment</span>
        <button class="close" (click)="hideCardForm()">×</button>
      </div>
      <ng-container *ngIf="!newCard && customer">
        <app-billing-confirm [connected]="customer" [plan]="selectedPlan" (authorized)="subscribe($event)"
          (switchNew)="newCard=true">
        </app-billing-confirm>
      </ng-container>
      <ng-container *ngIf="newCard">
        <app-billing-card-form (authorized)="subscribe($event)"></app-billing-card-form>
      </ng-container>
      <div class="terms">I agree to the <a href="javascript:void(0)" (click)="openTerms()">Terms and Conditions</a>
      </div>
      <div class="p-2 text-center">
        <img src="/assets/stripe-logo.png" alt="powered by stripe" style="width: 100px;opacity: .4;">
      </div>
    </div>
  </div>
</div>

<div class="box error" *ngIf="!state">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div class="box error" *ngIf="state==='error'">
  {{error}}
</div>

<div class="box loading" *ngIf="state==='processing'">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="label">Processing Payment</div>
  <div class="sublabel">
    Please wait, this may take a few minutes. This panel will refresh on successful
    payment
  </div>
</div>

<ng-template #plansLoadingTmpl>
  <div class="text-center text-muted p-4">
    loading billing plans...
  </div>
</ng-template>