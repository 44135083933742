import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base-component';
import { TypingService } from './services/typing.service';

@Component({
  selector: 'app-is-typing',
  templateUrl: './is-typing.component.html',
  styleUrls: ['./is-typing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IsTypingComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() channelId: string;

  MAX_IN_VIEW = 3;

  get typings() {
    return this.typingService.channelsTyping[this.channelId]?.slice(0, this.MAX_IN_VIEW);
  }

  get restTyping() {
    if (!this.typingService.channelsTyping[this.channelId]) {
      return '';
    } else if (this.typingService.channelsTyping[this.channelId].length === 1) {
      return 'is ';
    } else if (this.typingService.channelsTyping[this.channelId].length <= this.MAX_IN_VIEW) {
      return 'are ';
    } else if (this.typingService.channelsTyping[this.channelId].length === this.MAX_IN_VIEW + 1) {
      return 'and 1 other are ';
    }
    const other = this.typingService.channelsTyping[this.channelId].length - this.MAX_IN_VIEW;
    return `and ${other} others are `;
  }

  constructor(
    public typingService: TypingService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.typingService.typingUpdated$, (roomId) => {
      if (roomId === this.channelId) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
