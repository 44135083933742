<div class="modal-header">
  <h4 class="modal-title">
    <span #title class="edtbl" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
      contenteditable="true" (blur)="mainTitle=(title.innerText || 'SINGAL'); title.innerText=mainTitle;"
      (keydown)="onTitleKeyDown($event, title)">{{mainTitle}}</span>
    <i class="far fa-pen-square"></i>
  </h4>
  <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<form [formGroup]="signalForm" #f="ngForm" (ngSubmit)="signalForm.valid && send()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <h6>Order Type</h6>
      </div>
    </div>
    <div class="row">
      <span class="col-5 sell-title title"><b>SELL</b></span>
      <span class="offset-2 col-5 buy-title title"><b>BUY</b></span>
    </div>
    <div ngbRadioGroup name="orderType" formControlName="orderType" class="order-types">
      <div class="btn-group btn-group-toggle row">
        <label ngbButtonLabel class="btn-danger btn-sm col-auto">
          <input ngbButton type="radio" [value]="'SELL'">SELL
        </label>
        <label ngbButtonLabel class="btn-danger btn-sm col-auto">
          <input ngbButton type="radio" [value]="'SELL_MARKET'">MARKET
        </label>
        <label ngbButtonLabel class="btn-danger btn-sm col-auto">
          <input ngbButton type="radio" [value]="'SELL_STOP'">STOP
        </label>
        <label ngbButtonLabel class="btn-danger btn-sm col-auto">
          <input ngbButton type="radio" [value]="'SELL_LIMIT'">LIMIT
        </label>

        <label ngbButtonLabel class="btn-default btn-sm col-auto">
          <input ngbButton type="radio" [value]="'NONE'">NONE
        </label>

        <label ngbButtonLabel class="btn-success btn-sm col-auto">
          <input ngbButton type="radio" [value]="'BUY'"> BUY
        </label>
        <label ngbButtonLabel class="btn-success btn-sm col-auto">
          <input ngbButton type="radio" [value]="'BUY_MARKET'">MARKET
        </label>
        <label ngbButtonLabel class="btn-success btn-sm col-auto">
          <input ngbButton type="radio" [value]="'BUY_STOP'">STOP
        </label>
        <label ngbButtonLabel class="btn-success btn-sm col-auto">
          <input ngbButton type="radio" [value]="'BUY_LIMIT'">LIMIT
        </label>

      </div>
    </div>

    <div *ngIf="f.submitted && f.invalid" class="signal-error">
      <small *ngIf="orderType.invalid && orderType.errors.required" class="form-text">
        You must select an Order Type
      </small>
      <small *ngIf="signalForm.hasError('emptyFields')" class="form-text">
        Please fill at least one field
      </small>
    </div>

    <div class="row signal-table" style="text-align: center; margin-top: 20px;">
      <div class="col">
        <span>Symbol</span>
        <input formControlName="symbol" style="width: 80px;" type="text" class="form-control signal-input first-input"
          [ngbTypeahead]="search">
      </div>
      <div class="col">
        <span>Entry</span>
        <input formControlName="entry" type="text" class="form-control signal-input">
      </div>
      <div class="col">
        <span #title1 class="edtbl" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          contenteditable="true"
          (blur)="signalFieldTitle1=(title1.innerText || 'TP'); title1.innerText=signalFieldTitle1;"
          (keydown)="onTitleKeyDown($event, title1)">{{signalFieldTitle1}}</span>
        <input formControlName="field1" type="text" id="el_tp" class="form-control signal-input">
      </div>
      <div class="col">
        <span #title2 class="edtbl" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          contenteditable="true"
          (blur)="signalFieldTitle2=(title2.innerText || 'SL'); title2.innerText=signalFieldTitle2;"
          (keydown)="onTitleKeyDown($event, title2)">{{signalFieldTitle2}}</span>
        <input formControlName="field2" type="text" id="el_sl" class="form-control signal-input">
      </div>
      <div class="col">
        <span #title3 class="edtbl" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          contenteditable="true"
          (blur)="signalFieldTitle3=(title3.innerText || 'Custom'); title3.innerText=signalFieldTitle3;"
          (keydown)="onTitleKeyDown($event, title3)">{{signalFieldTitle3}}</span>
        <input formControlName="field3" type="text" id="el_custom" class="form-control signal-input last-input">

      </div>
    </div>
    <div class="row">
      <div class="col" style="color: #999;"><small>* Fields and titles are free-text so you can type anything you
          like</small>
      </div>
    </div>

    <p>&nbsp;</p>


    <div class="form-group">
      <label for="mlink text-muted">Comment</label>
      <div class="input-group">
        <textarea formControlName="comment" class="form-control" rows="3"></textarea>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <div>
      <div class="position-relative mr-3">
        <ng-container *ngIf="!uploadImagePreview && files && files[0]">
          <i class="far fa-paperclip mr-2"></i>
          <span>{{files[0].filename}}</span>
          <i (click)="clearFiles()" class="fas fa-times align-middle text-white hand-cursor ml-1"></i>
        </ng-container>
        <ng-container *ngIf="!fileToUpload && (!files || !files[0])">
          <input type="file" (change)="importFile($event)" class="d-none" #file>
          <button type="button" class="btn btn-secondary" title="Attach file" (click)="file.value=null;file.click()">
            <i class="far fa-paperclip"></i>
            Attach file
          </button>
        </ng-container>
        <ng-container *ngIf="fileToUpload && uploadImagePreview">
          <div class="attach-container">
            <img [src]="uploadImagePreview" class="img-thumb">
            <div *ngIf="!files || !files[0]"
              class="upload-percent text-white shadowed h-100 d-flex justify-content-center align-items-center">
              {{uploadPercent+'%'}}</div>
            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
          </div>
          <i *ngIf="files && files[0]" (click)="clearFiles()"
            class="fas fa-times text-white hand-cursor circle-icon clear-thumb"></i>
        </ng-container>
        <ng-container *ngIf="fileToUpload && !uploadImagePreview  && (!files || !files[0])">
          <div class="attach-container">
            <div class="loading-bar">
              <div class="upload-percent text-center text-white shadowed">{{uploadPercent+'%'}}</div>
            </div>
            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="btn-group mr-3">
      <button type="submit" class="btn btn-success" [disabled]="fileToUpload && uploading">Publish</button>
      <div *ngIf="isTeamChatroom && !isInReplyMode" class="btn-group" ngbDropdown
        [placement]="['bottom-right','top-right']">
        <button class="btn btn-success dropdown-toggle-split" ngbDropdownToggle
          (click)="$event.preventDefault()"></button>
        <div class="dropdown-menu p-0" ngbDropdownMenu style="width: 200px">
          <div *ngFor="let chatroom of chatrooms; let i = index" class="px-3 py-2">
            <div class="custom-control custom-checkbox" formArrayName="chatrooms">
              <input [formControlName]="i" type="checkbox" class="custom-control-input" id="signal_room_{{i}}">
              <label class="custom-control-label" for="signal_room_{{i}}">#{{chatrooms[i].label}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>