import { ElementRef, Injectable } from '@angular/core';
import { Events } from '@echofin/libraries';
import { LocalStorage } from '@efaps/ngx-store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Panel, PanelMessageOrder, PanelType } from '../../_shared/models/room/channel';
import { BaseService } from './base.service';
import { EvType } from './socket.service/models/all.models';
import { SocketService } from './socket.service/socket.service';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class PanelsService extends BaseService {

  @LocalStorage() panels: (Panel[] & { save?: any }) = [];

  @LocalStorage('panelsConfig')
  panelsConfig: { [key: string]: any } = {};

  focusedItem = null;

  @LocalStorage()
  maximizedPanels: ({ [teamId: string]: string; } & { save?: any }) = {};
  @LocalStorage()
  defaultChatrooms: ({ [teamId: string]: boolean } & { save?: any }) = {};
  @LocalStorage()
  defaultwidgets: ({ [teamId: string]: boolean } & { save?: any }) = {};
  @LocalStorage()
  minimizeDirect: ({ val: boolean } & { save?: any }) = { val: false };

  @LocalStorage()
  panelMessageOrder: (PanelMessageOrder[] & { save?: any }) = [];

  showedItem$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  clickedMenuItem$: Subject<string> = new Subject<string>();

  scrollPosition: number = 0;

  panelArea: ElementRef;
  panelElements: { [key: string]: ElementRef } = {};

  subs = new Subscription();

  resetScrollToSaved$ = new Subject<string>();

  panelClosed$ = new Subject<string>();

  panelTitle$ = new Subject<[string, string]>();
  panelPermissions$ = new Subject<[string, boolean]>();
  panelLoading$ = new Subject<[string, boolean]>();
  refreshPanelState$ = new Subject<[string, boolean]>();
  panelChatroomType$ = new Subject<[string, string]>();
  panelUserIdAndStatus$ = new Subject<[string, string, string]>();
  openChatroomNotificationPreferences$ = new Subject<string>();

  refreshLiveStreamPosition$ = new Subject<string>();

  timestampCursors = {};

  // default behavior is chrome 88+: negative values for scroll top when column-reverse
  reverseScrollTopNegative = true;

  messageMouseoverTradingView$ = new Subject<[string, string, boolean, string]>();
  messagePDFResetFullView$ = new Subject<void>();

  constructor(
    private teamService: TeamService,
    private socketService: SocketService
  ) {
    super();
  }

  setup() {
    this.cleanup();

    this.socketService
      .getStream(EvType.FrameDeleted)
      .subscribe((data: Events.FrameDeleted) => {
        this.close({
          id: data.frame.id,
          teamId: data.teamId,
          type: PanelType.Frame,
        });
      });

    this.teamService.teamSidebarChanged$.subscribe((team) => {
      const removedPanels = (this.panels || []).filter(p => p.teamId && !team.sidebarResolved.map(s => s.refId).includes(p.id));
      removedPanels.forEach(p => {
        this.close(p);
      });
    });
  }

  slideToPanel(panelId: string) {
    const panel = this.panels.find(c => c.id === panelId);
    if (panel && this.panelElements[panelId] && !panel.isHidden) {
      if (this.panelElements[panelId].nativeElement.offsetLeft - this.panelArea.nativeElement.scrollLeft - this.panelArea.nativeElement.offsetLeft) {
        // added 6px to allow some margin left from the menu
        (this.panelArea.nativeElement as HTMLDivElement).scrollTo({
          left: (this.panelElements[panelId].nativeElement as HTMLElement).offsetLeft - this.panelArea.nativeElement.offsetLeft - 6,
          behavior: 'smooth',
        });
      } else if ((this.panelElements[panelId].nativeElement as HTMLElement).offsetLeft + (this.panelElements[panelId].nativeElement as HTMLElement).clientWidth > this.panelArea.nativeElement.scrollLeft + this.panelArea.nativeElement.clientWidth) {
        (this.panelArea.nativeElement as HTMLDivElement).scrollTo({
          left: (this.panelElements[panelId].nativeElement as HTMLElement).offsetLeft,
          behavior: 'smooth',
        });
      }
    }
  }

  openRoom(chatroomId: string, timestamp: Date = null) {
    const channel = this.teamService.activeTeam.chatrooms.find(c => c.id === chatroomId);
    if (!channel) {
      return;
    }
    if (timestamp) {
      this.timestampCursors[chatroomId] = timestamp;
    }
    this.open({
      id: chatroomId,
      teamId: channel.teamId,
      type: PanelType.Chatroom,
    });
  }

  openFrame(frameId: string) {
    const frame = this.teamService.getFrame(frameId);
    if (!frame) {
      return;
    }
    const panel = {
      id: frameId,
      teamId: frame.teamId,
      type: PanelType.Frame
    };
    this.open(panel);
  }

  async openDirect(roomId: string, timestamp: Date = null) {
    if (timestamp) {
      this.timestampCursors[roomId] = timestamp;
    }
    this.open({
      id: roomId,
      type: PanelType.Direct,
    });
  }

  open(panel: Panel) {
    if (!this.isOpened(panel.id)) {
      this.panels.push(panel);
    }
    this.show(panel.id);
  }

  show(panelId: string) {
    const panel = this.panels.find(x => x.id === panelId);

    if (this.maximizedPanels[this.teamService.activeTeamId] || this.maximizedPanels['direct']) {
      this.maximize(panel);
    }
    panel.visible = true;
    this.panels.save();

    this.showedItem$.next(panelId);
  }

  maximize(panel: Panel) {
    if (panel.type === PanelType.Direct) {
      this.maximizedPanels['direct'] = panel.id;
      // minimize the current team's panel, the direct panel does not belong to a team so we need to access via activeTeamId
      this.maximizedPanels[this.teamService.activeTeamId] = undefined;
    } else {
      this.maximizedPanels[panel.teamId] = panel.id;
      this.maximizedPanels['direct'] = undefined;
    }
    // this.focusedItem = panel.id;
    this.maximizedPanels.save();

    this.refreshLiveStreamPosition$.next(panel.id);
  }

  minimizeAll(teamId: string) {
    this.maximizedPanels['direct'] = undefined;

    this.maximizedPanels[teamId] = undefined;
    this.maximizedPanels.save();
    // this.focusedItem = null;

    this.refreshLiveStreamPosition$.next(null);
  }

  minimizeMaximizedDirect() {
    const panelId = this.maximizedPanels['direct'];
    if (!panelId) {
      return;
    }
    this.panels.find(p => p.id === panelId).visible = false;
    this.maximizedPanels['direct'] = undefined;
    this.minimizeDirect = { val: false };
    this.minimizeDirect.save();
  }

  hide(panel: Panel) {
    if (panel) {
      panel.visible = false;
      this.panels.save();
    }
  }

  close(panel: Panel) {
    this.minimizeAll(panel.teamId);
    this.hide(panel);
    this.panels = this.panels.filter(x => x.id !== panel.id);
    this.panels.save();
    this.panelClosed$.next(panel.id);
  }

  closeAll() {
    for (let index = 0; index < this.panels.length; index++) {
      const element = this.panels[index];
      this.close(element);
    }

    this.panels = [];
    this.panels.save();
  }

  isOpened(panelId: string): boolean {
    return (this.panels || []).findIndex(x => x.id === panelId) > -1;
  }

  isVisible(panelId: string): boolean {
    const panel = this.panels.find(x => x.id === panelId);
    return panel ? panel.visible : false;
  }

  isMaximizedPanel(panel: Panel) {
    if (panel && panel.type === PanelType.Direct) {
      return this.maximizedPanels['direct'] === panel.id;
    }
    return panel ? (this.maximizedPanels[panel.teamId] === panel.id) : null;
  }

  getMessageOrder(panelId: string): PanelMessageOrder {
    if (this.panelMessageOrder === null || this.panelMessageOrder.length === 0) {
      return { id: panelId, order: 'MessageDate' };
    }

    const selectedPanel = this.panelMessageOrder.filter(x => x.id === panelId);
    if (selectedPanel.length === 0) {
      return { id: panelId, order: 'MessageDate' };
    }

    return selectedPanel[0];
  }

  setMessageOrder(messageOrder: PanelMessageOrder) {
    const selectedPanel = this.panelMessageOrder.filter(x => x.id === messageOrder.id);
    if (selectedPanel.length === 0) {
      this.panelMessageOrder.push(messageOrder);
    } else {
      selectedPanel[0].order = messageOrder.order;
    }
    this.panelMessageOrder.save();
  }

  private cleanup() {
    const types = Object.keys(PanelType);
    this.panels = this.panels.filter(p => types.indexOf[p.type] !== -1);
    this.panelMessageOrder = this.panelMessageOrder.filter(p => types.indexOf[p.id] !== -1);
  }
}
