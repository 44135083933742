import { Injectable } from '@angular/core';
import { BookmarkMessagesServiceApi, Events } from '@echofin/libraries';
import { MessageService, MESSAGES_PER_PAGE } from 'app/_core/services/message.service';
import { MessageHelpers } from '../../../_shared/helpers/message-helpers';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EvType } from '../../../_core/services/socket.service/models/all.models';
import { SocketService } from '../../../_core/services/socket.service/socket.service';
import { TeamService } from '../../../_core/services/team.service';
import { Message } from '../../../_shared/models/commons/message';

@Injectable({
  providedIn: 'root'
})
export class BookmarkMessagesService {
  bookmarkMessageAdded$: Subject<Events.BookmarkMessageAdded> = new Subject<Events.BookmarkMessageAdded>();
  bookmarkMessageRemoved$: Subject<Events.BookmarkMessageRemoved> = new Subject<Events.BookmarkMessageRemoved>();

  constructor(
    private teamService: TeamService,
    private messageService: MessageService,
    private socketService: SocketService,
    private bookmarkMessagesServiceApi: BookmarkMessagesServiceApi,
    private toastr: ToastrService,
  ) {
    this.listenToEvents();
  }

  listenToEvents() {
    this.socketService
      .getStream(EvType.BookmarkMessageAdded)
      .subscribe((data: Events.BookmarkMessageAdded) => {
        const fav = {
          ...data,
          message: {
            ...data.message,
            timestamp: new Date(data.message.timestamp)
          }
        };
        this.log(data);
        this.bookmarkMessageAdded$.next(fav);
      });
    this.socketService
      .getStream(EvType.BookmarkMessageRemoved)
      .subscribe((data: Events.BookmarkMessageRemoved) => {
        this.log(data);
        this.bookmarkMessageRemoved$.next(data);
      });
  }

  async addBookmarkMessage(messageId: string) {
    return this.bookmarkMessagesServiceApi.Toggle({
      messageId
    }).toPromise().then((res) => {
      this.toastr.success(res.message);
      return res;
    })
      .catch((err) => {
        console.log(err);
        this.toastr.error(err.error.ErrorMessage, 'Error');
        return null;
      });
  }

  async loadBookmarkMessages(panelId: string, order: any, teamId?: string, timestamp?: string) {
    if (timestamp && !this.messageService.hasMoreMessages[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }
    const messages = await this.load(
      timestamp,
      order
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessages[panelId] = false;
    } else {
      this.messageService.hasMoreMessages[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeBookmarkMessages(messages);
  }

  async load(timestamp: string, order: 'MessageDate' | 'CreatedDate') {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const bookmarkMessages = await this.bookmarkMessagesServiceApi.Get({
      TeamId: this.teamService.activeTeam.id,
      Timestamp: timestampString,
      Order: order !== 'MessageDate' ? order : null,
      Limit: MESSAGES_PER_PAGE
    }).toPromise().then((res) => {
      return res;
    })
      .catch((err) => {
        return null;
      });

    if (!bookmarkMessages) return;

    const mappedBookmarkMessages = bookmarkMessages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedBookmarkMessages;
  }

  async loadBookmarkMessagesAfter(panelId: string, order: any, teamId?: string, timestamp?: string, includeTimestamp?: boolean) {
    if (timestamp && !this.messageService.hasMoreMessagesBelow[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }
    const messages = await this.loadAfter(
      timestamp,
      includeTimestamp,
      order
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessagesBelow[panelId] = false;
    } else {
      this.messageService.hasMoreMessagesBelow[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeBookmarkMessages(messages);
  }

  async loadAfter(timestamp: string, includeTimestamp: boolean, order: 'MessageDate' | 'CreatedDate') {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const bookmarkMessages = await this.bookmarkMessagesServiceApi.GetBookmarkMessagesAfter({
      TeamId: this.teamService.activeTeam.id,
      Timestamp: timestampString,
      IncludeTimestamp: includeTimestamp,
      Order: order !== 'MessageDate' ? order : null,
      Limit: MESSAGES_PER_PAGE
    }).toPromise().then((res) => {
      return res;
    })
      .catch((err) => {
        return null;
      });

    if (!bookmarkMessages) return;

    const mappedBookmarkMessages = bookmarkMessages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedBookmarkMessages;
  }
  async removeBookmarkMessage(messageId: string) {
    this.bookmarkMessagesServiceApi.Toggle({
      messageId
    }).toPromise()
      .then((res) => {
        this.toastr.success(res.message);
        return res;
      })
      .catch((err) => {
        console.log(err);
        this.toastr.error(err.error.ErrorMessage, 'Error');
        return null;
      });
  }
  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[BOOKMARKS SERVICE]', 'color:#FD9810', ...arg);
    }
  }
}
