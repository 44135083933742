import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ProfileService } from '../../../_core/services/profile.service';
import { TeamService } from '../../../_core/services/team.service';

@Component({
  selector: 'app-preferences-billing',
  templateUrl: './preferences-billing.component.html',
  styleUrls: ['./preferences-billing.component.scss'],
})
export class PreferencesBillingComponent implements OnInit {

  @Input() connected: any[];
  loading = false;
  teamNames = {};
  teamErrors = {};

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private teamService: TeamService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.teamErrors = {};
    this.teamNames = this.teamService.teams.reduce((l, i) => { l[i.id] = i.label || i.name; return l; }, {});
  }

  async navigateToPortal(teamId) {
    this.teamErrors[teamId] = false;
    const res = await this.http
      .get<any>(`${environment.config.endpoints['billing']}/billing/users/${this.profileService.me.id}/portal`, {
        params: {
          teamId
        }
      })
      .toPromise()
      .catch((res) => {
        this.teamErrors[teamId] = true;
      });

    if (!res) return;

    window.open(res.portal.url, '_blank');
  }
}
