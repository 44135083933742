import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PanelsService } from 'app/_core/services/panels.service';
import { Helpers } from 'app/_shared/helpers/helpers';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnInit, OnChanges {

  @Input() thumb: string;
  @Input() src: string;
  @Input() filename: string;
  @Input() width: number;
  @Input() height: number;
  @Input() mime: string;
  @Input() messageId: string;
  @Input() type: string;
  @Input() fullView: boolean = false;
  loaded: boolean = false;
  hide: boolean = false;

  maxHeight: number = 250;
  maxWidth: number = 250;
  minAspectRatio: number = this.maxHeight / this.maxWidth;
  aspectRatio: number;
  styleHeight: string;
  styleWidth: string;

  isElectron = false;

  constructor(
    private http: HttpClient,
    private panelService: PanelsService
  ) { }

  ngOnInit() {
    this.isElectron = Helpers.isElectron();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fullView'] && !changes['fullView'].previousValue && changes['fullView'].currentValue && this.mime === 'application/pdf' && this.isElectron) {
      this.fullView = false;
      this.downloadFile();
      this.panelService.messagePDFResetFullView$.next();
    }
  }

  hideImage() {
    this.hide = true;
  }

  onFullViewLoaded() {
    this.loaded = true;
  }

  async downloadFile(): Promise<void> {
    this.downloadResource(this.src, this.filename);
  }

  async getFile(path: string): Promise<any> {
    const res = await this.http.get(path);
    return res.toPromise();
  }

  forceDownload(blob, filename) {
    const a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    a.click();
  }

  // Current blob size limit is around 500MB for browsers
  downloadResource(url, filename) {
    let fname = filename;
    if (!fname) fname = url.split('\\').pop().split('/').pop();
    fetch(url, {
      headers: new Headers({
        Origin: location.origin
      }),
      mode: 'cors'
    })
      .then(response => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
  }

  // getHeight() {
  //   const height = (this.aspectRatio < this.minAspectRatio)
  //     ? `${this.maxWidth * this.aspectRatio}px`
  //     : `${this.maxHeight}px`;
  //   return height;
  // }

  // getWidth() {
  //   const width = (this.aspectRatio < this.minAspectRatio)
  //     ? `${this.maxWidth}px`
  //     : `${this.maxHeight / this.aspectRatio}px`;
  //   return width;
  // }

  // getImageSizeStyle() {
  //   return {
  //     width: this.styleWidth,
  //     height: this.styleHeight
  //   };
  // }
}
