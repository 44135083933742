import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@efaps/ngx-store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthError } from '../../_shared/models/commons/auth-error';

@Injectable({ providedIn: 'root' })
export class AuthService {
  sso: ElementRef<any>;
  identity: string;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) { }

  getSubdomain() {
    const hostname = window.location.hostname.split('.');
    if (hostname.length > 0) {
      return hostname[0];
    }
    return window.location.hostname;

  }

  async login(identity: string, password: string): Promise<AuthError> {
    console.log('Login to echofin...');
    let timeout = null;
    return new Promise((resolve, reject) => {
      window.addEventListener('message', (e) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        switch (e.data.type) {
          case 'loggedIn:success':
            resolve({});
            break;
          case 'loggedIn:fail':
            const error = <AuthError>{
              code: e.data.errorCode
            };
            switch (e.data.errorCode) {
              case 'USER_OR_PASSWORD_WRONG':
                error.message = 'Wrong username/email or password. Please try again.';
                break;
              default:
                error.message = 'Something went wrong. Please try again.';
            }
            resolve(error);
        }
      }, false);
      const req = {
        password,
        identity,
        type: 'login',
        host: this.getSubdomain()
      };
      this.sso.nativeElement.contentWindow.postMessage(req, '*');

      timeout = setTimeout(() => {
        resolve({
          message: 'Something went wrong. If you are on Brave web browser deactivate the Shields feature. Also, disable ad-blockers and try again.'
        });
      }, 10 * 1000);
    });
  }

  async logout(): Promise<void> {
    console.log('Logout from echofin...');
    return new Promise((resolve, reject) => {
      window.addEventListener('message', (e) => {
        switch (e.data.type) {
          case 'loggedOut:success':
            this.localStorageService.clear('prefix');
            resolve(null);
            break;
        }
      }, false);
      const req = {
        type: 'logout',
      };
      this.sso.nativeElement.contentWindow.postMessage(req, '*');
    });
  }

  async getShortToken() {
    const response = await this.http
      .get(`${environment.config.endpoints.authentication}/authentication/token`)
      .toPromise();
    return response ? response['token'] : null;
  }

  async getUserId() {
    const response = await this.http
      .get(`${environment.config.endpoints.authentication}/authentication/check`)
      .toPromise()
      .catch(() => {
        return null;
      });
    return response ? response['id'] : null;
  }
}

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthService,
    private router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const r = request.clone({
      withCredentials:
        request.url.indexOf('//assets.echofin.com') === -1 &&
        request.url.indexOf('//meet') === -1 &&
        request.url.indexOf('//jitsi') === -1
      // setHeaders: {
      //   Authorization: `Bearer ${this.authService.token}`,
      // },
    });
    return next.handle(r);
    // .pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     if (error.status === 401) {
    //       this.router.navigate(['/logout']);
    //     }
    //     return throwError(error);
    //   }));
  }
}
