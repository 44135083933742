<div class="modal-header">
  <h4 class="modal-title">START DIRECT OR GROUP CHAT</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="input-group mb-3">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">To:</span>
      </div> -->
      <input autofocus ngbAutofocus [(ngModel)]="term" class="form-control"
        [placeholder]="(users.length>=maxOtherUsers)?'Maximum users allowed in group selected!':'Search for team members'"
        (selectItem)="selected($event)" [ngbTypeahead]="search" [ngModelOptions]="{standalone: true}"
        [resultTemplate]="rt" [inputFormatter]="formatter" [disabled]="users.length>=maxOtherUsers">
      <div class="convo-badge" *ngIf="activeTeam"><span>{{activeTeamLabel}}</span></div>
    </div>
    <div class="borderfx"></div>
    <ng-container *ngIf="users.length; else empty">
      <div>
        <ul class="list-group">
          <ng-container *ngFor="let user of users; let i = index">
            <li class="list-group-item d-flex">
              <app-user-avatar [username]="user.username" [avatarUrl]="(user && user.avatar)?user.avatar:null"
                class="mr-2"></app-user-avatar>
              {{user.username}} <small *ngIf="!dms[user.id]" class="text-danger ml-2">you can not dm this user</small>
              <button class="btn btn-sm btn-danger ml-auto text-white" (click)="remove(user.id)">
                <i class="fas fa-times"></i>
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-success" [disabled]="!canCreate" (click)="createChannel()">{{(users.length <
      2)?'Start Direct Chat':'Start Group Chat'}}</button>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="user-div">
    <div class="float-left mr-1">
      <app-user-avatar [username]="r.username" [avatarUrl]="(r.username && r.avatar)?r.avatar:null"></app-user-avatar>
    </div>
    <ngb-highlight [result]="r.username" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<ng-template #empty>
  <div class="text-center pt-4 p-1  text-muted">
    Select a member to send a <b>Direct Message</b> or<br /> add more members for a <b>Group Chat</b>.
  </div>
</ng-template>