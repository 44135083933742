import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as AES from 'crypto-js/aes.js';
import * as Utf8 from 'crypto-js/enc-utf8.js';
import { SecretFormComponent } from '../../chat/secret-form/secret-form.component';
import { MessageType } from '../../_shared/models/message/message-type';
import { Message } from './../../_shared/models/commons/message';
import { BaseService } from './base.service';
import { SmiliesService } from './smilies.service';
import { TeamService } from './team.service';

@Injectable({ providedIn: 'root' })
export class MessageTextFormatterService extends BaseService {
  autoChashtagsRegex = /(\b)(AMZN|NVDA|GOOG|AAPL|BABA|TSLA|SPY|MSFT|QQQ|EBAY|TWTR)(\b)/g;
  // mentionRegex = /\@[a-zA-Z0-9][a-zA-Z0-9_.-]*\b/g;
  // parsedMentionRegex = /{@([a-zA-Z0-9][a-zA-Z0-9_.-]*)}/g;
  acceptablePrefixes = [' ', '.', ','];
  urlRegex = /((https?:\/\/)|(www\.))[^\s]+\w(\/?)/gim;
  cashtagRegex = /\B(\$[a-z]+)/gi;

  // QUOTE regex
  firstQuoteRegex = /\<br\>(\&gt\;)(.*)/m;
  allQuoteRegex = /(\r)?\n(\&gt\;) \@(.*)\:(\r)?(((\r)?\n)(.*))+/gm;
  editQuoteRegex = /(\r)?\n\> \@(.*)\:(\r)?\n(.*)/m;

  htmlEncoder: HTMLTextAreaElement;

  markdownMap: { [key: string]: OpenClose } =
    {
      bold: {
        symbol: '\\*\\*',
        open: '<b>',
        close: '</b>',
        // regex: /\B((\*\*)[a-zA-Z0-9\s<>]+(\*\*))\B/g
        regex: /(\*\*)[^**]+(\*\*)/g
      },
      underline: {
        symbol: '__',
        open: '<span class="underline">',
        close: '</span>',
        // regex: /\b((__)[a-zA-Z0-9\s<>]+(__))\b/g
        regex: /(__)[^__]+(__)/g
      },
      italic: {
        symbol: '_',
        open: '<i>',
        close: '</i>',
        // regex: /\b(_[a-zA-Z0-9\s<>]+_)\b/g
        regex: /(_)[^_]+(_)/g
      },
      strikethrough: {
        symbol: '~~',
        open: '<del>',
        close: '</del>',
        // regex: /\B(~~[a-zA-Z0-9\s<>]+~~)\B/g
        regex: /(~~)[^~~]+(~~)/g
      },
      codenewline: {
        symbol: '```',
        open: '<code class="block">',
        close: '</code>',
        // regex: /\B(`[a-zA-Z0-9\s<>]+`)\B/g
        regex: /(`{3}).*(`{3})/g
      },
      code: {
        symbol: '`',
        open: '<code>',
        close: '</code>',
        // regex: /\B(`[a-zA-Z0-9\s<>]+`)\B/g
        regex: /(`)[^`]+(`)/g
      },
    };

  constructor(
    private teamService: TeamService,
    private smiliesService: SmiliesService,
    private modal: NgbModal
  ) {
    super({ log: false });
  }

  async getFormatter(text: string): Promise<Formatter> {

    const t = text || '';

    if (t.startsWith('/secret')) {
      const msg = await this.parseSecret(t);
      return msg;
    }

    if (t.startsWith('/shout')) {
      const msg = await this.parseShout(t);
      return msg;
    }

    return {
      text: t,
      type: MessageType.TEXT,
      secret: undefined
    };
  }

  parseMessageTextForDisplay(message: Message, params?: { term: string }): string {

    if (!message.text) { return null; }
    const text = this.decodeHTMLEntities(message.text);

    // parse
    const { urlsOutput, urls } = this.extractUrls(text);
    const { mentionsOutput, mentions } = this.extractMentions(urlsOutput, message.mentions as any);
    const { cashtagsOutput, cashtags } = this.extractCashtags(mentionsOutput);
    const { autoCashtagsOutput, autoCashtags } = this.extractAutoCashtags(cashtagsOutput);
    const { smiliesOutput, smilies } = this.smiliesService.extract(autoCashtagsOutput);
    let parsedText = smiliesOutput;

    parsedText = this.encodeHTMLEntities(parsedText);

    parsedText = this.applySpaces(parsedText);
    parsedText = this.applyTabs(parsedText);

    if (params && params.term) {
      this.log('Apply Highlight', params.term);
      parsedText = this.applyHighlight(parsedText, params.term);
    }

    // parsedText = this.formatTempQuotedText(parsedText);
    parsedText = this.replaceQuotedText(parsedText);

    // apply
    this.log('Apply Markdown');
    parsedText = this.applyMarkdown(parsedText);

    if (urls) {
      this.log('Apply Urls', urls);
      parsedText = this.applyUrls(parsedText, urls, params.term);
    }
    // if (params && params.term) {
    //   this.log('Apply Highlight', params.term);
    //   parsedText = this.applyHighlight(parsedText, params.term);
    // }
    if (autoCashtags) {
      this.log('Apply Auto Cashtags', autoCashtags);
      parsedText = this.applyAutoCashtags(parsedText, autoCashtags);
    }
    if (cashtags) {
      this.log('Apply Cashtags', cashtags);
      parsedText = this.applyCashtags(parsedText, cashtags);
    }
    if (mentions) {
      this.log('Apply Mentions', mentions);
      parsedText = this.applyMentions(parsedText, mentions, message.chatroom.teamId, params.term);
    }
    if (smilies) {
      this.log('Apply Smilies', smilies);
      parsedText = this.smiliesService.apply(parsedText, smilies);
    }

    parsedText = this.applyEveryone(parsedText, params.term);

    return parsedText;
  }

  applySpaces(text: string) {
    return text.replace(/([ ][ ]+)/g, (match) => {
      const correct = match.replace(/[ ]/g, '&nbsp;');
      if (match.length === 2) {
        return ` ${correct.substr(6)}`;
      }
      if (match.length > 2) {
        return ` ${correct.substr(6, correct.length - 12)} `;
      }
      return match;
    });
  }

  applyTabs(text: string) {
    return text.replace(/\t/g, '&nbsp;');
  }

  decodeHTMLEntities(text: string) {
    if (!this.htmlEncoder) {
      this.htmlEncoder = document.createElement('textarea');
    }

    this.htmlEncoder.innerHTML = text;
    return this.htmlEncoder.value;
  }

  encodeHTMLEntities(text) {
    if (!this.htmlEncoder) {
      this.htmlEncoder = document.createElement('textarea');
    }

    this.htmlEncoder.innerText = text;
    return this.htmlEncoder.innerHTML;
  }

  removeAllQuotes(text: string) {
    return text.replace(this.allQuoteRegex, '');
  }

  removeQuoteForEdit(text: string) {
    let editableText = text;
    let quote = '';
    let position = 0;

    const match = this.editQuoteRegex.exec(text);
    if (match) {
      position = match.index;
      editableText = text.substring(0, position);
      quote = text.substring(position).replace(/\n/g, '\r\n');
    }
    return { editableText, quote };
  }

  encrypt(text: string, password: string) {
    return AES.encrypt(text, password).toString();
  }

  decrypt(text: string, password: string) {
    try {
      return AES.decrypt(text, password).toString(Utf8);
    } catch (error) {
      return null;
    }
  }

  private replaceQuotedText(text: string) {
    this.log('TRACK QUOTE', text);
    return text.replace(this.firstQuoteRegex, (match) => {
      this.log('QUOTE MATCH', match);
      const replaceText = match.replace(/\<br\>(\&gt\;)/, '');
      return `<app-content-quote>${replaceText}</app-content-quote>`;
    });
  }

  private extractUrls(input: string) {
    const urls: string[] = [];
    const urlsOutput = input.replace(this.urlRegex, match => {
      urls.push(match);
      return '{url}';
    });
    return { urlsOutput, urls };
  }

  private extractMentions(input: string, msgMentions: { key: string, username: string, userId }[]) {
    const mentions = [];
    let mentionsOutput = input;
    if (!msgMentions || !msgMentions.length) return { mentionsOutput, mentions };

    const mentionsEscapedKeys = [];

    msgMentions.forEach(mention => {
      let escapedKey = mention.key.replace(/\./g, '\.');
      escapedKey = mention.key.replace(/\-/g, '\-');
      mentionsEscapedKeys.push({ escapedKey, mention });
    });

    const detectRegex = new RegExp(`(${mentionsEscapedKeys.map(c => c.escapedKey).join('|')})`, 'g');

    mentionsOutput = mentionsOutput.replace(detectRegex, match => {
      const mentionIndex = mentionsEscapedKeys.map(c => c.escapedKey).indexOf(match);
      const mention = mentionsEscapedKeys[mentionIndex].mention;
      mentions.push(mention);
      return '{mention}';
    });

    return { mentionsOutput, mentions };
  }

  private extractCashtags(input: string) {
    const cashtags: string[] = [];
    const cashtagsOutput = input.replace(this.cashtagRegex, match => {
      cashtags.push(match);
      return '{cashtag}';
    });
    return { cashtagsOutput, cashtags };
  }

  private extractAutoCashtags(input: string) {
    const autoCashtags: string[] = [];
    const autoCashtagsOutput = input.replace(this.autoChashtagsRegex, match => {
      autoCashtags.push(match);
      return '{autoCashtag}';
    });
    return { autoCashtagsOutput, autoCashtags };
  }

  private applyMarkdown(text: string): string {
    if (!text) { return text; }
    let ret = text;
    for (const key in this.markdownMap) {
      if (!this.markdownMap.hasOwnProperty(key)) { return; }
      const mapped = this.markdownMap[key];
      ret = ret.replace(mapped.regex, (match, b, c, index) => {
        const keyword = match.replace(new RegExp(`${mapped.symbol}`, 'g'), '');
        return `${mapped.open}${keyword}${mapped.close}`;
      });
    }
    return ret;
  }

  private applyUrls(text: string, urls: string[], searchTerm: string) {
    let ret = text;
    urls.forEach(url => {
      const urlToPut = url.startsWith('http') ? url : `http://${url}`;
      let viewUrl = url;
      if (searchTerm) {
        this.log('Apply Highlight in URL', searchTerm);
        viewUrl = this.applyHighlight(viewUrl, searchTerm);
      }
      ret = ret.replace(/\{url\}/, `<a target="_blank" href="${urlToPut}" class="externalUrl">${viewUrl}</a>`);
    });
    return ret;
  }

  private applyMentions(text: string, mentions: { key: string, username: string, userId }[], teamId, searchTerm: string) {
    let ret = text;
    mentions.forEach(mention => {
      if (!mention) return;
      ret = ret.replace('{mention}', `<app-content-mention user-id="${mention.userId || ''}" username="${mention.username}" team-id="${teamId || ''}" search-term="${searchTerm}"></app-content-mention>`);
    });
    return ret;
  }

  private applyEveryone(text: string, searchTerm: string) {
    let viewEveryone = '@everyone';
    if (searchTerm) {
      this.log('Apply Highlight in @everyone', searchTerm);
      viewEveryone = this.applyHighlight(viewEveryone, searchTerm);
    }
    return text.replace('@everyone', `<span class="everyoneMention">${viewEveryone}</span>`);
  }

  private applyCashtags(text: string, symbols: string[]): string {
    let ret = text;
    symbols.forEach((symbol) => {
      ret = ret.replace('{cashtag}', `<app-content-cashtag symbol="${symbol.toUpperCase().substr(1)}"></app-content-cashtag>`);
    });
    return ret;
  }

  private applyAutoCashtags(text: string, symbols: string[]): string {
    let ret = text;
    symbols.forEach((symbol) => {
      ret = ret.replace('{autoCashtag}', `<app-content-cashtag symbol="${symbol.toUpperCase()}"></app-content-cashtag>`);
    });
    return ret;
  }

  clearMarkdown(text: string): string {
    if (!text) { return text; }

    let parsedText = text;
    for (const key in this.markdownMap) {
      if (!this.markdownMap.hasOwnProperty(key)) { return; }
      const mapped = this.markdownMap[key];
      parsedText = parsedText.replace(mapped.regex, (match, b, c, index) => {
        return match.replace(new RegExp(`${mapped.symbol}`, 'g'), '');
      });
    }
    return parsedText;
  }

  public async getSecretInput(title: string) {

    const modal = this.modal.open(SecretFormComponent, {
      centered: true,
      windowClass: 'modal-dark',
      backdrop: 'static',
      container: 'body'
    });
    modal.componentInstance.title = title;
    const res = await modal.result
      .catch(rej => {
        return {
          title: null,
          secret: null,
          encryptKey: null
        };
      });
    return res;
  }

  private async parseSecret(text: string) {
    const parts = text.split(' ');
    const initialTitle = parts.slice(1).join(' '); // messageParts.join(' ');

    const { title, secret, encryptKey } = await this.getSecretInput(initialTitle);

    if (!title && !secret && !encryptKey) {
      return null;
    }

    const encryptedText = this.encrypt(secret, encryptKey);
    return {
      type: MessageType.SECRET,
      text: title,
      secret: encryptedText
    };
  }

  private async parseShout(text: string) {
    const parts = text.split(' ');
    const messageParts = parts.slice(1);
    const clearText = messageParts.join(' ');
    return {
      type: MessageType.SHOUT,
      text: clearText,
      secret: undefined
    };
  }

  applyHighlight(text: string, searchtext: string) {
    const searcharr = searchtext.split(' ');
    let returnedText = text;
    searcharr.forEach(element => {
      returnedText = returnedText.replace(new RegExp(element, 'ig'), (a, b) => {
        return `<mark>${a}</mark>`;
      });
    });
    return returnedText;
  }
}

interface OpenClose {
  symbol: string;
  open: string;
  close: string;
  regex: RegExp;
}

interface Formatter {
  type: MessageType;
  text: string;
  secret: string;
}
