<div class="error alert alert-danger" *ngIf="error">{{error}}</div>
<form #stripeCard novalidate (ngSubmit)="authorize()" [formGroup]="paymentForm" class="form"
  [ngClass]="{'loading':loading}">
  <div class="form-group">
    <input formControlName="name" class="form-control" placeholder="Name as it appears on card">
    <ng-container *ngIf="isFieldValid('name')">
      <small *ngIf="paymentForm.controls.name.errors.required" class="text-danger">NAME REQUIRED</small>
    </ng-container>
  </div>
  <!-- <div class="form-group">
    <input formControlName="street" class="form-control" placeholder="Street Address">
    <ng-container *ngIf="isFieldValid('street')">
      <small *ngIf="paymentForm.controls.street.errors.required" class="text-danger">street required</small>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-sm">
      <div class="form-group">
        <input formControlName="postalCode" class="form-control" placeholder="Postcode">
        <ng-container *ngIf="isFieldValid('postalCode')">
          <small *ngIf="paymentForm.controls.postalCode.errors.pattern" class="text-danger">only numbers</small>
          <small *ngIf="paymentForm.controls.postalCode.errors.required" class="text-danger">postcode is
            required</small>
        </ng-container>
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <input formControlName="city" class="form-control" placeholder="City">
        <ng-container *ngIf="isFieldValid('city')">
          <small *ngIf="paymentForm.controls.city.errors.required" class="text-danger">city required</small>
        </ng-container>
      </div>
    </div>
  </div> -->
  <!-- <div class="form-group">
    <select formControlName="country" class="form-control">
      <option class="disabled" value="" disabled selected>Country</option>
      <option *ngFor="let c of countries" [value]="c.code">{{c.name}}</option>
    </select>
    <ng-container *ngIf="isFieldValid('country')">
      <small *ngIf="paymentForm.controls.country.errors.required" class="text-danger">country required</small>
    </ng-container>
  </div> -->
  <div #card class="card mb-4"></div>
  <button class="btn btn-success btn-block" type="submit">Authorize Payment</button>
</form>