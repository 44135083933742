import { Component, HostBinding, Input, NgZone, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { Message } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { MentionedMessagesService } from './services/mentioned-messages.service';
import { BaseComponent } from '../base-component';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';

@Component({
  selector: 'app-panel-mentioned-messages',
  templateUrl: './panel-mentioned-messages.component.html',
  styleUrls: ['./panel-mentioned-messages.component.scss']
})
export class PanelMentionedMessagesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @ViewChild('timeline') timeline: TimelineComponent;
  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  initialMessages: Message[];

  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.MentionedMessages;

  canPin: boolean;

  constructor(
    private panelsService: PanelsService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private teamService: TeamService,
    private mentionedMessagesService: MentionedMessagesService,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  get hasMoreMessages() {
    return (this.panel && this.panel.id) ? this.messageService.hasMoreMessages[this.panel.id] : true;
  }

  async ngOnInit() {
    console.log('mention Init', this.panel.id);
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    this.panelsService.panelTitle$.next([this.panel.id, 'Mentions']);

    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.registerEvents();

    await this.mentionedMessagesService.loadMentionedMessages(this.panel.id, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  registerEvents() {
    this.subscribe(this.messageService.messageReceived$, (message: Message) => {
      this.onMentionMessageReceived(message);
    });

    this.subscribe(this.messageService.messageReplacementReceived$, message => this.processReplacementMessage(message.replace as Message));
    this.subscribe(this.messageService.messageDeleted$, messageDeleted => this.deleteMessage(messageDeleted.messageId));
  }

  onMentionMessageReceived(message: Message) {
    if (
      (message.mentions && message.mentions.filter(m => m.userId === this.profileService.me.id).length > 0) ||
      (message.text && message.text.toLowerCase().indexOf('@everyone') > -1)
    ) {
      // mention still valid
      this.messageService.messages[this.panel.id] = MessageHelpers.sortMessages([...this.messageService.messages[this.panel.id].filter(m => m.id !== message.id), message]);
      this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    } else {
      // mention invalid, remove message
      this.deleteMessage(message.id);
    }
  }

  processReplacementMessage(message: Message) {
    let found = false;
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === message.id);
      if (findMessage.length > 0) {
        found = true;
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== message.id);
        this.messageService.messages[this.panel.id].push(message);
        this.messageService.messages[this.panel.id] = MessageHelpers.organizeBookmarkMessages(this.messageService.messages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
    // in this case maybe it may be a new message where the sender is the current user
    // it then emits a replace message event and not a received message event, so we need
    // to reroute to the receiving function
    if (!found) {
      this.onMentionMessageReceived(message);
    }
  }

  deleteMessage(messageId: string) {
    if (messageId === null || messageId === undefined) {
      return;
    }
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === messageId);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== messageId);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }
}
