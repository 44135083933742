import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { TeamService } from '../../_core/services/team.service';
import { TrackingService } from '../../_core/services/tracking.service';
import { MessageType } from '../../_shared/models/message/message-type';

@Component({
  selector: 'app-message-share',
  templateUrl: './message-share.component.html',
  styleUrls: ['./message-share.component.scss']
})
export class MessageShareComponent implements AfterViewInit {

  @Input() messageId;
  @Input() messageType;

  loading = true;

  @ViewChild('messageUrl', { static: false }) messageUrl: ElementRef;
  error: any = null;

  messageTypEnum: MessageType;
  errorMessage: string;

  deactiveLink;

  thumbUrl;

  get teamname() {
    return this.teamService.activeTeam?.name;
  }

  get teamlabel() {
    return (this.teamService.activeTeam?.label && this.teamService.activeTeam?.label.length > 0) ? this.teamService.activeTeam?.label : this.teamService.activeTeam?.name;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    private tracking: TrackingService,
    private teamService: TeamService,
    private cd: ChangeDetectorRef
  ) { }

  async ngAfterViewInit() {
    this.deactiveLink = this.sanitizer.bypassSecurityTrustUrl('javascript:void(0)');

    this.error = null;
    if (this.messageType === MessageType.SECRET) {
      this.errorMessage = 'Secret messages cannot be shared!';
      this.loading = false;
      this.cd.detectChanges();
      return;
    }
    await this.http
      .get<any>(`${environment.config.endpoints['share']}/share/generate/${this.messageId}`)
      .toPromise()
      .then((resp) => {
        (this.messageUrl.nativeElement as HTMLInputElement).value = resp.link;

        const parts = resp.link.split('/').splice(2);
        const id = parts.pop();
        const l = parts.pop();
        if (l !== 'url') parts.push(l);
        this.thumbUrl = `https://${parts.join('/')}/thumb/${id}`;
      })
      .catch((err) => {
        this.error = err.error.message;
      })
      .finally(() => {
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  preventChange() {
    return false;
  }

  copyLink() {
    this.track('copy');
    (this.messageUrl.nativeElement as HTMLInputElement).select();
    (this.messageUrl.nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
  }

  track(target: string) {
    this.tracking.trackEvent('share-action', {
      target,
      messageid: this.messageId,
      teamname: this.teamname
    });
  }
}
