import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FilestackPolicyServiceApi } from '@echofin/libraries';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { FilestackService } from '@filestack/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaded } from '../../_shared/models/commons/filestack.models';
import { ToastrService } from 'ngx-toastr';
import { DateService } from '../../_core/services/date.service';
import { FileUploadService } from '../../_core/services/file-upload.service';
import { MessageTextFormatterService } from '../../_core/services/message-text-formatter.service';
import { MAX_CHARS, MAX_CHARS_THRESHOLD, MAX_EDIT_TIMESPAN, MessageService } from '../../_core/services/message.service';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';
import { RULE } from '../../_shared/models/commons/permissions.constants';
import { ItemType } from '../../_shared/models/room/channel';
import { BaseComponent } from '../base-component';
import { EmojiService } from '../../_core/services/emoji.service';
import { DirectLabelPipe } from '../../_shared/pipes/direct-channel-members.pipe';

@Component({
  selector: 'app-multiline-modal',
  templateUrl: './multiline-modal.component.html',
  styleUrls: ['./multiline-modal.component.scss']
})
export class MultilineModalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() chatroomId: string;
  @Input() chatroomName: string;
  @Input() messageText: string = null;
  @Input() participants: { userId: string, user: { username: string } }[];

  @Input() okBtnLabel = 'Send message';
  title = 'Compose message';
  editTitle = 'Edit message';
  modalTitle: string;

  @Input() files: FileAttachment[];
  @Input() fileToUpload: File | Blob;

  @Input() isReplyPanel = false;

  @Input() isTeamChatroom: boolean;

  textToEdit = '';
  quote = '';

  @Input() senderId: string;
  @Input() messageTimestamp: Date;
  timeLeft: number;
  updateInterval: any;

  @ViewChild('text') text: ElementRef;

  loading = false;
  textField = new FormControl(null, [Validators.required]);
  chatrooms: { id: string, name: string, control: FormControl }[] = [];
  submitted = false;
  charsLeft: number = null;
  messageLength = 0;
  rules = RULE;

  srcImgWithSecurity: string;
  uploading = false;
  uploadImagePreview: string;
  uploadPercent = 0;
  cancelUploadToken = {};

  caretInfo: { start: number, end: number };

  textEmpty = true;

  get isAdmin() {
    if (this.senderId === this.profileService.me.id &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      return true;
    }
    return false;
  }

  get canEdit() {
    if (this.isAdmin && this.chatroomId.indexOf('chr_') === 0) {
      return true;
    }
    return this.timeLeft < MAX_EDIT_TIMESPAN;
  }

  get canEditMessage() {
    if (this.isAdmin && this.chatroomId.indexOf('chr_') === 0) {
      return '';
    }

    if (this.canEdit) {
      const timespan = this.dateService.fromTimespan(MAX_EDIT_TIMESPAN - this.timeLeft);
      return `${timespan.mins}:${timespan.seconds.toString().padStart(2, '0')} left to edit`;
    }

    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    return 'You can only edit a message for 5 minutes after posting it';
  }

  get isInReplyMode(): boolean {
    if (this.isReplyPanel) {
      return true;
    }
    return !!this.messageService.isInReplies[this.chatroomId];
  }

  constructor(
    private activeModal: NgbActiveModal,
    private dateService: DateService,
    private teamService: TeamService,
    private messageService: MessageService,
    private profileService: ProfileService,
    private mtf: MessageTextFormatterService,
    private filestack: FilestackService,
    private toastr: ToastrService,
    private fsApi: FilestackPolicyServiceApi,
    private uploadService: FileUploadService,
    private emojiService: EmojiService,
    private directLabelPipe: DirectLabelPipe,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {

    if (this.fileToUpload) {
      this.previewImage();
    } else {
      await this.prepareImgPreview(this.files);
    }

    if (this.fileToUpload) {
      this.beginUploading();
    }

    this.subscribe(this.textField.valueChanges, () => {
      this.textEmpty = this.textField.value?.trim() === '';
      this.cd.detectChanges();
    });

    if (this.messageTimestamp) {
      this.timeLeft = Date.now() - this.messageTimestamp.getTime();
      this.updateInterval = setInterval(
        () => {
          this.timeLeft = Date.now() - this.messageTimestamp.getTime();
        },
        1000);
    }

    this.textToEdit = this.mtf.decodeHTMLEntities(this.messageText);
    const { editableText, quote } = this.mtf.removeQuoteForEdit(this.textToEdit);
    this.textToEdit = editableText;
    this.quote = quote;
    this.textField.patchValue(this.textToEdit);
    if (!this.messageTimestamp) {
      this.addCheckboxes();
    }

    // initial calculate when text already typed
    this.typing();

    this.subscribe(this.messageService.insertEmojiInMultiline$, (emojiData) => {
      if (this.chatroomId === emojiData.panelId) {
        const prev: string = this.text.nativeElement.value;
        let current = '';
        if (this.caretInfo) {
          current = prev.slice(0, this.caretInfo.start) + emojiData.emoji + prev.slice(this.caretInfo.end);
        } else {
          current = prev + emojiData.emoji;
        }
        this.textField.patchValue(current);
        const newCaretPosition = this.caretInfo ? this.caretInfo.start + emojiData.emoji.length : current.length;
        this.caretInfo = null;
        setTimeout(() => {
          this.text.nativeElement.focus({ preventScroll: true });
          this.text.nativeElement.selectionStart = newCaretPosition;
          this.text.nativeElement.selectionEnd = newCaretPosition;
        }, 100);
      }
    });

    if (this.isReplyPanel) {
      this.modalTitle = `<i class="fas fa-reply"></i>Thread on #${this.chatroomName}`;
    } else if (this.isTeamChatroom) {
      this.modalTitle = `<i class="fas fa-hashtag"></i>${this.chatroomName}`;
    } else if (this.participants) {
      const participantsCount = this.participants.filter(m => m.userId !== this.profileService.me.id).length;
      const directName = this.createName();
      if (participantsCount == 1) {
        this.modalTitle = `<i class="far fa-at"></i>${directName}`;
      } else {
        this.modalTitle = `<i class="fad fa-comments-alt"></i>${directName}`;
      }
    }

    if (this.messageTimestamp) {
      this.modalTitle += ` - ${this.editTitle}`;
    } else {
      this.modalTitle += ` - ${this.title}`;
    }
  }

  toggleEmojis(event) {
    this.emojiService.openMultilinePicker(event.target, this.chatroomId);
  }

  inputFocus($event) {
    this.caretInfo = null;
  }

  inputBlur($event) {
    this.caretInfo = { start: this.text.nativeElement.selectionStart, end: this.text.nativeElement.selectionEnd };
  }

  private beginUploading() {
    if (this.fileToUpload.size > 100 * 1024 * 1024) {
      this.toastr.error('File is too big. The accepted file size is less than 100MB.');
      this.clearFiles();
      return;
    }
    this.uploading = true;
    this.uploadService.uploadFilesImmediately(this.fileToUpload, this.cancelUploadToken, (evt) => { this.onUploadProgress(evt); }).toPromise()
      .then(async (file: FileUploaded) => {
        const attachments = [{
          filename: file.filename,
          mime: file.mimetype,
          url: file.url,
          handle: file.handle,
        }];
        this.files = attachments;
      })
      .catch(err => {
        this.clearFiles();
        this.toastr.error('Could not upload file.');
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  onUploadProgress(evt) {
    this.uploadPercent = evt.totalPercent;
  }

  previewImage() {
    if (this.fileToUpload.type.indexOf('image/') < 0) {
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(this.fileToUpload);

    reader.onload = () => {
      this.uploadImagePreview = reader.result as string;
    };
  }

  private addCheckboxes() {
    const chatrooms = this.teamService.activeTeam.sidebarResolved.filter(s => ItemType[s.itemType] === ItemType.Channel && s.permissions.user && s.permissions.user[this.rules.POST_MESSAGES]);
    chatrooms.forEach(c => {
      this.chatrooms.push({ id: c.refId, name: c.label, control: new FormControl(this.chatroomId === c.refId) }); // true on current chatroom
    });
  }

  ngAfterViewInit() {
    setTimeout(
      () => {
        this.text.nativeElement.focus();
      },
      100);
  }

  send() {
    if ((this.fileToUpload && this.uploading) || (this.charsLeft !== null && this.charsLeft < 0) || (this.messageTimestamp && !this.canEdit)) {
      return;
    }
    if (this.textField.value?.trim() === '/shout') {
      return;
    }
    this.submitted = true;
    if (this.textField.valid || (this.files && this.files[0])) {
      const selectedChatroomIds = this.chatrooms
        .map(c => c && c.control && c.control.value ? c.id : null)
        .filter(v => v !== null);

      // must select a chatroom, unless it's in edit or reply mode
      if (selectedChatroomIds.length === 0 && !this.messageTimestamp && this.isTeamChatroom && !this.isReplyPanel) {
        this.toastr.error('Please select at least one chatroom', null, { onActivateTick: true });
        return;
      }

      const text = `${this.textField.value.replace(/\n/g, '\r\n')}${this.quote}`;
      this.activeModal.close({ text, files: this.files, chatrooms: selectedChatroomIds });
    }
  }

  typing() {
    this.messageLength = this.textField.value ? (this.textField.value.trim().length + ((this.textField.value || '').match(/\n/g) || []).length) : 0;

    const charsLeft = MAX_CHARS - this.messageLength;
    this.charsLeft = charsLeft <= MAX_CHARS_THRESHOLD ? charsLeft : null;
  }

  async onKeyDown(e) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      await this.send();
    }
  }

  async importFile(event) {
    if (this.messageTimestamp) {
      return;
    }

    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return
    }
    this.fileToUpload = event.target.files[0];

    this.previewImage();
    this.beginUploading();
  }

  clearFiles() {
    this.fileToUpload = null;
    this.uploading = false;
    this.uploadPercent = 0;
    this.uploadImagePreview = null;
    this.files = null;
    this.srcImgWithSecurity = null;
  }

  async prepareImgPreview(files: FileAttachment[]) {
    if (files && files[0] && files[0].mime.indexOf('image') === 0) {
      const pickerPolicy = await this.fsApi.GetPickerPolicy().toPromise();
      this.srcImgWithSecurity = this.filestack.transform(files[0].url, {
        security: {
          policy: pickerPolicy.policy,
          signature: pickerPolicy.signature
        }
      });
    }
  }

  createName() {
    return this.directLabelPipe.transform({ name: this.chatroomName, participants: this.participants }, this.profileService.me.id);
  }

  closeModal() {
    if (this.cancelUploadToken && this.cancelUploadToken['cancel']) {
      this.cancelUploadToken['cancel']();
    }
    this.activeModal.dismiss('cancel');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }
}
