import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DefaultAvatarService {
  svgCode: string;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }

  async getDefaultAvatar(username: string): Promise<SafeResourceUrl> {
    if (!username || !username.length || username.length < 2) { return; }
    const initials = username.substr(0, 2);
    let svgCode = await this.getSvgCode();
    if (svgCode) {
      svgCode = svgCode.replace('{RE}', initials.toUpperCase());
      const color = this.getRandomColor(username);
      svgCode = svgCode.replace('{COLOR}', color.toRgbString());
    }
    const url = `data:image/svg+xml,${svgCode}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async  getSvgCode() {
    if (!this.svgCode) {
      this.svgCode = await this.http
        .get('/assets/default-avatar.svg', { responseType: 'text' })
        .toPromise()
        .then((res) => {
          return res.replace(/\r?\n|\r/g, '');
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    }
    return this.svgCode;
  }

  public getRandomColor(seed: string): Color {
    if (!seed) { throw new Error('Seed cannot be null'); }

    const hashHex = this.hashFnv32a(seed, seed.length + 15);
    const hashForColor = hashHex.substr(hashHex.length - 6, 6);
    const bytes = this.stringToByteArray(hashForColor);

    const color = this.balanceColor(bytes);
    return color.ensureDistantValues();
  }

  private balanceColor(bytes: any[]) {
    const red = Math.max(Math.min(bytes[0] / 255, 0.9), 0.6);
    const green = Math.max(Math.min(bytes[1] / 255, 0.9), 0.3);
    const blue = Math.max(Math.min(bytes[2] / 255, 0.9), 0.4);
    return new Color(red, green, blue);
  }

  private stringToByteArray(hex: string): any[] {
    return [
      parseInt(hex.substr(0, 2), 16) % 255,
      parseInt(hex.substr(2, 4), 16) % 255,
      parseInt(hex.substr(4), 16) % 255,
    ];
  }

  private hashFnv32a(str: string, seed: number): string {
    let i;
    let l;
    let hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return (`0000000${(hval >>> 0).toString(16)}`).substr(-8);
  }

}

class Color {
  private minDistance: number = 0.1;
  private correctionStep: number = 0.05;

  red: number;
  green: number;
  blue: number;

  constructor(red: number, green: number, blue: number) {
    this.red = red;
    this.green = green;
    this.blue = blue;
  }

  public toRgbString(): string {
    const red = this.red * 255;
    const green = this.green * 255;
    const blue = this.blue * 255;

    return `rgb(${red},${green},${blue})`;
  }

  toHSL() {
    const hue = 360 * Math.random();
    const saturation = (25 + 70 * Math.random());
    const lightness = 85 + 10 * Math.random();
    const cssHSL = `hsl( ${hue}, ${saturation}%, ${lightness}%)`;
    return cssHSL;
  }

  public ensureDistantValues(): Color {
    let red = this.red;
    const green = this.green;
    let blue = this.blue;

    let farEnough = this.areValuesDistantEnough(red, green, blue);
    let iterations = 0;
    while (!farEnough) {
      const step = this.getCorrectionStep(red, blue);
      red += step;
      blue -= step;
      farEnough = this.areValuesDistantEnough(red, green, blue);

      if (iterations++ > 5) { break; }
    }

    return new Color(red, green, blue);
  }

  private getCorrectionStep(red: number, blue: number): number {
    return (red > blue) ? this.correctionStep : -this.correctionStep;
  }

  private areValuesDistantEnough(red: number, green: number, blue: number): boolean {

    return Math.abs(red - green) > this.minDistance
      && Math.abs(red - blue) > this.minDistance
      && Math.abs(green - blue) > this.minDistance;
  }
}
