<div class="mt-5 pb-5">
    <div>
        <p class="pr-2 pl-2 text-center">Open in the Echofin mobile app</p>
        <div class="text-center">

            <button type="button" (click)="goToApp()" class="btn btn-primary"
                style="padding-left: 2rem; padding-right: 2rem;">Launch mobile
                app</button>
        </div>
    </div>
</div>

<hr>
<div *ngIf="me" class="text-center text-primary">
    <a href="javascript:void(0)" (click)="logout()">
        <i class="fal fa-sign-out-alt fa-fw"></i> Logout
    </a>
</div>

<!-- FOR DEBUG PURPOSES ONLY <p style="text-align: center;">The url should go to {{action.toUpperCase()}} with extra data:
    {{extraData?(extraData|json):'-'}}</p> -->