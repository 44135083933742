import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FocusService {
  // isFocused: boolean;
  // focusChanged$: Subject<boolean> = new Subject<boolean>();
  // constructor() {
  //   this.isFocused = this.checkHasfocus();
  //   this.registerEvents();
  // }

  // get nativeWindow(): any {
  //   return window;
  // }
  // checkHasfocus():boolean {
  //   return window.document.hasFocus();
  // }
  // registerEvents() {
  //   this.log('REGISTER EVENTS');
  //   this.nativeWindow.addEventListener('focus', () => {
  //     this.log('focus event');
  //     this.setFocused();
  //   });
  //   this.nativeWindow.addEventListener('blur', () => {
  //     this.log('blur event');
  //     this.setBlur();
  //   });
  // }
  // setFocused(): void {
  //   this.isFocused = true;
  //   this.focusChanged$.next(this.isFocused);
  // }
  // setBlur(): void {
  //   this.isFocused = false;
  //   this.focusChanged$.next(this.isFocused);
  // }
  // log(...arg) {
  //   /* istanbul ignore if  */
  //   if (environment.config.debug) {
  //     console.log('%c[FOCUS SERVICE]', 'color:#BF9800', ...arg);
  //   }
  // }
}
