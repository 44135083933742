import { PermissionItem } from './permission-item.model';

export enum RULE {
  TEAM_ACCESS = 'team_access',
  DASHBOARD_ACCESS = 'dashboard_access',
  BILLING_ACCESS = 'billing_access',
  READ_MESSAGES = 'read_messages',
  POST_MESSAGES = 'post_messages',
  UPLOAD_FILES = 'upload_files',
  POST_LINKS = 'post_links',
  POST_SIGNALS = 'post_signals',
  POST_SHOUTS = 'post_shouts',
  POST_SECRETS = 'post_secrets',
  POST_MARKET_ANALYSIS = 'post_market_analysis',
  PINBOARD = 'pinboard',
  BAN_USERS = 'ban_users',
  INIT_DM_WITH_NON_ADMIN = 'init_dm_with_non_admin',
  DELETE_ALL_MESSAGES = 'delete_all_messages',
}

export const CHATROOM_RULEGROUPS = [{
}, {
  label: 'Messaging',
  rules: [
    RULE.READ_MESSAGES,
    RULE.POST_MESSAGES,
    RULE.UPLOAD_FILES,
    RULE.POST_LINKS,
    RULE.POST_SIGNALS,
    RULE.POST_SHOUTS,
    RULE.POST_SECRETS,
    RULE.POST_MARKET_ANALYSIS,
  ],
}, {
  label: 'Moderation',
  rules: [
    RULE.DELETE_ALL_MESSAGES,
  ],
}];

export const FRAME_RULEGROUPS = [{
}, {
  label: 'Access',
  rules: [],
}];

export const ROLE_RULEGROUPS = [{
  label: 'Team Management',
  rules: [
    RULE.DASHBOARD_ACCESS,
  ],
}, {
  label: 'Messaging',
  rules: [
    RULE.READ_MESSAGES,
    RULE.POST_MESSAGES,
    RULE.UPLOAD_FILES,
    RULE.POST_LINKS,
    RULE.POST_SIGNALS,
    RULE.POST_SHOUTS,
    RULE.POST_SECRETS,
    RULE.POST_MARKET_ANALYSIS,
    RULE.PINBOARD,
  ],
}, {
  label: 'Moderation',
  rules: [
    RULE.DELETE_ALL_MESSAGES,
    RULE.BAN_USERS,
  ],
}, {
  label: 'Team config',
  rules: [
    RULE.INIT_DM_WITH_NON_ADMIN,
  ],
}];

export const RULE_DESCRIPTIONS: PermissionItem[] = [
  { key: RULE.TEAM_ACCESS, label: 'Team access', description: 'lorem ispum dolor sit amet' },
  { key: RULE.DASHBOARD_ACCESS, label: 'Dashboard access', description: 'lorem ispum dolor sit amet' },
  { key: RULE.BILLING_ACCESS, label: 'Billing access', description: 'lorem ispum dolor sit amet' },
  { key: RULE.READ_MESSAGES, label: 'Read messages', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_MESSAGES, label: 'Post messages', description: 'lorem ispum dolor sit amet' },
  { key: RULE.UPLOAD_FILES, label: 'Upload files', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_LINKS, label: 'Post links', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_SIGNALS, label: 'Post signals', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_SHOUTS, label: 'Post shouts', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_SECRETS, label: 'post_secrets', description: 'lorem ispum dolor sit amet' },
  { key: RULE.POST_MARKET_ANALYSIS, label: 'post_market_analysis', description: 'lorem ispum dolor sit amet' },
  { key: RULE.PINBOARD, label: 'Pinboard', description: 'lorem ispum dolor sit amet' },
  { key: RULE.DELETE_ALL_MESSAGES, label: 'Delete all messages', description: 'lorem ispum dolor sit amet' },
  { key: RULE.BAN_USERS, label: 'Ban users', description: 'lorem ispum dolor sit amet' },
  { key: RULE.INIT_DM_WITH_NON_ADMIN, label: 'Init dm with non admin', description: 'lore ispum dolor sit amet' },
];
