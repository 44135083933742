import { Injectable } from '@angular/core';
import { TeamTeamsServiceApi } from '@echofin/libraries';
import { Branding } from '@echofin/libraries/api/team/models/branding';
import { environment } from '../../../environments/environment';
import { ColorStyle } from '../../_shared/models/commons/branding';
import { Team } from '../../_shared/models/teams/team';
import { TeamBaseResp } from '@echofin/libraries/api/team/models/team-base-resp';

export const DEFAULT_BRANDING: Branding = {
  backgroundUrl: 'https://assets.echofin.com/app/cover/newbg.jpg',
  showLogo: true,
  logoUrl: 'https://assets.echofin.com/logos/logo_white_md.png',
  colorCode: '#f99500'
};

@Injectable({
  providedIn: 'root',
})
export class BrandingService {

  team: Team;

  constructor(
    private teamApi: TeamTeamsServiceApi
  ) { }

  async loadBranding() {

    if (this.isCustomDomain()) {
      this.log('Config fetched for custom host');
      this.team = await this.searchTeam(window.location.host);
      return;
    }

    const prefix = this.team && this.team.customDomain ? '' : 'app';
    const teamName = window.location.host.replace(`.${environment.config.domain}`, '');

    // app subdomain case
    if ((window.location.host === `${prefix}.${environment.config.domain}` && prefix === 'app') ||
      // mobile subdomain case
      (window.location.host === `${teamName}.${environment.config.domain}` && teamName === 'mobile') ||
      // custom domain case
      (window.location.host === `${environment.config.domain}` && prefix === '')) {
      this.team = {
        name: prefix,
        label: 'Echofin',
        config: {
          branding: DEFAULT_BRANDING
        }
      };
      this.log('Base host config selected', this.team);
      return;
    }

    this.team = await this.searchTeam(teamName);

    if (!this.team) {
      return 'TEAM_NOT_FOUND';
    }
  }

  async getCustomDomain() {
    const host = window.location.host;
    if (host === 'echo.local:4200') {
      return window.location.host;
    } {
      const team = await this.searchTeam(host);
      return team.customDomain;
    }
  }

  async searchTeam(term): Promise<TeamBaseResp> {
    return await this.teamApi.GetTeamBranding(term).toPromise()
      .then((team) => {
        this.log(`team fetched for ${term}`, team);
        team.config.branding = team.config.branding || DEFAULT_BRANDING;
        return team;
      })
      .catch((err) => {
        return null;
      });
  }

  isCustomDomain() {
    const host = window.location.host;
    return !host.endsWith(environment.config.domain);
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[BRANDING]', 'color:#960088', ...arg);
    }
  }
}
