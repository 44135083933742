<div *ngIf="hasLoaded" class="frame-wrapper" #frameWrapper>
  <div *ngIf="dragging" class="drag-target"></div>

  <ng-container *ngIf="!frame['hasAccess']">
    <div class="widget-lock">
      <div class="widget-lock-box">
        <i class="fal fa-lock" style="font-size: 3rem;color: #aaa;margin-bottom: 1rem;"></i>
        <h4>Service locked</h4>
        <div>Please join any of the following chatrooms to unlock the Service.</div>
        <div class="text-center">{{unlockChatrooms}}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="frame['hasAccess']">
    <iframe #iframe [id]="frame.id" [src]="frame.url|safe" (load)="onLoad($event)"></iframe>
  </ng-container>

</div>