import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export enum ERROR_CODE {
  DEFAULT = 'default',
  OFFLINE = 'offline',
  LIGHTSTREAMER_CAN_NOT_CONNECT = 'lightstreamer_can_not_connect',
}

export const ERROR_DESCR = {
  default: 'An unknown error was occured. Sorry! 😥',
  offline: 'Could not connect to echofin. Please check your internet connection',
  lightstreamer_can_not_connect: `
    The connection with message server was failed.
    Maximum number of retries was made.
    Please check your internet connection.`,
};

@Component({
  selector: 'chat-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {

  errorCode: string;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private elRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.hideLoader();
  }

  hideLoader() {
    const loader = this.elRef.nativeElement.ownerDocument.querySelector('.root-loader');
    if (loader) {
      loader.style.display = 'none'; // hide loader
    }
  }

  ngOnInit() {
    this.errorCode = this.route.snapshot.queryParams['err'] || ERROR_CODE.DEFAULT;
    this.errorMessage = ERROR_DESCR[this.errorCode];
  }

}
