import { Injectable } from '@angular/core';
import { Events, TeamProfilesServiceApi, TeamTeamsServiceApi } from '@echofin/libraries';
import { MessageResp } from '@echofin/libraries/api/message/models/message-resp';
import { Subject } from 'rxjs';
import { ChannelChange } from '../../_shared/models/commons/channel-change';
import { LocalMessageProcessed } from '../../_shared/models/commons/message';
import { RoomType } from '../../_shared/models/enums/room-type';
import { PanelType } from '../../_shared/models/room/channel';
import { Chatroom, DirectRoom } from '../../_shared/models/room/room';
import { PanelsService } from './panels.service';
import { ProfileService } from './profile.service';
import { EvType } from './socket.service/models/all.models';
import { SocketService } from './socket.service/socket.service';
import { ChatroomHandlers } from './state-handlers/chatroom.handlers';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  highLightedRoom: string;

  directs: DirectRoom[] = [];

  teamRoomAdded$: Subject<Chatroom> = new Subject<Chatroom>();
  teamRoomUpdated$: Subject<Chatroom> = new Subject<Chatroom>();
  teamRoomRemoved$: Subject<any> = new Subject<any>();

  directCreated$: Subject<DirectRoom> = new Subject<DirectRoom>();
  directUpdated$: Subject<DirectRoom> = new Subject<DirectRoom>();

  channelChange$: Subject<ChannelChange> = new Subject<ChannelChange>();

  participantJoined$: Subject<Events.ParticipantJoined> = new Subject<Events.ParticipantJoined>();
  participantRemoved$: Subject<Events.ParticipantRemoved> = new Subject<Events.ParticipantRemoved>();

  constructor(
    private profileService: ProfileService,
    private socketService: SocketService,
    private teamService: TeamService,
    private panelsService: PanelsService,
    private teamProfileApi: TeamProfilesServiceApi,
    private teamApi: TeamTeamsServiceApi
  ) { }

  setup() {
    this.setupRoomSubs();
  }

  private setupRoomSubs() {

    this.directCreated$
      .subscribe((room: DirectRoom) => {
        this.profileService.addUsers(room.participants.map(p => p.user));
      });

    // this.socketService
    //   .getStream(EvType.TeamRoomCreated)
    //   .subscribe((data: Events.TeamRoomCreated) => {
    //     const chatroom = ChatroomHandlers.handleTeamRoomCreated(data, this.teamService.teams);

    //     const team = this.teamService.teams.find(x => x.id === chatroom.teamId);
    //     if (!team) {
    //       return;
    //     }
    //     this.teamService.parseSidebar(team);

    //     this.teamRoomAdded$.next(chatroom);
    //   });

    this.socketService
      .getStream(EvType.RoomUpdated)
      .subscribe((data: Events.RoomUpdated) => {

        const room = this.getRoomById(data.chatroom.id);

        if (room) {
          Object.assign(room, {
            name: data.chatroom.name,
            color: data.chatroom.color,
            description: data.chatroom.description,
            topic: data.chatroom.topic,
            isHidden: data.isHidden,
            subtype: data.chatroom.subtype
          });

          if (room.teamId) {
            const team = this.teamService.teams.find(t => t.id === room.teamId);
            this.teamService.parseSidebar(team);
            this.teamRoomUpdated$.next(data.chatroom as unknown as Chatroom);
          }

          if (data.isHidden && !room.permissions.user.read_messages) {
            this.panelsService.close({
              id: room.id,
              teamId: room.teamId,
              type: PanelType.Chatroom,
            });
          }
        }
      });

    this.socketService
      .getStream(EvType.RoomDeleted)
      .subscribe((data: Events.RoomDeleted) => {
        const chatroom = ChatroomHandlers.handleRoomDeleted(data, this.teamService.teams);

        const team = this.teamService.teams.find(x => x.id === chatroom.teamId);
        if (!team) {
          return;
        }

        this.teamService.parseSidebar(team);
        this.panelsService.close({
          id: chatroom.chatroomId,
          teamId: chatroom.teamId,
          type: PanelType.Chatroom,
        });

        this.teamRoomRemoved$.next(chatroom);
      });

    this.socketService
      .getStream(EvType.RoomPermUpdated)
      .subscribe(async (data: Events.RoomPermUpdated) => {
        const team = this.teamService.teams.find(x => x.id === data.teamId);
        if (!team || !team.sidebarResolved) {
          return;
        }

        const fetchedTeam = await this.teamApi.GetTeam({
          teamId: data.teamId,
          ext: true
        }).toPromise()
          .catch(() => { return null; });
        if (!fetchedTeam) return;
        const chatroom = fetchedTeam.chatrooms.find(c => c.id === data.chatroomId);

        this.teamService.parseSidebar(fetchedTeam);
        this.teamService.upsertTeamInTeams(fetchedTeam);

        if (chatroom) {
          this.teamRoomUpdated$.next(chatroom);
        }
      });

    this.socketService
      .getStream(EvType.GroupRoomCreated)
      .subscribe((data: Events.GroupRoomCreated) => {
        // const room: DirectRoom = data.chatroom;
        // room.participants = data.users.map(user => {
        //   const participant: Participant = {
        //     user
        //   };
        //   return participant;
        // });

        // room.lastActiveDate = (new Date(room.lastActive)).getTime();
        // this.directs.push(room);
        // this.directCreated$.next(room);

        // this.handleGroupRoomCreated(data);
        const panel = this.panelsService.panels.find(p => p.id === data.chatroom.id);
        if (!panel) {
          this.loadDirects();
        }
      });

    this.socketService
      .getStream(EvType.ParticipantJoined)
      .subscribe((data: Events.ParticipantJoined) => {
        this.participantJoined$.next(data);
      });

    this.socketService
      .getStream(EvType.ParticipantRemoved)
      .subscribe((data: Events.ParticipantRemoved) => {
        this.participantRemoved$.next(data);
      });

    this.socketService
      .getStream(EvType.MessageProcessed)
      .subscribe((m: LocalMessageProcessed) => {
        const message = m.message as unknown as MessageResp;
        if (!(message.chatroom.type === RoomType.Team)) {
          if (this.profileService.me.mutedUsers && this.profileService.me.mutedUsers.includes(message.sender.id)) {
            return;
          }
          const room = this.directs.find(c => c.id === message.chatroom.id);
          if (!room) {
            this.loadDirects();
          } else {
            room.lastActive = message.timestamp;
            room.lastActiveDate = (new Date(message.timestamp)).getTime();
          }
        }
      });
  }

  public handleGroupRoomCreated(room: DirectRoom) {

    // const room: DirectRoom = data.chatroom;
    // room.participants = data.users.map(user => {
    //   const participant: Participant = {
    //     user
    //   };
    //   return participant;
    // });

    // room.lastActiveDate = (new Date(room.lastActive)).getTime();
    this.directs.push(room);
    this.directCreated$.next(room);
  }

  createRoom(contextTeamId: string, usersIds: string[]) {
    const userIds = usersIds.concat(this.profileService.me.id);
    return this.teamProfileApi
      .CreateRoom({
        userId: this.profileService.me.id,
        teamId: contextTeamId,
        body: {
          userIds
        }
      })
      .toPromise();
  }

  async loadDirects() {
    await this.teamProfileApi
      .GetRooms({
        userId: this.profileService.me.id
      })
      .toPromise()
      .then(directs => {
        this.setDirects(directs);
      })
      .catch(() => { return; });
  }

  getRoomById(id) {
    const teamRoom = this.teamService.getRoom(id);
    if (teamRoom) return teamRoom;

    const directRoom = this.directs.find(x => x.id === id);
    if (directRoom) return directRoom;
  }

  setDirects(directs: DirectRoom[]) {
    this.directs = directs.map(d => {
      d.unread = d.unread || 0;
      d.lastActiveDate = (new Date(d.lastActive)).getTime();
      return d;
    });
  }

  // async addDirect(member: Member): Promise<DirectRoom> {

  //   if (this.profileService.me.id === member.userId) { return; }
  //   const rooms = await this.teamProfileApi
  //     .GetRooms({
  //       userId: this.profileService.me.id,
  //       recipientId: member.userId
  //     })
  //     .toPromise();

  //   if (rooms && rooms.length) {
  //     return rooms[0];
  //   }

  //   const newRoom: DirectRoom = {
  //     recipientId: member.userId,
  //     localOnly: true,
  //     type: RoomType.Direct,
  //     id: member.userId,
  //     canSend: true,
  //     participants: [
  //       { user: this.profileService.me },
  //       { user: member.user },
  //     ],
  //     lastActiveDate: Date.now()
  //   };

  //   this.directs.push(newRoom);
  //   return newRoom;
  // }

  // addGroup(roomId: string, members: Member[]) {
  //   let room = this.directs.find(c => c.id === roomId);
  //   const participants: Participant[] = members
  //     .filter(m => !!m)
  //     .map(m => {
  //       return { user: m.user };
  //     });
  //   if (!room) {
  //     room = {
  //       participants,
  //       type: RoomType.Group,
  //       localOnly: true,
  //       id: roomId,
  //       lastActiveDate: Date.now()
  //     };
  //     this.directs.push(room);
  //   }
  //   return room;
  // }
}
