<table class="table table-sm signals" [ngClass]="getSignalClassType(signal.orderType)">
  <thead>
    <tr>
      <th colspan="2" class="p-2">
        <i *ngIf="getSignalClassType(signal.orderType)==='buy'" class="fas fa-caret-up fa-lg"></i>
        <i *ngIf="getSignalClassType(signal.orderType)==='sell'" class="fas fa-caret-down fa-lg"></i>
        <i *ngIf="getSignalClassType(signal.orderType)==='none'" class="fas fa-caret-right fa-lg"></i>
        <span>{{signal.title}}</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="signal.orderType || signal.symbol">
      <td *ngIf="signal.orderType && signal.orderType !== 'NONE'">
        {{getSignalOrderType(signal.orderType)}}</td>
      <td *ngIf="signal.orderType==='NONE'">SYMBOL</td>
      <!-- ngIf in bold element, symbol cell should always render, otherwise there may be only 1 td for the row and the table breaks -->
      <td>
        <b *ngIf="signal.symbol && !parsedSignalSymbol">{{signal.symbol}}</b>
        <b *ngIf="parsedSignalSymbol" [innerHTML]="parsedSignalSymbol"></b>
      </td>
    </tr>
    <tr *ngIf="signal.entry">
      <td>ENTRY</td>
      <td><b>{{signal.entry}}</b></td>
    </tr>
    <tr *ngFor="let field of signal.fields">
      <td>{{field.label}}</td>
      <td><b>{{field.value}}</b></td>
    </tr>
  </tbody>
  <tfoot *ngIf="parsedHtml || (files && files.length)" [ngClass]="getSignalClassType(signal.orderType)">
    <!-- <tr>
      <td colspan="2">hasText: {{hasText}}</td>
    </tr> -->
    <tr *ngIf="hasText && parsedHtml">
      <td colspan="2">
        <i class="fal fa-info-circle"></i>&nbsp;
        <p [innerHTML]="parsedHtml"></p>
      </td>
    </tr>
    <tr *ngIf="files && files.length" class="p-0">
      <td colspan="2" class="pl-2 pb-2 pt-0">
        <div *ngIf="messageStatus === status.SEND" class="text-center p-4 text-secondary">
          <i class="fas fa-cog fa-3x fa-fw fa-spin"></i>
          <br>
          <small>Uploading...</small>
        </div>
        <app-file-attachment *ngIf="messageStatus === status.DELIVERED" [attachments]="files" [hideFilename]="true"
          [fullView]="fullView && fullViewType==='file'" [messageId]="messageId">
        </app-file-attachment>
      </td>
    </tr>
  </tfoot>
</table>