import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../_core/services/team.service';
import { DiscoverService } from '../../_core/services/discover.service';
import { Team } from '../../_shared/models/teams/team';
import { BaseComponent } from '../base-component';
import { TrackingService } from '../../_core/services/tracking.service';

@Component({
  selector: 'app-discover-team',
  templateUrl: './discover-team.component.html',
  styleUrls: ['./discover-team.component.scss']
})
export class DiscoverTeamComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('search') searchInput: ElementRef;

  loading = false;
  teamToJoin: Team;
  searchedAlready = false;
  alreadyJoined = false;
  isBanned = false;
  errorMessage = '';

  searchField: FormControl = new FormControl();
  createTeam = false;

  featuredTeams: Team[] = [];

  get teams() {
    return this.discover.teams;
  }

  get isAdmin() {
    if (this.teamService.activeTeam &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      return true;
    }
    return false;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private teamService: TeamService,
    private discover: DiscoverService,
    private router: Router,
    private tracking: TrackingService
  ) {
    super();
  }

  async ngOnInit() {
    this.discover.discoveringNow = true;
    if (this.discover.term) {
      this.searchField.patchValue(this.discover.term);
    }
    this.featuredTeams = await this.teamService.getFeaturedTeams();
    this.inputFocus();
  }

  inputFocus() {
    setTimeout(
      () => {
        if (this.searchInput && this.searchInput.nativeElement) {
          this.searchInput.nativeElement.focus();
        }
      },
      110);
  }

  async discoverTeams() {
    this.discover.term = this.searchField.value;
    if (this.discover.term.length > 1) {
      this.tracking.trackEvent('discover-search', { term: this.discover.term });
      this.discover.teams = await this.teamService.discoverTeams(this.discover.term);
    }
  }

  trackByFn(index, item) {
    return item.id;
  }

  checkOutTeam(teamname: string) {
    this.tracking.trackEvent('discover-click-team', { teamname });
    this.router.navigateByUrl(`/join?team=${teamname}`);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.discover.reset();
    this.discover.discoveringNow = false;
  }
}
