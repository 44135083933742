import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageIdGeneratorService {
  getTempId() {
    return `tmp_${Math.floor(Math.random() * Math.floor(1000000))}`;
  }
}
