<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Preferences</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container">
      <div class="row">
        <div class="col-sm-2 pref-menu">
          <nav class="nav flex-column">
            <a class="nav-link" [class.active]="activeCategory === 0" href="javascript:void(0)" (click)="activeCategory = 0">Profile </a>
            <a class="nav-link" [class.active]="activeCategory === 1" href="javascript:void(0)" (click)="activeCategory = 1">Mute</a>
            <a class="nav-link" *ngIf="connected && connected.length>0" [class.active]="activeCategory === 2" href="javascript:void(0)" (click)="activeCategory = 2">Billing</a>
          </nav>
        </div>
        <div class="col-sm-10">
          <app-preferences-profile #selected  [me]="me" class="col-sm" *ngIf="activeCategory === 0"></app-preferences-profile>
          <app-preferences-mute #selected class="col-sm" *ngIf="activeCategory === 1"></app-preferences-mute>
          <app-preferences-billing #selected class="col-sm" *ngIf="activeCategory === 2" [connected]="connected"></app-preferences-billing>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer d-flex align-items-center justify-content-between">
    <small>Build #{{build}}</small>
    <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="activeModal.dismiss()">Close</button>
  </div>
</div>