<div class="root-loader">
  <div class="loader">
    <svg id="erj2nmbj4jx81" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 283.500000 283.500000" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
      <style>
        <![CDATA[
        #erj2nmbj4jx84_ts {
          animation: erj2nmbj4jx84_ts__ts 2000ms linear infinite normal forwards
        }

        @keyframes erj2nmbj4jx84_ts__ts {
          0% {
            transform: translate(125.570289px, 159.299632px) scale(1, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
          }

          10% {
            transform: translate(125.570289px, 159.299632px) scale(1.050000, 1.050000);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
          }

          15% {
            transform: translate(125.570289px, 159.299632px) scale(1, 1)
          }

          100% {
            transform: translate(125.570289px, 159.299632px) scale(1, 1)
          }
        }

        #erj2nmbj4jx820 {
          animation: erj2nmbj4jx820_c_o 2000ms linear infinite normal forwards
        }

        @keyframes erj2nmbj4jx820_c_o {
          0% {
            opacity: 0.500000
          }

          35% {
            opacity: 1
          }

          55% {
            opacity: 0.885714
          }

          85% {
            opacity: 0.500000
          }

          100% {
            opacity: 0.500000
          }
        }

        #erj2nmbj4jx824 {
          animation: erj2nmbj4jx824_c_o 2000ms linear infinite normal forwards
        }

        @keyframes erj2nmbj4jx824_c_o {
          0% {
            opacity: 0.500000
          }

          25% {
            opacity: 0.500000
          }

          55% {
            opacity: 1
          }

          70% {
            opacity: 1
          }

          95% {
            opacity: 0.500000
          }

          100% {
            opacity: 0.500000
          }
        }
        ]]>
      </style>
      <g id="erj2nmbj4jx82">
        <g id="erj2nmbj4jx83">
          <g id="erj2nmbj4jx84_ts" transform="translate(125.570289,159.299632) scale(1,1)">
            <path id="erj2nmbj4jx84"
              d="M93.700000,266.500000C91.800000,266.500000,89.900000,266.500000,88,266.500000C82,263.900000,80,259.200000,80.100000,252.900000C80.300000,243.800000,80.100000,234.600000,80.200000,225.500000C80.200000,222.900000,79.400000,221.500000,77.200000,220.100000C59.300000,208.800000,46.700000,193.200000,39.700000,173.200000C37.500000,166.800000,36.200000,160.300000,35.200000,153.600000C33.900000,145,35.200000,131,35.300000,130.300000C40.900000,86.500000,80,52.200000,124.300000,52.100000C151.500000,52,174.600000,62.100000,193.100000,82.100000C225.200000,116.700000,224.100000,172.900000,191,206.500000C181,216.700000,168.700000,223.100000,156.600000,230.100000C135.600000,242.200000,114.700000,254.400000,93.700000,266.500000ZM100.900000,238.400000C102.400000,237.600000,103.100000,237.200000,103.800000,236.800000C122.900000,225.900000,142.100000,214.900000,161.200000,203.900000C186.700000,189.100000,200.500000,158.900000,195.300000,130.800000C188.500000,93.700000,153.400000,67.700000,115.100000,73.200000C82.200000,78,55,108,55.200000,141.300000C55.300000,172.300000,69.800000,194.500000,97.800000,207.700000C100.100000,208.800000,101,209.900000,100.900000,212.500000C100.800000,220.900000,100.900000,229.400000,100.900000,238.400000Z"
              transform="translate(-125.570289,-159.299632)" fill="rgb(255,255,255)" stroke="none" stroke-width="1" />
          </g>
        </g>
        <g id="erj2nmbj4jx85" display="none">
          <g id="erj2nmbj4jx86">
            <path id="erj2nmbj4jx87"
              d="M150.200000,46.600000C149,46.400000,148.300000,46.100000,147.700000,45.600000C146.100000,44.200000,146.200000,40.600000,147.400000,39.300000C148.100000,38.500000,149.300000,38.100000,150.400000,38.100000C164.500000,39.500000,184.500000,46.100000,199.200000,59C214.200000,72.100000,224.900000,93.700000,227.100000,105.900000C227.300000,107.500000,227.100000,108.800000,226.400000,109.600000C224.700000,111.600000,221.600000,111.100000,220.400000,110.100000C219.700000,109.500000,219.300000,108.700000,219,107.700000C214.200000,92.200000,204.900000,77.500000,192.600000,66.600000C179.700000,55.300000,162.500000,48.200000,150.200000,46.600000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1" />
          </g>
          <g id="erj2nmbj4jx88">
            <path id="erj2nmbj4jx89"
              d="M153.500000,23.300000C152,23,151,22.600000,150.300000,21.900000C148.200000,20.100000,148.300000,15.400000,149.800000,13.700000C150.700000,12.600000,152.300000,12.100000,153.700000,12.200000C172,14,198.100000,22.600000,217.300000,39.400000C236.800000,56.500000,250.700000,84.600000,253.600000,100.500000C253.900000,102.600000,253.600000,104.200000,252.700000,105.300000C250.400000,107.900000,246.400000,107.200000,244.900000,105.900000C244,105.100000,243.500000,104,243,102.800000C236.700000,82.700000,224.700000,63.400000,208.600000,49.300000C192,34.700000,169.600000,25.400000,153.500000,23.300000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1" />
          </g>
          <path id="erj2nmbj4jx810"
            d="M88.700000,271.200000C86.800000,271.200000,84.900000,271.200000,83,271.200000C77,268.600000,75,263.900000,75.100000,257.600000C75.300000,248.500000,75.100000,239.300000,75.200000,230.200000C75.200000,227.600000,74.400000,226.200000,72.200000,224.800000C54.300000,213.500000,41.700000,197.900000,34.700000,177.900000C32.500000,171.500000,31.200000,165,30.200000,158.300000C28.900000,149.700000,30.200000,135.700000,30.300000,135C35.900000,91.200000,75,56.900000,119.300000,56.800000C146.500000,56.700000,169.600000,66.800000,188.100000,86.800000C220.200000,121.400000,219.100000,177.600000,186,211.200000C176,221.400000,163.700000,227.800000,151.600000,234.800000C130.600000,246.900000,109.700000,259,88.700000,271.200000ZM95.900000,243.100000C97.400000,242.300000,98.100000,241.900000,98.800000,241.500000C117.900000,230.600000,137.100000,219.600000,156.200000,208.600000C181.700000,193.800000,195.500000,163.600000,190.300000,135.500000C183.500000,98.400000,148.400000,72.400000,110.100000,77.900000C77.300000,82.600000,50.200000,112.600000,50.300000,145.900000C50.400000,176.900000,64.900000,199.100000,92.900000,212.300000C95.200000,213.400000,96.100000,214.500000,96,217.100000C95.800000,225.600000,95.900000,234,95.900000,243.100000Z"
            fill="rgb(0,0,0)" stroke="none" stroke-width="1" />
        </g>
        <g id="erj2nmbj4jx811">
          <g id="erj2nmbj4jx812">
            <path id="erj2nmbj4jx813" display="none"
              d="M150.200000,46.600000C149,46.400000,148.300000,46.100000,147.700000,45.600000C146.100000,44.200000,146.200000,40.600000,147.400000,39.300000C148.100000,38.500000,149.300000,38.100000,150.400000,38.100000C164.500000,39.500000,184.500000,46.100000,199.200000,59C214.200000,72.100000,224.900000,93.700000,227.100000,105.900000C227.300000,107.500000,227.100000,108.800000,226.400000,109.600000C224.700000,111.600000,221.600000,111.100000,220.400000,110.100000C219.700000,109.500000,219.300000,108.700000,219,107.700000C214.200000,92.200000,204.900000,77.500000,192.600000,66.600000C179.700000,55.300000,162.500000,48.200000,150.200000,46.600000Z"
              fill="rgb(255,255,255)" stroke="none" stroke-width="1" />
          </g>
          <g id="erj2nmbj4jx814">
            <path id="erj2nmbj4jx815" display="none"
              d="M153.500000,23.300000C152,23,151,22.600000,150.300000,21.900000C148.200000,20.100000,148.300000,15.400000,149.800000,13.700000C150.700000,12.600000,152.300000,12.100000,153.700000,12.200000C172,14,198.100000,22.600000,217.300000,39.400000C236.800000,56.500000,250.700000,84.600000,253.600000,100.500000C253.900000,102.600000,253.600000,104.200000,252.700000,105.300000C250.400000,107.900000,246.400000,107.200000,244.900000,105.900000C244,105.100000,243.500000,104,243,102.800000C236.700000,82.700000,224.700000,63.400000,208.600000,49.300000C192,34.700000,169.600000,25.400000,153.500000,23.300000Z"
              fill="rgb(255,255,255)" stroke="none" stroke-width="1" />
          </g>
          <path id="erj2nmbj4jx816" display="none"
            d="M88.700000,271.200000C86.800000,271.200000,84.900000,271.200000,83,271.200000C77,268.600000,75,263.900000,75.100000,257.600000C75.300000,248.500000,75.100000,239.300000,75.200000,230.200000C75.200000,227.600000,74.400000,226.200000,72.200000,224.800000C54.300000,213.500000,41.700000,197.900000,34.700000,177.900000C32.500000,171.500000,31.200000,165,30.200000,158.300000C28.900000,149.700000,30.200000,135.700000,30.300000,135C35.900000,91.200000,75,56.900000,119.300000,56.800000C146.500000,56.700000,169.600000,66.800000,188.100000,86.800000C220.200000,121.400000,219.100000,177.600000,186,211.200000C176,221.400000,163.700000,227.800000,151.600000,234.800000C130.600000,246.900000,109.700000,259,88.700000,271.200000ZM95.900000,243.100000C97.400000,242.300000,98.100000,241.900000,98.800000,241.500000C117.900000,230.600000,137.100000,219.600000,156.200000,208.600000C181.700000,193.800000,195.500000,163.600000,190.300000,135.500000C183.500000,98.400000,148.400000,72.400000,110.100000,77.900000C77.300000,82.600000,50.200000,112.600000,50.300000,145.900000C50.400000,176.900000,64.900000,199.100000,92.900000,212.300000C95.200000,213.400000,96.100000,214.500000,96,217.100000C95.800000,225.600000,95.900000,234,95.900000,243.100000Z"
            fill="rgb(255,255,255)" stroke="none" stroke-width="1" />
        </g>
      </g>
      <g id="erj2nmbj4jx817">
        <g id="erj2nmbj4jx818">
          <g id="erj2nmbj4jx819">
            <path id="erj2nmbj4jx820"
              d="M155.200000,41.900000C154,41.700000,153.300000,41.400000,152.700000,40.900000C151.100000,39.500000,151.200000,35.900000,152.400000,34.600000C153.100000,33.800000,154.300000,33.400000,155.400000,33.400000C169.500000,34.800000,189.500000,41.400000,204.200000,54.300000C219.100000,67.400000,229.900000,89,232.100000,101.200000C232.300000,102.800000,232.100000,104.100000,231.400000,104.900000C229.700000,106.900000,226.600000,106.400000,225.400000,105.400000C224.700000,104.800000,224.300000,104,224,103C219.200000,87.500000,209.900000,72.800000,197.600000,61.900000C184.700000,50.700000,167.500000,43.600000,155.200000,41.900000Z"
              opacity="0.5" fill="rgb(249,149,0)" stroke="none" stroke-width="1" />
          </g>
        </g>
      </g>
      <g id="erj2nmbj4jx821">
        <g id="erj2nmbj4jx822">
          <g id="erj2nmbj4jx823">
            <path id="erj2nmbj4jx824"
              d="M158.500000,18.600000C157,18.300000,156,17.900000,155.300000,17.200000C153.200000,15.400000,153.300000,10.700000,154.800000,9C155.700000,7.900000,157.300000,7.400000,158.700000,7.500000C177,9.300000,203.100000,17.900000,222.300000,34.700000C241.800000,51.800000,255.700000,79.900000,258.600000,95.800000C258.900000,97.900000,258.600000,99.500000,257.700000,100.600000C255.400000,103.200000,251.400000,102.500000,249.900000,101.200000C249,100.400000,248.500000,99.300000,248,98.100000C241.800000,78,229.700000,58.700000,213.600000,44.600000C197,30,174.600000,20.800000,158.500000,18.600000Z"
              opacity="0.5" fill="rgb(249,149,0)" stroke="none" stroke-width="1" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
<span class="process-message">signing out...</span>

<iframe #sso [src]="ssoUrl | safe" height="0" frameborder="0"></iframe>