import { Injectable } from '@angular/core';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EcfnStipeService {
    
    stripe: StripeInstance;

    constructor(
        private stripeFactory: StripeFactoryService,
    ) {
        this.stripe = this.stripeFactory.create(environment.config.stripe.publicKey);
    }
}