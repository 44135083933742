<a class="mentionUrl" href="javascript:void(0)" popoverClass="popover-usermenu"
    [placement]="['left', 'left-bottom', 'left-top', 'left-bottom', 'right-top', 'auto']" container="body"
    [ngbPopover]="popContent" [popoverTitle]="username" triggers="manual" #p="ngbPopover" (click)="openProfile()"
    [innerHTML]="viewUsername">
</a>

<!-- <ng-template #popContent let-member="member" let-team-id="teamId">
    <app-profile-view [member]="member" [teamId]="team-id"></app-profile-view>
</ng-template> -->

<ng-template #popContent>
    <app-profile-view [member]="member" [userId]="userId" [username]="username" [teamId]="teamId" [avatar]="avatar">
    </app-profile-view>
</ng-template>