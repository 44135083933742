import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../_core/services/profile.service';
import { UserExtResp } from '@echofin/libraries/api/profile/models/user-ext-resp';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'app-preferences-mute',
  templateUrl: './preferences-mute.component.html',
})
export class PreferencesMuteComponent extends BaseComponent implements OnInit, OnDestroy {

  loading = false;
  mutedUsers: UserExtResp[];

  constructor(
    public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private toastr: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadMutedUsers();
    this.subscribe(this.profileService.muteAdded$, async () => {
      await this.loadMutedUsers();
    });
    this.subscribe(this.profileService.muteRemoved$, async () => {
      await this.loadMutedUsers();
    });
  }

  async unmute(userId) {
    this.profileService.toggleMute(userId).then(async (muted) => {
      if (muted) {
        this.toastr.success('User muted!');
      } else {
        this.mutedUsers = this.mutedUsers.filter(m => m.id !== userId);
        this.toastr.success('User unmuted!');
      }
    }).catch(() => {
      this.toastr.error('Action Failed');
    });
  }

  async loadMutedUsers() {
    if (this.profileService.me.mutedUsers && this.profileService.me.mutedUsers.length > 0) {
      this.mutedUsers = await this.profileService.getUsers(this.profileService.me.mutedUsers);
    } else {
      this.mutedUsers = null;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
