import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProfileAvatarServiceApi, ProfileMeServiceApi, ProfileProfilesServiceApi } from '@echofin/libraries';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from '../../../_core/services/confirm.service';
import { DateService } from '../../../_core/services/date.service';
import { DefaultAvatarService } from '../../../_core/services/default-avatar.service';
import { FileUploadService } from '../../../_core/services/file-upload.service';
import { User } from '../../../_shared/models/commons/user';

@Component({
  selector: 'app-preferences-profile',
  templateUrl: './preferences-profile.component.html',
  styleUrls: ['./preferences-profile.component.scss'],
})
export class PreferencesProfileComponent implements OnInit {

  @Input() me: User;
  newMe: User;
  width: number = 150;
  height: number = 150;
  timeFormat: string = '24h';
  updatingAvatar: boolean;
  usernameEditable = false;

  loading = false;
  filestackUrl: string;

  @ViewChild('usernameInput') usernameInput: ElementRef;

  constructor(
    private avatarApi: ProfileAvatarServiceApi,
    private profileApi: ProfileProfilesServiceApi,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private filesService: FileUploadService,
    public avatarService: DefaultAvatarService,
    private dateService: DateService,
    private meApi: ProfileMeServiceApi,
    private confirm: ConfirmService
  ) { }

  async ngOnInit() {
    this.newMe = { ...this.me };
    this.timeFormat = this.newMe.is24h ? '24h' : '12h';
  }

  async saveAvatar() {
    if (this.newMe.avatar !== this.me.avatar) {
      await this.updateAvatar();
    }
  }

  async saveTimeFormat() {
    this.newMe.is24h = (this.timeFormat === '24h');

    await this.updateProfile('Time format updated successfuly');
  }

  editUsername() {
    this.usernameEditable = true;
    setTimeout(
      () => {
        (this.usernameInput.nativeElement as HTMLInputElement).select();
      });
  }

  async saveUsername() {
    this.loading = true;
    if (this.usernameNeedsUpdate()) {
      const renameResp = await this.meApi
        .Rename({
          newUsername: this.newMe.username
        })
        .toPromise()
        .then()
        .catch((err) => {
          this.newMe.username = this.me.username;
          switch (err.error) {
            case 'USERNAME_ALREADY_EXIST':
              this.toastr.error('This username exists!');
              break;
            case 'RESERVED_USERNAME':
              this.toastr.error('This username is not allowed!');
              break;
            default:
              this.toastr.error(err.error.Message);
              break;
          }
        });

      if (typeof renameResp !== 'undefined') {
        this.me.username = this.newMe.username;
        this.toastr.success('Username changed successfuly');
      }
    }
    this.loading = false;
    this.usernameEditable = false;
  }

  private async updateProfile(successMessage: string) {
    this.loading = true;
    if (this.profileNeedsUpdate()) {
      const profResp = await this.profileApi
        .UpdateProfile({
          userId: this.newMe.id,
          req: this.newMe
        })
        .toPromise()
        .then()
        .catch((err) => {
          this.toastr.error(err.error.Message, 'Error');
        });

      if (typeof profResp !== 'undefined') {
        this.me.avatar = this.newMe.avatar;
        this.me.fullname = this.newMe.fullname;
        this.me.timezone = this.newMe.timezone;
        this.me.dateFormat = this.newMe.dateFormat;
        this.me.is24h = this.newMe.is24h;
        this.dateService.setTimeFormat(this.newMe.is24h);
        this.toastr.success(successMessage);
      }
    }
    this.loading = false;
  }

  profileNeedsUpdate() {
    return this.me.fullname !== this.newMe.fullname
      || this.me.timezone !== this.newMe.timezone
      || this.me.dateFormat !== this.newMe.dateFormat
      || this.me.is24h !== this.newMe.is24h;
  }

  usernameNeedsUpdate() {
    return this.me.username !== this.newMe.username;
  }

  async triggerFileSelection() {
    const imageUrl = await this.filesService.pickAvatar();
    if (!imageUrl.length) return
    const previewObj = await this.avatarApi
      .PreviewAvatar({
        profileId: this.me.id,
        newUrl: imageUrl[0].url
      })
      .toPromise();
    this.newMe.avatar = previewObj.avatarUrl;

    await this.saveAvatar();
  }

  clearFilestackPolicies(url: string) {
    if (!url) {
      return null;
    }
    const urlArr = url.split('?');
    if (urlArr.length > 1) {
      return urlArr[0];
    }
    return url;
  }

  async updateAvatar() {
    this.updatingAvatar = true;
    await this.avatarApi
      .UpdateAvatar({
        newUrl: this.clearFilestackPolicies(this.newMe.avatar),
        profileId: this.me.id
      })
      .toPromise()
      .then(
        (res) => {
          this.newMe.avatar = this.me.avatar = res.avatarUrl;
          this.toastr.success('Avatar was updated successfuly');
        })
      .catch((err) => {
        this.toastr.error(err.message, 'Could not update avatar');
      });
    this.updatingAvatar = false;
  }

  async setDefaultAvatar() {
    this.newMe.avatar = null;

    const confirmRes = await this.confirm.confirm('Reset avatar', 'Are you sure you want to reset your avatar?');
    if (!confirmRes) {
      this.newMe.avatar = this.me.avatar;
      return;
    }

    await this.saveAvatar();
  }

  async toggle(timeformat: string) {
    this.timeFormat = timeformat;
    await this.saveTimeFormat();
  }

  clearUsernameEdit() {
    this.newMe.username = this.me.username;
    this.usernameEditable = false;
  }
}
