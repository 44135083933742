import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-autocomplete-list',
  templateUrl: './autocomplete-list.component.html',
  styleUrls: ['./autocomplete-list.component.scss']
})
export class AutocompleteListComponent {

  @Input()
  autocompleteList: any[];
  @Input()
  autocompleteIndex: number;
  @Input()
  autocompleteBaseLength: number;

  @Output()
  selectItem = new EventEmitter<number>();

  constructor() { }

  onSelect(i) {
    this.selectItem.emit(i);
  }
}
