<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title" [innerHTML]="modalTitle"></h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <textarea #text [formControl]="textField" name="mcomment" class="form-control" rows="8"
                                [ngClass]="{'is-invalid': submitted && textField.invalid && !(files && files[0])}"
                                (input)="typing()" (keydown)="onKeyDown($event)" (focus)="inputFocus($event)"
                                (blur)="inputBlur($event)"></textarea>
                            <i class="fas fa-grin-wink fa-md position-absolute multiline-emoji hand-cursor"
                                (click)="toggleEmojis($event)"></i>
                            <div class="invalid-feedback">
                                <small class="d-block" *ngIf="submitted && textField.invalid && !(files && files[0])">
                                    Enter a text message or upload a file</small>
                            </div>
                        </div>
                        <div class="mb-4 mt-0"><small class="text-dark-grey"><b>**bold**</b>, <em>_italic_</em>,
                                ___underline___</small></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between align-items-start">
            <div>
                <div *ngIf="!messageTimestamp" class="position-relative mr-3">
                    <ng-container *ngIf="srcImgWithSecurity && !fileToUpload">
                        <div class="attach-container">
                            <img [src]="srcImgWithSecurity" class="img-thumb">
                        </div>
                        <i (click)="clearFiles()"
                            class="fas fa-times text-white hand-cursor circle-icon clear-thumb"></i>
                    </ng-container>
                    <ng-container *ngIf="!srcImgWithSecurity && !uploadImagePreview && files && files[0]">
                        <i class="far fa-paperclip mr-2"></i>
                        <span>{{files[0].filename}}</span>
                        <i (click)="clearFiles()" class="fas fa-times align-middle text-white hand-cursor ml-1"></i>
                    </ng-container>
                    <ng-container *ngIf="!fileToUpload && (!files || !files[0])">
                        <input type="file" (change)="importFile($event)" class="d-none" #file>
                        <button type="button" class="btn btn-secondary" title="Attach file"
                            (click)="file.value=null;file.click()">
                            <i class="far fa-paperclip"></i>
                            Attach file
                        </button>
                    </ng-container>
                    <ng-container *ngIf="fileToUpload && uploadImagePreview && !srcImgWithSecurity">
                        <div class="attach-container">
                            <img [src]="uploadImagePreview" class="img-thumb">
                            <div *ngIf="!files || !files[0]"
                                class="upload-percent text-white shadowed h-100 d-flex justify-content-center align-items-center">
                                {{uploadPercent+'%'}}</div>
                            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
                        </div>
                        <i *ngIf="files && files[0]" (click)="clearFiles()"
                            class="fas fa-times text-white hand-cursor circle-icon clear-thumb"></i>
                    </ng-container>
                    <ng-container
                        *ngIf="fileToUpload && !uploadImagePreview && !srcImgWithSecurity  && (!files || !files[0])">
                        <div class="attach-container">
                            <div class="loading-bar">
                                <div class="upload-percent text-center text-white shadowed">{{uploadPercent+'%'}}</div>
                            </div>
                            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
                        </div>
                    </ng-container>
                </div>
                <div><small [ngClass]="{'text-danger': charsLeft<0, 'text-warning': charsLeft>=0}"
                        *ngIf="charsLeft != null">{{charsLeft}} characters left</small>
                    <small *ngIf="messageTimestamp" class="d-block"
                        [ngClass]="{'text-primary': canEdit, 'text-danger': !canEdit}">{{canEditMessage}}</small>
                </div>
            </div>

            <div>
                <div class="btn-group">
                    <button class="btn btn-success" (click)="send()"
                        [disabled]="(fileToUpload && uploading) || (charsLeft!==null && charsLeft<0) || (messageTimestamp && !canEdit) || (textField.value?.trim() === '/shout')">{{okBtnLabel}}</button>
                    <div *ngIf="!messageTimestamp && isTeamChatroom && !isInReplyMode" class="btn-group" ngbDropdown
                        [placement]="['bottom-right','top-right']">
                        <button class="btn btn-success dropdown-toggle-split" ngbDropdownToggle
                            (click)="$event.preventDefault()"
                            [disabled]="(charsLeft!==null && charsLeft<0) || (messageTimestamp && !canEdit)"></button>
                        <div class="dropdown-menu p-0" ngbDropdownMenu>
                            <div *ngFor="let c of chatrooms; let i = index" class="px-3 py-2">
                                <div class="custom-control custom-checkbox">
                                    <input [formControl]="c.control" type="checkbox" class="custom-control-input"
                                        id="rm-{{c.id}}">
                                    <label class="custom-control-label" for="rm-{{c.id}}">#{{c.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <small class="d-block text-dark-grey text-center send-help">Ctrl + Enter</small>
            </div>
        </div>
    </div>
</div>