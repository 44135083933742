<div #chatbox class="chatbox" [ngClass]="{'blurred':isLocked}" (scroll)="onScroll($event)">
  <div>
    <div *ngIf="!initialTimelineMessage && noResultsMessage && (!messages || messages.length === 0)"
      class="channel-intro">
      {{noResultsMessage}}
    </div>
    <app-message *ngIf="parentReplyMessage" class="thread-initial-post" [message]="parentReplyMessage"
      [timelineType]="timelineType" [canDelete]="canDelete" [canPin]="canPin" [canPost]="canPost" [panelId]="panelId"
      [showNamePopover]="messageClickedId===parentReplyMessage.id && popoverElement==='name'"
      [showAvatarPopover]="messageClickedId===parentReplyMessage.id && popoverElement==='avatar'"
      [fullView]="showFullLightboxId===parentReplyMessage.id" [fullViewType]="showFullLightboxType">
    </app-message>
    <div
      *ngIf="timelineType === timelineTypeEnum.RepliedMessages && !isLocked && !!messages && !parentReplyMessage && !hasMoreMessages"
      class="deleted-reply-parent">
      The message has been deleted.
    </div>
    <div *ngIf="initialTimelineMessage && !hasMoreMessages" class="channel-intro" [innerHTML]="initialTimelineMessage">
    </div>
    <ng-container *ngIf="messages && messages.length">
      <div *ngIf="loadingMore" class="load-more">
        Loading more...
      </div>
      <ng-container *ngFor="let message of messages; let i=index; let first=first; let last=last; trackBy: trackByFn">
        <ng-container *ngIf="!mutedUsers.includes(message.sender.id)">
          <div *ngIf="message.systemText" class="system-text">
            {{message.systemText}}
          </div>
          <ng-container *ngIf="!message.systemText">
            <div #newMessagesLine *ngIf="isNewMessagesLineRendered && message.isFirstNewMessage"
              class="new-messages-container">
              <div class="new-messages-strike-through"></div>
              <div class="new-messages">
                New messages<button type="button" class="btn" (click)="focusAndClearLine()">
                  <i class="fal fa-times fa-sm"></i>
                </button>
              </div>
            </div>

            <app-daily-date [message]="message"
              *ngIf="(timelineType === timelineTypeEnum.TeamChatroomMessages || timelineType===timelineTypeEnum.DirectOrGroupMessages || timelineType===timelineTypeEnum.PulseMessages || timelineType===timelineTypeEnum.SignalMessages) && message.firstMessageOfDay">
            </app-daily-date>
            <ng-container *ngIf="i>0 && (messages[i-1].timestamp<loadingBetween) && (loadingBetween<message.timestamp)">
              <div class="text-center">
                <div class="loading-circle one"></div>
                <div class="loading-circle two"></div>
                <div class="loading-circle three"></div>
              </div>
              <div class="text-center text-secondary mb-1">Loading messages...</div>
              <div class="text-center">
                <div class="loading-circle four"></div>
                <div class="loading-circle five"></div>
                <div class="loading-circle six"></div>
              </div>
            </ng-container>

            <app-message id="msgcomp-{{message.id}}" #msg [searchText]="searchText" [message]="message"
              [timelineType]="timelineType" [showRoomInfo]="showRoomInfo" [canDelete]="canDelete" [canPin]="canPin"
              [canPost]="canPost" [panelId]="panelId"
              [showNamePopover]="messageClickedId===message.id && popoverElement==='name'"
              [showAvatarPopover]="messageClickedId===message.id && popoverElement==='avatar'"
              [fullView]="showFullLightboxId===message.id" [fullViewType]="showFullLightboxType">
            </app-message>
          </ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf="hasMoreMessagesBelow && !loadingBetween" class="load-more">
        <a href="javascript:void(0)" (click)="loadMoreBelow()">Load more...</a>
      </div>
    </ng-container>
    <div #bottom class="bottom"></div>
  </div>
</div>



<a [hidden]="hideScrollToBottom || showGoToUnread" class="round-float-btn go-bottom" href="javascript:void(0)"
  (click)="scrollToBottom()">
  <span class="fa-stack">
    <i class="fas fa-circle fa-stack-1x"></i>
    <i class="fal fa-arrow-down fa-stack-1x inner-icon"></i>
  </span>
</a>


<a href="javascript:void(0)" (click)="focusAndClearLine()" class="unread-switch-x" (mouseenter)="ishovering=true;"
  (mouseleave)="ishovering=false;" [hidden]="!showGoToUnread || !(isNewMessagesLineAbove || isNewMessagesLineBelow)">
  <span *ngIf="!ishovering">NEW</span>
  <i *ngIf="ishovering" class="fas fa-times"></i>
</a>
<a class="round-float-btn go-bottom unread" href="javascript:void(0)" (click)="focusAndGoToUnread()"
  [hidden]="!showGoToUnread || !(isNewMessagesLineAbove || isNewMessagesLineBelow)">
  <span class="fa-stack">
    <i class="fas fa-circle fa-stack-1x"></i>
    <i *ngIf="isNewMessagesLineAbove" class="fal fa-arrow-up fa-stack-1x inner-icon"></i>
    <i *ngIf="isNewMessagesLineBelow" class="fal fa-arrow-down fa-stack-1x inner-icon"></i>
  </span>
</a>

<a *ngIf="hasMeeting" href="javascript:void(0)" (click)="openMeeting()" class="stream-live"
  [ngStyle]="{'top': (10 + topicHeight) +'px', 'left': ((hostElement.nativeElement.clientWidth/2)-33)+'px'}">
  <i class="fas fa-signal-stream"></i> LIVE
</a>

<ng-container
  *ngIf="!hideScrollToBottom && (timelineType === timelineTypeEnum.TeamChatroomMessages || timelineType===timelineTypeEnum.DirectOrGroupMessages)">
  <input class="d-none" placeholder="yyyy-mm-dd" [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker"
    [minDate]="{year: 2020, month: 1, day: 1}" [maxDate]="{year: currentYear, month: 12, day: 31}"
    placement="bottom-right" [positionTarget]="dateBtnEl" [footerTemplate]="footerPicker" autoClose="false">
  <a #dateBtnEl class="round-float-btn go-to-date" href="javascript:void(0)"
    [ngStyle]="{'top': (28 + topicHeight) +'px'}" (click)="d.toggle()">
    <span class="fa-stack">
      <i class="fas fa-circle fa-stack-1x"></i>
      <i class="fal fa-history fa-stack-1x inner-icon"></i>
    </span>
  </a>

  <ng-template #footerPicker>
    <hr class="my-0">
    <div class="d-flex justify-content-between align-items-center">
      <span class="pl-1">Navigate to selected date</span>
      <div class="p-1">
        <button class="btn btn-primary btn-sm float-right" (click)="goToDate(d)">Go</button>
      </div>
    </div>
  </ng-template>
</ng-container>