import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddTeamComponent } from '../../chat/add-team/add-team.component';
import { Team } from '../../_shared/models/teams/team';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class DiscoverService {

  term: string;
  teams: Team[];

  discoveringNow = false;
  addModal: NgbModalRef;

  constructor(
    private tracking: TrackingService,
    private modal: NgbModal
  ) { }

  reset() {
    this.term = null;
    this.teams = null;
  }

  addTeam() {
    this.tracking.trackEvent('discover-create');
    this.reset();
    this.modal.dismissAll();
    this.addModal = this.modal.open(AddTeamComponent, {
      backdrop: null,
      keyboard: null,
      centered: true,
      windowClass: 'modal-dark',
    });
  }
}
