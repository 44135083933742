<div class="loading" [ngClass]="{'loading':loading}">
  <div class="header">
    <h1 *ngIf="!invCode">Sign In</h1>
    <h1 *ngIf="invCode">You have been invited!</h1>
    <p *ngIf="teamName">
      Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
      <ng-container *ngIf="!isEchofin"> team</ng-container>
    </p>
  </div>
  <div class="alert alert-danger" *ngIf="error">
    <strong>Sign In failed</strong><br>{{error}}
  </div>
  <form (ngSubmit)="login()">
    <div class="custom-form-group">
      <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [required]="true" name="identity"
        #usernameControl="ngModel" [(ngModel)]="identity" placeholder="Username or email" [disabled]="loading">
    </div>
    <div class="custom-form-group">
      <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [required]="true" name="password"
        #usernamePassword="ngModel" [(ngModel)]="password" type="password" placeholder="Password" [disabled]="loading">
    </div>
    <button type="submit" [ngStyle]="{'background-color': colorCode, color:contrastColor}" [disabled]="loading">
      <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
      <span class="ml-2">SIGN IN</span>
    </button>
  </form>

  <div class="info">
    <p>No account? <a routerLink="/signup" queryParamsHandling="preserve" [ngStyle]="{'color': colorCode}">Sign Up</a>
    </p>
    <p><a routerLink="/reset-password" [ngStyle]="{'color': colorCode}">Forgot password?</a></p>
  </div>
</div>