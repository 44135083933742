export const teamNameRules = {
  blackListedTeamNames: [
    'register',
    'login',
    'reset-password',
    'verify',
    'logout',
    'error',
    'not-found',
    'mobile',
    'signup',
    'invitation'
  ],
  // blacklisted for team, but should redirect normally
  blackListedTeamNamesNormalRoute: ['join', 'create', 'discover', 'invite']
};
