import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { PulseMessagesService } from '../../_core/services/pulse-messages.service';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { LocalMessageProcessed, Message } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { TimelineComponent } from '../timeline/timeline.component';
import { PinboardMessagesService } from '../panel-pinboard-messages/services/pinboard-messages.service';
import { MessageType } from '../../_shared/models/message/message-type';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { EvType } from '../../_core/services/socket.service/models/all.models';

@Component({
  selector: 'app-panel-pulse',
  templateUrl: './panel-pulse.component.html',
  styleUrls: ['./panel-pulse.component.scss']
})
export class PanelPulseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @ViewChild('timeline') timeline: TimelineComponent;
  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  initialMessages: Message[];

  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.PulseMessages;

  config: any;
  // isConfigOpened: boolean = false;
  slOrder: 'MessageDate' | 'CreatedDate' = 'MessageDate';

  isLoading: boolean = false;
  canPin: boolean;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private messageService: MessageService,
    private socketService: SocketService,
    private pulseMessagesService: PulseMessagesService,
    private pinboardMessagesService: PinboardMessagesService,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  async ngOnInit() {
    console.log(this.panel.id);
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelTitle$.next([this.panel.id, 'Team Pulse']);
    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.registerEvents();

    this.slOrder = this.panelsService.getMessageOrder(this.panel.id).order;
    this.isLoading = true;
    await this.pulseMessagesService.loadPulseMessages(this.panel.id, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  registerEvents() {
    this.subscribe(this.socketService.getStream(EvType.MessageProcessed),
      (m: LocalMessageProcessed) => {
        if (m.type === 'NEW' && this.teamService.activeTeam.id === m.message.chatroom?.teamId) {
          if (m.message.type === MessageType.MARKET_ANALYSIS || m.message.type === MessageType.SHOUT) {
            this.onPulseMessageAdded(m.message);
          }
        }
      });
    this.subscribe(this.pinboardMessagesService.pinboardMessageAdded$, m => {
      if (this.teamService.activeTeam.id === m.teamId) {
        this.onPulseMessageAdded(m.message);
      }
    });
    this.subscribe(this.messageService.messageReactionsChanged$, m => {
      if (this.teamService.activeTeam.id === m.message.chatroom?.teamId) {
        if (m.reactions.length > 2) {
          this.onPulseMessageAdded(m.message);
        } else if (m.message.type !== MessageType.MARKET_ANALYSIS && m.message.type !== MessageType.SHOUT && !m.message.pinned) {
          this.onPulseMessageRemoved(m.id);
        }
      }
    });
    this.subscribe(this.messageService.messageDeleted$, m => {
      if (this.teamService.activeTeam.id === m.messageId) {
        this.onPulseMessageRemoved(m.messageId);
      }
    });
    this.subscribe(this.pinboardMessagesService.pinboardMessageRemoved$, m => {
      if (this.teamService.activeTeam.id === m.teamId) {
        const message = this.messageService.messages[this.panel.id].find(c => c.id === m.messageId.toString());
        if (message.type !== MessageType.MARKET_ANALYSIS && message.type !== MessageType.SHOUT && message.reactions.length < 3) {
          this.onPulseMessageRemoved(m.messageId);
        }
      }
    });
    this.subscribe(this.messageService.messageReplacementReceived$, message => this.processReplacementMessage(message.replace as Message));
    this.subscribe(this.messageService.messageDeleted$, messageDeleted => { if (messageDeleted) { this.deleteMessage(messageDeleted.messageId); } });
  }

  onPulseMessageAdded(pulseMessage) {
    const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === pulseMessage.id.toString());
    if (messageIndex < 0) {
      const m = pulseMessage as unknown as Message;
      this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray([...this.messageService.messages[this.panel.id], m], this.messageService.hasMoreMessages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    }
  }

  onPulseMessageRemoved(messageId: string) {
    const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === messageId.toString());
    if (messageIndex > -1) {
      this.messageService.messages[this.panel.id].splice(messageIndex, 1);
      this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray(this.messageService.messages[this.panel.id], this.messageService.hasMoreMessages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    }
  }

  processReplacementMessage(message: Message) {
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === message.id);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== message.id);
        this.messageService.messages[this.panel.id].push(message);
        this.messageService.messages[this.panel.id] = MessageHelpers.organizePulseMessages(this.messageService.messages[this.panel.id], this.messageService.hasMoreMessages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  deleteMessage(messageId: string) {
    if (messageId === null || messageId === undefined) {
      return;
    }
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === messageId);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = MessageHelpers.filterSortAndCollapsifyArray(this.messageService.messages[this.panel.id].filter(m => m.id !== messageId), this.messageService.hasMoreMessages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }
}
