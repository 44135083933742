import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GeneralResourcesService {
  resources: string;

  constructor(private http: HttpClient) { }

  async setup() {
    const resources = await this.http
      .get<any>('/assets/general-resources.json')
      .toPromise();

    this.resources = resources;
  }

  async getIframeUrl(chartId: string): Promise<string> {
    await this.init();
    return this.resources['iframeBaseUrl'].replace('{ef_chart_id}', chartId);
  }

  async getSymbols() {
    await this.init();
    return this.resources['symbols'];
  }

  private async init() {
    if (!this.resources) {
      await this.setup();
    }
  }
}
