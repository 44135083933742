import { compare } from 'compare-versions';

export class Helpers {

  static isElectron() {
    return /electron/i.test(navigator.userAgent);
  }

  static getElectronVersion() {
    const ver = navigator.userAgent.match(/(?=echofin\/)([^\s]+)/g);
    if (!ver) return null;
    return ver[0].replace('echofin/', '');
  }

  static isElectronLatest(version) {
    const current = this.getElectronVersion();
    // const major = '2.1';
    console.log(current, version);
    return compare(current, version, '>=');
  }
}
