import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../_core/services/team.service';
import { TrackingService } from '../../_core/services/tracking.service';

@Component({
  selector: 'app-quick-help',
  templateUrl: './quick-help.component.html',
  styleUrls: ['./quick-help.component.scss']
})
export class QuickHelpComponent implements OnInit {

  @Input()
  whatsNewSlides: any[];
  loading = false;

  sentFeedbackTitle: string[] = [];

  get activeTeam() {
    return this.teamService.activeTeam;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private tracking: TrackingService,
    private teamService: TeamService
  ) { }

  ngOnInit() {
    // add dashboard tag disclaimer
    for (const slide of this.whatsNewSlides) {
      if (slide.tags.indexOf('dashboard') > -1) {
        slide.desc += '<br><br><br><small>This message appears to team admins only.</small>';
      }
    }
  }

  trackEvent(featureTitle: string, value: string) {
    this.tracking.trackEvent("what's new", {
      teamname: this.activeTeam ? this.activeTeam.name : null,
      feature: featureTitle,
      feedback: value
    });
    this.sentFeedbackTitle.push(featureTitle);
  }
}
