import { environment } from '../../../environments/environment';

export class BaseService {

  title: string;
  color: string;
  params = { log: true };

  constructor(params?: any) {
    if (params) {
      this.params = params;
    }
    this.title = this.constructor.name;
    this.color = this.intToRGB(this.hashCode(this.title));
  }

  log(...arg) {
    if (!environment.production && this.params.log) {
      console.log(`%c[${this.title}]`, `color:#${this.color}`, ...arg);
    }
  }

  hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  }
}
