<div class="p-0 m-0 teambar-container mt-2">
  <div class="scroll-y-hidden-inner mt-2" [ngClass]="{'no-desktop-button': !showDownload}">
    <!-- <div class="p-0 m-0 scroll-y-hidden-inner"> -->
    <ng-container *ngIf="teams.length">
      <div cdkDropList [cdkDropListData]="teams" (cdkDropListDropped)="drop($event)">
        <button cdkDrag [cdkDragData]="team" *ngFor="let team of teams; let i=index; trackByFn" [id]="team.id"
          class="btn teambar-team-btn teambar-team-btn-team team-button team-drag-container" id="teambar-{{team.id}}"
          [ngbTooltip]="team.label || team.name" container="body" [placement]="['right', 'right-bottom', 'right-top']"
          [ngClass]="{'selected': team.id === activeTeamId, 'loading': team.id === loadingButton}">
          <img [src]="team.logo||'/assets/no-logo.png'" onError="this.src='/assets/no-logo.png';" alt="logo">
          <app-team-message-count [teamId]="team.id"></app-team-message-count>
        </button>
      </div>
    </ng-container>
    <button id="discover-team" *ngIf="!activeTeam || activeTeam['features'].indexOf('DisableTeamDiscovery')===-1"
      class="btn teambar-team-btn teambar-team-btn-new discover" ngbTooltip="Discover/Create Teams" container="body"
      placement="right">
      <i class="fad fa-compass"></i>
    </button>
    <button id="share-team" class="btn teambar-team-btn teambar-team-btn-new share" ngbTooltip="Team Invite Link"
      container="body" placement="right">
      <i class="fad fa-share-all"></i>
    </button>
  </div>
  <div *ngIf="showDownload" class="mt-auto text-center p-2" ngbTooltip="Download Desktop Apps" container="body"
    placement="right">
    <a id="download-client" class="btn teambar-team-btn teambar-team-btn-new share" href="javascript:void"
      title="Download Desktop">
      <span class="fa-stack fa-1x">
        <i class="fal fa-tv-alt fa-stack-1x"></i>
        <i class="fad fa-arrow-alt-from-top fa-stack-1x shrink-6"></i>
      </span>
    </a>
  </div>
  <div class="mt-auto text-center">
    <button id="toggle-sidebar" type="button" class="btn text-secondary animated-scaled rounded-0 border-0 w-100"
      [title]="sidebarClosed?'Expand':'Collapse'">
      <i *ngIf="sidebarClosed" class="fas fa-angle-double-right"></i>
      <i *ngIf="!sidebarClosed" class="fas fa-angle-double-left"></i>
    </button>
  </div>
</div>