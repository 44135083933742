import { Events } from "@echofin/libraries";

export interface LocalUserStatusChanged extends Omit<Events.UserStatusChanged, 'teamIds'> {
  teamId: string;
}

export enum UserStatus {
  AVAILABLE = 'AVAILABLE',
  BUSY = 'BUSY',
  AWAY = 'AWAY',
  OFFLINE = 'OFFLINE',
  UNKNOWN = 'UNKNOWN'
}
