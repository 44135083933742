<div class="panel-config-search">
</div>
<div class="chatroom-wrapper">
  <app-timeline #timeline [isLocked]="false" [messages]="messages" [initialTimelineMessage]="initialTimelineMessage"
    [timelineType]="timelineType" [noResultsMessage]="'No Results'" [showRoomInfo]="true" [teamId]="panel.teamId"
    [panelId]="panel.id">
  </app-timeline>
  <div class="footer" [ngClass]="{'isMaximized':maximized}">
    <form>
      <div class="form-group m-0">
        <div class="input-group">
          <input #searchInput class="form-control bg-dark text-white" type="text"
            placeholder="Search for message and chat history" value="" (focus)="focusRoom()" (blur)="unfocusRoom()">
          <button [disabled]="searching" type="submit" class="btn sbtn" (click)="onSearchClick()" style="float: right;">
            <i class="fal fa-search"></i>
          </button>

        </div>
        <div class="borderfx"></div>
      </div>
      <div class="mt-1 foot-wrap" style="height:60px">
        <div class="foot-icons">
          <button class="btn btn-sm mr-1 filter-icon animated-scaled" style="position: relative;overflow: hidden;"
            (click)="openFilterPanel()" title="Search Filters">
            <i class="fas fa-filter"></i>
            <span *ngIf="filterCount && filterCount > 0" class="filter-counter">{{ filterCount }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>