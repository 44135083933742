<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Leave Team</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="reject()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="color:silver">
    <p class="warning">If you abandon the team you will completely lose access to all services provided by the team. You also give up any user Roles that may have been assigned to you. Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" type="button" (click)="confirm()">Leave Team</button>
    <button class="btn btn-success" type="button" (click)="reject()">Stay</button>
  </div>
</div>