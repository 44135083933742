<div class="form-group m-0">
  <div class="input-container" [ngClass]="isQuote ? 'input-group-prepend' : 'input-group'">
    <button *ngIf="isQuote" (click)="removeQuotes()" class="btn quote-btn" type="button" title="Remove quote">
      <i class="fas fa-quote-right"></i>
    </button>
    <ng-container *ngIf="isReplyPanel">
      <button class="btn reply-btn" type="button" title="Remove reply" style="cursor: default;">
        <i class="fas fa-reply"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="!isReplyPanel">
      <button *ngIf="isReply" (click)="removeReplies()" class="btn reply-btn" type="button" title="Remove reply">
        <i class="fas fa-reply"></i>
      </button>
    </ng-container>
    <input #text class="form-control bg-dark text-white" [placeholder]="placeholder" (focus)="inputFocus($event)"
      (blur)="inputBlur($event)" (paste)="inputPaste($event)">
    <i *ngIf="isSecret" class="fas fa-lock input-lock"></i>
    <span class="input-group-btn ml-2">
      <!-- <button id="send" *ngIf="allowPost" [disabled]="!canPost" type="button"
        (click)="inputSend($event)">
        <fa-icon [icon]="['fal','level-down-alt']" [rotate]="90"></fa-icon>
      </button> -->
      <button id="expand" *ngIf="!isReadOnly && inFocus" [disabled]="disabled" type="button" (click)="onExpandInput()">
        <i class="fal fa-expand-alt"></i>
      </button>
      <button *ngIf="isReadOnly || noCharsLeft" type="button" class="readonlyBtn">
        <i class="fal fa-ban"></i>
      </button>
    </span>
  </div>
  <div class="borderfx"></div>
</div>

<ng-container *ngIf="!readonly">
  <div class="mt-1 foot-wrap">
    <div class="foot-icons">
      <input type="file" (change)="importFile($event)" class="d-none" #file>
      <button class="btn btn-sm mr-1 attachment-icon animated-scaled" style="position: relative;overflow: hidden;"
        (click)="file.value=null;file.click()" title="Attach File">
        <i class="fas fa-paperclip"></i>
      </button>

      <button class="btn btn-sm mr-1 img-video-icon animated-scaled" style="position: relative;overflow: hidden;"
        (click)="openAttachment()" title="Attach Media">
        <i class="fas fa-photo-video"></i>
      </button>

      <!-- <div #smiliePopover="ngbPopover" popoverClass="popover-smilies" placement="top-left" container="body"
        [autoClose]="'outside'" [ngbPopover]="smilies" triggers="click" class="d-inline-block"> -->
      <button class="btn btn-sm mr-1 emoji-icon animated-scaled" title="Emoji" (click)="toggleEmojis($event)">
        <i class="fas fa-grin-wink"></i>
      </button>
      <!-- </div> -->

      <button class="btn btn-sm mr-1 signals-icon animated-scaled" (click)="openSignal()" title="Signals">
        <svg version="1.1" id="signal-stream" focusable="false" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 576 576"
          style="enable-background:new 0 0 576 576;" xml:space="preserve">
          <path d="M339.4,279.3l-80.4-23c-10.9-3.1-19.8-11.9-21.5-23.2c-2.7-17.6,10.9-32.8,28-32.8h51c11,0,21.8,3.5,30.7,9.8
        c4.4,3.1,10.4,2.6,14.2-1.2l15.8-15.8c4.7-4.7,4.2-12.6-1-16.7c-17.1-13.2-37.8-20.3-59.7-20.3h-1.9v-33.2c0-6.1-5-11.1-11.1-11.1
        h-22.1c-6.1,0-11.1,5-11.1,11.1v33.2h-1.7c-34,0-65.4,22-73.6,55.1c-9.6,38.9,14,76.9,50.5,87.4l80.4,23
        c10.9,3.1,19.8,11.9,21.5,23.2c2.7,17.6-10.9,32.8-28,32.8h-51c-11,0-21.8-3.5-30.7-9.8c-4.4-3.1-10.4-2.6-14.2,1.2l-15.8,15.8
        c-4.7,4.7-4.2,12.6,1,16.7c17,13.2,37.8,20.3,59.7,20.3h1.9v33.2c0,6.1,5,11.1,11.1,11.1h22.1c6.1,0,11.1-5,11.1-11.1v-33.2h1.7
        c34,0,65.4-22,73.6-55.1C399.5,327.8,375.9,289.8,339.4,279.3L339.4,279.3z M100.8,108.7L89.6,97.4c-3-3.1-8-3.2-11.1-0.1
        c-0.1,0.1-0.2,0.2-0.2,0.2C31.3,148.2,5.5,214.1,5.5,284s25.8,135.8,72.8,186.5c3,3.2,8,3.3,11.1,0.3c0.1-0.1,0.2-0.2,0.2-0.2
        l11.2-11.3c3-3.1,3-8,0.1-11.1C59.6,403.6,37,345.5,37,284s22.6-119.5,63.9-164.2C103.8,116.7,103.8,111.8,100.8,108.7z
         M163.4,176.6l-11.1-11.3c-3-3.1-8-3.2-11.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-60.1,67.6-60.1,169.4,0,237c2.9,3.2,7.9,3.4,11.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3l11.1-11.3c2.9-3.1,3-7.9,0.1-11c-48.4-55.1-48.4-137.5,0-192.6C166.4,184.5,166.3,179.8,163.4,176.6
        L163.4,176.6z M499.7,97.5c-3-3.2-8-3.3-11.1-0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-11.2,11.3c-3,3.1-3,8-0.1,11.1
        C518.3,164.5,541,222.5,541,284s-22.6,119.5-63.9,164.2c-2.9,3.1-2.9,8,0.1,11.1l11.2,11.3c3,3.1,8,3.2,11.1,0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c47-50.7,72.8-116.6,72.8-186.5S546.7,148.2,499.7,97.5z M425.7,165.4l-11.1,11.3c-2.9,3.1-3,7.9-0.1,11
        c48.4,55.1,48.4,137.5,0,192.6c-2.9,3.1-2.8,8,0.1,11l11.1,11.3c3,3.1,8,3.2,11.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3
        c60.1-67.6,60.1-169.4,0-237c-2.9-3.2-7.9-3.4-11.1-0.5C425.9,165.1,425.8,165.2,425.7,165.4L425.7,165.4z" />
        </svg>
      </button>
      <button class="btn btn-sm mr-1 market-overview-icon animated-scaled" (click)="openMarket()"
        title="Market Insight/Commentary">
        <i class="fas fa-analytics"></i>
      </button>

      <button *ngIf="room && room.type=='Team'" class="btn btn-sm mr-1 stream-icon animated-scaled"
        [ngClass]="{'active':hasMeeting}" (click)="openMeeting()" title="Start or Join a Meeting">
        <i class="fas fa-signal-stream"></i>
      </button>

      <!-- <button *ngIf="room.type=='Direct'" class="btn btn-sm mr-1 stream-icon animated-scaled" (click)="startMeeting()"
      title="Start a call">
      <fa-icon [icon]="['fas','phone']"></fa-icon>
    </button> -->

      <button class="btn btn-sm mr-1 formatting-icon animated-scaled" (click)="openMarkdown()"
        title="Formatting Options">
        <i class="fas fa-font"></i>
      </button>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="readonly">
  <div class="mt-1 foot-wrap">
    <div class="foot-icons">
      <button class="btn btn-sm mr-1" style="position: relative;overflow: hidden;" title="Attach File" disabled="true">
        <i class="fas fa-paperclip"></i>
      </button>

      <button class="btn btn-sm mr-1" style="position: relative;overflow: hidden;" title="Attach Media" disabled="true">
        <i class="fas fa-photo-video"></i>
      </button>

      <button class="btn btn-sm mr-1" title="Emoji" disabled="true">
        <i class="fas fa-grin-wink"></i>
      </button>

      <button class="btn btn-sm mr-1" title="Signals" disabled="true">
        <svg version="1.1" id="signal-stream" focusable="false" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 576 576"
          style="enable-background:new 0 0 576 576;" xml:space="preserve">
          <path d="M339.4,279.3l-80.4-23c-10.9-3.1-19.8-11.9-21.5-23.2c-2.7-17.6,10.9-32.8,28-32.8h51c11,0,21.8,3.5,30.7,9.8
        c4.4,3.1,10.4,2.6,14.2-1.2l15.8-15.8c4.7-4.7,4.2-12.6-1-16.7c-17.1-13.2-37.8-20.3-59.7-20.3h-1.9v-33.2c0-6.1-5-11.1-11.1-11.1
        h-22.1c-6.1,0-11.1,5-11.1,11.1v33.2h-1.7c-34,0-65.4,22-73.6,55.1c-9.6,38.9,14,76.9,50.5,87.4l80.4,23
        c10.9,3.1,19.8,11.9,21.5,23.2c2.7,17.6-10.9,32.8-28,32.8h-51c-11,0-21.8-3.5-30.7-9.8c-4.4-3.1-10.4-2.6-14.2,1.2l-15.8,15.8
        c-4.7,4.7-4.2,12.6,1,16.7c17,13.2,37.8,20.3,59.7,20.3h1.9v33.2c0,6.1,5,11.1,11.1,11.1h22.1c6.1,0,11.1-5,11.1-11.1v-33.2h1.7
        c34,0,65.4-22,73.6-55.1C399.5,327.8,375.9,289.8,339.4,279.3L339.4,279.3z M100.8,108.7L89.6,97.4c-3-3.1-8-3.2-11.1-0.1
        c-0.1,0.1-0.2,0.2-0.2,0.2C31.3,148.2,5.5,214.1,5.5,284s25.8,135.8,72.8,186.5c3,3.2,8,3.3,11.1,0.3c0.1-0.1,0.2-0.2,0.2-0.2
        l11.2-11.3c3-3.1,3-8,0.1-11.1C59.6,403.6,37,345.5,37,284s22.6-119.5,63.9-164.2C103.8,116.7,103.8,111.8,100.8,108.7z
         M163.4,176.6l-11.1-11.3c-3-3.1-8-3.2-11.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-60.1,67.6-60.1,169.4,0,237c2.9,3.2,7.9,3.4,11.1,0.5
        c0.1-0.1,0.2-0.2,0.3-0.3l11.1-11.3c2.9-3.1,3-7.9,0.1-11c-48.4-55.1-48.4-137.5,0-192.6C166.4,184.5,166.3,179.8,163.4,176.6
        L163.4,176.6z M499.7,97.5c-3-3.2-8-3.3-11.1-0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-11.2,11.3c-3,3.1-3,8-0.1,11.1
        C518.3,164.5,541,222.5,541,284s-22.6,119.5-63.9,164.2c-2.9,3.1-2.9,8,0.1,11.1l11.2,11.3c3,3.1,8,3.2,11.1,0.2
        c0.1-0.1,0.2-0.2,0.2-0.2c47-50.7,72.8-116.6,72.8-186.5S546.7,148.2,499.7,97.5z M425.7,165.4l-11.1,11.3c-2.9,3.1-3,7.9-0.1,11
        c48.4,55.1,48.4,137.5,0,192.6c-2.9,3.1-2.8,8,0.1,11l11.1,11.3c3,3.1,8,3.2,11.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3
        c60.1-67.6,60.1-169.4,0-237c-2.9-3.2-7.9-3.4-11.1-0.5C425.9,165.1,425.8,165.2,425.7,165.4L425.7,165.4z" />
        </svg>
      </button>

      <button class="btn btn-sm mr-1" title="Market Insight/Commentary" disabled="true">
        <i class="fas fa-analytics"></i>
      </button>

      <button *ngIf="room && room.type=='Team'" class="btn btn-sm mr-1" [ngClass]="{'active':hasMeeting}"
        title="Start or Join a Meeting" disabled="true">
        <i class="fas fa-signal-stream"></i>
      </button>

      <button class="btn btn-sm mr-1" style="float:right" title="Formatting Options" disabled="true">
        <i class="fas fa-font"></i>
      </button>
    </div>
  </div>
</ng-container>

<div class="d-flex align-items-center justify-content-between">
  <app-is-typing *ngIf="!isReplyPanel" [channelId]="room.id"></app-is-typing>
  <small *ngIf="charsLeft !== null" [ngClass]="{'text-danger': charsLeft < 0, 'text-warning': charsLeft >= 0}">
    {{charsLeft}} characters left</small>
</div>

<ng-template #smilies>
  <div class="">
    <div class="btn-group btn-group-sm smilies-tabs text-center mb-1">
      <button *ngFor="let smilieTab of smilieTabs" class="btn border-0"
        [ngClass]="{'active':smilieTab.key === smilieSelTab}" (click)="smilieSelTab = smilieTab.key">
        {{smilieTab.key}}
      </button>
    </div>
    <ng-container *ngFor="let smilieTab of smilieTabs">
      <div *ngIf="smilieTab.key === smilieSelTab" class="smilies-icons">
        <button class="btn btn-sm" *ngFor="let s of smilieTab.smilies" (click)="addSmilie(s)">
          <span>{{s.char}}</span>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>


<div #autocomplete *ngIf="autocompleteList.length" class="autocomplete"
  [ngStyle]="{'transform': 'translateY(-'+autocompleteList.length*32+'px)'}">
  <app-autocomplete-list [autocompleteList]="autocompleteList" [autocompleteIndex]="autocompleteIndex"
    [autocompleteBaseLength]="autocompleteBaseLength" (selectItem)="selectItem($event)">
  </app-autocomplete-list>
</div>

<!-- 
<ng-template #commandTpl let-r="result" let-t="term">
  <div>
    <ngb-highlight [result]="r" [term]="getLastTerm(t)"></ngb-highlight>
  </div>
</ng-template> -->