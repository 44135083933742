import { Events } from '@echofin/libraries';
import { Team } from '../../../_shared/models/teams/team';

export class TeamHandlers {
  static handleTeamUpdated(model: Events.TeamUpdated, teams: Team[]) {
    let team: Team = teams.find(t => t.id === model.team.id);
    team = Object.assign(team, {
      name: model.team.name,
      label: model.team.label,
      description: model.team.description,
      logo: model.team.logo,
      config: model.team.config,
      profileTags: model.team.profileTags
    });
    return team;
  }

  static handleSidebarChanged(model: Events.SidebarReorderded, teams: Team[]) {
    const team: Team = teams.find(t => t.id === model.teamId);
    if (team) {

      // model.items.forEach((item: { Key: string, Value: any }) => {
      //   const room = team.chatrooms.find(s => s.id === item.Key);
      //   if (room) room.sort = item.Value;
      //   const divider = team.dividers.find(s => s.id === item.Key);
      //   if (divider) divider.sort = item.Value;
      //   const frame = team.frames.find(s => s.id === item.Key);
      //   if (frame) frame.sort = item.Value;
      // });

      if (team.chatrooms) {
        team.chatrooms.forEach(r => {
          const sidebarItemIndex = model.items.findIndex(s => s === r.id);
          r.sort = sidebarItemIndex;
        });
      }
      if (team.dividers) {
        team.dividers.forEach(r => {
          const sidebarItemIndex = model.items.findIndex(s => s === r.id);
          r.sort = sidebarItemIndex;
        });
      }
      if (team.frames) {
        team.frames.forEach(r => {
          const sidebarItemIndex = model.items.findIndex(s => s === r.id);
          r.sort = sidebarItemIndex;
        });
      }
    }
    return team;
  }
}
