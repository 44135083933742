import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../_core/services/team.service';
import { TrackingService } from '../../_core/services/tracking.service';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss']
})
export class AddTeamComponent {

  get isAdmin() {
    if (this.teamService.activeTeam &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      return true;
    }
    return false;
  }

  constructor(
    private teamService: TeamService,
    private tracking: TrackingService,
    public activeModal: NgbActiveModal
  ) { }

  dismiss() {
    this.activeModal.dismiss();
  }

  trackEvent(event) {
    this.tracking.trackEvent(event);
  }

}
