<ng-container *ngIf="!isLoading">
  <div class="avatar-container">
    <app-user-avatar [username]="username" [avatarUrl]="(username && avatar)?avatar:null"></app-user-avatar>
  </div>
  <button *ngIf="itIsMe" (click)="openPreferences()" class="btn btn-block">
    <i class="fal fa-cogs fa-fw"></i> Preferences
  </button>
  <button *ngIf="!itIsMe && inTeam && !isMuted" [disabled]="!member['dm']" (click)="startDirect()"
    class="btn btn-block">
    <i class="fal fa-comment-dots fa-fw"></i> Direct Message
  </button>
  <button *ngIf="!itIsMe" (click)="toggleMute()" class="btn btn-block">
    <i class="fal fa-microphone-alt-slash fa-fw"></i>
    <ng-container *ngIf="!isMuted">
      Mute User
    </ng-container>
    <ng-container *ngIf="isMuted">
      Unmute User
    </ng-container>
  </button>
  <button *ngIf="showBan && member" [disabled]="!member['ban']" (click)="toggleBan()" class="btn btn-block">
    <i class="fal fa-trash-alt fa-fw"></i>
    <ng-container *ngIf="!member.isBanned">
      Ban from Team
    </ng-container>
    <ng-container *ngIf="member.isBanned">
      Unban from Team
    </ng-container>
  </button>
  <div class="ct_wrap" *ngIf="commonTeams && commonTeams.length > 0">
    <span class="label">You are both a member of:</span>
    <div class="ct_box">
      <ng-container *ngFor="let commonTeam of commonTeams">
        <span class="badge one-line-ellipsis mw-100 align-middle">
          <span>{{commonTeam.label && commonTeam.label.length ?
            commonTeam.label : commonTeam.name}}</span></span>
      </ng-container>
    </div>
  </div>
  <div class="ct_wrap" *ngIf="!itIsMe && (!commonTeams || commonTeams.length === 0)">
    <span class="label">You have no common teams</span>
  </div>
</ng-container>

<div *ngIf="isLoading" class="d-flex loading-container text-secondary  justify-content-center align-items-center">
  <i class="fas fa-cog fa-fw fa-spin fa-3x"></i>
</div>