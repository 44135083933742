import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BrandingService } from '../../_core/services/branding.service';
import { MobileService } from './mobile.service';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {

  constructor(private mobile: MobileService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.mobile.isMobileDevice()) {
      return this.router.parseUrl('/');
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MobileDomainGuard implements CanActivate {

  constructor(
    private brandingService: BrandingService,
    private mobile: MobileService,
    private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.mobile.isMobileDevice() && this.brandingService.isCustomDomain()) {
      await this.brandingService.loadBranding();
      // console.log(`https://${this.brandingService.team.name}.${environment.config.domain}/login`);
      window.location.href = `https://${this.brandingService.team.name}.${environment.config.domain}/login`;
      return false;
    }
    return true;
  }
}
