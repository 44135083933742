import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TeamService } from './team.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TitleHandlerService extends BaseService {

  panelId: string;

  constructor(
    private titleService: Title,
    private teamService: TeamService
  ) {
    super();
    this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Echofin');
    this.teamService.activeTeamId$.subscribe(tid => {
      this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Echofin');
    });
  }

  setTitle(newTitle: string, panelId: string) {
    this.panelId = panelId;
    this.titleService.setTitle(newTitle);
  }

  removeTitle(panelId: string) {
    if (this.panelId === panelId) {
      this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Echofin');
    }
  }
}
