import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageTextFormatterService } from '../../../_core/services/message-text-formatter.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { Message, MessageStatus } from '../../../_shared/models/commons/message';
import { MessageType } from '../../../_shared/models/message/message-type';
import { BaseComponent } from '../../base-component';
import { TimelineType } from '../../timeline/models/timeline-type.enum';
import { PanelsService } from '../../../_core/services/panels.service';
import { MessageService } from '../../../_core/services/message.service';
import { PinboardMessagesService } from '../../../chat/panel-pinboard-messages/services/pinboard-messages.service';
import { BookmarkMessagesService } from '../../../chat/panel-bookmark-messages/services/bookmark-messages.service';
import { Events } from '@echofin/libraries';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() searchText: string; // input values used by setters should always be above - SETTING THE VALUE SHOULD BE FIRST IN THE COMPONENT'S TEMPLATE TOO
  @Input() timelineType: TimelineType;
  _message: Message;
  @Input()
  set message(value: Message) {
    if ((value.text && value.text !== (this._message ? this._message.text : '')) || // if changes in text
      value.mentions?.length !== this._message?.mentions?.length) { // or changes in mentions
      const parsedText = this.mtf.parseMessageTextForDisplay(value, { term: this.searchText });
      this.parsedHtml = this.domSanitizer.bypassSecurityTrustHtml(parsedText);
    }
    this._message = value;
  }
  get message() {
    return this._message;
  }
  @Input() showRoomInfo: boolean = false;
  @Input() canDelete: boolean;
  @Input() canPin: boolean;
  @Input() canPost: boolean;
  @Input() fullView: boolean;
  @Input() fullViewType: string;

  @Input() panelId: string;

  parsedHtml: SafeHtml = '';

  @Output()
  shouldFocusInput = new EventEmitter();

  get allowEdit() {
    return this.message.sender ? (this.message.sender.id === this.profileService.me.id) : false;
  }

  type = MessageType;
  status = MessageStatus;

  @Input()
  showAvatarPopover: boolean;
  @Input()
  showNamePopover: boolean;
  tradingViewMouseOver: boolean;
  overReaction: string = null;
  messageHovering: boolean;

  menuHovering = false;
  isReplyPanel = false;

  constructor(
    private mtf: MessageTextFormatterService,
    private domSanitizer: DomSanitizer,
    private profileService: ProfileService,
    private hostElement: ElementRef,
    private panelService: PanelsService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private pinboardMessagesService: PinboardMessagesService,
    private bookmarkMessagesService: BookmarkMessagesService,
  ) {
    super();
  }

  ngOnInit() {
    this.isReplyPanel = this.timelineType === TimelineType.RepliedMessages;

    this.subscribe(this.panelService.messageMouseoverTradingView$, ([panelId, msgId, overTradingView, overReaction]) => {
      if (panelId !== this.panelId) {
        return;
      }
      const newHovering = msgId === this.message.id
      const newTradingViewMouseOver = newHovering && overTradingView;
      const newOverReaction = (newHovering && overReaction) ? overReaction : null;
      if (this.messageHovering !== newHovering || this.tradingViewMouseOver !== newTradingViewMouseOver || this.overReaction !== newOverReaction) {
        this.messageHovering = newHovering;
        this.tradingViewMouseOver = newTradingViewMouseOver;
        this.overReaction = newOverReaction;
        this.cd.detectChanges();
      }
    });

    this.subscribe(this.messageService.messageReplacementReceived$, r => {
      if (r.replace.id === this.message.id) {
        this.message = { ...r.replace, isCollapsed: this.message.isCollapsed, firstMessageOfDay: this.message.firstMessageOfDay, localVersion: this.message.localVersion ? this.message.localVersion + 1 : 1 };
        this.cd.detectChanges();
      }
    });

    this.subscribe(this.messageService.messageFailed$, msgId => {
      if (this.message.id === msgId) {
        this.cd.detectChanges();
      }
    });

    this.subscribe(this.messageService.messageReactionsChanged$, e => {
      if (e.id === this.message.id) {
        this.message.reactions = e.reactions;
        this.cd.detectChanges();
      }
    });

    this.subscribe(this.bookmarkMessagesService.bookmarkMessageAdded$, (b: Events.BookmarkMessageAdded) => {
      if (this.message.id === b.message.id) {
        this.message.bookmarked = true;
      }
    });
    this.subscribe(this.bookmarkMessagesService.bookmarkMessageRemoved$, (b: Events.BookmarkMessageRemoved) => {
      if (this.message.id === b.messageId) {
        this.message.bookmarked = false;
      }
    });
    this.subscribe(this.pinboardMessagesService.pinboardMessageAdded$, (b: Events.PinboardMessageAdded) => {
      if (this.message.id === b.message.id) {
        this.message.pinned = true;
      }
    });
    this.subscribe(this.pinboardMessagesService.pinboardMessageRemoved$, (b: Events.PinboardMessageRemoved) => {
      if (this.message.id === b.messageId) {
        this.message.pinned = false;
      }
    });
  }

  isTradingView() {
    return this.message.urlData.type === 'TRADINGVIEW';
  }

  onMenuHoverChange($event: boolean) {
    this.menuHovering = $event;
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
