import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageTextFormatterService } from '../../_core/services/message-text-formatter.service';
import { FileUploaded } from '../../_shared/models/commons/filestack.models';
import { ToastrService } from 'ngx-toastr';
import { DateService } from '../../_core/services/date.service';
import { FileUploadService } from '../../_core/services/file-upload.service';
import { MAX_CHARS, MAX_CHARS_THRESHOLD, MAX_EDIT_TIMESPAN, MessageService } from '../../_core/services/message.service';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';
import { RULE } from '../../_shared/models/commons/permissions.constants';
import { ItemType } from '../../_shared/models/room/channel';

@Component({
  selector: 'app-market-overview',
  templateUrl: './market-overview.component.html',
  styleUrls: ['./market-overview.component.scss']
})
export class MarketOverviewComponent implements OnInit, OnDestroy {

  @Input() chatroomId: string;
  @Input() textToEdit: string = null;
  @Input() files: FileAttachment[];
  @Input() isTeamChatroom: boolean;
  @Input() isReplyPanel = false;

  @Input() senderId: string;
  @Input() messageTimestamp: Date;
  timeLeft: number;
  updateInterval: any;

  loading = false;
  subjectField = new FormControl(null);
  textField = new FormControl(null, [Validators.required]);
  linkField = new FormControl(null, [Validators.pattern(new RegExp('^(http|https):\/\/[^ "]+[.][^ "]+$'))]);
  submitted = false;
  charsLeft: number = null;
  messageLength: { headline: number, description: number, url: number } = { headline: 0, description: 0, url: 0 };
  chatrooms = null;
  marketForm: FormGroup;
  rules = RULE;

  fileToUpload: File | Blob;
  uploading = false;
  uploadImagePreview: string;
  uploadPercent = 0;
  cancelUploadToken = {};

  get isAdmin() {
    if (this.senderId === this.profileService.me.id &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      return true;
    }
    return false;
  }

  get canEdit() {
    if (this.isAdmin && this.chatroomId.indexOf('chr_') === 0) {
      return true;
    }
    return this.timeLeft < MAX_EDIT_TIMESPAN;
  }

  get canEditMessage() {
    if (this.isAdmin && this.chatroomId.indexOf('chr_') === 0) {
      return '';
    }

    if (this.canEdit) {
      const timespan = this.dateService.fromTimespan(MAX_EDIT_TIMESPAN - this.timeLeft);
      return `${timespan.mins}:${timespan.seconds.toString().padStart(2, '0')} left to edit`;
    }

    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    return 'You can only edit a message for 5 minutes after posting it';
  }

  get isInReplyMode(): boolean {
    if (this.isReplyPanel) {
      return true;
    }
    return !!this.messageService.isInReplies[this.chatroomId];
  }

  constructor(
    private activeModal: NgbActiveModal,
    private messageService: MessageService,
    private dateService: DateService,
    private teamService: TeamService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private builder: FormBuilder,
    private uploadService: FileUploadService,
    private mtf: MessageTextFormatterService
  ) { }

  ngOnInit() {
    if (this.textToEdit) {
      this.timeLeft = Date.now() - this.messageTimestamp.getTime();
      this.updateInterval = setInterval(
        () => {
          this.timeLeft = Date.now() - this.messageTimestamp.getTime();
        },
        1000);

      const lines = this.textToEdit.split('\r\n');
      const lastLine = lines[lines.length - 1];
      const firstLine = lines[0];
      let title: string;
      if (firstLine.indexOf('**') === 0 && firstLine.lastIndexOf('**') === firstLine.length - 2) {
        title = firstLine.replace(/\*\*/g, '');
        if (title) {
          const decodedTitle = this.mtf.decodeHTMLEntities(title);
          this.subjectField.patchValue(decodedTitle);
        }
      }
      if ((lastLine.indexOf('http://') === 0 || lastLine.indexOf('https://') === 0)
        && lastLine.trimRight().indexOf(' ') === -1) {
        const url = lastLine.trimRight();
        // lines should be split by \n just like initial input, otherwise continuous edits add new lines like \r\r\r\r\n
        const text = lines.slice(title ? 1 : 0, -1).join('\n');
        const decodedText = this.mtf.decodeHTMLEntities(text);
        this.linkField.patchValue(url);
        this.textField.patchValue(decodedText);
      } else {
        const text = (title ? lines.slice(1) : lines).join('\n');
        const decodedText = this.mtf.decodeHTMLEntities(text);
        this.textField.patchValue(decodedText);
      }
    }
    this.marketForm = this.builder.group(
      {
        chatrooms: new FormArray([])
      });
    this.chatrooms = this.teamService.activeTeam.sidebarResolved.filter(s => ItemType[s.itemType] === ItemType.Channel && s.permissions.user && s.permissions.user[this.rules.POST_MARKET_ANALYSIS]);
    this.addCheckboxes();
  }

  private addCheckboxes() {
    this.chatrooms.forEach((o, i) => {
      const control = new FormControl(this.chatroomId === o.refId); // true on current chatroom
      (this.marketForm.controls.chatrooms as FormArray).push(control);
    });
  }

  get fullMessageLength() {
    return this.messageLength.headline + this.messageLength.description + this.messageLength.url;
  }

  async send() {
    const selectedChatroomIds = this.marketForm.value.chatrooms
      .map((v, i) => (v ? this.chatrooms[i].refId : null))
      .filter(v => v !== null);

    if (selectedChatroomIds.length === 0 && this.isTeamChatroom) {
      this.toastr.error('Please select at least one chatroom');
      return;
    }

    this.submitted = true;
    if (this.textField.valid && this.linkField.valid) {
      // tslint:disable-next-line: prefer-template
      const text = `${this.subjectField.value ? '**' + this.subjectField.value + '**\r\n' : ''}${this.linkField.value ? `${this.textField.value.replace(/\n/g, '\r\n')}\r\n${this.linkField.value}` : `${this.textField.value.replace(/\n/g, '\r\n')}`}`;

      this.activeModal.close({ text, files: this.files, chatrooms: selectedChatroomIds });
      console.log(text);
    }
  }

  typing(text: string, field: number) {
    // must account for bold characters added for headline and for multiline description (\r\n accounts for 2 characters while \n for 1, so we musy compensate)
    if (field === 0) {
      this.messageLength.headline = this.subjectField.value ? (this.subjectField.value.trim().length + 6 + ((this.subjectField.value || '').match(/\n/g) || []).length) : 0;
    } else if (field === 1) {
      this.messageLength.description = this.textField.value ? (this.textField.value.trim().length + ((this.textField.value || '').match(/\n/g) || []).length) : 0;
    } else if (field === 2) {
      this.messageLength.url = this.linkField.value ? this.linkField.value.trim().length : 0;
    }

    const charsLeft = MAX_CHARS - this.fullMessageLength;
    this.charsLeft = charsLeft <= MAX_CHARS_THRESHOLD ? charsLeft : null;
  }

  async importFile(event) {
    if (this.messageTimestamp) {
      return;
    }

    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return
    }
    this.fileToUpload = event.target.files[0];

    this.previewImage();
    this.beginUploading();
  }

  private beginUploading() {
    if (this.fileToUpload.size > 100 * 1024 * 1024) {
      this.toastr.error('File is too big. The accepted file size is less than 100MB.');
      this.clearFiles();
      return;
    }
    this.uploading = true;
    this.uploadService.uploadFilesImmediately(this.fileToUpload, this.cancelUploadToken, (evt) => { this.onUploadProgress(evt); }).toPromise()
      .then(async (file: FileUploaded) => {
        const attachments = [{
          filename: file.filename,
          mime: file.mimetype,
          url: file.url,
          handle: file.handle,
        }];
        this.files = attachments;
      })
      .catch(() => {
        this.clearFiles();
        this.toastr.error('Could not upload file.');
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  onUploadProgress(evt) {
    this.uploadPercent = evt.totalPercent;
  }

  previewImage() {
    if (this.fileToUpload.type.indexOf('image/') < 0) {
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(this.fileToUpload);

    reader.onload = () => {
      this.uploadImagePreview = reader.result as string;
    };
  }

  clearFiles() {
    this.fileToUpload = null;
    this.uploading = false;
    this.uploadPercent = 0;
    this.uploadImagePreview = null;
    this.files = null;
  }

  closeModal() {
    if (this.cancelUploadToken && this.cancelUploadToken['cancel']) {
      this.cancelUploadToken['cancel']();
    }
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }
}
