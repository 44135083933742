import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  is24h: boolean = true;
  timeZone: string = null;
  timezoneChanged$: Subject<string> = new Subject<string>();
  timeformatChanged$: Subject<boolean> = new Subject<boolean>();

  now: number = (new Date()).valueOf();
  private interval: any;

  constructor(
  ) {
    this.interval = window.setInterval(
      () => { this.now = (new Date()).valueOf(); },
      10 * 1000);
  }

  // public setTimeZone(timeZone: string) {
  //   Settings.defaultZoneName = timeZone;
  //   this.timezoneChanged$.next(timeZone);
  // }s

  // https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
  public getLocalDateFormatted(utcDate: number, format: string): string {
    return DateTime.fromMillis(utcDate).toFormat(format);
  }

  public getLocalTime(utcDate: number): string {
    let format;
    if (!this.is24h) {
      format = 'h:mm a';
    } else {
      format = 'HH:mm';
    }
    return DateTime.fromMillis(utcDate).toFormat(format);
  }

  public getDayHeader(date: Date): string {
    const today = DateTime.local();
    const yesterday = DateTime.local().minus({ days: 1 });
    const dateComp = DateTime.fromJSDate(date).toLocal();

    if (today.day === dateComp.day &&
      today.month === dateComp.month &&
      today.year === dateComp.year) {
      return 'Today';
    }

    if (yesterday.day === dateComp.day &&
      yesterday.month === dateComp.month &&
      yesterday.year === dateComp.year) {
      return 'Yesterday';
    }

    return dateComp.toFormat('MMMM dd, yyyy');
  }

  public isToday(date: Date): boolean {
    const today = DateTime.local();
    const dateComp = DateTime.fromJSDate(date).toLocal();
    return (today.day === dateComp.day &&
      today.month === dateComp.month &&
      today.year === dateComp.year);
  }

  public setTimeFormat(is24h: boolean) {
    this.is24h = is24h;
    this.timeformatChanged$.next(is24h);
  }

  public fromTimespan(timespanInMs: number) {
    let leftTimespan = timespanInMs;
    const days = Math.floor(leftTimespan / (1000 * 60 * 60 * 24));
    leftTimespan = leftTimespan - days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(leftTimespan / (1000 * 60 * 60));
    leftTimespan = leftTimespan - hours * (1000 * 60 * 60);

    const mins = Math.floor(leftTimespan / (1000 * 60));
    leftTimespan = leftTimespan - mins * (1000 * 60);

    const seconds = Math.floor(leftTimespan / (1000));
    leftTimespan = leftTimespan - seconds * (1000);

    return { days, hours, mins, seconds };
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[DATE-SRV]', 'color:#E91E93', ...arg);
    }
  }

}
