import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../_core/services/profile.service';
import { TeamService } from '../../../_core/services/team.service';
import { Chatroom } from '../../../_shared/models/room/room';
import { Team } from '../../../_shared/models/teams/team';
import { Me } from './../../../_shared/models/commons/me';
import { AppNotification } from './models/app-notification';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.scss']
})
export class NotificationsSettingsComponent implements OnInit {

  @Input() me: Me;

  notifications: AppNotification[] = [];
  team: Team;
  availableChannels: Chatroom[];
  selectedChannel: string;

  constructor(
    public notificationsService: NotificationsService,
    private teamService: TeamService,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.team = this.teamService.activeTeam;

    this.notifications = this.notificationsService.getTeamNotifications(this.team.id);
    this.setDefault(this.notifications.find(n => n.chatroomId === '*           '));
    this.loadAvailableChannelList();
    this.resolveChannelNames();
  }

  setDefault(defaultNotification: AppNotification) {
    if (defaultNotification) {
      defaultNotification.default = true;
    } else {
      this.notifications.push({
        userId: this.profileService.me.id,
        teamId: this.team.id,
        all: true,
        none: false,
        mentions: true,
        default: true,
        chatroomId: '*           '
      });
    }
  }

  resolveChannelNames() {
    this.notifications.forEach((item) => {
      if (!item.default) {
        item.chatroomName = this.team.chatrooms.find(c => c.id === item.chatroomId).name;
      } else {
        item.chatroomName = 'Default';
      }
    });
  }

  loadAvailableChannelList() {
    this.availableChannels = [];

    // exclude already defined channel's
    this.team.chatrooms.forEach((item) => {
      const notification = this.notifications.find(n => n.chatroomId === item.id);
      if (notification === null || typeof notification === 'undefined') {
        this.availableChannels.push(item);
      }
    });
  }

  allNotificationClick(appNotification: AppNotification) {
    appNotification.none = false;
  }

  noneNotificationClick(appNotification: AppNotification) {
    appNotification.all = false;
  }

  async savePreferences() {
    await this.notificationsService
      .saveCurrentTeamNotifications(this.team.id, this.notifications);
  }

  addChannelNotification(chatroomId: string) {
    if (!this.selectedChannel) {
      return;
    }
    this.notifications.push({
      chatroomId,
      chatroomName: this.team.chatrooms.find(c => c.id === chatroomId).name,
      all: true,
      default: false,
      mentions: true,
      none: false,
      userId: this.profileService.me.id
    });
    this.loadAvailableChannelList();
    this.selectedChannel = ''; // set selected to nothing so it will reset
  }
}
