import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../base-component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { TeamService } from '../../_core/services/team.service';

@Component({
  selector: 'app-banned-team-user',
  templateUrl: './banned-team-user.component.html',
  styleUrls: ['./banned-team-user.component.scss'],
})
export class BannedTeamUserComponent extends BaseComponent implements OnInit {

  constructor(
    private teamService: TeamService,
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
  ) {
    super();
  }

  ngOnInit() {

  }

  leaveTeam() {
    const modal = this.modal.open(ConfirmComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.confirmTitle = 'Leave team';
    modal.componentInstance.confirmMessage = `You are about to leave ${this.teamService.activeTeam.name}. Are you sure?`;
    modal.result.then((result) => {
      if (result) {
        this.teamService.leaveActiveTeam();
        this.teamService.removeTeam(this.teamService.activeTeam.id);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
