import { Injectable } from '@angular/core';
import { SearchMessagesServiceApi } from '@echofin/libraries';
import { MessageType } from 'app/_shared/models/message/message-type';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { Message } from '../../_shared/models/commons/message';
import { MessageService, MESSAGES_PER_PAGE } from './message.service';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root'
})
export class SignalsMessagesService {
  constructor(
    private messageService: MessageService,
    private teamService: TeamService,
    private searchMessagesServiceApi: SearchMessagesServiceApi,
  ) { }

  async loadSignalMessages(panelId: string, teamId?: string, timestamp?: string) {
    if (timestamp && !this.messageService.hasMoreMessages[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }
    const messages = await this.load(
      timestamp,
      teamId ?? this.teamService.activeTeam.id
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessages[panelId] = false;
    } else {
      this.messageService.hasMoreMessages[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeSignalMessages(messages, this.messageService.hasMoreMessages[panelId]);
  }

  async load(timestamp: string, teamId: string) {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const signalMessages = await this.searchMessagesServiceApi.GetSearchMessages({
      Text: null,
      TeamId: teamId,
      Timestamp: timestampString,
      MessageTypes: [MessageType.SIGNAL],
      Limit: MESSAGES_PER_PAGE
    }).toPromise()
      .then((res) => {
        return res.messages;
      })
      .catch((err) => {
        return null;
      });

    if (!signalMessages) return;

    const mappedSignalMessages = signalMessages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedSignalMessages;
  }

  async loadSignalMessagesAfter(panelId: string, teamId?: string, timestamp?: string, includeTimestamp?: boolean) {
    if (timestamp && !this.messageService.hasMoreMessagesBelow[panelId]) {
      return;
    }

    if (!this.messageService.messages[panelId] || !timestamp) {
      this.messageService.messages[panelId] = [];
    }
    const messages = await this.loadAfter(
      timestamp,
      includeTimestamp,
      teamId ?? this.teamService.activeTeam.id
    );

    if (messages.length < MESSAGES_PER_PAGE) {
      this.messageService.hasMoreMessagesBelow[panelId] = false;
    } else {
      this.messageService.hasMoreMessagesBelow[panelId] = true;
    }

    messages.push(...this.messageService.messages[panelId]);
    this.messageService.messages[panelId] = MessageHelpers.organizeSignalMessages(messages, this.messageService.hasMoreMessages[panelId]);
  }

  async loadAfter(timestamp: string, includeTimestamp: boolean, teamId: string) {
    const timestampString = (timestamp) ? new Date(timestamp).toISOString() : '';

    const signalMessages = await this.searchMessagesServiceApi.GetSearchMessagesAfter({
      TeamId: teamId,
      Timestamp: timestampString,
      IncludeTimestamp: includeTimestamp,
      MessageTypes: [MessageType.SIGNAL],
      Limit: MESSAGES_PER_PAGE
    }).toPromise()
      .then((res) => {
        return res.messages;
      })
      .catch((err) => {
        return null;
      });

    if (!signalMessages) return;

    const mappedSignalMessages = signalMessages.map((m) => {
      const message: Message = {
        ...m,
        // status: MessageStatus.DELIVERED,
        timestamp: new Date(m.timestamp)
      };
      return message;
    });

    return mappedSignalMessages;
  }
}