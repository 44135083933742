import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-secret-form',
  templateUrl: './secret-form.component.html',
  styleUrls: ['./secret-form.component.scss']
})
export class SecretFormComponent implements OnInit {

  @ViewChild('title') titleEl: ElementRef;
  @ViewChild('secret') secretEl: ElementRef;
  @Input() title: string;
  secretForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    // this.inputText = new FormControl('', this.inputValidators);
    this.secretForm = new FormGroup({
      title: new FormControl(this.title, Validators.required),
      secret: new FormControl('', Validators.required),
      encryptKey: new FormControl('', [Validators.required, Validators.minLength(3)])
    });

    setTimeout(() => {
      if (this.title) {
        this.secretEl.nativeElement.focus();
      } else {
        this.titleEl.nativeElement.focus();
      }
    },         100);
  }

  onKeyDown(event) {
    this.submitted = false;
    setTimeout(() => {
      if (event.key === 'Enter') {
        this.send();
      }
    },         0);
  }

  send() {
    this.submitted = true;
    if (this.secretForm.valid) {
      this.activeModal.close(this.secretForm.value);
    }
  }

  close() {
    this.activeModal.close({
      title: null,
      secret: null,
      encryptKey: null
    });
  }
}
