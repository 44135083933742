import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { TeamService } from '../../_core/services/team.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { TrackingService } from '../../_core/services/tracking.service';

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit {

  @ViewChild('teamUrl', { static: true }) teamUrl: ElementRef;

  get teamname() {
    return this.teamService.activeTeam ? (this.teamService.activeTeam.label ? this.teamService.activeTeam.label : this.teamService.activeTeam.name) : '';
  }

  constructor(
    public activeModal: NgbActiveModal,
    private teamService: TeamService,
    private tracking: TrackingService
  ) { }

  ngOnInit() {
    (this.teamUrl.nativeElement as HTMLInputElement).value = this.getDomainPrefix();
  }

  getDomainPrefix() {
    if (this.teamService.activeTeam && this.teamService.activeTeam.customDomain) {
      return `https://${this.teamService.activeTeam.customDomain}`;
    }
    const teamName = (this.teamService.activeTeam && this.teamService.activeTeam.name && this.teamService.activeTeam.name.length > 0) ? this.teamService.activeTeam.name : 'app';

    return `https://${teamName}.${environment.config.domain}`;
  }

  preventChange() {
    return false;
  }

  copyLink() {
    this.track('copy');
    (this.teamUrl.nativeElement as HTMLInputElement).select();
    (this.teamUrl.nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
  }

  track(target: string) {
    this.tracking.trackEvent('invite-action', {
      target,
      teamname: this.teamService.activeTeam ? this.teamService.activeTeam.name : null,
      teamid: this.teamService.activeTeam ? this.teamService.activeTeam.id : null
    });
  }
}
