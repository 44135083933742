import { Injectable, OnDestroy } from '@angular/core';
import { empty, EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, groupBy, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../_core/services/message.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { EvType } from '../../../_core/services/socket.service/models/all.models';
import { SocketService } from '../../../_core/services/socket.service/socket.service';
import { ProfileChannelTyping } from '../models/profile-channel-typing';
import { RoomService } from './../../../_core/services/room.service';
import { Message, LocalMessageProcessed } from './../../../_shared/models/commons/message';

@Injectable({ providedIn: 'root' })
export class TypingService implements OnDestroy {
  public static HTTP_SEND_INTERVAL: number = 7000;
  public static CHECK_REMOVE_OVERHEAD: number = 1000;

  public channelsTyping: { [roomId: string]: ProfileChannelTyping[] } = {};

  typingUpdated$ = new Subject<string>();

  meTyping: { [key: string]: number } = {};

  // holds outer and inner observables
  subs = {};

  constructor(
    private profileService: ProfileService,
    private socketService: SocketService,
    private messageService: MessageService,
    private roomService: RoomService,
  ) {
    this.typingEventHandler();
    this.incomingMessageHandler();
  }

  typingEventHandler() {
    // listen for socket typing events
    this.subs['OuterTyping'] = this.socketService
      .getStream(EvType.Typing)
      .pipe(
        // we don't care for current user's events
        filter(e => e.profileId !== this.profileService.me.id),
        tap(e => {
          if (!this.channelsTyping[e.chatroomId]) {
            this.channelsTyping[e.chatroomId] = [];
            this.typingUpdated$.next(e.chatroomId);
          }
          if (this.profileService.me.mutedUsers && this.profileService.me.mutedUsers.includes(e.profileId)) {
            return;
          }
          if ((this.channelsTyping[e.chatroomId].map(t => t.profileId).indexOf(e.profileId) < 0) && // when incoming typing, if profile not already in array
            !this.hasMessagesAfter(e.profileId, e.chatroomId, e.created)) {// and a later message from profile has not yet arrived
            // add to the channel typing array
            this.channelsTyping[e.chatroomId].push(new ProfileChannelTyping(e.profileId, e.profileName, e.chatroomId, e.created));
            this.typingUpdated$.next(e.chatroomId);
          }
        }),
        // this splits the outer obs to multiple inner obs
        // we need the sub to the outer obs to be able to create new inner obs when a new user types for the first time
        // we need the sub to the inner obs to be able to keep removing a user from the array whenever he stops typing
        // multiple "sessions" of typing belong to the same observable with this way of handling
        // playground for this logic:
        // https://stackblitz.com/edit/chr-is-typing-correct-sub-handle?devtoolsheight=33&file=index.ts
        // you can play around thinking of different event types as different users typing
        groupBy(e => e.chatroomId + e.profileId)
        // the groupBy key must NOT be an object and must contain the different parts to differentiate between debounces
        // we need to debounce the input of each USER in each CHATROOM, so we need a key combined of these two properties
      )
      .subscribe(obs => {
        if (!this.subs[obs.key]) {
          this.subs[obs.key] = obs.pipe(
            // value is already in array so debounce while new events come
            // time is minimum time between calls plus check some overhead between comminucations
            debounceTime(TypingService.HTTP_SEND_INTERVAL + TypingService.CHECK_REMOVE_OVERHEAD),
            takeWhile(e => !!this.channelsTyping[e.chatroomId])
          ).subscribe(e => {
            this.removeIsTyping(e.chatroomId, e.profileId);
          });
        }
      });
  }

  incomingMessageHandler() {
    // remove user from list when the message is processed
    this.socketService
      .getStream(EvType.MessageProcessed)
      .subscribe((m: LocalMessageProcessed) => {
        const message = m.message;
        this.removeIsTyping(message.chatroom.id, message.sender.id);
      });
  }

  removeIsTyping(chatroomId: string, profileId: string) {
    if (this.meTyping[chatroomId]) {
      this.meTyping[chatroomId] = null;
    }
    if (!this.channelsTyping[chatroomId]) {
      return;
    }
    const i = this.channelsTyping[chatroomId].map(t => t.profileId).indexOf(profileId);
    if (i > -1) {
      this.channelsTyping[chatroomId].splice(i, 1);
      this.typingUpdated$.next(chatroomId);
    }
  }

  hasMessagesAfter(userId: string, roomId: string, created: Date | string): boolean {
    created = new Date(created);
    const lastDate: Date = this.messageService.getLastDate(userId, roomId);
    if (lastDate == null) {
      return false;
    }
    if (created.getTime() <= lastDate.getTime()) {
      return true;
    }

    return false;
  }

  getTypingForChannel(channelId: string): ProfileChannelTyping[] {
    return this.channelsTyping[channelId];
  }

  private log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[IS-TYPING]', 'color:#E91E69', ...arg);
    }
  }

  //#region SEND DATA
  subscribeChatboxTyping(isTypingRoomId$: Observable<any>, roomId: string): Subscription {
    const sub = isTypingRoomId$
      .pipe(
        // map to room id, to keep updated, due to temporary local room ids
        map(e => roomId),
        switchMap(cid => {
          // console.log('TYPING', this.channelsTyping, cid, this.getTypingForChannel(cid)?.map(t => t.profileId), this.profileService.me.id);
          // if (this.getTypingForChannel(cid)?.map(t => t.profileId).indexOf(this.profileService.me.id) > -1) {
          //   return of(cid).pipe(throttleTime(TypingService.HTTP_SEND_INTERVAL));
          // } else {
          //   return of(cid);
          // }

          if (this.meTyping[cid] && (new Date()).getTime() - this.meTyping[cid] < TypingService.HTTP_SEND_INTERVAL) {
            return EMPTY;
          }
          return of(cid);
        })
      ).subscribe(chatroomId => {
        this.currentUserIsTyping(this.profileService.me.id, chatroomId);
      });

    return sub;
  }

  private currentUserIsTyping(profileId: string, roomId: string) {
    const typingDate = new Date();

    if (roomId.indexOf('local-id-') < 0) {

      if (profileId === this.profileService.me.id) {
        let participants = null;

        if (!roomId.startsWith('chr_')) {
          const room = this.roomService.getRoomById(roomId);
          if (!room) { return; }
          participants = room.participants.map(p => p.user.id);
        }

        this.meTyping[roomId] = (new Date()).getTime();

        this.socketService.typing({
          participants,
          room: roomId,
          userId: this.profileService.me.id,
          username: this.profileService.me.username
        });
      }
    }
  }
  //#endregion

  ngOnDestroy() {
    // this unsubs from inner observables as well
    for (const key in this.subs) {
      this.subs[key].unsubscribe();
    }
  }
}
