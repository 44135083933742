import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Events } from '@echofin/libraries';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';
import { Member } from '../../_shared/models/teams/member';
import { Team } from '../../_shared/models/teams/team';
import { BaseComponent } from '../base-component';
import { StartConversationComponent } from '../start-conversation/start-conversation.component';
import { UserPreferencesComponent } from '../user-preferences/user-preferences.component';
import { User } from '../../_shared/models/commons/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent extends BaseComponent implements OnInit {

  @Input() teamId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() avatar: string;

  // not required: if not defined, it will be loaded on init
  @Input() member: Member;

  itIsMe: boolean;
  showBan: boolean;
  isLoading: boolean = true;

  inTeam: boolean = false;

  commonTeams: Team[];

  user: User;

  get isMuted() {
    return this.profileService.me.mutedUsers.filter(m => m === this.userId).length > 0;
  }

  constructor(
    private profileService: ProfileService,
    private teamService: TeamService,
    private modal: NgbModal,
    private ch: ChangeDetectorRef,
    private toastr: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.itIsMe = this.userId === this.profileService.me.id;

    if (!this.itIsMe) {
      if (this.teamId) {
        this.member = await this.teamService.getMemberRequest(this.userId);
        if (!this.avatar) {
          this.avatar = this.member?.user?.avatar;
        }

        if (this.member && this.member.commonTeams) {
          this.commonTeams = this.teamService.teams.filter(v => {
            return this.member.commonTeams.indexOf(v.id) > -1;
          });
        }
        this.inTeam = !(this.member.commonTeams.filter(t => t === this.teamService.activeTeamId).length === 0);
      } else {
        this.user = await this.profileService.getUser(this.userId);
      }
    } else {
      if (!this.avatar) {
        this.avatar = this.profileService.me.avatar;
      }
    }

    this.showBan = this.teamService.activeTeam.permissions.ban_users && !this.itIsMe;

    if (this.member) {
      this.subscribe(
        this.teamService.teamMemberBanned$,
        (data: Events.MemberBanned) => {
          if (this.userId === data.userId) {
            this.member.isBanned = true;
          }
        });

      this.subscribe(
        this.teamService.teamMemberUnbanned$,
        (data: Events.MemberUnbanned) => {
          if (this.userId === data.userId) {
            this.member.isBanned = false;
          }
        });
    }
    this.isLoading = false;
  }

  async startDirect() {
    // const room = await this.roomService.addDirect(this.member);
    // this.profileService.openDirect(room.id);
    // return;
    // TODO: this is a hotfix until proper implementation
    const compRef = this.modal.open(StartConversationComponent, { backdrop: 'static', centered: true, windowClass: 'modal-dark' });
    compRef.componentInstance.dms = {}
    if (this.member) {
      compRef.componentInstance.users = [this.member.user];
      compRef.componentInstance.dms[this.member.user.id] = true;
    } else {
      compRef.componentInstance.users = [{ id: this.userId, username: this.username, avatar: this.avatar }];
      compRef.componentInstance.dms[this.userId] = true;
    }
  }

  async toggleMute() {
    this.profileService.toggleMute(this.userId).then((muted) => {
      if (muted) {
        this.toastr.success('User muted!');
      } else {
        this.toastr.success('User unmuted!');
      }
    }).catch(() => {
      this.toastr.error('Action Failed');
    });
  }

  async toggleBan() {
    // empty
    await this.teamService.toggleBan(this.userId, this.teamId);
  }

  openPreferences() {
    const modal = this.modal.open(UserPreferencesComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg',
    });
    modal.componentInstance.me = this.profileService.me;

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
