<div class="text-center mb-3">
  {{ plan.label }}
</div>
<p>Use a Stripe stored card <a href="javascript:void" (click)="switch()">or add a new</a></p>
<ng-container *ngIf="cards">
  <div ngbDropdown class="mb-2 ">
    <button class="btn btn-block btn-outline-primary btn-lg text-left" ngbDropdownToggle>
      <ng-container *ngTemplateOutlet="card;context:selected"></ng-container>
    </button>
    <div ngbDropdownMenu class="w-100">
      <ng-container *ngFor="let p of cards">
        <button ngbDropdownItem (click)="selectedPm = p" class="">
          <ng-container *ngTemplateOutlet="card;context:p"></ng-container>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
<button class="btn btn-success btn-block" (click)="authorize()">
  Authorize Payment</button>

<ng-template #card let-card="card">
  <ng-container [ngSwitch]="card.brand">
    <ng-container *ngSwitchCase="'visa'">
      <i class="fab fa-cc-visa"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'jcb'">
      <i class="fab fa-cc-jcb"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'diners'">
      <i class="fab fa-cc-diners-club"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'discover'">
      <i class="fab fa-cc-discover"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'amex'">
      <i class="fab fa-cc-amex"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'mastercard'">
      <i class="fab fa-cc-mastercard"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <i class="far fa-credit-card"></i>
    </ng-container>
  </ng-container>
  <span class="px-3 text-monospace">•••• {{card.last4}}</span>
  <small>{{(card.exp_month+"").padStart(2, '0')}}/{{card.exp_year}}</small>
</ng-template>