import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() confirmMessage: string;
  @Input() confirmTitle: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.activeModal.close(true);
  }

  reject() {
    this.activeModal.close(false);
  }
}
