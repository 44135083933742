<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Formatting & Commands</h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-striped table-borderless">
                        <tbody>
                            <tr>
                                <td>Bold</td>
                                <td>**hello there**</td>
                                <td><b>hello there</b></td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>_hello there_</td>
                                <td><em>hello there</em></td>
                            </tr>
                            <tr>
                                <td>Underline</td>
                                <td>__hello there__</td>
                                <td><span style="text-decoration: underline;">hello there</span></td>
                            </tr>
                            <tr>
                                <td>Highlight</td>
                                <td>hello `there`</td>
                                <td>hello <code>there</code></td>
                            </tr>
                            <tr>
                                <td>Highlighted Block</td>
                                <td>```hello there```</td>
                                <td><code class="block">hello there</code></td>
                            </tr>
                            <tr>
                                <td>Secret Message</td>
                                <td>/secret message-subject</td>
                                <td>Password-protected message</td>
                            </tr>
                            <tr>
                                <td>Shout</td>
                                <td>/shout My message</td>
                                <td>
                                    <div class="shout_container">
                                        <div class="shout_txt"><i class="fas fa-bullhorn fa-fw"></i> My message</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>