<div class="add-team">
    <div class="modal-header">
        <h4 class="modal-title">What do you want to do?</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex p-3 justify-content-between">
            <a [routerLink]="['/discover']" class="btn d-flex flex-column justify-content-center btn-add-team"
                (click)="trackEvent('discover')">
                <i class="fad fa-compass fa-fw fa-4x m-auto"></i>
                <br>
                Discover teams<br><br>
            </a>
            <a [routerLink]="['/create']" class="btn d-flex flex-column justify-content-center btn-add-team"
                (click)="trackEvent('creation')">
                <i class="fad fa-users-crown fa-fw fa-4x m-auto"></i>
                <br>
                Create your team<br><br>
            </a>
        </div>
        <div *ngIf="isAdmin" class="text-muted">
            <small><i class="far fa-info-circle"></i> Remove Team Discovery & Creation from your users' workspace with
                the Business or Enterprise <a href="https://www.echofin.com/plans/?utm_source=team-discovery"
                    target="_blank">plan</a></small>
        </div>
    </div>
</div>