<div class="text-center p-4" *ngIf="!loading && status==='request_success'">
    <div class="text-success mb-4">
        <div class="mb-2">
            <i class="fal fa-check-circle fa-4x"></i>
        </div>
        <h4>Thank you!</h4>
    </div>
    <p>If the email or username corresponds to a user in our system, you will shortly receive an email.</p>
    <p><small>Please follow the included link to reset your password.</small></p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='success'">
    <div class="text-success mb-4">
        <div class="mb-2">
            <i class="fal fa-check-circle fa-4x"></i>
        </div>
        <h4>Your password has changed!</h4>
    </div>
    <p></p>
    <p>You can sign in <a [ngStyle]="{'color': colorCode}" routerLink="/login">here</a>.</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='LINK_ALREADY_USED'">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>This link has already been used.</h4>
    </div>
    <p>If you mistyped your email<br /><a [routerLink]="['/reset-password']" [queryParams]="{}">try again</a>.</p>
</div>

<div *ngIf="!loading && status==='INVALID_TOKEN'" class="text-center p-4">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>Something is wrong with the link you provided.</h4>
    </div>
    <div class="text-light">
        <p>You can try to reset your password again <br /><a [ngStyle]="{'color': colorCode}"
                [routerLink]="['/reset-password']" [queryParams]="{}">here</a>.</p>
    </div>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='fail-generic'">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>Oh no!</h4>
    </div>
    <p>Something went wrong</p>
    <p><a [ngStyle]="{'color': colorCode}" [routerLink]="['/reset-password']" [queryParams]="{}">try again</a>.</p>
</div>


<div *ngIf="status==='start'||status==='validation-error'">

    <div class="header">
        <h1>Reset password</h1>
        <p>Fill in your username or email and we will send you an email. Follow the link inside to change your password
        </p>
    </div>
    <form #reqForm="ngForm">
        <div class="custom-form-group">
            <input class="custom-form-control" [formControl]="emailOrUsername" type="text"
                placeholder="Email or Username">
        </div>
        <p *ngIf="emailOrUsername.touched && emailOrUsername.invalid && emailOrUsername.errors.required"
            class="text-danger">
            <small>Please fill in your username or email</small>
        </p>
        <div class="text-right">
            <button type="button" (click)="requestReset()"
                [ngStyle]="{'background-color': colorCode, color:contrastColor}" [disabled]="loading">
                <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
                <span class="ml-2">RESET</span>
            </button>
        </div>
    </form>
    <div class="info">
        <p>Do you have an account? Please <a routerLink="/login" [ngStyle]="{'color': colorCode}">Sign In</a> instead.
        </p>
    </div>
</div>

<div *ngIf="status==='change'||status==='validation-error'">
    <div class="header">
        <h1>Reset password</h1>
        <p>Fill in your new password below and click "Change"</p>
    </div>
    <form #resetForm="ngForm">
        <div class="custom-form-group">
            <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [formControl]="resetpass"
                type="password" placeholder="New password">
        </div>
        <div class="custom-form-group">
            <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [formControl]="confirmpass"
                type="password" placeholder="Confirm new password">
        </div>
        <div class="invalid-feedback d-block">
            <small class="d-block" *ngIf="submitted && resetpass.errors && resetpass.errors.required">
                Please fill in your password</small>
            <small class="d-block" *ngIf="submitted && resetpass.errors && resetpass.errors.pattern">
                No white space allowed in password</small>
            <small class="d-block" *ngIf="submitted && resetpass.errors && resetpass.errors.minlength">
                Password must be at least 6 characters long</small>
            <small class="d-block" *ngIf="submitted && confirmpass.errors && confirmpass.errors.matches">
                Passwords do not match</small>
            <small class="d-block" *ngIf="submitted && confirmpass.errors && confirmpass.errors.required">
                Please confirm your new password</small>
        </div>
        <button type="button" (click)="reset()" [ngStyle]="{'background-color': colorCode, color:contrastColor}"
            [disabled]="loading">
            <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
            <i *ngIf="!loading" class="fas fa-check-circle fa-fw"></i>
            <span class="ml-2">Change</span>
        </button>
    </form>
</div>