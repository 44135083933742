<div class="modal-header">
    <button class="close" type="button" (click)="m.dismiss()">
        <span>×</span>
    </button>
</div>
<div class="modal-body">
    <i class="fal fa-lock" style="font-size: 3rem;color: #aaa;margin-bottom: 1rem;"></i>
    <h4>Service locked</h4>
    <div>Please join any of the following chatrooms to unlock the Service.</div>
    <div class="text-center">{{unlockChatrooms}}</div>
</div>