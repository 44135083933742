<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">What's New</h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss('manual')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="carousel">
            <ul class="slides">
                <ng-container *ngFor="let slide of whatsNewSlides; let i=index">
                    <input type="radio" name="radio-buttons" id="img-{{i}}" [checked]="i===0" />
                    <li class="slide-container">
                        <div class="slide-image">
                            <div *ngIf="slide.img" class="img-wrapper"><img [src]="slide.img"></div>
                            <h6 class="text-white">{{slide.title}}</h6>
                            <p [innerHTML]="slide.desc" [ngClass]="{'no-image': !slide.img}"></p>
                            <div class="feedback" [ngClass]="{'single-slide': whatsNewSlides.length===1}">
                                <ng-container *ngIf="sentFeedbackTitle.indexOf(slide.title)<0">
                                    <i class="fas fa-laugh-beam fa-fw" title="I like this"
                                        (click)="trackEvent(slide.title, 'happy')"></i>
                                    <i class="fas fa-meh fa-fw" title="It's ok"
                                        (click)="trackEvent(slide.title, 'neutral')"></i>
                                    <i class="fas fa-frown fa-fw" title="I don't like this"
                                        (click)="trackEvent(slide.title, 'unhappy')"></i>
                                </ng-container>
                                <span *ngIf="sentFeedbackTitle.indexOf(slide.title)>=0">Thank you for your
                                    feedback!</span>
                            </div>
                        </div>
                    </li>
                </ng-container>
                <div class="carousel-dots" *ngIf="whatsNewSlides.length>1">
                    <label *ngFor="let slide of whatsNewSlides; let i=index" for="img-{{i}}" class="carousel-dot"
                        id="img-dot-{{i}}"></label>
                </div>
            </ul>
        </div>
    </div>
</div>