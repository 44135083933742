import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  action = '';
  extraData: ParamMap = null;
  path = '';

  constructor() { }

  public isMobileDevice() {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }
}
