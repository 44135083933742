import { Component, Input, OnInit, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { OrderType, Signal } from './../../../../_shared/models/message/signal';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { TimelineType } from '../../models/timeline-type.enum';
import { TeamService } from '../../../../_core/services/team.service';
import { ProfileService } from '../../../../_core/services/profile.service';
import { MessageStatus } from '../../../../_shared/models/commons/message';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';

declare var window: any;

@Component({
  selector: 'app-content-signal',
  templateUrl: './content-signal.component.html',
  styleUrls: ['./content-signal.component.scss']
})
export class ContentSignalComponent implements OnInit, OnDestroy {

  @Input()
  hasText: boolean;
  @Input()
  parsedHtml: SafeHtml;

  @Input() files: FileAttachment[];
  @Input() messageStatus: MessageStatus;

  parsedSignalSymbol: SafeHtml;

  @Input() signal: Signal;
  orderTypeEnum = OrderType;

  @Input() searchText: string;
  @Input() timelineType: TimelineType;
  @Input() messageId: string;
  @Input() fullView: boolean = false;
  @Input() fullViewType: string;

  status = MessageStatus;

  constructor(
    private domSanitizer: DomSanitizer,
    private hostElement: ElementRef,
    private teamService: TeamService,
    private profileService: ProfileService,
  ) { }

  ngOnInit() {
    if (this.timelineType === TimelineType.SearchMessages && this.signal.symbol) {
      this.parsedSignalSymbol = this.domSanitizer.bypassSecurityTrustHtml(this.parseHighlight(this.signal.symbol, this.searchText));
    }
  }

  private parseHighlight(text: string, searchtext: string) {
    const searcharr = searchtext.split(' ');
    let returnedText = text;
    searcharr.forEach(element => {
      returnedText = returnedText.replace(new RegExp(element, 'ig'), (a, b) => {
        return `<mark>${a}</mark>`;
      });
    });
    return returnedText;
  }

  getSignalOrderType(type: OrderType) {
    switch (this.orderTypeEnum[type]) {
      case OrderType.BUY:
        return 'BUY';
      case OrderType.BUY_LIMIT:
        return 'BUY LIMIT';
      case OrderType.BUY_MARKET:
        return 'BUY MARKET';
      case OrderType.BUY_STOP:
        return 'BUY STOP';
      case OrderType.SELL:
        return 'SELL';
      case OrderType.SELL_LIMIT:
        return 'SELL LIMIT';
      case OrderType.SELL_MARKET:
        return 'SELL MARKET';
      case OrderType.SELL_STOP:
        return 'SELL STOP';
      case OrderType.NONE:
        return 'SYMBOL';
      default:
        return null;
    }
  }

  getSignalClassType(type: string) {
    switch (this.orderTypeEnum[type]) {
      case OrderType.BUY:
        return 'buy';
      case OrderType.BUY_LIMIT:
        return 'buy';
      case OrderType.BUY_MARKET:
        return 'buy';
      case OrderType.BUY_STOP:
        return 'buy';
      case OrderType.SELL:
        return 'sell';
      case OrderType.SELL_LIMIT:
        return 'sell';
      case OrderType.SELL_MARKET:
        return 'sell';
      case OrderType.SELL_STOP:
        return 'sell';
      case OrderType.NONE:
        return 'none';
      default:
        return 'none';
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
