import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeCardElementOptions, StripeElement, StripeElements } from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ProfileService } from '../../../_core/services/profile.service';
import { EcfnStipeService } from '../../../_core/services/stripe.service';
import { TeamService } from './../../../_core/services/team.service';

@Component({
  selector: 'app-billing-card-form',
  templateUrl: './billing-card-form.component.html',
  styleUrls: ['./billing-card-form.component.scss']
})
export class BillingCardFormComponent implements OnInit {

  loading;

  @Output() authorized = new EventEmitter<{ paymentId: string, correlationId: string }>();

  @ViewChild('card', { static: true }) cardElem: ElementRef;

  paymentForm: FormGroup;
  elements: StripeElements;
  cardElement: StripeElement;
  error: string;
  formSubmitAttempt: boolean;

  // get countries() {
  //   return this.countriesService.countries;
  // }

  constructor(
    private builder: FormBuilder,
    // private countriesService: CountriesService,
    private http: HttpClient,
    private profileService: ProfileService,
    // private stripeFactory: StripeFactoryService,
    private teamService: TeamService,
    private toastr: ToastrService,
    private stipeService: EcfnStipeService,
  ) {
  }

  async ngOnInit() {
    this.paymentForm = this.builder.group({
      name: ['', Validators.required],
      // street: ['', Validators.required],
      // postalCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      // city: ['', Validators.required],
      // country: ['', Validators.required],
    });


    this.stipeService.stripe.elements().subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      setTimeout(
        () => {
          if (!this.cardElement) {
            this.cardElement = this.elements.create('card', {
              style: {
                base: {
                  iconColor: '#ccc',
                  color: '#fff',
                  lineHeight: '40px',
                  fontWeight: 300,
                  '::placeholder': {
                    color: '#929292'
                  }
                }
              }
            } as unknown as StripeCardElementOptions);
            this.cardElement.mount(this.cardElem.nativeElement);
          }
        },
        200);
    });
    // await this.countriesService.setup();
  }

  async authorize() {
    this.formSubmitAttempt = true;
    this.paymentForm.clearValidators();
    Object.keys(this.paymentForm.controls).forEach(field => { // {1}
      const control = this.paymentForm.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });

    if (this.paymentForm.invalid) {
      console.log(this.paymentForm);
      return;
    }

    this.loading = true;

    const setupInt = await this.http
      .post<any>(`${environment.config.endpoints['billing']}/billing/cards/setup`, {
        userId: this.profileService.me.id
      })
      .toPromise()
      .catch((err) => {
        this.error = 'Could not setup card';
        this.loading = false;
      });

    if (!setupInt) {
      this.toastr.error('Could not setup card. Please try later');
      return;
    }

    const stripeInstance: any = this.stipeService.stripe.getInstance();
    const handleCardSetupRes = await stripeInstance.confirmCardSetup(setupInt.clientSecret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          name: this.paymentForm.controls['name'].value,
          // address: {
          //   line1: this.paymentForm.controls['street'].value,
          //   line2: null,
          //   postal_code: this.paymentForm.controls['postalCode'].value,
          //   country: this.paymentForm.controls['country'].value,
          //   city: this.paymentForm.controls['city'].value,
          //   state: null
          // }
        }
      }
    });

    if (handleCardSetupRes.error) {
      this.error = `Could not setup card. ${handleCardSetupRes.error.message}`;
      this.loading = false;
      return;
    }

    await this.http.post(
      `${environment.config.endpoints['billing']}/billing/cards/confirm`,
      {
        userId: this.profileService.me.id,
        paymentMethodId: handleCardSetupRes.setupIntent.payment_method
      },
      {
        headers: {
          'Echofin-Correlation-Id': setupInt.correlationId
        }
      }
    ).toPromise()
      .then(() => {
        this.authorized.emit({ paymentId: handleCardSetupRes.setupIntent.payment_method, correlationId: setupInt.correlationId });
      })
      .catch((err) => {
        this.error = `Could not setup card. ${err.message}`;
        this.loading = false;
      });
  }

  isFieldValid(field: string) {
    return (!this.paymentForm.get(field).valid && this.paymentForm.get(field).touched) ||
      (this.paymentForm.get(field).untouched && this.formSubmitAttempt);
  }
}
