import { Injectable } from '@angular/core';
import { FilestackPolicyServiceApi } from '@echofin/libraries';
import { FilestackService } from '@filestack/angular';
import { PickerFileMetadata, PickerInstance } from 'filestack-js';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FileUploaded } from '../../_shared/models/commons/filestack.models';

const defaultOptions: any = {
  maxSize: 100 * 1024 * 1024,
  maxFiles: 1,
  storeTo: {
    // THESE ARE NOT NEEDED! THEY WERE COPIED FROM THE FILESTACK ADMIN DASHBOARD EXAMPLES AND ARE THE REASON OF ERROR ON VIDEO/AUDIO CAPTURE
    // IF WE USE A CLOUD PROVIDER IN THE DASHBOARD, IT WILL USE IT BY DEFAULT IF WE LEAVE THIS EMPTY
    // (EVEN WHEN WE PASSED location: S3 AND HAD SET AZURE PROVIDER, IT UPLOADED TO AZURE AND NOT THE DEFAULT FILESTACK SPACE ON S3)
    // container: 'devportal-customers-assets',
    // path: 'user-uploads/',
    // region: 'us-east-1'
  },
  uploadInBackground: false,
};

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  //view only policies
  policy: string;
  signature: string;

  pickerFiles: PickerInstance;
  pickerAvatar: PickerInstance;
  pickerLogo: PickerInstance;
  pickerPaste: PickerInstance;

  files$: Subject<FileUploaded[]> = new Subject<FileUploaded[]>()

  constructor(
    private toastr: ToastrService,
    private fsApi: FilestackPolicyServiceApi,
    private filestackService: FilestackService,
  ) {
  }

  async setup() {

    const viewOnlySecurity = await this.fsApi.GetDefaultPolicy().toPromise();
    this.policy = viewOnlySecurity.policy
    this.signature = viewOnlySecurity.signature

    const pickerPolicy = await this.fsApi.GetPickerPolicy().toPromise();
    this.filestackService.init(environment.config.filestackKey, {
      security: {
        policy: pickerPolicy.policy,
        signature: pickerPolicy.signature
      },
      cname: 'fs.echofin.com'
    });

    this.pickerFiles = await this.filestackService.picker({
      ...defaultOptions,
      // accept: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.csv', '.zip', '.rar', '.tar', 'image/jpeg', 'image/*', 'video/*', 'video/mp4', 'audio/*', 'application/*', 'text/*',],
      fromSources: ['local_file_system', 'webcam', 'video', 'audio', 'url', 'facebook', 'googledrive', 'dropbox'],
      onUploadDone: (res) => this.onUploadDone(res),
      onClose: () => this.onClose(),
    })
  }

  async pickFiles(): Promise<FileUploaded[]> {

    if (!this.pickerFiles) {
      this.pickerFiles = await this.filestackService.picker({
        ...defaultOptions,
        // accept: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.csv', '.zip', '.rar', '.tar', 'image/jpeg', 'image/*', 'video/*', 'video/mp4', 'audio/*', 'application/*', 'text/*',],
        fromSources: ['local_file_system', 'webcam', 'video', 'audio', 'url', 'facebook', 'googledrive', 'dropbox'],
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerFiles.open();

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  async pickLogo(): Promise<FileUploaded[]> {

    if (!this.pickerLogo) {
      this.pickerLogo = await this.filestackService.picker({
        ...defaultOptions,
        maxFiles: 1,
        accept: ['image/*'],
        fromSources: ['local_file_system', 'webcam', 'url', 'facebook', 'googledrive', 'dropbox'],
        imageMax: [512, 512],
        transformations: {
          crop: {
            aspectRatio: 1
          },
        },
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerLogo.open();

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  async pickAvatar(): Promise<FileUploaded[]> {

    if (!this.pickerAvatar) {
      this.pickerAvatar = await this.filestackService.picker({
        ...defaultOptions,
        accept: ['image/*'],
        fromSources: ['local_file_system', 'webcam', 'url', 'facebook', 'googledrive', 'dropbox'],
        imageMax: [512, 512],
        transformations: {
          crop: {
            aspectRatio: 1,
            force: true
          },
          circle: true,
          rotate: true
        },
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerAvatar.open();

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  async pickImagePaste(blob: any): Promise<FileUploaded[]> {

    if (!this.pickerPaste) {
      this.pickerPaste = await this.filestackService.picker({
        ...defaultOptions,
        accept: ['image/*'],
        fromSources: ['local_file_system', 'webcam', 'url', 'facebook', 'googledrive', 'dropbox'],
        transformations: {
          crop: {
            force: true
          },
          circle: true,
          rotate: true
        },
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerPaste.crop(blob);

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  private onUploadDone(res) {
    if (res.filesFailed.length) {
      const failed: PickerFileMetadata[] = res.filesFailed;
      const fNames = failed.reduce((prev, curr) => `${prev} ${curr.filename}`, '');
      this.toastr.error(`${fNames} failed to be uploaded`);
      this.files$.next([])
    }
    if (res.filesUploaded.length) {
      const files = res.filesUploaded.map(f => new FileUploaded(f));
      this.files$.next(files)
    }
  }

  private onClose() {
    setTimeout(() => {
      this.files$.next([])
    }, 500);
  }

  uploadFilesImmediately(files: File | Blob, cancelToken: any, onProgress: (evt) => void) {
    return this.filestackService.upload(files, { onProgress, progressInterval: 100 }, {}, cancelToken);
  }
}
