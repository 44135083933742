export class ProfileChannelTyping {
  profileId?: string;
  profileName?: string;
  channelId?: string;
  date?: Date;
  constructor(profileId?: string, profileName?: string, channelId?: string, date?: Date) {
    this.profileId = profileId;
    this.profileName = profileName;
    this.channelId = channelId;
    this.date = date;
  }
}
