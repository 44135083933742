import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BrandingService } from '../../_core/services/branding.service';
import { ProfileRegisterServiceApi } from '@echofin/libraries';
import { environment } from './../../../environments/environment';
import { LoginService } from '../_commons/login.service';
import { MobileService } from '../_commons/mobile.service';
import { Router, ActivatedRoute, convertToParamMap, Params } from '@angular/router';

@Component({
  selector: 'app-pin-registration',
  templateUrl: './pin-registration.component.html',
  styleUrls: ['./pin-registration.component.scss']
})
export class PinRegistrationComponent implements OnInit {
  loading: boolean;
  email = new FormControl('', [Validators.required, Validators.email]);
  status: string = 'start';
  step: 'request-pin' | 'verify-pin' | 'register' = 'request-pin';
  err: string;

  pinRegex = new RegExp('^[0-9]{6}$');

  token: string;

  focusedPin: string = null;

  colorCode: string;
  contrastColor: string;

  username: string;
  password: string;
  emailModel: string;

  @ViewChildren('pin', { read: ElementRef }) pinFields: QueryList<ElementRef>;
  pin0: string;
  pin1: string;
  pin2: string;
  pin3: string;
  pin4: string;
  pin5: string;

  resetLegacyMode = false;
  tokenTeamName: string;
  confirmPassword: string;

  invitationName: string;

  get isEchofin() {
    return this.brandingService.team.name === 'app';
  }

  get team() {
    return this.brandingService.team;
  }

  get verifiedInvitation() {
    return this.loginService.closedTeamVerifiedInvitation;
  }

  get teamName() {
    if (this.isEchofin) {
      return 'Echofin';
    }
    if (this.brandingService.team.label) {
      return this.brandingService.team.label;
    }
    return this.brandingService.team.name;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService,
    private profileServiceApi: ProfileRegisterServiceApi,
    private loginService: LoginService,
    private mobile: MobileService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.colorCode = this.getColorCode();
    this.contrastColor = this.getContrastYIQ(this.colorCode);

    if (!environment.production) {
      const rand = Math.floor(Math.random() * 10000) + 1000;
      this.username = `user.${rand}`;
      this.password = `user.${rand}`;
    }

    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      if (queryParams['inv']) {
        this.invitationName = queryParams['inv'];
        if (this.verifiedInvitation === null) {
          // if not yet verified (immediate load on /signup) send to normal invitation url first to validate
          this.router.navigate([`/invitation/${this.invitationName}`]);
        }
      }
    });
  }

  resetComponent() {
    this.status = 'start';
    this.invitationName = null;
    this.loginService.closedTeamVerifiedInvitation = null;
    this.router.navigate(['/signup'], { queryParams: null });
  }

  async inputPaste(event: any) {
    const items = event.clipboardData.items;
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'text/plain') {
          let text = event.clipboardData.getData('text');
          if (text) {
            text = text.trim();
          }
          if (!text || !this.pinRegex.test(text)) {
            event.preventDefault();
            return;
          }
          this.pin0 = text[0];
          this.pin1 = text[1];
          this.pin2 = text[2];
          this.pin3 = text[3];
          this.pin4 = text[4];
          this.pin5 = text[5];
        }
      }
    }
  }

  handlePinKeyDown(event: KeyboardEvent, digitOrder: number) {
    if (event.key === 'Tab' || event.key === 'Shift' || event.key === 'Control' || event.key === 'Alt' || event.key === 'CapsLock' || (event.ctrlKey || event.metaKey)) {
      return;
    }
    const numberRegex = new RegExp('^[0-9]$');
    if (!numberRegex.test(event.key)) {
      return;
    }
  }

  handlePinKeyUp(event: KeyboardEvent, digitOrder: number) {
    if (event.key === 'Tab' || event.key === 'Shift' || event.key === 'Control' || event.key === 'Alt' || event.key === 'CapsLock' || (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (event.key === 'Backspace' && digitOrder > 0) {
      (this.getPinElement(digitOrder - 1).nativeElement as HTMLInputElement).select();
      (this.getPinElement(digitOrder - 1).nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
      return;
    }
    const pinValue = (this.getPinElement(digitOrder).nativeElement as HTMLInputElement).value;
    const numberRegex = new RegExp('^[0-9]$');
    if (!numberRegex.test(pinValue)) {
      this[`pin${digitOrder}`] = '';
      return;
    }
    if (digitOrder < 5) {
      (this.getPinElement(digitOrder + 1).nativeElement as HTMLInputElement).select();
      (this.getPinElement(digitOrder + 1).nativeElement as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
    }
  }

  getPinElement(digit: number): ElementRef {
    const el = this.pinFields.find((el, i, a) =>
      (el.nativeElement as HTMLElement).id === `pin-${digit}`
    );
    return el;
  }

  focusPin(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
    (event.target as HTMLInputElement).setSelectionRange(0, 99999); /*For mobile devices*/
    this.focusedPin = (event.target as HTMLInputElement).id;
  }

  async requestEmail() {
    if (this.email.valid) {
      this.loading = true;

      this.profileServiceApi.RequestEmailWithPin(
        {
          email: this.email.value,
          joinTeam: this.brandingService.team.name,
          inviteCode: this.invitationName
        }).toPromise()
        .then((res) => {
          this.status = 'verify-pin';
          this.loading = false;
          this.step = 'verify-pin';
          this.emailModel = this.email.value;

          this.pin0 = undefined;
          this.pin1 = undefined;
          this.pin2 = undefined;
          this.pin3 = undefined;
          this.pin4 = undefined;
          this.pin5 = undefined;

          this.cd.detectChanges();
          (this.getPinElement(0).nativeElement as HTMLInputElement).focus();
        })
        .catch((resp) => {
          if (!resp.error) {
            this.status = 'fail-generic';
          }
          if (resp.error === 'EMAIL_ALREADY_EXIST') {
            this.status = 'EMAIL_ALREADY_EXIST';
          }
          if (resp.error === 'INVALID_EMAIL') {
            this.status = 'INVALID_EMAIL';
          }
          this.err = resp.message;
          this.loading = false;
        });
    }
  }

  async verifyPin(form: any) {
    this.loading = true;

    if (!this.pin0 || !this.pin1 || !this.pin2 || !this.pin3 || !this.pin4 || !this.pin5) {
      this.loading = false;
      return;
    }
    const pinValue = `${this.pin0.trim()}${this.pin1.trim()}${this.pin2.trim()}${this.pin3.trim()}${this.pin4.trim()}${this.pin5.trim()}`;
    if (!pinValue || !this.pinRegex.test(pinValue)) {
      this.loading = false;
      return;
    }

    this.profileServiceApi.VerifyPin(
      {
        email: this.email.value,
        pin: pinValue,
        joinTeam: this.brandingService.team.name
      }).toPromise()
      .then((res) => {
        this.status = 'register';
        this.loading = false;
        this.step = 'register';
        this.emailModel = this.email.value;
      })
      .catch((resp) => {
        if (!resp.error) {
          this.status = 'fail-generic';
        }
        if (resp.error === 'INVALID_PIN') {
          this.status = 'INVALID_PIN';
        }
        this.err = resp.message;
        this.loading = false;
      });
  }

  getColorCode() {
    if (this.brandingService.team &&
      this.brandingService.team.config &&
      this.brandingService.team.config.branding &&
      this.brandingService.team.config.branding.colorCode) {
      return this.brandingService.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  getContrastYIQ(hexcolor) {
    const hc = hexcolor.replace('#', '');
    const r = parseInt(hc.substr(0, 2), 16);
    const g = parseInt(hc.substr(2, 2), 16);
    const b = parseInt(hc.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 136) ? 'black' : 'white';
  }

  async register(form: any) {
    console.log(form);
    this.loading = true;

    this.profileServiceApi.VerifyPinAndRegister(
      {
        email: this.email.value,
        pin: `${this.pin0.trim()}${this.pin1.trim()}${this.pin2.trim()}${this.pin3.trim()}${this.pin4.trim()}${this.pin5.trim()}`,
        username: this.username,
        password: this.password,
        joinTeam: this.tokenTeamName ? this.tokenTeamName : this.brandingService.team.name,
        bypassLegacyUserCheck: this.resetLegacyMode
      }).toPromise()
      .then(async (res) => {
        this.status = 'success';

        if (this.mobile.isMobileDevice()) {
          const params: Params = { inv: this.invitationName };
          this.mobile.extraData = convertToParamMap(params);
          this.router.navigateByUrl('/mobile');
        } else {
          await this.loginService.login(this.username, this.password, this.brandingService.team.name, res.invitationState ? this.invitationName : null);
        }
      })
      .catch((resp) => {
        if (!resp.error) {
          this.status = 'fail-generic';
        }
        switch (resp.error) {
          case 'USERNAME_ALREADY_EXIST': {
            this.status = 'USERNAME_ALREADY_EXIST';
            break;
          }
          case 'EMAIL_ALREADY_EXIST': {
            this.status = 'EMAIL_ALREADY_EXIST';
            break;
          }
          case 'INVALID_PIN': {
            this.status = 'INVALID_PIN';
            break;
          }
          default: {
            this.status = 'fail-generic';
            break;
          }
        }
        this.err = resp.message;
        this.loading = false;
      });
  }
}
