<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="reject()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <form [formGroup]="inputForm" (ngSubmit)="confirm()">
          <div class="form-group">
            <input #input style="width:100%;" formControlName="inputText" (keydown)="onKeyDown($event)" type="text"
              class="form-control"
              [ngClass]="{'mask-text': maskText, 'is-invalid': submitted && inputForm.controls.inputText.errors }" />
            <div class="borderfx"></div>
            <div class="invalid-feedback">
              <ng-container *ngFor="let v of validatorTexts">
                <small class="d-block"
                  *ngIf="submitted && inputForm.controls.inputText.errors && inputForm.controls.inputText.errors[v.validatorName]">
                  {{v.validatorText}}</small>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-secondary" type="button" (click)="reject()">Cancel</button>
    <button class="btn btn-sm btn-success" type="button" (click)="confirm()">{{buttonLabel || 'Ok'}}</button>
  </div>
</div>