<h6>Profile</h6>
<div class="container">
  <div class="row">
    <div class="col-md-3">
      <div class="row">
        <div class="avatar-container col-md-12">
          <app-user-avatar [username]="me.username" [avatarUrl]="newMe.avatar"></app-user-avatar>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <button class="btn btn-success btn-sm btn-avatar" type="button" data-dismiss="modal"
              (click)="triggerFileSelection()">
              <i class="fas fa-upload" [ngClass]="{'fa-spin': updatingAvatar}"></i>
              Upload Avatar
            </button>
            <button class="btn btn-secondary btn-sm btn-avatar" type="button" data-dismiss="modal"
              (click)="setDefaultAvatar()">Reset
              Avatar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="form-group">
        <!-- Username -->
        <label for="company">Username:</label>
        <div class="input-group">
          <input type="text" #usernameInput class="form-control" #usernameControl="ngModel" [(ngModel)]="newMe.username"
            placeholder="Username" pattern="^[a-zA-Z0-9][a-zA-Z0-9_.-]*$" [disabled]="loading || !usernameEditable"
            required minlength="2" maxlength="18" autocomplete="off" spellcheck="false" (keydown.enter)="saveUsername()"
            [ngClass]="{'disabled text-muted': !usernameEditable}">
          <div class="input-group-append">
            <button type="button" *ngIf="!usernameEditable" title="Change Username" class="btn btn-secondary"
              (click)="editUsername()">
              <i class="fas fa-pencil"></i>
            </button>
            <button type="button" *ngIf="usernameEditable" class="btn btn-success" title="Change Username"
              (click)="saveUsername()" [disabled]="loading || newMe.username===me.username || usernameControl.invalid">
              <i class="fas fa-save"></i>
            </button>
            <button type="button" *ngIf="usernameEditable" class="btn btn-secondary color-danger"
              (click)="clearUsernameEdit()" [disabled]="loading">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div *ngIf="usernameControl.invalid" class="d-block invalid-feedback">
            <small *ngIf="usernameControl.errors.minlength||usernameControl.errors.required">Username must be at least 2
              characters long</small>
            <small *ngIf="usernameControl.errors.maxlength">Username must be up to 18 characters long</small>
            <small *ngIf="usernameControl.errors.pattern">Usernames include lower or upper case characters, numbers,
              dashes, dots and underscores and must start with a lower or upper case character or number</small>
          </div>
          <div class="borderfx"></div>
        </div>
      </div>
      <div class="form-group">
        <!-- Email -->
        <label for="email">Email:</label>
        <div class="input-group">
          <input autocomplete="off" class="form-control disabled text-muted" id="input3-group1" name="input3-group1"
            placeholder="Email" type="email" [(ngModel)]="newMe.email" readonly />

        </div>
      </div>
      <!-- Name -->
      <div class="form-group" style="display: none">
        <label for="company">Full Name:</label>
        <input class="form-control" id="company" placeholder="Enter your name" type="text" [(ngModel)]="newMe.fullname">
        <div class="borderfx"></div>
      </div>
      <!-- Markets -->

      <!-- <div class="form-group">
        <label for="bio">Bio:</label>
        <input class="form-control" id="bio" placeholder="Describe your self in a few words" type="text">
        <div class="borderfx"></div>
      </div> -->

      <!-- Markets -->
      <!-- <div class="form-group">
        <label for="interests">Interests:</label>
        <input class="form-control" id="interests" placeholder="Add up to 5 market interests" type="text">
        <div class="borderfx"></div>
      </div>  -->
      <!-- Timezone -->
      <!-- <div class="form-group">
        <label for="company">Timezone:</label>
        <select class="form-control ng-valid ng-dirty ng-touched" [(ngModel)]="newMe.timezone">
          <option disabled></option>
          <option *ngFor="let timezone of orderedTimezones" [value]="timezone.name">{{timezone.name}} |
            {{timezone.utcOffset.winterTime}}</option>
        </select>
      </div> -->
      <!-- Date Format
      <div class="form-group">
        <label for="company">Date format:</label>
        <select class="form-control ng-valid ng-dirty ng-touched" [(ngModel)]="newMe.dateFormat">
          <option [ngValue]="'mm/dd'">mm/dd</option>
          <option [ngValue]="'dd/mm'">dd/mm</option>
        </select>
      </div>-->
      <!-- Time Format -->
      <!-- <div class="form-group">
        <label for="company">Time format:</label>
        <select class="form-control ng-valid ng-dirty ng-touched" [(ngModel)]="timeFormat">
          <option [ngValue]="'12h'">12h</option>
          <option [ngValue]="'24h'">24h</option>
        </select>
      </div> -->
      <div class="form-group">
        <label for="company">Time format:</label>
        <div>
          <div class="btn-group">
            <button class="btn btn-time-format" (click)="toggle('12h')"
              [ngClass]="{'btn-success':timeFormat==='12h', 'btn-secondary':timeFormat!=='12h'}">
              <span>12h</span>
            </button>
            <button class="btn btn-time-format" (click)="toggle('24h')"
              [ngClass]="{'btn-success':timeFormat==='24h', 'btn-secondary':timeFormat!=='24h'}">
              <span>24h</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>