export enum TimelineType {
  TeamChatroomMessages = 1,
  PinboardMessages = 2,
  BookmarkMessages = 3,
  DirectOrGroupMessages = 4,
  MentionedMessages = 5,
  SearchMessages = 6,
  FilesMessages = 7,
  RepliedMessages = 8,
  PulseMessages = 9,
  SignalMessages = 10,
}
