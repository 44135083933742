import { OnDestroy, Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  template: `
      <div>
          Base Component - not to be used as is. Only to be extended.
      </div>
  `})
export abstract class BaseComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();

  subscribe<T>(subject: Observable<T>, action: (any: T) => void) {
    // seems that there may be cases that new subscriptions happen just before the component gets destroyed
    if (this.subscriptions) {
      this.subscriptions.add(subject.subscribe(action));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    delete this.subscriptions;
    this.subscriptions = null;
  }
}
