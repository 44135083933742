import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilestackModule } from '@filestack/angular';
import { DirectLabelPipe } from './pipes/direct-channel-members.pipe';
import { ElapsedTimePipe } from './pipes/elapsed-time.pipe';
import { FilterChannelsPipe } from './pipes/filter-channels.pipe';
import { FixHttpsPipe } from './pipes/fix-https.pipe';
import { HtmlSafePipe } from './pipes/html-safe.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { UnreadPipe } from './pipes/unread.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';

@NgModule({
  imports: [CommonModule, FilestackModule],
  declarations: [
    SafePipe,
    HtmlSafePipe,
    DirectLabelPipe,
    ElapsedTimePipe,
    FilterChannelsPipe,
    UnreadPipe,
    FixHttpsPipe,
    TruncateTextPipe,
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useClass: AppErrorHandler
    // }
  ],
  exports: [
    CommonModule,
    FilestackModule,

    SafePipe,
    HtmlSafePipe,
    DirectLabelPipe,
    ElapsedTimePipe,
    FilterChannelsPipe,
    UnreadPipe,
    FixHttpsPipe,
    TruncateTextPipe
  ],
})
export class SharedModule { }
