import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, convertToParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { MobileService } from './mobile.service';

@Injectable({
  providedIn: 'root'
})
export class DesktopGuard implements CanActivate {

  constructor(private mobile: MobileService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.mobile.isMobileDevice()) {
      this.mobile.path = (next.url && next.url.length > 0) ? next.url.map(s => s.path).join('/') : '';
      const query = { ...next.queryParams };
      this.mobile.extraData = convertToParamMap(query);

      return this.router.parseUrl('/mobile');

      // window.location.href = 'https://mobile.echofin.app/b4m0p8';
      // return false;
    }
    return true;
  }
}
