<!-- <div class="controls">
  <span title="TradingView Previewer">
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="19" viewBox="0 0 33 19" onload="onLoaded()">
      <path fill="#3BB3E4"
        d="M29.032 7.382a5.47 5.47 0 0 1 .963 2.872A4.502 4.502 0 0 1 28.5 19H6a5.98 5.98 0 0 1-4.222-1.737l9.546-7.556c.35.187.75.293 1.176.293a2.49 2.49 0 0 0 1.066-.238l4.55 3.981a2.5 2.5 0 1 0 4.711-.157l6.205-6.204zm-1.414-1.414l-6.204 6.204A2.494 2.494 0 0 0 20.5 12a2.49 2.49 0 0 0-1.066.238l-4.55-3.981a2.5 2.5 0 1 0-4.801-.118L.608 15.638A6 6 0 0 1 6.061 7a8.001 8.001 0 0 1 15.625-1.227A5.474 5.474 0 0 1 24.5 5c1.157 0 2.231.358 3.118.968z">
      </path>
    </svg>
  </span> -->
<!-- <div class="toolbar">
    <a href="javascript:void(0)" (click)="expandIFrame()" placement="top">
      <fa-icon icon="search-plus"></fa-icon>
    </a>
    <a [href]="getLinkUrl()" target="_blank" placement="top">
      <fa-icon icon="external-link-alt"></fa-icon>
    </a>
  </div> 
</div>-->
<!-- <div class="img-frame-container" (mouseenter)="loadIframe()" (mouseleave)="unloadIFrame()">
  <img class="img-thumbnail p-0" style="max-height: 450px;"
    [src]="getImageUrl()||'/assets/no-file.png'" onError="this.src='/assets/no-file.png';">
  <div class="">
    <strong class="link_title">{{urlData.title}}</strong>
  </div>
  <div>
    <span class="link_description">{{urlData.description}}</span>
  </div>
  <span *ngIf="iFrameVisible" title="TradingView Previewer" class="tv_preview">
    <fa-icon [icon]="['fas','search-plus']"></fa-icon> Open Interactive Chart
  </span>
</div> -->

<div class="img-frame-container tradingview-mousearea">
  <img class="img-thumbnail p-0" style="max-height: 450px;" [src]="getImageUrl()||'/assets/no-file.png'"
    onError="this.src='/assets/no-file.png';">
  <a href="javascript:void(0)" class="tradingview-link-modal tv_preview" [attr.data-tradingview-title]="urlData?.title"
    [attr.data-tradingview-url]="extractedUrl" *ngIf="showOpenButton" title="TradingView Chart Preview">
    <span>
      <i class="fas fa-search-plus"></i> Open Interactive Chart
    </span>
  </a>
</div>
<div class="tv_box">
  <a href="javascript:void(0)" class="tradingview-link-modal" [attr.data-tradingview-title]="urlData?.title"
    [attr.data-tradingview-url]="extractedUrl"><strong class="link_title">{{urlData.title}}</strong></a>
  <span class="link_description">{{urlData.description}}</span>
</div>