import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EvType } from './models/all.models';
import { SocketStatus } from './models/socket-status';

@Injectable({ providedIn: 'root' })
export abstract class SocketService {
  status$: BehaviorSubject<SocketStatus> = new BehaviorSubject<SocketStatus>(null);
  checkForVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  abstract connect(): Promise<void>;
  abstract disconnect();
  // idle deprecated in favor of alive()
  abstract idle(status);
  abstract alive();
  abstract focus(data);
  abstract typing(data: { room: string, participants?: string[], userId: string, username: string });
  abstract getStream(type: EvType): Subject<any>;
}
