<ng-container *ngIf="team; else loadingTeam">
  <ng-container *ngIf="showMenu">
    <div ngbDropdown placement="bottom">
      <!-- <div ngbDropdownToggle class="team-wrapper" -->
      <div ngbDropdownToggle class="team-wrapper"
        [ngStyle]="{'background-image':teamStyle.sideCoverUrl?'url('+teamStyle.sideCoverUrl+')':'none'}">
        <!--<div class="team-wrapper">-->
        <div class="flex-grow-1 text-white d-flex flex-column">
          <div *ngIf="teamStyle.showLabel !== false">
            <div>
              <strong class="team-title">{{team.label || team.name}}</strong>
              <ng-container *ngIf="team['features'].indexOf('TeamVerifiedBadge')>-1">
                <span class="verified-badge ml-15" title="Team verified" ngbTooltip="Team verified" placement="right"
                  container="body">
                  <i class="fad fa-badge-check"
                    style="--fa-primary-color: #fff; --fa-secondary-color: #35729e;--fa-primary-opacity: 1.0; --fa-secondary-opacity: 1.0;"></i>
                </span>
              </ng-container>
            </div>
            <div *ngIf="team.description">
              <small class="team-desc text-secondary">{{team.description}}</small>
            </div>
          </div>
        </div>
      </div>
      <div ngbDropdownMenu class="team-dropdown" style="width: 250px;">
        <button id="team-info" class="dropdown-item">
          <i class="far fa-info-circle fa-fw"></i> Team info
        </button>
        <div *ngIf="haveDashboardAccess">
          <div class="dropdown-divider"></div>
          <button id="team-dashboard" class="dropdown-item">
            <i class="far fa-chart-line fa-fw"></i> Admin Dashboard
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showMenu">
    <div>
      <div class="team-wrapper"
        [ngStyle]="{'background-image':teamStyle.sideCoverUrl?'url('+teamStyle.sideCoverUrl+')':'none'}">
        <div class="flex-grow-1 text-white d-flex flex-column">
          <div *ngIf="teamStyle.showLabel !== false">
            <strong class="team-title">{{team.label || team.name}}</strong>
            <div *ngIf="team.description">
              <small class="team-desc text-secondary">{{team.description}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="team && team.id==='tnm_8fa6148b'" class="personal-invite p-1">
    <button id="personal-invite" class="btn btn-primary btn-sm">Invite and get
      paid</button>
  </div>
  <div class="menu">
    <ng-container *ngIf="team.sidebarResolved; else noItems">
      <ng-container *ngIf="team.sidebarResolved.length; else noItems">
        <ng-container *ngFor="let item of team.sidebarResolved">
          <ng-container [ngSwitch]="item.itemType">
            <ng-container *ngSwitchCase="itemType[itemType.Divider]">
              <div class="menu-title">
                <span>{{item.label}}</span>
                <button id="dashboard-rooms" *ngIf="haveDashboardAccess && item.isFirstDivider" class="groupChatBtn">
                  <i class="fas fa-plus fa-fw"></i>
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="itemType[itemType.Channel]">
              <button *ngIf="!item.isHidden || (item.isHidden && item.permissions.user[rules.READ_MESSAGES])"
                id="sidebar-{{item.refId}}" class="btn btn-block menu-item sidebar-room {{item.domClassList}}"
                [ngClass]="{'focused': item.refId===focusedItem}" [style.border-color]="item.color? item.color : ''">
                <i class="fas fa-hashtag mr-1 text-muted"></i>
                <span>{{item.label}}</span>

                <span class="menu-item-icons">

                  <i id="sidebar-meeting" *ngIf="meetingIds[item.refId]" class="fas fa-signal-stream ml-2"
                    style="color:#bf371c" ngbTooltip="Live Meeting" container="body"></i>

                  <ng-container *ngIf="item.permissions">

                    <ng-container *ngIf="!item.permissions.members[rules.READ_MESSAGES]">
                      <ng-container *ngIf="item.permissions.user[rules.READ_MESSAGES]">
                        <ng-container *ngIf="item.subtype === 'Private'">
                          <i class="fal fa-user-unlock fa-fw ml-2" ngbTooltip="Private" container="body"></i>
                        </ng-container>
                        <ng-container *ngIf="item.subtype === 'Premium'">
                          <i class="fal fa-unlock-alt fa-fw ml-2" ngbTooltip="Premium" container="body"></i>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!item.permissions.user[rules.READ_MESSAGES]">
                        <ng-container *ngIf="item.subtype === 'Private'">
                          <i class="fal fa-user-lock fa-fw ml-2" ngbTooltip="Private" container="body"></i>
                        </ng-container>
                        <ng-container *ngIf="item.subtype === 'Premium'">
                          <i class="fal fa-lock-alt fa-fw ml-2" ngbTooltip="Premium" container="body"></i>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="item.permissions.user[rules.READ_MESSAGES]">
                    <app-room-message-count [roomId]="item.refId" [teamId]="item.teamId">
                    </app-room-message-count>
                  </ng-container>
                </span>
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="itemType[itemType.Frame]">
              <button id="sidebar-{{item.refId}}" [attr.data-frame-new]="item.newWindow"
                class="btn btn-block menu-item sidebar-frame {{item.domClassList}}"
                [ngClass]="{'focused': item.refId===focusedItem}" [style.border-color]="item.color">
                <i class="far fa-globe-americas mr-1 text-muted"></i>
                <span>{{item.label}}</span>
                <span class="menu-item-icons">
                  <ng-container *ngIf="item.newWindow">
                    <i class="fal fa-external-link fa-fw"></i>
                  </ng-container>
                </span>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<div class="menu-title">
  <span>Direct & Group chats</span>
  <button id="start-conversation-btn" *ngIf="team" class="groupChatBtn">
    <i class="fas fa-plus fa-fw"></i>
  </button>
</div>
<div class="menu" style="max-height: 350px;">
  <ng-container *ngIf="directs; else loading">
    <ng-container *ngIf="directs.length; else empty">
      <div class="chats-wrap">
        <div *ngFor="let direct of directs; trackBy: trackByFn" class="profile-chat">
          <div id="sidebar-{{direct.id}}" class="btn btn-block menu-item sidebar-direct {{direct.domClassList}}"
            [ngClass]="{'focused': direct.id===focusedItem}">

            <div class="direct-label">
              <i *ngIf="direct.type === 'Direct'" class="far fa-at fa-fw mr-1"></i>
              <i *ngIf="direct.type !== 'Direct'" class="fad fa-comments-alt fa-fw fa-swap-opacity text-muted mr-1"></i>
              <span>{{direct | directLabel:me.id}}</span>
              <span *ngIf="direct.type === 'Direct'" class="ml-2 dot {{colorClass(direct)}}"></span>
            </div>
            <!-- <small class="pl-2 text-muted float-right">{{direct.lastActiveDate|date:"shortTime"}}</small> -->
            <!-- <span *ngIf="panelsService.isVisible(direct.id)" class="ml-auto far fa-folder-open mt-1"></span> -->

            <span class="menu-item-icons dms-only">
              <i id="hide-direct" class="fas fa-times fa-fw" [attr.data-directid]="direct.id"></i>
            </span>

            <app-room-message-count id="team" [roomId]="direct.id">
            </app-room-message-count>
            <!-- <button class="hide-btn" (click)="hideDirect(direct.id)">
              <fa-icon [icon]="['fas', 'times']" [fixedWidth]="true" transform="shrink-5" class="text-muted mr-1">
              </fa-icon>
            </button> -->
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- <div class="menu mt-2 brand-wrap">
  <div class="brand-tag">Powered by <a href="https://www.echofin.com" target="_new">Echofin</a></div>
</div> -->

<ng-template #noChannel>
  <div class="card-body">
    <div class="p-4 text-center">Select a channel to join</div>
  </div>
</ng-template>
<ng-template #empty>
  <div class="p-3 text-center">
    <div class="alert alert-neutral small">No active conversations</div>
  </div>
</ng-template>
<ng-template #loadingTeam>
  <div class="text-white p-4">
    <div class="alert alert-neutral small">You haven't joined any teams yet. <a id="add-team-btn"
        href="javascript:void(0)">Join or create a team</a></div>
  </div>
</ng-template>
<ng-template #loading>
  <div class="p-4 text-center text-white">loading...</div>
</ng-template>
<ng-template #noTeams>
  <div class="p-3 text-center">
    <div class="alert alert-neutral small">No teams</div>
  </div>
</ng-template>
<ng-template #noItems>
  <div class="p-3 text-center">
    <div class="alert alert-neutral small">This team has currently no availble rooms to join</div>
  </div>
</ng-template>
<ng-template #teamBan>
  <div class="p-3 text-center">
    <div class="alert alert-neutral small">You've been banned from this team</div>
  </div>
</ng-template>
<ng-template #notSelected>
  <div class="p-3 text-center">
    <div class="alert alert-neutral small">No team is selected</div>
  </div>
</ng-template>
<ng-template #teamInfoModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Team Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissTeamInfo()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-teams">
    <app-team-info id="team" [team]="team" [showLeave]="true" (abandonTeam)="abandonTeam($event)"></app-team-info>
  </div>
  <div class="modal-footer text-center">
  </div>
</ng-template>