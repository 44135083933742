<div class="text-center">
    <span *ngIf="!mutedUsers || mutedUsers.length === 0">You have not muted any user</span>
    <span *ngIf="mutedUsers && mutedUsers.length > 0">Muted Users</span>
    <div class="mute-wrapper">
        <ng-container *ngFor="let user of mutedUsers">
            <div class="re-row">
                <div>
                    <app-user-avatar [username]="user.username" [avatarUrl]="user.avatar"
                        class="re-avatar d-inline-block">
                    </app-user-avatar>
                    <div class="d-inline-block">{{ user.username }}</div>
                </div>
                <i class="fas fa-times fa-fw hand-cursor" (click)="unmute(user.id)"></i>
            </div>
        </ng-container>
    </div>
</div>