import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocketIoModule } from 'ngx-socket-io';
import { WebStorageModule } from '@efaps/ngx-store';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { ChatModule } from './chat/chat.module';
import { UserInputComponent } from './chat/user-input/user-input.component';
import { CHAT_ROUTES } from './chat/_commons/chat.routes';
import { ErrorComponent } from './error/error.component';
import { IntroModule } from './intro/intro.module';
import { INTRO_ROUTES } from './intro/_commons/intro.routes';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppComponent } from './_app/app.component';
import { CoreModule } from './_core/core.module';
import { ClientErrorHandler } from './_core/error-handlers/error.handler';
import { SharedModule } from './_shared/shared.module';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    WebStorageModule,
    SocketIoModule,
    NgxStripeModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: 'logout',
          component: LogoutComponent,
        }, {
          path: 'error',
          component: ErrorComponent,
        }, {
          path: 'not-found',
          component: NotFoundComponent,
        },
        ...INTRO_ROUTES,
        ...CHAT_ROUTES,
        {
          path: '**',
          redirectTo: 'not-found'
        }
      ],
      {
        // enableTracing: true
      }),
    IntroModule,
    ChatModule,
  ],
  declarations: [
    AppComponent,
    UserInputComponent,
    LogoutComponent,
    ErrorComponent,
    NotFoundComponent
  ],
  bootstrap: [AppComponent],
  exports: [],
  providers: [{
    provide: ErrorHandler,
    useClass: ClientErrorHandler
  }]
})
export class AppModule { }
