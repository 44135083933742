<div *ngIf="fullView" class="lbx-back" [class.full-view]="fullView"></div>
<div class="lbx-container">
  <a href="javascript:void(0)" class="lightbox-thumb" [attr.data-messageId]="messageId" [attr.data-type]="type">
    <ng-container *ngIf="mime==='application/pdf'">
      <div class="file-block">
        <span class="file-icon">
          <i class="fad fa-file-pdf fa-2x"></i>
        </span>
        <small>{{filename}}</small>
      </div>
    </ng-container>
    <ng-container *ngIf="mime!=='application/pdf' && thumb && !hide">
      <img class="img-thumbnail max-h240 p-0 " [src]="thumb" (error)="hideImage()" [alt]="filename">
    </ng-container>
  </a>
  <div [class.full-view]="fullView">
    <div class="lbx-content" [class.full-view]="fullView">
      <div *ngIf="fullView && !loaded" class="loader-container">
        <i class="fal fa-circle-notch fa-fw fa-spin"></i>
      </div>
      <a href="javascript:void(0)" class="lightbox-full" [attr.data-messageId]="messageId" [attr.data-type]="type">
        <ng-container *ngIf="mime==='application/pdf'">
          <iframe *ngIf="fullView" class="pdf-viewer" [src]="src | safe" (load)="onFullViewLoaded()"></iframe>
        </ng-container>
        <ng-container *ngIf="mime!=='application/pdf' && src && !hide">
          <img *ngIf="fullView" class="lbx-full-img" [src]="src" (error)="hideImage()" (load)="onFullViewLoaded()"
            [alt]="filename">
        </ng-container>
      </a>
    </div>
    <!-- full view controls -->
    <div *ngIf="fullView" class="controls" [class.full-view]="fullView">
      <a class="closeBtn" class="lightbox-full" [attr.data-messageId]="messageId" [attr.data-type]="type"
        title="Close fullscreen view">
        <i class="fas fa-times"></i>
      </a>
      <a class="downBtn" (click)="downloadFile()" title="Download image">
        <i class="fas fa-download"></i>
        <small>Download this file</small>
      </a>
      <div><small>Filename: {{filename}}</small></div>
    </div>
  </div>
</div>