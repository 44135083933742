import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { TeamService } from '../../../_core/services/team.service';
import { MessageCountService } from '../services/message-count.service';
import { environment } from '../../../../environments/environment';
import { Team } from '../../../_shared/models/teams/team';

@Component({
  selector: 'app-pinboard-message-count',
  templateUrl: './pinboard-message-count.component.html'
})
export class PinboardMessageCountComponent implements OnInit {
  @Input() teamId: string;

  team: Team;
  unreadCount: string = null;

  constructor(
    private messageCountService: MessageCountService,
    private teamService: TeamService
  ) { }

  ngOnInit() {
    this.team = this.teamService.getTeam(this.teamId);
    this.unreadCount = this.messageCountService.convertMessageCountForView(this.team.pinboardUnread);
    this.messageCountService.pinboardMessageCounterChanged$.subscribe((team: Team) => {
      if (this.teamId === team.id) {
        this.unreadCount = this.messageCountService.convertMessageCountForView(team.pinboardUnread);
      }
    });
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[TEAM-MESSAGE-COUNT]', 'color:#2996F3', ...arg);
    }
  }
}
