import { Injectable, OnDestroy } from '@angular/core';
import { interval, Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntervalUpdateTimerService implements OnDestroy {

  showRelativeTimeFormat = new BehaviorSubject<boolean>(true);
  chatUpdateInterval: Observable<number>;
  private destroyed: Subject<null> = new Subject<null>();

  private sub;

  constructor() {
    this.chatUpdateInterval = interval(10000).pipe(takeUntil(this.destroyed));
    this.sub = this.chatUpdateInterval.subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next();
  }
}
