import { Component, HostBinding, Input, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Events } from '@echofin/libraries';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { Message } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { BookmarkMessagesService } from './services/bookmark-messages.service';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { MessageResp } from '@echofin/libraries/api/message/models/message-resp';

@Component({
  selector: 'app-panel-bookmark-messages',
  templateUrl: './panel-bookmark-messages.component.html',
  styleUrls: ['./panel-bookmark-messages.component.scss']
})
export class PanelBookmarkMessagesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @ViewChild('timeline') timeline: TimelineComponent;
  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  initialMessages: Message[];

  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.BookmarkMessages;

  config: any;
  // isConfigOpened: boolean = false;
  slOrder: 'MessageDate' | 'CreatedDate' = 'MessageDate';

  isLoading: boolean = false;
  canPin: boolean;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private messageService: MessageService,
    private bookmarkMessagesService: BookmarkMessagesService,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  async ngOnInit() {
    console.log(this.panel.id);
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelTitle$.next([this.panel.id, 'Bookmarks']);
    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.registerEvents();

    this.slOrder = this.panelsService.getMessageOrder(this.panel.id).order;
    this.isLoading = true;
    await this.bookmarkMessagesService.loadBookmarkMessages(this.panel.id, this.slOrder, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  registerEvents() {
    this.subscribe(this.bookmarkMessagesService.bookmarkMessageAdded$, b => {
      if (this.teamService.activeTeam.id === b.teamId) {
        this.onBookmarkMessageAdded(b);
      }
    });
    this.subscribe(this.bookmarkMessagesService.bookmarkMessageRemoved$, (b: Events.BookmarkMessageRemoved) => {
      this.onBookmarkMessageRemoved(b);
    });
    this.subscribe(this.messageService.messageReplacementReceived$, message => this.processReplacementMessage(message.replace as Message));
    this.subscribe(this.messageService.messageDeleted$, messageDeleted => { if (messageDeleted) { this.deleteMessage(messageDeleted.messageId); } });
  }

  onBookmarkMessageAdded(bookmarkMessage: Events.BookmarkMessageAdded) {
    const m = MessageHelpers.mapMessageResp(bookmarkMessage.message as unknown as MessageResp);
    this.messageService.messages[this.panel.id] = MessageHelpers.sortMessages([...this.messageService.messages[this.panel.id], m]);
    this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  onBookmarkMessageRemoved(bookmarkMessage: Events.BookmarkMessageRemoved) {
    const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === bookmarkMessage.messageId.toString());
    if (messageIndex > -1) {
      this.messageService.messages[this.panel.id].splice(messageIndex, 1);
    }
    this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  processReplacementMessage(message: Message) {
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === message.id);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== message.id);
        this.messageService.messages[this.panel.id].push(message);
        this.messageService.messages[this.panel.id] = MessageHelpers.organizeBookmarkMessages(this.messageService.messages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  deleteMessage(messageId: string) {
    if (messageId === null || messageId === undefined) {
      return;
    }
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === messageId);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== messageId);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }

  resetPanel() {
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.messages[this.panel.id] = [];
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  async changeOrder() {
    this.resetPanel();
    this.panelsService.setMessageOrder({ id: this.panel.id, order: this.slOrder });
    await this.bookmarkMessagesService.loadBookmarkMessages(this.panel.id, this.slOrder, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }
}
