import { Component, Input, OnDestroy, ElementRef, ChangeDetectionStrategy, SimpleChanges, OnChanges, ChangeDetectorRef, ViewChild } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-url-attachment',
  templateUrl: './url-attachment.component.html',
  styleUrls: ['./url-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlAttachmentComponent implements OnChanges, OnDestroy {

  @ViewChild('generalVideoElement') generalVideoElement: ElementRef;
  @ViewChild('youTubeElement') youTubeElement: ElementRef;

  // YOUTUBE is checked through urlData.type
  private supportedVideoProviders: string[] = ['dailymotion', 'vimeo'];

  @Input() urlData: any;
  @Input() fullView: boolean;
  @Input() messageId: string;

  embeddedVideoHtml: any;
  fullUrl: string;
  isVideoUrl = false;

  constructor(
    private embedService: EmbedVideoService,
    private hostElement: ElementRef,
    private cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['urlData']) {
      if (!changes['urlData'].currentValue) { return; }

      this.isVideoUrl = this.isUrlVideo(changes['urlData'].currentValue.resourceType, changes['urlData'].currentValue.siteName);

      const url = changes['urlData'].currentValue.url;
      if (url) {
        this.fullUrl = url.indexOf('http') >= 0
          ? url
          : `https://${url}`;
      } else if (changes['urlData'].currentValue.type === 'YOUTUBE') {
        this.fullUrl = `https://www.youtube.com/watch?v=${changes['urlData'].currentValue.id}`;
      }
      this.cd.detectChanges();
    }
  }

  isUrlVideo(type: string, siteName: string): boolean {
    const typeLower = (type || '').toLowerCase();
    return typeLower.indexOf('video') > -1
      && this.isSupportedProvider(siteName);
  }

  getEmbeddedVideoHtml(url: string) {
    if (!url) {
      return;
    }
    this.embeddedVideoHtml = this.embedService.embed(url);
    return this.embeddedVideoHtml;
  }

  isSupportedProvider(siteName: string) {
    const nameLower = (siteName || '').toLowerCase();
    return this.supportedVideoProviders.indexOf(nameLower) > -1;
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
