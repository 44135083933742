import { Component, ElementRef, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-content-cashtag',
  templateUrl: './content-cashtag.component.html',
  styleUrls: ['./content-cashtag.component.scss']
})
export class ContentCashtagComponent implements OnDestroy {
  @Input() symbol: string;

  constructor(private hostElement: ElementRef) { }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
