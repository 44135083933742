<div *ngIf="reactions && reactions.length" class="reactions-div">
  <span class="badge reaction reaction-pill" [attr.data-messageId]="messageId"
    *ngFor="let reactionType of groupedReactionsKeys" [attr.data-reaction]="reactionType" #reactions
    [ngClass]="{'hovering': reactionType===mouseoverReaction}"><span class="emoji">{{reactionType}}</span>
    <span class="count">{{groupedReactions[reactionType].length}}</span>
  </span>
  <i class="fas fa-smile-plus fa-xs reactions-icon" [attr.data-messageId]="messageId"></i>
</div>
<div class="replies-div" *ngIf="!!reply && !isReplyPanel">
  <span class="badge reaction reply-pill" [attr.data-replyPanelId]="replyPanelId">
    <span class="reply-dot"
      [ngStyle]="{'color': reply.parent===true ? colorizeReply(this.messageId) : colorizeReply(reply.parentId)}">&#x25cf;</span>
    <span class="fas fa-reply fa-xs d-inline-block"></span>
  </span>
</div>