import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageTextFormatterService } from '../../../../_core/services/message-text-formatter.service';
import { UserInputService } from '../../../../_core/services/user-input.service';
import { MessageStatus } from '../../../../_shared/models/commons/message';
import { CustomValidators } from '../../../../_shared/validators/custom-validators';

@Component({
  selector: 'app-content-secret',
  templateUrl: './content-secret.component.html',
  styleUrls: ['./content-secret.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentSecretComponent implements OnDestroy {

  @Input() text: string;
  decryptedMessage: string;
  parsedHtml: SafeHtml = '';
  messageStatuses = MessageStatus;

  constructor(
    private userInput: UserInputService,
    private mtf: MessageTextFormatterService,
    private domSanitizer: DomSanitizer,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) { }

  async decryptMessage() {
    const password = await this.userInput.getInput({ title: 'Encryption key', maskText: true, validators: [CustomValidators.validKey(this.mtf, this.text)], validatorTexts: [{ validatorName: 'validKey', validatorText: 'Invalid Key!' }] });
    if (password) {
      this.decryptedMessage = this.mtf.decrypt(this.text, password);

      // SECRET MUST NOT SUPPORT FORMATTING FEATURES - SIMPLE TEXT - TO BE FUTURE BUG PROOF
      // const parsedText = this.mtf.parseMessageTextForDisplay({ text: this.decryptedMessage });
      const parsedText = this.decryptedMessage;
      this.parsedHtml = this.domSanitizer.bypassSecurityTrustHtml(parsedText);
      this.ch.detectChanges();
    }
  }

  hideSecret() {
    this.decryptedMessage = null;
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
