import { Injectable } from '@angular/core';
import { CardPayment } from '../../_shared/models/commons/card-payment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor() { }

  async makePayment(params: CardPayment): Promise<any> {
    const res = await new Promise(resolve => setTimeout(resolve, 1000));
  }
}
