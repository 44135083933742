<div class="modal-header">
    <h4 class="modal-title">
        <i class="fal fa-envelope-open-text fa-lg"></i> Invitation
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('manual')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-teams">
    <ng-container *ngIf="!loading && !verificationStatus && invitation">
        <div>
            <span *ngIf="invitation.expireAt">This invitation expires at
                {{invitation.expireAt|date:"MMM dd, HH:mm"}}</span>
        </div>
        <div *ngIf="alreadyInTeam" class="break-word">
            <span>You have already joined <span class="text-primary">{{teamLabel}}</span></span>
        </div>
        <div *ngIf="!alreadyInTeam" class="pt-3">
            <app-team-info [team]="team"></app-team-info>
        </div>
        <div class="mt-3">
            <span *ngIf="invitation.role && invitation.roleName && (!team.member || !team.member.roleId)">You will be
                assigned the <span class="text-primary">{{roleName}}</span> role</span>
            <span *ngIf="invitation.role && invitation.roleName && team.member && team.member.roleId">Your role in the
                team will change. Your new role will be <span class="text-primary">{{roleName}}</span></span>
        </div>
        <div *ngIf="additionalChatroomNames && additionalChatroomNames.length" class="mt-3">
            You will get access to <span class="text-primary">{{additionalChatroomNamesFormatted}}</span>, along with
            all the public chatrooms of the team
        </div>
        <div *ngIf="!alreadyInTeam && (!additionalChatroomNames || !additionalChatroomNames.length)" class="mt-3">
            You will get access to the team's public chatrooms
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && (verificationStatus==='INVITATION_EXPIRED' || verificationStatus==='NOT_FOUND')">
        <div>
            <span>Sorry, this invitation has expired :(</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && verificationStatus==='INVITATION_FULL'">
        <div>
            <span>Sorry, all available spots for this invitation are already taken :(</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && verificationStatus==='USER_BANNED'">
        <div>
            <span>You are banned from this team and cannot use an invitation.</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && verificationStatus==='INVITATION_ALREADY_ACCEPTED'">
        <div>
            <span>You have already accepted this invitation. Enjoy!</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && verificationStatus==='INVALID_EMAIL'">
        <div>
            <span>Sorry. This invitation is not associated with your email address.</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && verificationStatus==='FAIL_GENERIC'">
        <div>
            <span>It seems that we cannot validate the Invitation right now. Please try again later.</span>
        </div>
    </ng-container>
</div>

<div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-success" *ngIf="!verificationStatus" [disabled]="loading"
        (click)="acceptInvitation()">Accept
        <i *ngIf="!accepting" class="fas fa-check fa-fw"></i>
        <i *ngIf="accepting" class="far fa-sync-alt fa-fw fa-spin"></i>
    </button>
    <button type="button" class="btn btn-secondary" *ngIf="verificationStatus" (click)="close()">Close</button>
</div>