import { Component, ElementRef, HostBinding, Input, OnDestroy, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Events } from '@echofin/libraries';
import { PanelsService } from '../../_core/services/panels.service';
import { RoomService } from '../../_core/services/room.service';
import { TeamService } from '../../_core/services/team.service';
import { Frame } from '../../_shared/models/frame/frame';
import { Panel } from '../../_shared/models/room/channel';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-panel-service',
  templateUrl: './panel-service.component.html',
  styleUrls: ['./panel-service.component.scss'],
})
export class PanelServiceComponent extends BaseComponent implements OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;
  frame: Frame;
  hasLoaded = false;
  setupError = false;

  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  @Input()
  dragging = false;

  private localPanel: Panel;

  @Input()
  set panel(p: Panel) {
    if (p) {
      this.localPanel = p;
      if (!p.isHidden && !this.hasLoaded && !this.setupError) {
        try {
          this.setup();
        } catch (ex) {
          this.setupError = true;
        }
        this.hasLoaded = true;
      }
    }
  }
  get panel() {
    return this.localPanel;
  }

  unlockChatrooms;

  constructor(
    private roomService: RoomService,
    private panelsService: PanelsService,
    private teamService: TeamService,
    private sanitizer: DomSanitizer,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panel ? (this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id) : null;
  }

  get url() {
    const safeUrl = this.sanitizer.sanitize(SecurityContext.URL, this.frame.url);
    return safeUrl;
  }

  setup() {
    this.frame = this.teamService.getFrame(this.panel.id);
    this.panelsService.panelTitle$.next([this.panel.id, this.frame?.name ?? '']);

    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    this.caclulatePermissions();

    this.subscribe(
      this.teamService.frameUpdated$,
      (frame: Events.FrameUpdated) => {
        if (frame.frame.id !== this.panel.id) {
          return;
        }
        this.frame = this.teamService.getFrame(this.panel.id);
        this.panelsService.panelTitle$.next([this.panel.id, this.frame?.name ?? '']);
        this.caclulatePermissions();
      });

    this.subscribe(this.panelsService.refreshPanelState$, ([panelId, mustRefresh]) => {
      if (panelId === this.panel.id && mustRefresh) {
        this.iframe.nativeElement.src = (this.iframe.nativeElement as HTMLIFrameElement).src;
      }
    });

    this.subscribe(
      this.roomService.teamRoomUpdated$,
      (data: Events.RoomPermUpdated) => {
        this.caclulatePermissions();
      });

    this.subscribe(
      this.roomService.teamRoomRemoved$,
      (data: Events.RoomPermUpdated) => {
        this.caclulatePermissions();
      });
  }

  caclulatePermissions() {
    if (this.frame['billingChatrooms'].length) {
      const roomPermissions = this.teamService.activeTeam.chatrooms
        .filter(c => this.frame['billingChatrooms'].indexOf(c.id) > -1)
        .map(c => c.permissions.user.read_messages);

      this.frame['hasAccess'] = roomPermissions.some(x => x) || roomPermissions.length === 0;

      this.unlockChatrooms = this.teamService.activeTeam.chatrooms
        .filter(c => this.frame['billingChatrooms'].indexOf(c.id) > -1)
        .map(c => `#${c.name}`)
        .join(', ');
    } else {
      this.frame['hasAccess'] = true;
    }

    if (!this.frame['hasAccess']) {
      this.panelsService.refreshPanelState$.next([this.panel.id, false]);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }

    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }

  onLoad() {
    this.panelsService.refreshPanelState$.next([this.panel.id, false]);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
    // this.panelsService.hide(this.panel);
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }
}
