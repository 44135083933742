import { Component, HostBinding, Input, NgZone, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Events } from '@echofin/libraries';
import { MessageService } from '../../_core/services/message.service';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { Message } from '../../_shared/models/commons/message';
import { Panel } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { PinboardMessagesService } from './services/pinboard-messages.service';
import { BaseComponent } from '../base-component';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { MessageHelpers } from '../../_shared/helpers/message-helpers';
import { MessageResp } from '@echofin/libraries/api/message/models/message-resp';

@Component({
  selector: 'app-panel-pinboard-messages',
  templateUrl: './panel-pinboard-messages.component.html',
  styleUrls: ['./panel-pinboard-messages.component.scss']
})
export class PanelPinboardMessagesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;

  @HostBinding('class.panel-max') get isMax() { return this.panelsService.isMaximizedPanel(this.panel); }
  @HostBinding('hidden') get isHidden() { return this.panel.isHidden; }

  @ViewChild('timeline') timeline: TimelineComponent;

  initialMessages: Message[];

  teamId: string;
  loadingMessages: boolean = false;
  lastId: string = null;

  timelineType: TimelineType = TimelineType.PinboardMessages;

  config: any;
  // isConfigOpened: boolean = false;
  slOrder: 'MessageDate' | 'CreatedDate' = 'MessageDate';
  isLoading: boolean = false;
  canPin: boolean;

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private messageService: MessageService,
    private pinboardMessagesService: PinboardMessagesService,
    private ch: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {
    super();
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.panel.teamId] === this.panel.id;
  }

  get hasMoreMessages() {
    return (this.panel && this.panel.id) ? this.messageService.hasMoreMessages[this.panel.id] : true;
  }

  async ngOnInit() {
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.hasMoreMessagesBelow[this.panel.id] = false;

    this.panelsService.panelElements[this.panel.id] = this.hostElement;

    this.panelsService.panelTitle$.next([this.panel.id, 'Pinboard']);

    this.teamId = this.panel.teamId;
    const team = this.teamService.teams.find(t => t.id === this.panel.teamId);
    this.canPin = team && team.permissions && team.permissions.pinboard;

    this.registerEvents();

    this.slOrder = this.panelsService.getMessageOrder(this.panel.id).order;

    this.isLoading = true;
    await this.pinboardMessagesService.loadPinboardMessages(this.panel.id, this.slOrder, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  registerEvents() {
    this.subscribe(this.pinboardMessagesService.pinboardMessageAdded$, (b: Events.PinboardMessageAdded) => {
      if (this.teamService.activeTeam.id === b.teamId) {
        console.log('pinboardMessageAdded', b.message.pinned);
        this.onPinboardMessageAdded(b);
      }
    });
    this.subscribe(this.pinboardMessagesService.pinboardMessageRemoved$, (b: Events.PinboardMessageRemoved) => {
      this.onPinboardMessageRemoved(b);
    });
    this.subscribe(this.messageService.messageReplacementReceived$, message => this.processReplacementMessage(message.replace as Message));
    this.subscribe(this.messageService.messageDeleted$, messageDeleted => this.deleteMessage(messageDeleted.messageId));
  }

  onPinboardMessageAdded(pinboardMessage: Events.PinboardMessageAdded) {
    const m = MessageHelpers.mapMessageResp(pinboardMessage.message as unknown as MessageResp);
    this.messageService.messages[this.panel.id] = MessageHelpers.sortMessages([...this.messageService.messages[this.panel.id], m]);
    this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  onPinboardMessageRemoved(pinboardMessage: Events.PinboardMessageRemoved) {
    if (this.teamService.activeTeam.id === pinboardMessage.teamId) {
      const messageIndex = this.messageService.messages[this.panel.id].findIndex(m => m.id === pinboardMessage.messageId.toString());
      if (messageIndex > -1) {
        this.messageService.messages[this.panel.id].splice(messageIndex, 1);
      }
      this.messageService.messages[this.panel.id] = MessageHelpers.collapsifyMessages(this.messageService.messages[this.panel.id]);
      this.initialMessages = this.messageService.messages[this.panel.id];
    }
  }

  deleteMessage(messageId: string) {
    if (messageId === null || messageId === undefined) {
      return;
    }
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === messageId);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== messageId);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  processReplacementMessage(message: Message) {
    if (this.messageService.messages[this.panel.id]) {
      const findMessage: Message[] = this.messageService.messages[this.panel.id].filter(m => m.id === message.id);
      if (findMessage.length > 0) {
        this.messageService.messages[this.panel.id] = this.messageService.messages[this.panel.id].filter(m => m.id !== message.id);
        this.messageService.messages[this.panel.id].push(message);
        this.messageService.messages[this.panel.id] = MessageHelpers.organizePinboardMessages(this.messageService.messages[this.panel.id]);
        this.initialMessages = this.messageService.messages[this.panel.id];
      }
    }
  }

  maximize() {
    this.panelsService.maximize(this.panel);
  }

  minimize() {
    this.panelsService.minimizeAll(this.panel.teamId);
  }

  close() {
    this.panelsService.minimizeAll(this.panel.teamId);
    this.panelsService.close(this.panel);
  }

  resetPanel() {
    this.messageService.hasMoreMessages[this.panel.id] = true;
    this.messageService.messages[this.panel.id] = [];
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

  async changeOrder() {
    this.resetPanel();
    this.panelsService.setMessageOrder({ id: this.panel.id, order: this.slOrder });
    await this.pinboardMessagesService.loadPinboardMessages(this.panel.id, this.slOrder, this.panel.teamId);
    this.initialMessages = this.messageService.messages[this.panel.id];
  }

}
