import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from '../../../_core/services/team.service';

@Component({
  selector: 'app-service-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {
  
  @Input() frame: any;

  unlockChatrooms: string;

  get m() {
    return this.activeModal;
  }

  constructor(
    private teamService: TeamService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.unlockChatrooms = this.teamService.activeTeam.chatrooms
      .filter(c => this.frame['billingChatrooms'].indexOf(c.id) > -1)
      .map(c => `#${c.name}`)
      .join(', ');
  }

}
