import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamTeamsServiceApi } from '@echofin/libraries';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingService } from '../../_core/services/tracking.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { TeamService } from '../../_core/services/team.service';
import { Team } from '../../_shared/models/teams/team';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'app-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.scss'],
})
export class JoinTeamComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('search') searchInput: ElementRef;

  loading = false;
  teamToJoin: Team;
  searchedAlready = false;
  alreadyJoined = false;
  isBanned = false;
  errorMessage = '';

  searchField: FormControl = new FormControl();
  acceptedTermsField: FormControl = new FormControl(false, Validators.requiredTrue);
  createTeam = false;
  teams: Team[] = [];

  routeSub: Subscription;

  initTeamLoading = true;

  get teamToJoinLabel() {
    return this.teamToJoin ? (this.teamToJoin.label && this.teamToJoin.label.length ? this.teamToJoin.label : this.teamToJoin.name) : '';
  }

  constructor(
    public activeModal: NgbActiveModal,
    private teamService: TeamService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private teamApi: TeamTeamsServiceApi,
    private tracking: TrackingService
  ) {
    super();
  }

  async ngOnInit() {
    const t = this.route.snapshot.queryParams['team'];
    if (t) {
      this.searchField.patchValue(t);
      await this.validateTeamToJoin();
    } else {
      this.initTeamLoading = false;
    }

    this.routeSub = this.route.queryParams.subscribe(async (queryParams) => {
      if (queryParams['team']) {
        this.searchField.patchValue(queryParams['team']);
        await this.validateTeamToJoin();
      }
      this.inputFocus();
    });
    this.inputFocus();
  }

  inputFocus() {
    setTimeout(
      () => {
        if (this.searchInput && this.searchInput.nativeElement) {
          this.searchInput.nativeElement.focus();
        }
      },
      110);
  }

  async searchTeams() {
    this.router.navigate(['/', 'join'], { queryParams: { team: this.searchField.value } });
  }

  async validateTeamToJoin() {
    this.initTeamLoading = true;
    const term = this.searchField.value;
    const teams = await this.teamService.searchTeams(term);

    this.teams = teams.filter((t: Team) =>
      (this.teamService.teams.map(tm => tm.name).indexOf(t.name) === -1) &&
      (t.name.toLowerCase() === term.toLowerCase()));

    if (teams.length === 1 && teams[0].name.toLowerCase() === term.toLowerCase() && this.teams.length === 0) {
      this.alreadyJoined = true;
    }

    if (this.teams.length === 1) {
      this.isBanned = await this.teamApi.GetIfBanned(this.teams[0].id).toPromise();
      this.teamToJoin = this.teams[0];
    }
    this.searchedAlready = true;
    this.initTeamLoading = false;
  }

  async join() {
    if (this.acceptedTermsField.value) {
      this.loading = true;

      this.teamService.teamAdded$
        .pipe(
          skipWhile(t => t.name !== this.teamToJoin.name),
          take(1))
        .subscribe(async t => {
          await this.activeModal.dismiss();
          this.router.navigateByUrl(`/${t.name}`);
        });

      this.tracking.trackEvent('join', { teamname: this.teamToJoin.name, teamid: this.teamToJoin.id });
      await this.teamService
        .joinTeam(this.teamToJoin.id, this.acceptedTermsField.value)
        .catch((resp) => {
          console.log(resp);
          if (resp && resp.error) {
            if (resp.error.code === 'INVALID_EMAIL') {
              this.errorMessage = resp.error.code;
            } else {
              this.toastr.error(resp.error.message);
            }
          }
          this.loading = false;
        });
    }
  }

  backToSearch() {
    this.searchField.patchValue('');
    this.searchedAlready = false;
    this.alreadyJoined = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.routeSub.unsubscribe();
  }
}
