<div class="chatroom-wrapper">
  <ng-container *ngIf="hasLoaded && room && panel && panel.visible && !setupError; else setupErrorTpl">
    <ng-container *ngIf="room.permissions.user[rules.READ_MESSAGES]; else lockTpl">
      <div #topic class="topic" [ngStyle]="roomStyle">
        {{room.topic}}
      </div>
      <!-- <div class="flex-grow-1"></div> -->
      <app-timeline #timeline [isLocked]="!room.permissions.user[rules.READ_MESSAGES]" [messages]="initialMessages"
        (requestTimeframe)="requestTimeframe($event)"
        [initialTimelineMessage]="(room.type===roomType.Team)?initialRoomMessage:((room.type===roomType.Group)?initialGroupMessage:initialDirectMessage+(room | directLabel:me.id))"
        [timelineType]="timelineType" [canDelete]="canDelete" [canPin]="canPin"
        [canPost]="room.permissions.user[rules.POST_MESSAGES]" [panelId]="panel.id" [teamId]="panel.teamId"
        [unreadCount]="room.unread" [unreadTimestamp]="room.lastReadTimestamp" [roomLastActive]="room.lastActive"
        [topicHeight]="room.topic?topic.clientHeight:0">
      </app-timeline>
    </ng-container>
    <app-chatbox [room]="room" [conversationJustStarted]="conversationJustStarted" (focus)="chatboxFocus()"
      (blur)="chatboxBlur()" (roomJustCreated)="roomJustCreated($event)" [panelId]="panel.id" (send)="msgSent()">
    </app-chatbox>
  </ng-container>
</div>

<ng-template #setupErrorTpl>
  <app-panel-error [panel]="panel"></app-panel-error>
</ng-template>
<ng-template #lockTpl>
  <app-lock-panel *ngIf="hasLoaded && room && !room.permissions.user[rules.READ_MESSAGES]" [room]="room">
  </app-lock-panel>
</ng-template>